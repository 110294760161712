import { Injectable } from "@angular/core";
import { BaseRestService } from "./BaseRestService";
import { LocalStorageService } from "../LocalStorageService";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { CameraResponseModel, CameraRequestModel, CameraUpdateSortPushModel } from "src/app/api-handling/models/CameraResponseModel";
import { map } from "rxjs";
import { plainToClass, classToPlain } from "class-transformer";
import { CameraRegisterReq1Model } from "src/app/api-handling/models/CameraRegisterReq1Model";
import { CameraReigster1RespModel } from "src/app/api-handling/models/CameraRegister1RespModel";
import { CameraRegister2ReqModel } from "src/app/api-handling/models/CameraRegister2ReqModel";
import { InvitationRespModel } from "src/app/api-handling/models/InvitationRespModel";


const userCameraEndpoint = "v1/cameras/usercameras/";
const myinvitationsEndpoint = "v1/camerafriendinvitations/myinvitations/";
const cameraRegistraionEndpoint = "v1/cameraregistration/";
@Injectable()
export class CameraRestService extends BaseRestService {

    constructor(private httpClient: HttpClient, localStorageService: LocalStorageService) {
        super(localStorageService);
    }



    public getCameraList(): Observable<CameraResponseModel[]> {
        const header = this.getBearerHeader();
        if ( header ) {
            let parameter = new HttpParams();
            parameter = parameter.set("page", "1");
            parameter = parameter.set("limit", "0");
            return this.httpClient.get(this.webserviceUrl + userCameraEndpoint, {headers: header, params: parameter}).pipe(
                map((result) => plainToClass(CameraResponseModel, result as any[])));
        } else {
            return null;
        }
    }

    /**
     * Loads all camera details from backend.
     * @param cameraId The _id from the camera.
     */
    public getCameraDetailsById(cameraId: string): Observable<CameraResponseModel> {
        const header = this.getBearerHeader();
        if ( header ) {
            return this.httpClient.get(this.webserviceUrl + userCameraEndpoint + cameraId,  {headers: header}).pipe(
                map((result) => plainToClass(CameraResponseModel, result)));
        } else {
            return null;
        }
    }

    public patchCameraSortOrPush(camera: CameraUpdateSortPushModel): Observable<CameraResponseModel> {
        const header = this.getBearerHeader();
        if ( header ) {
            header.append("Content-Type", "application/json");
            return this.httpClient.patch(this.webserviceUrl + userCameraEndpoint + camera._id + "/sortpush", classToPlain(camera), {headers: header}).pipe(
                map((result) => plainToClass(CameraResponseModel, result)));
        } else {
            return null;
        }
    }

    public patchCamera(camera: CameraRequestModel): Observable<CameraResponseModel> {
        const header = this.getBearerHeader();
        if ( header ) {
            header.append("Content-Type", "application/json");
            return this.httpClient.patch(this.webserviceUrl + userCameraEndpoint + camera._id, classToPlain(camera), {headers: header}).pipe(
                map((result) => plainToClass(CameraResponseModel, result)));
        } else {
            return null;
        }
    }

    /**
     * Deletes a camera and all here content from backend.
     * @param id Id of the camera to be deleted.
     */
    public deleteCameraById(id: string): Observable<any> {
        const header = this.getBearerHeader();
        if ( header ) {
            return this.httpClient.delete(this.webserviceUrl + userCameraEndpoint + id,  {headers: header});
        } else {
            return null;
        }
    }

    /**
     * First step to register camera on the server. Note: Response from server is nessecary for registerCameraStep2. This api call can take up to 5 minutes.
     * @param registerModel Informations about the camera for the registration.
     */
    public registerCameraStep1(registerModel: CameraRegisterReq1Model): Observable<CameraReigster1RespModel> {
        let header = this.getBearerHeader();
        if ( header ) {
            header = header.append("Content-Type", "application/json");
            return this.httpClient.post<any>(this.webserviceUrl +  cameraRegistraionEndpoint + "register1", classToPlain(registerModel), {headers: header}).pipe(
                map((result) => plainToClass(CameraReigster1RespModel, result)));
        } else {
            return null;
        }
    }

    public registerCameraStep2(registerModel: CameraRegister2ReqModel): Observable<CameraReigster1RespModel> {
        let header = this.getBearerHeader();
        if ( header ) {
            header = header.append("Content-Type", "application/json");
            return this.httpClient.post<any>(this.webserviceUrl + cameraRegistraionEndpoint + "register2", classToPlain(registerModel), {headers: header});
        } else {
            return null;
        }
    }

    public getInviteList(): Observable<InvitationRespModel[]> | null {
        const header = this.getBearerHeader();
        if ( header ) {
            return this.httpClient.get(this.webserviceUrl + myinvitationsEndpoint, {headers: header}).pipe(
                map((result) => plainToClass(InvitationRespModel, result as any[])));
        } else {
            return null;
        }
    }

    public rejectInvite(id: string): Observable<InvitationRespModel[]> | null {
        const header = this.getBearerHeader();
        if ( header ) {
            return this.httpClient.delete(this.webserviceUrl + myinvitationsEndpoint + id, {headers: header}).pipe(
                map((result) => plainToClass(InvitationRespModel, result as any[])));
        } else {
            return null;
        }
    }

    public acceptInvite(id: string): Observable<InvitationRespModel[]> | null {
        const header = this.getBearerHeader();
        if ( header ) {
            return this.httpClient.post(this.webserviceUrl + myinvitationsEndpoint + id, null, {headers: header}).pipe(
                map((result) => plainToClass(InvitationRespModel, result as any[])));
        } else {
            return null;
        }
    }

    public reconnect(id: string): Observable<any> {
        const header = this.getBearerHeader();
        if ( header ) {
            return this.httpClient.post(this.webserviceUrl + userCameraEndpoint + id + "/reconnect/", null, {headers: header});
        } else {
            return null;
        }
    }

    public resendSettings(id: string): Observable<{success: true}> | null {
        const header = this.getBearerHeader();
        if ( header ) {
            return this.httpClient.post<{success: true}>(this.webserviceUrl + userCameraEndpoint + id + "/resendsettings/", null, {headers: header});
        } else {
            return null;
        }
    }
}
