<div class="row w-100 img-container h-auto mh-100 minh-sm-100 justify-content-sm-around text-center mx-0 align-items-center">
    <button *ngIf="showPreviousBtn" mat-mini-fab color="primary" (click)="showPrevious()" class="d-none d-sm-block">
        <mat-icon>chevron_left</mat-icon>
    </button>    
    <div *ngIf="!showPreviousBtn" class="d-none d-sm-inline-block w-40 h-40" style="float: left; top: calc(50% - 20px);"></div>
    <div class="h-100 col-12 col-sm-10 justify-content-center px-0">
        <img #imageView *ngIf="this.image" class="col-auto img-size h-auto h-sm-100" [src]="image" (click)="onClick()" alt="">
    </div>
    <button *ngIf="showNextBtn" mat-mini-fab color="primary" (click)="showNext()" class="d-none d-sm-block">
        <mat-icon>chevron_right</mat-icon>
    </button>
    <div *ngIf="!showNextBtn" class="d-none d-sm-inline-block w-40 h-40" style="float: right; top: calc(50% - 20px);"></div>
</div>
<div class="row d-flex d-sm-none justify-content-evenly py-2">
    <button *ngIf="showPreviousBtn" mat-mini-fab color="primary" (click)="showPrevious()">
        <mat-icon>chevron_left</mat-icon>
    </button>
    <button *ngIf="showNextBtn" mat-mini-fab color="primary" (click)="showNext()">
        <mat-icon>chevron_right</mat-icon>
    </button>
</div>
