import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogButton } from "../dialog/dialog.component";

export interface ImgDialogData {
    title: string;
    message: string;
    imgName: string;
    buttons: DialogButton[];
}

@Component({
    selector: "app-spinnerdialog",
    templateUrl: "imageDialog.component.html",
})

export class ImageDialogComponent {

    constructor(
      public dialogRef: MatDialogRef<ImageDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: ImgDialogData) {}

      onClick(index): void {
        this.dialogRef.close(index);
      }
}
