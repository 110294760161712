<app-toolbar [name]="title" [leftBarButton]="backBarButton" [rightBarButtons]="navigationButtons"></app-toolbar>
<form [formGroup]="contactDetailForm" class="container-fluid container-overflow-scroll container-safe-area-bottom d-flex flex-column align-items-stretch">
 
    <div class="row justify-content-center">
        <div *ngIf="type === 1 || type === 2" class="col-12 mt-3 descriptionInfo">{{ 'contactDetail.friendDescription' | translate }}</div>
        <div *ngIf="type === 0" class="col-12 mt-3 descriptionInfo">{{ 'contactDetail.mailDescription' | translate }}</div>
        <div class="col-12 col-md-8 col-lg-5 px-3 pt-20">
            <div class="d-flex flex-column justify-content-between align-items-center w-100">                
                <div class="inputContainer">
                    <mat-form-field *ngIf="type === 0 || type === 1" appearance="fill" class="doerrInput">
                        <input matInput [readonly]="type === 1 && contactId" type="text" placeholder="{{'contactDetail.name' | translate}}" formControlName="name" (blur)="onBlur()">
                        <mat-error>{{ 'contactDetail.validName' | translate }}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="doerrInput">
                        <input matInput [readonly]="type === 1 && contactId" type="email" placeholder="{{'contactDetail.email' | translate}}" formControlName="email" (blur)="onBlur()">
                        <mat-error>{{ 'shared.validMail' | translate }}</mat-error>
                    </mat-form-field>
                </div>                
            </div>
        </div>        
    </div>
    <div class="row justify-content-center mt-auto">
        <div class="col-12 col-md-5 col-lg-2 mb-3">
            <button *ngIf="type === 0 && !(!isWeb() && contactId) || type === 2 && isWeb() && contactId" class="signInButton" mat-raised-button color="primary" (click)="goBackButtonPressed()">{{'shared.store' | translate }}</button>
            <button *ngIf="type === 2 && !contactId" class="signInButton" mat-raised-button color="primary" (click)="goBackButtonPressed()">{{'contactDetail.invite' | translate }}</button>
        </div>
        <div *ngIf="contactId" class="col-12 col-md-5 col-lg-2 mb-3">
            <button class="signInButton" mat-raised-button color="warn" (click)="deleteFriend()">{{'contactDetail.deleteFriend' | translate }}</button>
        </div>
    </div>
</form>
