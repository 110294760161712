<form [formGroup]="filterForm" class="container d-flex flex-column h-100" (ngSubmit)="filterImages()">
    <div style="min-height:100%;">
        <div class="row py-3">
            <div class="col">
                <div class="d-flex align-items-center">
                    <mat-icon mat-list-icon class="listIcon">filter_alt</mat-icon>
                    <span class="mx-1 sidebarSectionTitle">{{ 'images.filterTitle' | translate }}</span> 
                    <span class="d-flex flex-grow-1"></span>
                    <button [ngStyle]="{'visibility':isFilterSet() ? 'visible' : 'hidden'}" type="button" class="signInButton" mat-icon-button color="warn" (click)="clearFilter()"> <mat-icon matListAvatar class="font-weight-bold">clear</mat-icon></button>                   
                </div>
                <mat-divider class="mb-2"></mat-divider>
                <div class="row">
                    <span class="col-12 descriptionText">{{ 'images.spaceOfTime' | translate }}</span>
                </div>                    
                <div class="row position-relative">
                    <mat-form-field class="col-12 disabled-datepicker">
                        <input matInput [matDatepicker]="pickerFrom" tabindex="-1" [max]="today" placeholder="{{ 'images.pickerFromPlaceholder' | translate }}" formControlName="startDate">
                        <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                        <mat-datepicker #pickerFrom disabled="false"></mat-datepicker>
                    </mat-form-field>
                    <div class="click-overlay" (click)="pickerFrom.open()"></div>
                </div>
                <div class="row position-relative">
                    <mat-form-field class="col-12 disabled-datepicker">
                        <input matInput [matDatepicker]="pickerEnd" tabindex="-1" [max]="today" tabindex="-1" [min]="filter.startDate" placeholder="{{ 'images.pickerEndPlaceholder' | translate }}" formControlName="endDate">
                        <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                        <mat-datepicker #pickerEnd disabled="false"></mat-datepicker>
                    </mat-form-field>
                    <div class="click-overlay" (click)="pickerEnd.open()"></div>
                </div>
            </div>
        </div>
        <div class="row py-3">
            <div class="col">
                <div class="row">
                    <div class="d-flex align-items-center descriptionText">
                        <span>{{ 'images.cameraFilter' | translate }}</span>
                        <span class="d-flex flex-grow-1"></span>
                        <button type="button" class="selectAllCamerasButton" mat-icon-button color="primary" (click)="selectAllCameras()">
                            <mat-icon matListAvatar class="font-weight-bold">done_all</mat-icon>
                        </button>                   
                    </div>
                </div>
                <div class="row">
                    <section class="col-12">
                        <div formArrayName="filterCameras" *ngFor="let camera of cameras; let i = index">
                            <mat-checkbox class="matBox w-100" color="primary" labelPosition="before"  value="camera.id" formControlName="{{i}}">
                                <img matListAvatar srcset="assets/img/doerrCameraImageAvatar@2x.jpg 2x, assets/img/doerrCameraImageAvatar@3x.jpg 3x" alt="camera icon">
                                {{ camera.name }}
                            </mat-checkbox>
                        </div>                            
                    </section>
                </div>
            </div>
        </div>
        <div class="row py-3">
            <div class="col">
                <div class="row">
                    <div class="d-flex align-items-center descriptionText">
                        <span>{{ 'images.userFilter' | translate }}</span>
                        <span class="d-flex flex-grow-1"></span>
                        <button type="button" class="selectAllUsersButton" mat-icon-button color="primary" (click)="selectAllUsers()">
                            <mat-icon matListAvatar class="font-weight-bold">done_all</mat-icon>
                        </button>                   
                    </div>
                </div>
                <div class="row">
                    <section class="col-12">
                        <div formArrayName="filterUsers" *ngFor="let user of users; let i = index">
                            <mat-checkbox class="matBox matBoxUser w-100" color="primary" labelPosition="before"  value="user.id" formControlName="{{i}}">
                                <mat-icon matListAvatar>person</mat-icon>
                                <div class="user-name">{{ user.firstname }} {{ user.lastname}}</div>
                            </mat-checkbox>
                        </div>                            
                    </section>
                </div>
            </div>                
        </div>
        <div class="row justify-content-center mt-auto">
            <div class="col-12 mb-3 align-self-end">
                <button class="signInButton mb-4" type="submit" mat-raised-button color="primary">{{'images.filterButton' | translate }}</button>
                
                <div class="d-flex align-items-center mb-2">
                    <mat-icon mat-list-icon class="listIcon">collections</mat-icon>
                    <span class="sidebarSectionTitle mx-2">{{ 'images.actionTitle' | translate }}</span>                    
                </div>
                <mat-divider class="mb-2"></mat-divider>
                <button class="signInButton mb-3" type="button" mat-raised-button color="primary" (click)="shareImages()" [disabled]="shareButtonDisabled">{{'images.buttons.share' | translate }}</button>
                <button class="signInButton" type="button" mat-raised-button color="warn" (click)="deleteImages()" [disabled]="deleteButtonDisabled">{{'images.buttons.delete' | translate }}</button>
            </div>  
        </div>   
    </div>     
</form>