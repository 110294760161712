import { BaseRestService } from "./BaseRestService";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { LocalStorageService } from "../LocalStorageService";
import { Observable } from "rxjs";
import { map } from "rxjs";
import { UserModel } from "../../api-handling/models/UserModel";
import { plainToClass, classToPlain } from "class-transformer";
import { AboRespModel } from "../../api-handling/models/AboRespModel";

interface DeleteBody {
    password: String;
}
@Injectable()
export class UserRestService extends BaseRestService {

    constructor(private httpClient: HttpClient, localStorageService: LocalStorageService) {
        super(localStorageService);
    }

    public getUserProfile(): Observable<UserModel> | null {
        const header = this.getBearerHeader();
        if (header) {
            return this.httpClient.get(this.webserviceUrl + "v1/profile", {headers: header}).pipe(
                map((result) => plainToClass(UserModel, result))
            );
        }
        return null;
    }

    public getAbos(): Observable<AboRespModel> | null {
        const header = this.getBearerHeader();
        if (header) {
            return this.httpClient.get(this.webserviceUrl + "v1/userabo", {headers: header}).pipe(
                map((result) => {
                    const obj: AboRespModel = plainToClass(AboRespModel, result);
                    if (obj) {
                        this.localStorageService.storeQuotaInformation(obj.availableQuota, Math.ceil((obj.allowedFavoriteSharePercentage / 100) * obj.availableQuota));
                    }

                    return obj;
                })
            );
        }
        return null;
    }

    public setUserProfile(user: UserModel): Observable<UserModel> | null {
        const header = this.getBearerHeader();
        if (header) {
            return this.httpClient.patch(this.webserviceUrl + "v1/profile", classToPlain(user), {headers: header}).pipe(
                map((result) => plainToClass(UserModel, result))
            );
        }
        return null;
    }

    public async loadQuotaInformation(): Promise<void> {
        if (this.getAbos()) {
            try {
                await this.getAbos().toPromise();
            } catch (error) {
                console.log(error);
            }
        }
    }

    /**
     * Delete current user account.
     * @param password Password of the account to verifiy input.
     */
    public async deleteAccount(password: String): Promise<Boolean> {
        const body: DeleteBody = {
            password: password
        };
        const header = this.getBearerHeader();
        if (header) {
            try {
                header.append("Content-Type", "application/json");
                await this.httpClient.request("delete", this.webserviceUrl + "v1/account", {body: body, headers: header}).toPromise();
                return true;
            } catch (error) {
                return false;
            }
        }
    }
}
