import { Injectable } from "@angular/core";
import { ImageListResponseModel } from "../api-handling/models/ImageListResponseModel";
import { DeviceDetector } from "../helpers/DeviceDetector";
import { DatabaseService, DbImage } from "../native/DatabaseService";
import { FileService } from "../native/FileService";
import { ImageRestService } from "./http/ImageRestService";

@Injectable({
    providedIn: "root"
})

export class ImageSyncService {

    private maxImagesToSync = 200;
    private syncInProgress = false;
    private loadedImages: ImageListResponseModel;

    constructor(private databaseService: DatabaseService, private fileService: FileService, private imageRestService: ImageRestService) {}

    public async syncDatabase(): Promise<void> {
        if (DeviceDetector.isOnCordova() && !this.syncInProgress) {
            this.syncInProgress = true;
            try {
                await this.loadImages();
                const dbElements = await this.databaseService.getAllImages();
                await this.databaseService.setImages(this.loadedImages.images);
                for (const element of dbElements) {
                    if (this.shouldBeDeleted(element)) {
                        await this.fileService.deleteFile(element._id + ".jpg");
                        this.databaseService.deleteImageById(element._id);
                    }
                }
                this.syncInProgress = false;
            } catch (error) {
                console.log(error);
                throw error;
            }
        }
    }

    private async loadImages(): Promise<void> {
        const imagesRequest = this.imageRestService.getImageListObject(1, [], undefined, undefined, false, this.maxImagesToSync);
        if (imagesRequest) {
            try {
                this.loadedImages = await imagesRequest.toPromise();
            } catch (error) {
                throw error;
            }
        }
    }

    private shouldBeDeleted(dbImage: DbImage): boolean {
        return !this.loadedImages.images.some((a) => a._id === dbImage._id);
    }
}
