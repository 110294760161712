declare var device: {platform: string, version: string};

export class FirebaseRegisterModel {
    token: string;
    languagecode: string;
    name: string;
    meta: string;

    constructor(token: string, languagecode: string) {
        this.languagecode = languagecode;
        this.token = token;
        this.name = device.platform;
        this.meta = device.version;
    }
}
