import { CameraResponseModel } from "./CameraResponseModel";

export class ImageResponseModel {
    public _id: string;
    public readableName?: string;
    public uploaded?: Date;
    public created?: Date;
    public updated?: Date;
    public version?: number;
    public favorite?: boolean;
    public camera?: CameraResponseModel;
    public isOwner?: boolean;
    public isBroken = false;
}
