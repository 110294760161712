export class RegisterReqModel {
    public firstname: string;
    public lastname: string;
    public email: string;
    public password: string;
    public passwordConfirm: string;
    public language: string;
    public organizationKey: string;
    public agbAccepted: boolean;
}
