import { AES, enc } from "crypto-ts";

const cryptoPassword = "s8uveTQ9ZLuZNhfhGMzr2RGxesbkyuK9";

export class CryptoHelper {
    public static encryptString(string: string): string {
        const encrypt = AES.encrypt(string, cryptoPassword).toString();
        return encrypt;
    }

    public static decryptString(string: string): string {
        const bytes = AES.decrypt(string, cryptoPassword);
        return bytes.toString(enc.Utf8);
    }
}
