import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { InAppProduct } from "src/app/models/InAppProduct";
import { Router } from "@angular/router";

export interface InAppQuotaDialogData {
    products: InAppProduct[];
    activeQuotaProductId?: string;
}

@Component({
    selector: "app-inappquotadialog",
    templateUrl: "inappquotadialog.component.html",
    styleUrls: ["inappquotadialog.component.scss"]
})

export class InAppQuotaDialogComponent {
    public selected = "";
    public currentAboSelection = "";

    constructor(public dialogRef: MatDialogRef<InAppQuotaDialogComponent>, private router: Router,
        @Inject(MAT_DIALOG_DATA) public data: InAppQuotaDialogData) {
        this.currentAboSelection = this.data.activeQuotaProductId ? this.data.activeQuotaProductId : "";
        this.selected = this.currentAboSelection;
    }

    onClick(index): void {
        this.dialogRef.close(index);
    }

    public selectProduct(productId: string) {
        this.selected = productId;
    }

    public payButtonPressed() {
        this.dialogRef.close(this.selected);
    }

    public cancelButtonPressed() {
        this.dialogRef.close();
    }

    public showPolicy() {
        this.dialogRef.close();
        this.router.navigate(["home/datapolicy"]);
    }

    public showTerms() {
        this.dialogRef.close();
        this.router.navigate(["home/terms"]);
    }
}
