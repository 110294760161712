import { User } from "./User";

export class InvitationRespModel {
    public _id: string;
    public name: string;
    public email: string;
    public camera: Camera;
    public updated: Date;
}

export class Camera {
    public _id: string;
    public name: string;
    public user: User;
}
