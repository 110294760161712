export class CameraSmtpReceiverRespModel {
    public _id: string;
    public receiver: string;
    public from: string;
    public host: string;
    public secure: boolean;
    public port: number;
    public user: string; // THIS IS THE SMTP ACCOUNT USER! NOT THE LOGGED IN USER
    public password?: string;
    public languagecode: string;
    public created: Date;
    public updated: Date;
    public version: number;
}
