export class StolenModel {
    public firstname: string;
    public lastname: string;
    public email: string;
    public phone: string;
    public address: string;
    public zip: string;
    public city: string;
    public country: string;
}
