import { environment } from "../../../environments/environment";

export class PasswordReqModel {
    public email: string;
    public language: string;
    public organizationKey: string;

    constructor(email: string, language: string) {
        this.email = email;
        this.language = language;
        this.organizationKey = environment.organizationKey;
    }
}
