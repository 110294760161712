import { Component, OnDestroy } from "@angular/core";
import { Subscription, Observable, Subject } from "rxjs";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { SpinnerDialogComponent } from "./spinnerDialog/spinnerDialog.component";
import { DeviceDetector } from "src/app/helpers/DeviceDetector";
import { DialogService } from "src/app/services/DialogService";

declare var window: { scrollTo: (x: number, y: number) => void };

@Component({ selector: "app-base", template: `<div></div>` })

export abstract class BaseComponent implements OnDestroy {
    public subscriptions: Subscription[] = [];
    private spinnerRef: MatDialogRef<any>;

    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }

    constructor(public dialog: MatDialog, public dialogService: DialogService) {
    }

    public isOnline(): boolean {
        return !DeviceDetector.isOnCordova() || DeviceDetector.hasInternetConnection();
    }

    public isWeb(): boolean {
        return !DeviceDetector.isOnCordova();
    }

    public openDialog(title: string, message: string, buttons: string[]): Observable<number> {
        return this.openImgDialog(title, message, undefined, buttons);
    }

    public openImgDialog(title: string, message: string, imgName: string, buttons: string[]): Observable<number | null> {
        return this.dialogService.openDialog(title, message, imgName, buttons, this.subscriptions);
    }

    public showSpinner(title: string, message: string): Observable<any> {
        if (this.spinnerRef !== undefined) {
            this.closeSpinner();
        }
        this.spinnerRef = this.dialog.open(SpinnerDialogComponent, {
            width: "250px",
            data: {title: title, message: message},
            disableClose: true
        });
        return this.spinnerRef.afterClosed();
    }

    public closeSpinner() {
        if ( this.spinnerRef ) {
            this.spinnerRef.close();
        }
    }

    public onBlur() {
        if (DeviceDetector.isOnCordova()) {
            window.scrollTo(0, 0);
        }
    }
}
