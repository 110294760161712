import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { Observable, of, from } from "rxjs";
import { catchError, map } from "rxjs";
import { DeviceDetector } from "../helpers/DeviceDetector";
import { LocalStorageService } from "../services/LocalStorageService";
import { AppUserService } from "../services/database/AppUserService";
import { AuthRestService } from "../services/http/AuthRestService";

@Injectable()
export class LoginGuard {

    constructor(private router: Router, private localStorageService: LocalStorageService,
        private appUserService: AppUserService, private authRestService: AuthRestService) {

    }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
        return this.canActivateCheck(route);
    }

    canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
        return this.canActivateCheck(route);
    }

    canActivateCheck(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
        return from(this.mayUse()).pipe(map(e => {
                if (e) {
                    return true;
                } else {
                    this.router.navigate(["/signin"]);
                    return false;
                }
            }),
            catchError((err) => {
                console.log(err);
                this.router.navigate(["/signin"]);
                return of(false);
            })
        );
    }

    private async mayUse(): Promise<boolean> {
        const accessInfo = this.localStorageService.getAccessInfos();
        if (accessInfo.accessToken != null && accessInfo.accessTokenExpiresAt >= new Date()) {
            return true;
        } else if ( DeviceDetector.isOnCordova()) {
            const user = await this.appUserService.getAppUser();
            if ( user && user.email && user.password ) {
                const result = await this.authRestService.signIn(user.email, user.password).toPromise();
                return result.success;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

}
