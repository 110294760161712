import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs";
import { environment } from "src/environments/environment";
import { LocalStorageService } from "../LocalStorageService";
import { BaseRestService } from "./BaseRestService";

@Injectable()
export class CleverReachService extends BaseRestService {

    constructor(private httpClient: HttpClient, localStorageService: LocalStorageService) {
        super(localStorageService);
    }

    public postNewsletter(email: string): Observable<boolean> {
        const formData = new FormData();
        formData.append("email", email);
        return this.httpClient.post(environment.cleverReachUrl, formData, {responseType: "text", observe: "response"}).pipe(
            map((response) => {
                return response.url.includes(environment.ownHostDomain);
            })
        );
    }
}
