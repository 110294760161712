import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError, TimeoutError } from "rxjs";
import { catchError, timeout } from "rxjs";
import { IApiErrorResponse } from "../models/IApiErrorResponse";
import { TranslateService } from "@ngx-translate/core";
import { DialogService } from "../../services/DialogService";


/** Pass untouched request through to the next request handler. */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    private readonly defaultTimeout = 60000;

    constructor(private readonly translate: TranslateService, private readonly dialogService: DialogService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // Check if custom timeout exists. If not use defaultTimeout.
        const timeoutValue = req.body ? Number(req.body.timeout || this.defaultTimeout) : this.defaultTimeout;

        return next.handle(req).pipe(
            timeout(timeoutValue),
            catchError((err) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err && err.error && err.error.id ) {
                            const errorResponse: IApiErrorResponse = err.error;
                            const isNotShowing = this.getNotShwoingErrors().some(e => e === errorResponse.code);
                            if (!isNotShowing) {
                                this.showError(errorResponse.code, errorResponse.message);
                            }
                        } else {
                            if (!err.url.includes("pictures/userpictures/raw/") && err.status !== 503) {
                                // Unknown error not showing for image files.
                                this.showError("unknown");
                            }
                        }
                    } else if (err instanceof TimeoutError) {
                        this.showError("timeoutError");
                    }
                    return throwError(err);
                }
            )
        );
    }

    /**
     * Presents a error message to the user.
     * @param code code of the error to present.
     */
    private showError(code: string, message?: string) {
        const titleSub = this.translate.get("error.title").subscribe((title) => {

            if (code === "server_side_rendered_error" && message) {
                const observable = this.dialogService.showDialog(title, message, null, undefined);
                if (!observable) {
                    return;
                }

                const subscription = observable.subscribe(() => {
                    subscription.unsubscribe();
                    titleSub.unsubscribe();
                    msgSub.unsubscribe();
                });


            }

            const msgSub = this.translate.get(`error.${code}`).subscribe((msg) => {
                const observable = this.dialogService.showDialog(title, msg, null, undefined);
                if (!observable) {
                    return;
                }

                const subscription = observable.subscribe(() => {
                    subscription.unsubscribe();
                    titleSub.unsubscribe();
                    msgSub.unsubscribe();
                });
            });
        });
    }


    /**
     * Array of error codes which should not displayed to the user.
     */
    private getNotShwoingErrors(): string[] {
        const errors: string[] =  [];
        errors.push("device_already_registered");
        errors.push("authentication_required");
        errors.push("no_firmware_found");
        errors.push("camera_locked");
        return errors;
    }
}
