
export class AppleTransferModel {
    public email: string;
    public password: string;
    public originalTransactionIds: string[];

    constructor(email: string, password: string, transactions: string[]) {
        this.email = email;
        this.password = password;
        this.originalTransactionIds = transactions;
    }
}
