import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()

export class AssetService {

    constructor(private http: HttpClient) {}

    public async loadImgAsset(name: string): Promise<string | undefined> {

        const sub = new Subject<string>();
        try {
            const res = await this.http.get("./assets/img/" + name, { responseType: "blob" }).toPromise();
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64data = reader.result as string;
                sub.next(base64data);
                sub.complete();
            };
            reader.readAsDataURL(res);
        } catch (error) {
            console.log(error);
            sub.error(error);
        }
        return sub.toPromise();
    }

}
