import { Component } from "@angular/core";
import { BaseNavComponent } from "../base/baseNav.component";
import { ICameraSettings, ICameraSettingsHelper } from "src/app/api-handling/models/ICameraSettings";
import { RegisterDataService } from "src/app/services/RegisterDatatService";
import { MatDialog } from "@angular/material/dialog";
import { NavigationService } from "src/app/services/NavigationService";
import { CordovaInitHandler } from "src/app/native/CordovaInitHandler";
import { Location } from "@angular/common";
import { CameraRestService } from "src/app/services/http/CameraRestService";
import { CameraRegister2ReqModel } from "src/app/api-handling/models/CameraRegister2ReqModel";
import { Router } from "@angular/router";
import { ICameraValidSettings } from "../cameraSettings/cameraSettings.component";
import { TranslateService } from "@ngx-translate/core";
import { BreakpointObserver } from "@angular/cdk/layout";
import { UserRestService } from "src/app/services/http/UserRestService";
import { from } from "rxjs";
import { DialogService } from "src/app/services/DialogService";
import { ImageFilterService } from "src/app/services/ImageFilterService";

@Component({
    selector: "app-newsettings",
    templateUrl: "newSettings.component.html",
    styleUrls: ["newSettings.component.scss"]
})

export class NewSettingsComponent extends BaseNavComponent {

    constructor(private dataService: RegisterDataService, dialog: MatDialog, navigationService: NavigationService,
        location: Location, initHandler: CordovaInitHandler, private restApi: CameraRestService, private router: Router,
        private translate: TranslateService, breakpointObserver: BreakpointObserver, private userRestService: UserRestService,
        dialogService: DialogService, private readonly imageFilterService: ImageFilterService) {
        super(dialog, navigationService, location, initHandler, breakpointObserver, dialogService);
    }

    public isValid = true;
    public defaultCameraSettings: ICameraSettings = ICameraSettingsHelper.defaultSettings();

    public signUpCamera() {
        if (this.dataService.responseStep1) {
            if (this.isValid) {
                const reqModel = new CameraRegister2ReqModel(this.dataService.cameraReq1, this.dataService.responseStep1, this.defaultCameraSettings);
                const registerSubscription = this.restApi.registerCameraStep2(reqModel);
                if ( registerSubscription ) {

                    this.showSpinner(this.translate.instant("newSettings.signUpLoad"), "");
                    this.subscriptions.push(registerSubscription.subscribe((result) => {
                        this.subscriptions.push(from(this.userRestService.loadQuotaInformation()).subscribe(() => {
                            this.closeSpinner();
                            this.imageFilterService.updateFilterParameters(true);
                            this.router.navigate(["home/cameras"]);
                        }));
                    }, (err) => {
                        this.closeSpinner();
                    }));
                }
            } else {
                this.openDialog(this.translate.instant("settings.invalidTitle"), this.translate.instant("settings.invalidMsg"), null);
            }
        }
    }

    public onNotify(cameraSettings: ICameraValidSettings) {
        this.isValid = cameraSettings.isValid;
        if (this.isValid) {
            this.defaultCameraSettings = cameraSettings.settings;
        }
    }
}
