import { Injectable } from "@angular/core";
import { BaseRestService } from "./BaseRestService";
import { LocalStorageService } from "../LocalStorageService";
import { Observable, throwError } from "rxjs";
import { ImageResponseModel } from "src/app/api-handling/models/ImageResponseModel";
import { ImageListResponseModel } from "src/app/api-handling/models/ImageListResponseModel";
import { HttpBackend, HttpClient, HttpParams, HttpErrorResponse } from "@angular/common/http";
import { map, catchError } from "rxjs";
import { plainToClass } from "class-transformer";
import * as moment from "moment";
import { ImageFilter } from "src/app/components/images/models/ImageFilter";

const pictureEndpoint = "v1/pictures/userpictures/";
@Injectable()
export class ImageRestService extends BaseRestService {

    // not intercepted http client
    private niHttpClient: HttpClient;

    constructor(private httpBackend: HttpBackend, private httpClient: HttpClient, localStorageService: LocalStorageService) {
        super(localStorageService);

        this.niHttpClient = new HttpClient(httpBackend);
    }

    public getImageList(page: number, cameraIds: string[], startDate: Date | undefined, endDate: Date | undefined, fav: boolean | undefined): Observable<ImageListResponseModel> | null {
        return this.getImageListObject(page, cameraIds, startDate, endDate, fav, 72);
    }

    public getImageListObject(
        page: number,
        cameraIds: string[],
        startDate: Date | undefined,
        endDate: Date | undefined,
        fav: boolean | undefined,
        count: number,
        firstImageUploadDate?: Date | undefined
    ): Observable<ImageListResponseModel> | null {
        const header = this.getBearerHeader();
        if ( header ) {
            let parameter = new HttpParams();
            parameter = parameter.set("page", page.toString());
            parameter = parameter.set("limit", count.toString());
            if (cameraIds.length > 0) {
                parameter = parameter.set("cameras", cameraIds.toString());
            }
            if ( startDate ) {
                parameter = parameter.set("fromDate", startDate.toString());
            }
            if ( endDate ) {
                parameter = parameter.set("toDate", endDate.toString());
            }

            if ( firstImageUploadDate && ( !endDate || moment(endDate).isSame(moment(), "day") ) ) {
                // Must be ISO String, because we apply the server time
                parameter = parameter.set("toDate", firstImageUploadDate.toISOString());
            }

            if (fav) {
                parameter = parameter.set("fav", "1");
            }
            return this.httpClient.get(this.webserviceUrl + pictureEndpoint, {headers: header, params: parameter, observe: "response"}).pipe(
                map((result) => {
                    const imageList = new ImageListResponseModel();
                    imageList.images = plainToClass(ImageResponseModel, result.body as any[]);
                    imageList.page = page;
                    imageList.countAll = +result.headers.get("X-Total-Count");
                    imageList.countFiltered = +result.headers.get("X-Pagination-Count");
                    return imageList;
                }));
        } else {
            return null;
        }
    }

    public getImage(imageId: string, isThumbnail: boolean): Observable<any> | null {
        const header = this.getBearerHeader();
        if ( header ) {
            let parameter = new HttpParams();
            if ( isThumbnail ) {
                parameter = parameter.set("thumbnail", "1");
            }
            return this.httpClient.get(this.webserviceUrl + pictureEndpoint + "raw/" + imageId, {headers: header, params: parameter, responseType: "blob"})
                .pipe(catchError((err: HttpErrorResponse) => {
                    if (err && err.error && err.error.id) {
                        console.log(err);
                    }
                    return throwError(err);
                }));
        } else {
            return null;
        }
    }

    public getImageData(imageId: string, silent: boolean = false): Observable<ImageResponseModel> | null {
        const header = this.getBearerHeader();
        if ( header ) {
            const httpClient = silent ? this.niHttpClient : this.httpClient;
            return httpClient.get(this.webserviceUrl + pictureEndpoint + imageId, {headers: header}).pipe(
                map((result) => plainToClass(ImageResponseModel, result as any)));
        } else {
            return null;
        }
    }

    public deleteImage(imageId: string): Observable<ImageResponseModel> | null {
        const header = this.getBearerHeader();
        if ( header ) {
            return this.httpClient.delete(this.webserviceUrl + pictureEndpoint + imageId, {headers: header}).pipe(
                map((result) => plainToClass(ImageResponseModel, result as any)));
        } else {
            return null;
        }
    }

    public deleteImages(imageIds: string[]): Observable<ImageResponseModel> | null {
        const header = this.getBearerHeader();
        let parameters = new HttpParams();
        parameters = parameters.set("ids", JSON.stringify(imageIds));

        if ( header ) {
            return this.httpClient.delete(this.webserviceUrl + pictureEndpoint, {headers: header, params: parameters}).pipe(
                map((result) => plainToClass(ImageResponseModel, result as any)));
        } else {
            return null;
        }
    }

    public deleteImagesByFilter(excludedImageIds: string[], filters: ImageFilter, firstImageUploadDate?: Date | undefined): Observable<ImageResponseModel> | undefined {
        const header = this.getBearerHeader();
        let parameters = new HttpParams();
        if ( excludedImageIds.length > 0 ) {
            parameters = parameters.set("excludePictures", JSON.stringify(excludedImageIds));
        }
        if ( filters.cameras.length > 0 ) {
            parameters = parameters.set("cameras", JSON.stringify(filters.cameras));
        }
        if ( filters.startDate ) {
            parameters = parameters.set("fromDate", filters.startDate.toString());
        }
        if ( filters.endDate ) {
            parameters = parameters.set("toDate", filters.endDate.toString());
        }

        if ( firstImageUploadDate && ( !filters.endDate || moment(filters.endDate).isSame(moment(), "day") ) ) {
            // Must be ISO String, because we apply the server time
            parameters = parameters.set("toDate", firstImageUploadDate.toISOString());
        }

        if (filters.favourites === true) {
            parameters = parameters.set("fav", "1");
        } else {
            parameters = parameters.set("excludeFavorites", "1");
        }

        if ( header ) {
            return this.httpClient.delete(this.webserviceUrl + pictureEndpoint + "delete", {headers: header, params: parameters}).pipe(
                map((result) => plainToClass(ImageResponseModel, result as any)));
        } else {
            return null;
        }
    }

    public patchImage(imageId: string, image: ImageResponseModel): Observable<ImageResponseModel> | null {
        const header = this.getBearerHeader();
        if ( header ) {
            return this.httpClient.patch(this.webserviceUrl + pictureEndpoint + imageId, image, {headers: header}).pipe(
                map((result) => plainToClass(ImageResponseModel, result as any)));
        } else {
            return null;
        }
    }

}


