import { Component, OnInit, NgZone } from "@angular/core";
import { BaseNavComponent } from "../base/baseNav.component";
import { Router } from "@angular/router";
import { NavigationService } from "../../services/NavigationService";
import { CordovaInitHandler } from "../../native/CordovaInitHandler";
import { MatDialog } from "@angular/material/dialog";
import { Location } from "@angular/common";
import { BreakpointObserver } from "@angular/cdk/layout";
import { DeviceDetector } from "../../helpers/DeviceDetector";
import { PayPalAboReqModel } from "../../api-handling/models/PayPalAboReqModel";
import { PaypalRestService } from "../../services/http/PaypalRestService";
import { UserRestService } from "../../services/http/UserRestService";
import { UserModel } from "../../api-handling/models/UserModel";
import { PayDialogComponent } from "./components/paydialog/paydialog.component";
import { JSONService } from "../../services/JSONService";
import { AboModel } from "../../models/AboModel";
import { TranslateService } from "@ngx-translate/core";
import { AboRespModel, AboStatus } from "../../api-handling/models/AboRespModel";
import * as moment from "moment";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { PasswordValidation } from "../../validators/PasswordValidation";
import { DialogService } from "../../services/DialogService";
import { InAppPaymentService } from "../../native/InAppPaymentService";
import { InAppProduct } from "../../models/InAppProduct";
import { InAppQuotaDialogComponent } from "./components/inappquotadialog/inappquotadialog.component";
import { from, Subject, Observable } from "rxjs";
import { InAppPaymentStatus } from "../../enums/InAppPaymentStatus";
import { LanguageDetector } from "../../helpers/LanguageDetector";
import * as Sentry from "@sentry/browser";
import { NavigationBarItemModel, NavigationBarMenuItems } from "src/app/models/NavigationBarItemModel";

declare var window: { location: { replace: (url: string) => void; }; innerWidth: number };

const hdAboKey = "HD_IMAGES_ABO";
const quotaAboKey = "QUOTA_IMAGES_ABO";

const paypal = "paypal";
const webPaymentProvider = [paypal];

@Component({
    selector: "app-profile",
    templateUrl: "profile.component.html",
    styleUrls: ["profile.component.scss"]
})

export class ProfileComponent extends BaseNavComponent implements OnInit {

    public userInfoForm: FormGroup;
    public user: UserModel;
    public abos: AboModel[];
    public currentAbos: AboRespModel;
    public bookedQuality = "";
    public aboparam = { date: "", deleteDate: "" };
    public products: InAppProduct[] = [];
    public canMakePurchase = false;
    public shouldPresentRestore = false;
    // Needs to be true to perform purchases and close spinner;
    private isReady = false;

    // String literals for translation
    private readonly dateFormatString =  "shared.dateFormat";

    // Menu BarButton
    public rightBarButton = new NavigationBarItemModel("more_vert");
    // Indicates if the navigation Menu is open.
    public showMenu = false;

    public get isOnIOS() {
        return DeviceDetector.isOnIos();
    }

    constructor(private readonly router: Router, dialog: MatDialog, navigationService: NavigationService, location: Location,
        initHandler: CordovaInitHandler, breakpointObserver: BreakpointObserver, private readonly paypalRestService: PaypalRestService,
        private readonly userRestService: UserRestService, jsonService: JSONService, private readonly translate: TranslateService,
        private readonly formBuilder: FormBuilder, dialogService: DialogService, private readonly inAppPaymentService: InAppPaymentService,
        private readonly zone: NgZone) {
        super(dialog, navigationService, location, initHandler, breakpointObserver, dialogService);

        this.shouldPresentRestore = DeviceDetector.isOnIos();

        this.subscriptions.push(jsonService.getJSON().subscribe((abos) => {
            this.abos = abos;
        }));

        // Load inapp paymanet products and listen to events.
        if (DeviceDetector.isOnCordova()) {
            this.translate.get("abo.userProfileLoad").subscribe((translation) => {
                this.showSpinner(translation, "");
                this.subscriptions.push(from(this.getInAppProducts()).subscribe(() => {
                    this.zone.run(() => {
                        this.prepareForPurchases();
                    });
                }));
            });
            this.subscribeToInAppPaymentEvents();
        } else {
            this.prepareForPurchases();
        }

        this.setCanMakePayment();
        this.createNavigationMenu();
    }

    /**
     * Adds Observers to InAppPayment service to listen to changes.
     */
    private subscribeToInAppPaymentEvents() {
        this.subscriptions.push(this.inAppPaymentService.subscribeUpdateChanged().subscribe((message) => {
            if (message) {
                if (message.status === InAppPaymentStatus.transfered || message.status === InAppPaymentStatus.finished) {
                    if (this.isReady) {
                        this.closeSpinner();
                        this.updateAbos();
                    }
                }
            }
        }));

        this.subscriptions.push(this.inAppPaymentService.errorHandler.subscribe((error) => {
            if (error) {
                if (this.isReady) {
                    this.closeSpinner();
                }
                if (error.description) {
                    if (error.description === "Billing API version is not supported for the type requested") {
                        this.openDialog(this.translate.instant("play_store_error_title"), this.translate.instant("play_store_error_msg"), null);
                    } else {
                        Sentry.captureException(error);
                    }
                }
            }
        }));
    }

    private async prepareForPurchases() {
        try {
            const observable = this.loadUser();
            if (observable) {
                await observable.toPromise();
            }
            this.isReady = true;
        } catch (error) {
            this.isReady = true;
        }
    }

    public async setCanMakePayment() {
        if (!this.isWeb()) {
            this.canMakePurchase = await this.inAppPaymentService.canMakePayment();
        } else {
            this.canMakePurchase = true;
        }
    }
    public goBackButtonPressed() {
        this.router.navigate(["home/images"]);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.updateForm();
    }

    updateOnResume() {
        this.loadUser();
    }

    private updateForm() {
        this.userInfoForm = this.formBuilder.group({
            mail: [this.user ? this.user.email : "", [Validators.required, Validators.email]],
            passwords: this.formBuilder.group({
                password: [""],
                passwordRepeat: [""],
            }, {
                validator: PasswordValidation.MatchPassword
            })
        });
    }

    private createNavigationMenu() {

        const items: NavigationBarMenuItems[] = [];

        this.subscriptions.push(this.rightBarButton.action.subscribe(() => {
            this.showMenu = !this.showMenu;
        }));
        const deleteItem = new NavigationBarMenuItems("abo.navigationMenuItems.accountDelete");
        this.subscriptions.push(deleteItem.action.subscribe(() => {
            this.router.navigate(["home/accountDelete"]);
        }));
        items.push(deleteItem);

        if (this.shouldPresentRestore) {
            const restoreItem = new NavigationBarMenuItems("abo.navigationMenuItems.restore");
            this.subscriptions.push(restoreItem.action.subscribe(() => {
                this.restorePurchases();
            }));
            items.push(restoreItem);
        }

        if (!this.isWeb()) {
            const manageItem = new NavigationBarMenuItems("abo.navigationMenuItems.manageSubscriptions");
            this.subscriptions.push(manageItem.action.subscribe(() => {
                this.inAppPaymentService.openSubscriptionManagement();
            }));
            items.push(manageItem);
        }

        this.rightBarButton.menuItems = items;
    }

    /**
     * Checks if the given user informations are valid and uploads them to the server.
     */
    public updateUserInformations() {

        this.subscriptions.push(this.translate.get(["abo"]).subscribe(async (translation) => {
            if (this.userInfoForm.valid) {
                this.showSpinner(translation.abo.changingUserInfos, "");
                const newUser = new UserModel();
                newUser._id = this.user._id;
                newUser.email = this.userInfoForm.value.mail;
                newUser.version = this.user.version;
                const locale = await LanguageDetector.getLanguage();
                newUser.languagecode = locale ? locale : "en";
                const passwordGroup = this.userInfoForm.value.passwords;
                if (passwordGroup.password && passwordGroup.password !== "") {
                    newUser.password = passwordGroup.password;
                    newUser.passwordConfirm = passwordGroup.passwordRepeat;
                }
                this.subscriptions.push(this.userRestService.setUserProfile(newUser).subscribe((result) => {
                    this.user.email = result.email;
                    this.user.version = result.version;
                    this.closeSpinner();
                    this.openDialog(translation.abo.save, translation.abo.saveMsg, [translation.abo.ok]);
                    this.updateForm();
                }, () => {
                    this.openDialog(translation.abo.error, translation.abo.errorMsg, [translation.abo.ok]);
                    this.closeSpinner();
                }));
            }
        }));
    }

    /**
     * Loads informations of the user from server and updates form.
     */
    public loadUser(): Observable<void> | undefined {
        if (this.isOnline()) {
            const sub = new Subject<void>();
            this.subscriptions.push(this.translate.get("abo.userProfileLoad").subscribe((translation) => {
                this.showSpinner(translation, "");
                const userModelObservable = this.userRestService.getUserProfile();
                if (userModelObservable) {
                    this.subscriptions.push(userModelObservable.subscribe((userResp) => {
                        sub.next(void 0);
                        sub.complete();
                        this.closeSpinner();
                        this.user = userResp;
                        this.updateForm();
                    }, (error) => {
                        sub.error(error);
                        this.closeSpinner();
                    }));
                }
                this.updateAbos();
            }));
            return sub.asObservable();
        }
        return undefined;
    }

    public isWeb(): boolean {
        return !DeviceDetector.isOnCordova();
    }

    /**
     * Opens a popup to subscribe a hd abo.
     */
    public aboHD() {
        const hdAbo = this.abos.find((a) => a.key === hdAboKey);
        if (hdAbo) {
            this.showPaymentAlert(hdAbo);
        } else {
            console.error("Configuratio missing abos");
        }
    }

    public async inAppAboHD() {
        if (DeviceDetector.isOnCordova()) {
            if (this.products.length > 0) {
                this.subscriptions.push(this.translate.get("abo.loadInAppProcess").subscribe(async (text) => {
                    this.showSpinner(text, "");
                    const ids = DeviceDetector.isOnIos() ? await this.inAppPaymentService.getAppleIdsFor(hdAboKey) : await this.inAppPaymentService.getGoogleIdsFor(hdAboKey);
                    if (ids.length > 0) {
                        this.inAppPaymentService.buyProduct(ids[0]);
                    } else {
                        const provider = DeviceDetector.isOnIos() ? "Apple" : "Google";
                        Sentry.captureMessage(`No ${provider} InApp Product Ids found for ${hdAboKey}`);
                    }
                }));
            } else {
                Sentry.captureMessage("Error: No InApp Products found");
            }
        }
    }

    /**
     * Loads all available products for in app payment.
     **/
    public async getInAppProducts() {
        try {
            this.products = await this.inAppPaymentService.getProductList();
        } catch (error) {
            Sentry.captureException(error);
        }
    }

    private showPaymentAlert(abo: AboModel) {
        const dialogRef = this.dialog.open(PayDialogComponent, {
            width: "380px",
            data: { aboId: abo.key, message: abo.description, name: abo.name }
        });
        this.subscriptions.push(dialogRef.afterClosed().subscribe((result) => {
            if (result === paypal) {
                this.paypalHD(abo);
            }
        }));
    }

    /**
     * Opens a popup to cancel a hd abo, if the user has one.
     */
    public aboSD() {
        if (this.currentAbos.highres) {
            this.subscriptions.push(this.translate.get(["abo", "shared"]).subscribe((aboTranslations) => {
                const mButtons: string[] = [aboTranslations.shared.yes, aboTranslations.shared.no];
                this.subscriptions.push(this.openDialog(aboTranslations.abo.cancelAboTitle, aboTranslations.abo.cancelAboHDMsg, mButtons).subscribe((result) => {
                    if (result !== null && result === 0) {
                        this.cancelAbo(this.currentAbos.highres);
                    }
                }));
            }));
        } else {
            this.openDialog(this.translate.instant("error.title"), this.translate.instant("error.unknown"), null);
        }
    }

    private cancelAbo(abo: AboStatus) {
        this.showSpinner(this.translate.instant("abo.cancelInProgess"), "");
        if (abo.provider === paypal) {
            this.subscriptions.push(this.paypalRestService.cancelAbo(abo.id).subscribe(() => {
                this.closeSpinner();
                this.updateAbos();
            }, () => {
                this.closeSpinner();
            }));
        } else {
            this.closeSpinner();
        }
    }

    private updateAbos() {
        this.subscriptions.push(this.translate.get(["abo"]).subscribe((translation) => {
            this.showSpinner(translation.abo.loadingAbos, "");
            const aboObservable = this.userRestService.getAbos();
            if (aboObservable) {
                this.subscriptions.push(aboObservable.subscribe((newAbos) => {
                    this.zone.run(() => {
                        if (this.shouldPresentCameraManagement(newAbos)) {
                            this.showCameraManagement();
                        }
                    });
                    this.currentAbos = newAbos;
                    if (newAbos.highres) {
                        this.aboparam = { date: moment(newAbos.highres.nextBillingDate).format(this.translate.instant(this.dateFormatString)),
                         deleteDate: moment(newAbos.highres.nextBillingDate).add(newAbos.waitingPeriodDays, "d").format(this.translate.instant(this.dateFormatString)) };
                    }
                    this.bookedQuality = this.hasHDAbo() ? translation.abo.hasHDAbo : translation.abo.noHDAbo;
                    this.closeSpinner();
                }, () => {
                    this.closeSpinner();
                }));
            } else {
                this.closeSpinner();
            }
        }));
    }

    public shouldPresentCameraManagement(newAbos: AboRespModel): boolean {
        return this.currentAbos &&
            (!this.currentAbos.highres || (this.currentAbos.highres && this.currentAbos.highres.active  === false))
            && ((newAbos.highres) && newAbos.highres.active === true);
    }

    /**
     * Opens a popup to subscribe a quota abo.
     */
    public aboQuota() {
        const quotaAbo = this.abos.find((a) => a.key === quotaAboKey);
        if (quotaAbo) {
            this.showPaymentAlert(quotaAbo);
        } else {
            console.error("Configuratio missing abos");
        }
    }

    public cancelQuota() {
        if (this.currentAbos.quota.length > 0) {
            this.subscriptions.push(this.translate.get(["abo", "shared"]).subscribe((aboTranslations) => {
                const mButtons: string[] = [aboTranslations.shared.yes, aboTranslations.shared.no];
                this.subscriptions.push(this.openDialog(aboTranslations.abo.cancelAboTitle, aboTranslations.abo.cancelAboQuotaMsg, mButtons).subscribe((result) => {
                    if (result !== null && result === 0) {
                        const abo = this.currentAbos.quota.find((a) => a.active === true);
                        if (abo) {
                            this.cancelAbo(abo);
                        }
                    }
                }));
            }));
        } else {
            this.openDialog(this.translate.instant("error.title"), this.translate.instant("error.unknown"), null);
        }
    }

    public paypalHD(abo: AboModel) {
        this.showSpinner(this.translate.instant("abo.paypalLoadingSpinner"), "");

        const paypalName = this.translate.instant(abo.paypal.name);
        const paypalDescription = this.translate.instant(abo.paypal.description);

        const paypalAbo = new PayPalAboReqModel(abo.paypal.billingPlanId, paypalName, paypalDescription);
        this.subscriptions.push(this.paypalRestService.createAbo(paypalAbo).subscribe((result) => {
            this.closeSpinner();
            window.location.replace(result.approvalUrl);
        }, () => {
            this.closeSpinner();
        }));
    }

    public hasHDAbo(): boolean {
        if (this.currentAbos) {
            return this.currentAbos.highres !== null && this.currentAbos.highres.active;
        }
        return false;
    }

    public isFreeAbo(): boolean {
        if (this.currentAbos) {
            return this.currentAbos.highres !== null && this.currentAbos.highres.provider === "free";
        }
        return false;
    }

    public isFreeUser(): boolean {
        if (this.currentAbos) {
            return this.currentAbos.freeHd;
        }
        return false;
    }

    public hasCanceledHDAbo(): boolean {
        if (this.currentAbos) {
            return this.currentAbos.highres !== null && this.currentAbos.highres.active === false;
        }
        return false;
    }

    public textForGoogleHDStatus(): string | undefined {
        if (this.currentAbos && this.currentAbos.highres && this.currentAbos.highres.lastRealtimeStatus) {
            switch (this.currentAbos.highres.lastRealtimeStatus) {
                case 13: return this.translate.instant("abo.google.SUBSCRIPTION_EXPIRED");
                case 11:
                    const mMoment = moment(this.currentAbos.highres.nextBillingDate);
                    return this.translate.instant("abo.google.SUBSCRIPTION_PAUSE_SCHEDULE_CHANGED", { pauseDate: mMoment.format(this.translate.instant(this.dateFormatString))});
                case 10: return this.translate.instant("abo.google.SUBSCRIPTION_PAUSED");
                case 5: return this.translate.instant("abo.google.SUBSCRIPTION_ON_HOLD");
            }
        }
        return undefined;
    }

    public hasActiveWebPayedQuotaAbo(): boolean {
        if (this.currentAbos) {
            return this.currentAbos.quota.some((a) => a.active === true && webPaymentProvider.indexOf(a.provider) >= 0);
        }
        return false;
    }

    public getQuotaMax(): number {
        if (this.currentAbos) {
            return this.currentAbos.availableQuota;
        }
        return 0;
    }

    public getCurrentQuotaSlider(): number {
        if (this.currentAbos) {
            return this.currentAbos.availableQuota !== 0 ? 100 / this.currentAbos.availableQuota * this.currentAbos.usedQuota : 0;
        }
        return 0;
    }

    public getCurrentQuota(): number {
        if (this.currentAbos) {
            return this.currentAbos.usedQuota;
        }
        return 0;
    }

    /**
     * Returns true if a given abo can be canceled via application. Returns false for in app payments.
     * @param abo abo to check.
     */
    public canBeCancelled(abo: AboStatus): boolean {
        return abo.provider === paypal;
    }

    /**
     * Purchase a quota abo with in app payment.
     */
    public inAppQuotaAbo() {
        if (DeviceDetector.isOnCordova) {
            const quotaProducts = [];
            const knownProductIds = DeviceDetector.isOnIos() ? this.abos.find((a) => a.key === quotaAboKey).appleProductIds : this.abos.find((a) => a.key === quotaAboKey).googleProductIds;
            for (const knownProductId of knownProductIds) {
                const foundProduct = this.products.find((product) => product.id === knownProductId);
                if (foundProduct) {
                    quotaProducts.push(foundProduct);
                }
            }
            const quotaProductIds = quotaProducts.map((quotaProduct) => quotaProduct.id);
            const provider = DeviceDetector.isOnIos() ? "apple" : "google";
            const activeQuotaProductId = this.currentAbos.quota.find((quotaAbo) => quotaProductIds.indexOf(quotaAbo.productId) >= 0 && quotaAbo.provider === provider);

            const width = window.innerWidth - 32;
            const dialogRef = this.dialog.open(InAppQuotaDialogComponent, {
                width: width.toString(),
                data: {
                    products: quotaProducts,
                    activeQuotaProductId: (activeQuotaProductId && activeQuotaProductId.active) ? activeQuotaProductId["productId"] : ""
                }
            });

            this.subscriptions.push(dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    if (this.products.length > 0) {
                        this.subscriptions.push(this.translate.get("abo.loadInAppProcess").subscribe(async (text) => {
                            this.showSpinner(text, "");
                            if (DeviceDetector.isOnAndroid() && activeQuotaProductId && activeQuotaProductId.productId && activeQuotaProductId.active) {
                                this.inAppPaymentService.buyProduct(result, activeQuotaProductId.productId);
                            } else {
                                this.inAppPaymentService.buyProduct(result);
                            }
                        }));
                    }
                }
            }));
        }
    }

    public showTerms() {
        this.router.navigate(["home/terms"]);
    }

    public showPolicy() {
        this.router.navigate(["home/datapolicy"]);
    }

    public showCameraManagement() {
        this.router.navigate(["home/hdcameras"]);
    }

    /**
     * Function only for ios to restore pruchases.
     */
    public restorePurchases() {
        this.inAppPaymentService.restoreProducts();
        this.openDialog(this.translate.instant("abo.restoreAlertTitle"), this.translate.instant("abo.restoreAlertMsg"), null);
    }

    /**
     * Overwrite default behaviour
     */
    public abosChanged() {
        this.zone.run(() => {
            this.loadUser();
        });
    }
}
