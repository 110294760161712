export class AppUserModel {
    public accessToken: string;
    public accessTokenExpiresAt: Date;
    public client: string;
    public email: string;
    public organization: string;
    public refreshToken: string;
    public refreshTokenExpiresAt: Date;
    public scope: [string];
}
