import { Component } from "@angular/core";
import { BaseNavComponent } from "../base/baseNav.component";
import { Location } from "@angular/common";
import { NavigationBarItemModel } from "src/app/models/NavigationBarItemModel";
import { MatDialog } from "@angular/material/dialog";
import { NavigationService } from "src/app/services/NavigationService";
import { CordovaInitHandler } from "src/app/native/CordovaInitHandler";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { BreakpointObserver } from "@angular/cdk/layout";
import { TranslateService } from "@ngx-translate/core";
import { DialogService } from "src/app/services/DialogService";
import { CameraSmtpReceiverRespModel } from "src/app/api-handling/models/CameraSmtpReceiverRespModel";
import { CameraSmtpReceiverRestService } from "src/app/services/http/CameraSmtpReceiverRestService";

@Component({
    selector: "app-camerasmtps",
    templateUrl: "cameraSmtps.component.html",
    styleUrls: ["cameraSmtps.component.scss"]
})

export class CameraSmtpsComponent extends BaseNavComponent {

    public rightBarButton = new NavigationBarItemModel("add");
    public title = "";
    public cameraSmtpReceivers: CameraSmtpReceiverRespModel[] = [];
    private cameraId: string;
    public hasCameraSmtpReceivers = false;

    constructor(private router: Router, dialog: MatDialog, navigationService: NavigationService, location: Location,
        initHandler: CordovaInitHandler, private activatedRoute: ActivatedRoute, private cameraSmtpReceiverRestService: CameraSmtpReceiverRestService,
        breakpointObserver: BreakpointObserver, private translate: TranslateService, dialogService: DialogService) {
        super(dialog, navigationService, location, initHandler, breakpointObserver, dialogService);

        this.subscriptions.push(this.rightBarButton.action.subscribe(() => {
            this.addCameraSmtpReceiver();
        }));
        this.subscriptions.push(activatedRoute.queryParams.subscribe((params) => {
            if (params && params.id) {
                this.cameraId = params.id;
                this.loadSmtps(this.cameraId);
            }
        }));

    }

    private loadSmtps(cameraId: string) {
        this.subscriptions.push(this.translate.get("cameraSmtps.loadingTitle").subscribe((translation) => {
            this.showSpinner(translation, "");

            this.subscriptions.push( this.cameraSmtpReceiverRestService.getList(cameraId).subscribe((resultContacts) => {
                this.cameraSmtpReceivers = resultContacts;
                this.closeSpinner();
                this.hasCameraSmtpReceivers = this.cameraSmtpReceivers.length > 0;
            }));

        }));
    }

    updateOnResume() {
        this.loadSmtps(this.cameraId);
    }

    public addCameraSmtpReceiver() {
        this.router.navigate(["home/smtpDetail"], { queryParams: {id: this.cameraId }});
    }

    public editCameraSmtpReceiver(cameraSmtpReceiver: CameraSmtpReceiverRespModel) {
        this.router.navigate(["home/smtpDetail"], { queryParams: {cameraSmtpReceiverId: cameraSmtpReceiver._id, id: this.cameraId }});
    }
}


