import {AbstractControl} from "@angular/forms";
export class PasswordValidation {

    static MatchPassword(AC: AbstractControl) {
        const pwRepeatControl = AC.get("passwordRepeat");
        if (pwRepeatControl) {
            const password = AC.get("password").value; // to get value in input tag
            const passwordRepeat = pwRepeatControl.value; // to get value in input tag
            if (password !== passwordRepeat) {
                pwRepeatControl.setErrors( {MatchPassword: true} );
            } else {
                pwRepeatControl.setErrors( null );
            }
        }
    }
}
