import { Image } from "../components/images/models/Image";
import { Injectable, NgZone } from "@angular/core";
import { DatabaseService, DbImage } from "../native/DatabaseService";
import { DeviceDetector } from "../helpers/DeviceDetector";
import { ImageResponseModel } from "../api-handling/models/ImageResponseModel";
import { FileService } from "../native/FileService";
import { ImageListResponseModel } from "../api-handling/models/ImageListResponseModel";
import { Observable, from, Subject } from "rxjs";
import { map } from "rxjs";
import { CameraResponseModel } from "../api-handling/models/CameraResponseModel";
import { ImageLoadingService } from "../services/ImageLoadingService";
import { IMAGE_TYPE } from "../api-handling/models/enums/ImageType";
import { ImageRestService } from "../services/http/ImageRestService";
import { ImageFilter } from "../components/images/models/ImageFilter";

export class ImageLoadResponse {
    loadingPromise: Promise<Image> | null;
    image: Image | null;

    constructor(promise: Promise<Image> | null, thumb: Image | null) {
        this.loadingPromise = promise;
        this.image = thumb;
    }
}

@Injectable({
    providedIn: "root"
})

export class ImageRepository {

    /**
     * Metadata for images.
     */
    public images: ImageResponseModel[] = [];

    private currentLoadings: Image[] = [];

    /**
     * Array of loaded image files.
     */
    private imgDatas: Image[] = [];
    private initCompleted = false;
    private initSubject: Subject<void> = new Subject<void>();

    constructor(private databaseService: DatabaseService,
        private fileService: FileService, private zone: NgZone,
        private imageLoader: ImageLoadingService, private readonly imageRestService: ImageRestService) {

        // If cordova application load existings images from database
        if (DeviceDetector.isOnCordova()) {
            this.loadLocalImages();
        } else {
            this.initCompleted = true;
        }
    }

    public loadImages(filter: ImageFilter, page: number): Observable<ImageListResponseModel> | null {
        if (DeviceDetector.hasInternetConnection()) {
            return this.imageRestService.getImageList( page, filter.cameras, filter.startDate, filter.endDate , filter.favourites).pipe(
                map((result) => {
                    this.process(result.images);
                    return result;
                })
            );
        } else {
            return this.getImageList(filter.favourites).pipe(
                map((result) => {
                    this.process(result.images);
                    return result;
                })
            );
        }
    }

    private process(images: ImageResponseModel[]) {
        for (const image of images) {
            if (!this.images.some(i => i._id === image._id)) {
                this.images.push(image);
            }
        }
    }

    private async loadLocalImages() {
        const images = await this.databaseService.getAllImages();
        for (let index = 0; index < images.length; index++) {
            const img = images[index];
            const mImage = new Image();
            mImage.id = img._id;
            mImage.type = IMAGE_TYPE.thumbnail;
            this.imgDatas.push(mImage);
            try {
                await new Promise<void>((resolve, reject) => {
                    this.zone.run(async () => {
                        try {
                            mImage.file = await this.fileService.getFile(img._id + ".jpg");
                            resolve();
                        } catch (error) {
                            reject(error);
                        }
                    });
                });
            } catch (error) {
                if (DeviceDetector.hasInternetConnection()) {
                    this.reloadImgFile(mImage, mImage.type);
                }
            }
        }
        this.initSubject.next();
        this.initSubject.complete();
        this.initCompleted = true;
    }

    /**
     * If a local database exits, it returns a ImageListResponseModel based on the local stored data.
     */
    public getImageList(favs: boolean): Observable<ImageListResponseModel> | null {
        if (DeviceDetector.isOnCordova()) {
            return from(this.databaseService.getAllImages()).pipe(map((result) => {
                const imageList = new ImageListResponseModel();
                imageList.images = [];
                imageList.countAll = result.length;
                imageList.countFiltered = result.length;
                for (let index = 0; index < result.length; index++) {
                    const element: DbImage = result[index];
                    const img = new ImageResponseModel();
                    img._id = element._id;
                    img.readableName = element.readableName;
                    img.uploaded = element.uploaded;
                    img.favorite = element.favorite;
                    if (element.path && ((favs && img.favorite) || !favs)) {
                        imageList.images.push(img);
                    }
                }
                return imageList;
            }));
        } else {
            return null;
        }
    }

    /**
     * If a local database exits and contains a image with the given id, it returns a ImageResponseModel based on the local stored data.
     */
    public getImageById(id: string): Observable<ImageResponseModel> | null {
        if (DeviceDetector.isOnCordova()) {
            return from(this.databaseService.getImageById(id)).pipe(map((result: DbImage) => {
                const img = new ImageResponseModel();
                img._id = result._id;
                img.readableName = result.readableName;
                img.uploaded = result.uploaded;
                img.camera = new CameraResponseModel();
                img.camera.name = result.cameraName;
                img.favorite = result.favorite;
                return img;
            }));
        } else {
            return null;
        }
    }

    public clearImageFiles() {
        if (DeviceDetector.isOnCordova()) {
            this.databaseService.clearDatabase();
        } else {
            this.imgDatas = [];
        }
    }

    public async getImg(imageModel: ImageResponseModel, type: IMAGE_TYPE): Promise<ImageLoadResponse> {
        if (!this.initCompleted) {
            await this.initSubject.toPromise();
        }
        return this.getImage(imageModel, type);
    }

    private getImage(imageModel: ImageResponseModel, type: IMAGE_TYPE): ImageLoadResponse {
        if (this.imgDatas.some(img => img.id === imageModel._id)) {
            const image = this.imgDatas.find(img => img.id === imageModel._id && img.type === type);
            if (!image || !image.file) {
                const otherType = this.imgDatas.find(img => img.id === imageModel._id && img.type !== type);
                return new ImageLoadResponse(this.loadImgFile(imageModel._id, type), otherType);
            } else {
                return new ImageLoadResponse(null, image);
            }
        } else {
            return new ImageLoadResponse(this.loadImgFile(imageModel._id, type), undefined);
        }
    }

    public getImgFile(imgId: string, type: IMAGE_TYPE): any | undefined {
        if (this.initCompleted) {
            if (this.imgDatas.some(img => img.id === imgId)) {
                const image = this.imgDatas.find(img => img.id === imgId && img.type === type);
                // If image not exist but with different resolution load image.
                if (!image) {
                    this.loadImgFile(imgId, type);
                    // If Image type full and thumbnail exists return thumbnail instead. If thumbnail needed not full will returned.
                    const otherType = this.imgDatas.find(img => img.id === imgId && img.type !== type);
                    return type === IMAGE_TYPE.full && otherType ? otherType.file : null;
                } else if (image && (!this.isImageLoading(imgId) && (!image.file || image.isBroken))) {
                    // If image exists but image file not valid or broken reload image file.

                    // RACE: Don't try to reload image infinitly
                    if (image.downloadRetry < 3) {
                        this.reloadImgFile(image, type);
                        image.isBroken = false;
                    }
                } else {
                    return image.file;
                }
            } else {
                this.loadImgFile(imgId, type);
            }
        }
        return undefined;
    }

    /** Creating a new image object and downloading image file */
    public async loadImgFile(imgId: string, type: IMAGE_TYPE): Promise<Image | null> {
        const image = new Image();
        this.imgDatas.push(image);
        image.id = imgId;
        image.type = type;
        return this.reloadImgFile(image, type);
    }

    /**
     * Download and storing image file
     * @param image image object to load file for
     * @param type type of the file
     */
    private async reloadImgFile(image: Image, type: IMAGE_TYPE): Promise<Image | null> {
        if (DeviceDetector.isOnCordova() && !DeviceDetector.hasInternetConnection()) {
            return null;
        }
        this.currentLoadings.push(image);
        try {
            image.downloadRetry++;
            const result = await this.imageLoader.loadImgFile(image.id, type);
            if (result) {
                const file = await this.pFileReader(result);
                image.file = file;
                if (DeviceDetector.isOnCordova() && type === IMAGE_TYPE.thumbnail) {
                    await this.updateLocalFile(file, image.id);
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            const index = this.currentLoadings.indexOf(image);
            this.currentLoadings.splice(index, 1);
        }
        return image;
    }

    /**
     * Checks if a image is currently loading.
     * @param imageId image to check
     */
    private isImageLoading(imageId: string): boolean {
        return this.currentLoadings.some(img => img.id === imageId);
    }

    private async updateLocalFile(file: any, imgId: string) {
        const sub = new Subject<any>();
        this.fileService.storeFile(file, imgId + ".jpg", async (success, fileEntry) => {
            if (success) {
                await this.databaseService.setImagePath(imgId, fileEntry.nativeURL);
            } else {
                await this.databaseService.setImagePath(imgId, null);
            }
            sub.complete();
        });
        return sub.toPromise();
    }

    private pFileReader(file: Blob): Promise<any> {
        return new Promise((resolve, reject) => {
            const fr = new FileReader();
            fr.onloadend = (event) => {
                resolve(fr.result);
            };
            fr.onerror = () => {
                console.log(fr.error);
                reject(fr.error);
            };
            fr.readAsDataURL(file);
        });
      }

    /**
     * Sets image flag to broken.
     * @param imageId id of the image which is broken.
     */
    public setImgBroken(imageId: string) {
        const image = this.imgDatas.find(img => img.id === imageId);
        if (image && image.file) {
            image.isBroken = true;
        }
    }
}
