import { Injectable, NgZone } from "@angular/core";
import Dexie from "dexie";
import { AppUserModel } from "../../models/AppUserModel";
import { StoreUserModel } from "../../models/StoreUserModel";
import { CryptoHelper } from "../../helpers/CryptoHelper";
import { PushSettingsModel } from "src/app/models/PushSettingsModel";
import * as Sentry from "@sentry/browser";
@Injectable({
    providedIn: "root",
})

export class AppUserService {
    private db: any;

    constructor(private zone: NgZone) {
        this.createDatabase();
    }

    private createDatabase() {
        this.db = new Dexie("doerrDatabase");
        this.db.version(1).stores({
            appuser: "email, password, organization",
            pushSettings: "pushToken"
        });
    }

    /**
     * Clears all informations in the dexie database.
     */
    public async clearDexieDatabase() {
        await this.removeTokens();
        await this.removeAppUser();
    }


    //
    // Firebase
    //

    public async addPushToken(token: string) {
        this.db.pushSettings.add(new PushSettingsModel(token));
    }

    public async getPushToken(): Promise<string | null> {
        const pushSettings = await this.db.pushSettings.toArray();
        const token = pushSettings[0] as PushSettingsModel;
        if (token && token.pushToken ) {
            return token.pushToken;
        }
        return null;
    }

    public async removeTokens() {
        await this.db.pushSettings.clear();
    }


    //
    // User
    //

    public async addAppUser(user: AppUserModel, password: string) {
        const storeUser = new StoreUserModel();
        storeUser.email = user.email;
        storeUser.organization = user.organization;
        storeUser.password = CryptoHelper.encryptString(password);
        const storedUser = await this.getAppUser();
        if ( storedUser ) {
            try {
                await this.removeAppUser();
                await this.addToIndexedDb(storeUser);
                const userTest = this.getAppUser();
            } catch (err) {
                Sentry.captureException(err);
            }
        } else {
            try {
                await this.addToIndexedDb(storeUser);
                const userTest = this.getAppUser();
            } catch (err) {
                Sentry.captureException(err);
            }
        }
    }

    private async addToIndexedDb(user: StoreUserModel) {
        await this.db.appuser.add(user);
    }


    public async getAppUser(): Promise<StoreUserModel | undefined> {
        const userArray = await this.db.appuser.toArray();
        const user = userArray[0] as StoreUserModel;
        if ( user && user.password ) {
            user.password = CryptoHelper.decryptString(user.password);
        }
        return user;
    }

    public async removeAppUser(): Promise<void> {
        await this.db.appuser.clear();
    }
}
