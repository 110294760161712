<app-toolbar [name]="'abo.accountDelete.title'" [leftBarButton]="backBarButton"></app-toolbar>
<div class="container-overflow-scroll px-fit overflow-auto flex-column d-flex justify-content-between">    
    <form [formGroup]="deleteForm" (ngSubmit)="delete()" class="row justify-content-center">
        <div class="col-6 mt-3 note-field">{{ 'abo.accountDelete.text' | translate }}</div>
        <div class="col-12">
            <div class="row justify-content-center p-3">
                <mat-form-field appearance="fill" class="doerrInput col-10 col-sm-6 col-md-4">
                    <mat-icon matSuffix>vpn_key</mat-icon>
                    <input matInput type="password" placeholder="{{'shared.passwordPlaceholder' | translate}}" formControlName="password" [(ngModel)]="passwordValue" (blur)="onBlur()">
                    <mat-error>{{ 'login.requiredPassword' | translate }}</mat-error>
                </mat-form-field>            
            </div>            
            <div class="row justify-content-center">
                <button class="col-10 col-sm-6 col-md-4" mat-raised-button color="warn" >{{'abo.accountDelete.deleteButton' | translate }}</button>    
            </div>            
        </div>        
    </form>    
</div>