export const hybridAllowedProductTypes = ["cloud_4g_v1", "cloud_4g_v2"];

export interface CameraListItem {
    id: string;
    title: string;
    subtitle?: string;
    showBattery?: boolean;
    showStolen?: boolean;
    showUnverifiedBackofficeRegistered?: boolean;
    actions?: CameraListItemButton[];
    productType?: string;
    supportedProduct?: Boolean;
}

export interface CameraListItemButton {
    id: number;
    type: CameraListItemButtonType;
    value?: boolean;
    info?: string;
    titles?: string[];
    color?: string;
    selected_icon?: string;
    unselected_icon?: string;
}

export enum CameraListItemButtonType {
    button = 0,
    toggle = 1
}
