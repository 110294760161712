<app-toolbar [name]="'cameraDetail.title'" [leftBarButton]="backBarButton" [rightBarButtons]="[rightBarButton]"></app-toolbar>
<div class="container-overflow-scroll">
    <div class="container-fluid container-safe-area-bottom d-flex flex-column align-items-stretch minHeight">
        <div class="row">
            <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center">
                <img class="cameraImg" srcset="assets/img/doerrCameraImage@2x.jpg 2x, assets/img/doerrCameraImage@3x.jpg 3x" alt="">
                <div>{{ camera.name }}</div>
                <div class="descriptionInfo">{{ camera.imei }}</div>
                <div *ngIf="camera.isStolen()" class="row justify-content-center">
                    <div class="col-12 col-md-10 col-lg-8 col-xl-6 px-3 my-3">
                        <mat-card class="d-flex align-items-center justify-content-center flex-column pt-0">
                            <mat-card-header>
                                <mat-card-title class="warnText">{{ 'cameraDetail.stolenFeature.note' | translate }}</mat-card-title>
                            </mat-card-header>
                            <mat-card-content class="">
                                <div class="descriptionInfo">{{ 'cameraDetail.stolenFeature.noteMsg' | translate }}</div>
                                <div *ngIf="stolenInfo" class="descriptionInfo mt-2">
                                    <div class="descriptionTitle">{{ stolenInfo.firstname }} {{ stolenInfo.lastname }}</div>
                                    <div>{{ stolenInfo.address }}</div>
                                    <div>{{ stolenInfo.zip }}, {{ stolenInfo.city }}</div>
                                    <div>{{ stolenInfo.country }}</div>
                                    <div>{{ stolenInfo.email }}</div>
                                    <div>{{ stolenInfo.phone }}</div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
                
            </div>
            <div class="col-12 col-lg-6">
                <mat-list>            
                    <ng-container *ngIf="camera.unverifiedBackofficeRegisteredCamera">
                        <div class="d-flex align-items-center">
                            <mat-icon mat-list-icon class="listIcon">cloud_off</mat-icon>
                            <h3 mat-subheader>{{ 'cameraDetail.unverifiedBackofficeRegisteredTitle' | translate }}</h3>                    
                        </div>
                        <mat-divider></mat-divider>
                        <mat-list-item class="h-auto">
                            {{ 'cameraDetail.unverifiedBackofficeRegisteredInfo' | translate }}
                        </mat-list-item>   
                    </ng-container>
                    <div class="d-flex align-items-center">
                        <mat-icon mat-list-icon class="listIcon">photo_camera</mat-icon>
                        <h3 mat-subheader>{{ 'cameraDetail.infos' | translate }}</h3>                    
                    </div>
                    <mat-divider></mat-divider>
                    <mat-list-item>
                        <h4 mat-line>{{ 'cameraDetail.firmware' | translate }}</h4>
                        <div>{{ firmwareVersion }}</div>
                    </mat-list-item>
                    <mat-list-item>
                        <h4 mat-line>{{ 'shared.battery' | translate }}</h4>
                        <div [ngClass]="{'warnText': batteryLow()}">{{ camera.battery }}</div>
                    </mat-list-item>
                    <mat-list-item class="matCameraContent">
                        <h4 mat-line>{{ 'cameraDetail.lastDailyReport' | translate }}</h4>
                        <div class="text-right">{{lastDailyReportDate}}</div>
                    </mat-list-item>
                    <mat-list-item *ngIf="camera.isOwner">
                        <h4 mat-line>{{ 'cameraDetail.settingsConfirmed' | translate }}</h4>
                        <button mat-icon-button class="statusIcon" >
                            <mat-icon *ngIf="confirmedByCam()" color="primary" class="mat-24">check_box</mat-icon>
                            <mat-icon *ngIf="!confirmedByCam()" color="primary" class="mat-24">check_box_outline_blank</mat-icon>
                        </button>
                    </mat-list-item>
                    <mat-list-item *ngIf="showResendSettings()" class="matCameraContent">
                        <h4 mat-line>{{ 'cameraDetail.resendSettingsLabel' | translate }}</h4>
                        <button mat-raised-button color="primary" (click)="resendSettings()" [disabled]="camera.resendSettingsState !== resendSettingsStateEnum.enabled">
                            {{ 'cameraDetail.resendSettingsButton' | translate }}
                        </button>
                    </mat-list-item>
                    <div class="d-flex align-items-center">
                        <mat-icon mat-list-icon class="listIcon">build</mat-icon>
                        <h3 mat-subheader>{{ 'cameraDetail.settings' | translate }}</h3>
                    </div>
                    <mat-divider></mat-divider>
                    <mat-list-item *ngIf="camera.isOwner && !camera.isStolen()" class="cursor-pointer" (click)="showCameraSettings()">
                        <h4 mat-line>{{ 'cameraDetail.cameraSettings' | translate }}</h4>
                        <button mat-icon-button>
                            <mat-icon class="mat-24 listIcon">chevron_right</mat-icon>
                        </button>
                    </mat-list-item>
                    <mat-list-item>
                        <mat-label>{{ 'cameraDetail.sortNumber' | translate }}</mat-label>
                        <mat-select class="text-right" [ngModel]="camera.sortNumber" (selectionChange)="sortNumberChanged($event)">
                            <mat-option class="text-right" *ngFor="let sortNumber of sortNumbers" [value]="sortNumber">{{sortNumber}}</mat-option>
                        </mat-select>
                    </mat-list-item>
                    <mat-list-item>
                        <h4 mat-line>{{ 'cameraDetail.pushSettings' | translate }}</h4>
                        <button mat-icon-button (click)="changePush()">
                            <mat-icon *ngIf="camera.sendPush" color="primary" class="mat-24">check_box</mat-icon>
                            <mat-icon *ngIf="!camera.sendPush" color="primary" class="mat-24">check_box_outline_blank</mat-icon>
                        </button>
                    </mat-list-item>
                    <mat-list-item *ngIf="hasHDAbo">
                        <h4 mat-line>{{ 'cameraDetail.sendHD' | translate }}</h4>
                        <button mat-icon-button (click)="changeHD()">
                            <mat-icon *ngIf="!camera.doNotSendHighRes" color="primary" class="mat-24">check_box</mat-icon>
                            <mat-icon *ngIf="camera.doNotSendHighRes" color="primary" class="mat-24">check_box_outline_blank</mat-icon>
                        </button>
                    </mat-list-item>
                    <div *ngIf="camera.isOwner" class="d-flex align-items-center">
                        <mat-icon mat-list-icon class="listIcon">share</mat-icon>
                        <h3 mat-subheader>{{ 'cameraDetail.share' | translate }}</h3>
                    </div>                
                    <mat-divider *ngIf="camera.isOwner" ></mat-divider>
                    <mat-list-item *ngIf="camera.isOwner" class="cursor-pointer" (click)="showFriends()">
                        <h4 mat-line>{{ 'cameraDetail.friends' | translate }}</h4>
                        <button mat-icon-button>
                            <mat-icon class="mat-24 listIcon">chevron_right</mat-icon>
                        </button>
                    </mat-list-item>
                    <mat-list-item *ngIf="camera.isOwner" class="cursor-pointer" (click)="showMails()">
                        <h4 mat-line>{{ 'cameraDetail.mail' | translate }}</h4>
                        <button mat-icon-button>
                            <mat-icon class="mat-24 listIcon">chevron_right</mat-icon>
                        </button>
                    </mat-list-item>
                    <mat-list-item *ngIf="camera.isOwner" class="cursor-pointer" (click)="showSmtps()">
                        <h4 mat-line>{{ 'cameraDetail.smtp' | translate }}</h4>
                        <button mat-icon-button>
                            <mat-icon class="mat-24 listIcon">chevron_right</mat-icon>
                        </button>
                    </mat-list-item>
                </mat-list>
            </div>
        </div>
        <div class="row justify-content-center mt-auto">
            <div class="col-12 col-md-6 col-lg-3 mb-3 py-1">
                <button class="signInButton" mat-raised-button color="primary" (click)="showPictures()">{{'cameraDetail.showPictures' | translate }}</button>
            </div>
        </div>
    </div>
</div>
