import { Component, OnInit, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthRestService } from "src/app/services/http/AuthRestService";
import { TranslateService } from "@ngx-translate/core";
import { PasswordReqModel } from "src/app/api-handling/models/PasswordRepModel";
import { BaseComponent } from "../base/base.component";
import { MatDialog } from "@angular/material/dialog";
import { LanguageDetector } from "src/app/helpers/LanguageDetector";
import { DialogService } from "src/app/services/DialogService";
import { from } from "rxjs";

@Component({
    selector: "app-password",
    templateUrl: "password.component.html"
})

export class PasswordComponent extends BaseComponent implements OnInit {

    resetForm: FormGroup;
    submitted = false;

    constructor(private router: Router, private formBuilder: FormBuilder,
        private restService: AuthRestService, private translateService: TranslateService,
        public dialog: MatDialog, public zone: NgZone, dialogService: DialogService) {
        super(dialog, dialogService);
    }

    ngOnInit(): void {
        this.resetForm = this.formBuilder.group({
            mail: ["", [Validators.required, Validators.email]]
        });
    }

    public backToLogin() {
        this.router.navigate(["signin"]);
    }

    public resetPassword() {
        if ( !this.resetForm.invalid ) {
            this.submitted = true;
            from(LanguageDetector.getLanguage()).subscribe((locale) => {
                this.zone.run(() => {
                    this.showSpinner(this.translateService.instant("password.load"), "");
                    const model = new PasswordReqModel(this.resetForm.value.mail, locale ? locale : "en");
                    this.subscriptions.push(this.restService.resetPassword(model).subscribe((response) => {
                        this.closeSpinner();
                        this.openDialog(this.translateService.instant("password.sendMailTitle"),
                            this.translateService.instant("password.sendMail"), null).subscribe(() => {
                            this.zone.run(() => {
                                this.backToLogin();
                            });
                        });
                    }, (err) => {
                        this.submitted = false;
                        this.closeSpinner();
                    }));
                });
            });
        }
    }
}
