<app-toolbar [name]="'navigation.newcamera'" [leftBarButton]="backBarButton"></app-toolbar>
<div class="container-fluid container-overflow-scroll container-safe-area-bottom d-flex flex-column align-items-stretch">
    <div class="row justify-content-center">
        <div class="col-12 col-md-8 col-lg-5 px-3">
            <div class="descriptionInfo mt-3">{{ 'newcamera.info' | translate }}</div>
        </div>                
    </div>
    <div class="row justify-content-center">
        <div class="col-12 col-md-8 col-lg-5 px-3">
            <form [formGroup]="newCameraForm" id="ngForm" class="d-flex flex-column justify-content-between align-items-center w-100">                
                <div class="inputContainer">
                    <div class="d-flex align-items-baseline justify-content-between">
                        <mat-form-field appearance="fill" class="doerrInput col-auto" [ngClass]="{'col-12' : isWeb(), 'col-10' : !isWeb()}">
                            <input matInput type="text" inputmode="tel" placeholder="{{'newcamera.imeiPlaceholder' | translate}}" formControlName="imei" [(ngModel)]="imei" (blur)="onBlur()" maxLength="15">                        
                            <mat-error>{{ 'newcamera.validImei' | translate }}</mat-error>
                        </mat-form-field>
                        <button *ngIf="!isWeb()" mat-icon-button matSuffix (click)="openScanner()">
                            <mat-icon color="primary">{{'qr_code_scanner'}}</mat-icon>
                        </button>
                    </div>                    
                    <mat-form-field appearance="fill" class="doerrInput">
                        <input matInput type="tel" placeholder="{{'newcamera.phonePlaceholder' | translate}}" formControlName="phone" (blur)="onBlur()">
                        <mat-hint>{{'newcamera.phoneHint' | translate}}</mat-hint>
                        <mat-error>{{ 'shared.validPhone' | translate }}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="doerrInput">
                        <mat-select formControlName="country" placeholder="{{'newcamera.countryPlaceholder' | translate}}">
                            <mat-option *ngFor="let country of countries" [value]="country.code">{{country.name}}</mat-option>
                        </mat-select>
                        <mat-error>{{ 'shared.selectCountry' | translate }}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="doerrInput">
                        <input matInput type="text" placeholder="{{'newcamera.namePlaceholder' | translate}}" formControlName="name" (blur)="onBlur()">
                        <mat-error>{{ 'newcamera.validName' | translate }}</mat-error>
                    </mat-form-field>
                </div>                      
            </form>
        </div>        
    </div>
    <div class="row justify-content-center mt-auto">
        <div class="col-12 col-md-5 col-lg-3 mb-3">
            <button class="signInButton" mat-raised-button color="primary" form="ngForm" (click)="addCamera()">{{'shared.next' | translate }}</button>
        </div>  
    </div>
</div>