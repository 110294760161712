<h1 mat-dialog-title>{{'abo.paymentDialogTitle' | translate}}</h1>
<div mat-dialog-content class="pb-4">
    <div>{{data.name | translate}}</div>
    <small>{{data.message | translate}}</small>
</div>
<div mat-dialog-content class="overflow-hidden">
    <mat-radio-group [value]="selected">
        <mat-radio-button value="paypal" color="primary" labelPosition="before" (click)="switchPayment('paypal')">
            <img matListAvatar src="assets/img/paypal_icon.png" class="paymentIcon" alt="PayPal Icon">
            {{'abo.paypal' | translate}}    
        </mat-radio-button>
    </mat-radio-group>  
</div>
<div mat-dialog-actions>
    <button mat-button (click)="cancelButtonPressed()" cdkFocusInitial>{{'shared.cancelButton' | translate}}</button>
    <button mat-button (click)="payButtonPressed()" cdkFocusInitial>{{'abo.pay' | translate}}</button>
</div>