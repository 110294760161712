

import { Injectable, ErrorHandler, Injector, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";
import { environment } from "src/environments/environment";
import { TrackingService } from "../TrackingService";
import { DeviceDetector } from "../../helpers/DeviceDetector";
import { Subscription } from "rxjs";
@Injectable({
    providedIn: "root"
})
export class SentryErrorHandler implements ErrorHandler, OnDestroy {

    private isInitialised: boolean = false;
    private subscriptions: Subscription[] = [];

    constructor(private injector: Injector, private trackingService: TrackingService) {
        if (!DeviceDetector.isOnCordova()) {
            this.subscriptions.push(trackingService.settingsChanged$.subscribe((settings) => {
                if (settings.allowsAnalytics && !this.isInitialised) {
                    this.initSentry();
                }
            }));
        }
    }

    ngOnDestroy(): void {
        for (const sub of this.subscriptions) {
            sub.unsubscribe();
        }
    }

    /**
     * Sentry for web application.
     */
    initSentry() {
        Sentry.init({

            dsn: environment.sentryDns ,
            environment: environment.sentryEnvironmentName,
            debug: environment.logErrorInConsole,
            integrations: [
              // Registers and configures the Tracing integration,
              // which automatically instruments your application to monitor its
              // performance, including custom Angular routing instrumentation
              new Integrations.BrowserTracing({
                tracingOrigins: ["localhost", "https://doerr.cloud/", "https://camera.doerr-outdoor.de/", "https://doerr-wildcamera.qa.impacgroup.de/"],
                routingInstrumentation: Sentry.routingInstrumentation,
              }),
            ],
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,
        });
    }

    handleError(error: any) {
        console.log(error);
        if (this.trackingService.currentSettings.allowsAnalytics || DeviceDetector.isOnCordova()) {
            const router = this.injector.get(Router);
            const eventId = Sentry.captureException(error.originalError || error);
            if (Error instanceof HttpErrorResponse) {
                console.log(error.status);
            } else {
                console.error("error occured and reported: " + error);
            }
        } else {
            console.error(error);
        }
    }
}
