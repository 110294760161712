import * as moment from "moment";

export class PayPalAboReqModel {
    public billingPlanId: string;
    public name: string;
    public description: string;
    public start_date: string;

    constructor(billingPlanId: string, name: string, description: string) {
        this.start_date = moment(new Date()).add(10, "m").format("YYYY-MM-DD[T]HH:mm:ss[Z]");
        this.billingPlanId = billingPlanId;
        this.name = name;
        this.description = description;
    }
}
