import { ImageResponseModel } from "../api-handling/models/ImageResponseModel";
import { Observable, Subject } from "rxjs";
import { IMAGE_TYPE } from "../api-handling/models/enums/ImageType";
import { Injectable } from "@angular/core";
import * as Sentry from "@sentry/browser";
interface DatabaseInfos {
    name: string;
    location: string;
    androidDatabaseProvider?: string;
}

export interface DbImage {
    _id: string;
    readableName: string;
    uploaded: Date;
    type: IMAGE_TYPE;
    favorite: boolean;
    isOwner: boolean;
    path: string;
    cameraName: string;
}

const imageTableName = "ImagesTable";

declare var window: {sqlitePlugin: {echoTest: (onSuccess: (result: any) => void) => void, openDatabase: (dbInfos: DatabaseInfos, onSuccess: (result: any) => void, onError: (error: any) => void) => Promise<any> }};

@Injectable()
export class DatabaseService {

    private db: any;
    private isOpened = false;
    private openingObservable: Observable<any> | null = null;

    public openDatabase(): Observable<any> | null {
        if (!this.db && !this.openingObservable) {
            const openSub = new Subject<any>();
            this.db = window.sqlitePlugin.openDatabase({name: "doerr_image.db", location: "default", androidDatabaseProvider: "system"}, (result: any) => {
                this.isOpened = true;
                openSub.complete();
            }, (err) => {
                this.isOpened = false;
                this.db = null;
                openSub.error(err);
            });
            this.openingObservable = openSub.asObservable();
            return this.openingObservable;
        }
        return this.openingObservable;
    }

    public clearDatabase(): Observable<any> {
        if (this.db) {
            const sub = new Subject<any>();
            this.db.transaction((tx) => {
                tx.executeSql("DROP TABLE " + imageTableName , []);
            }, (error) => {
                console.log(error);
                sub.error(error);
            }, () => {
                sub.next(true);
            });
            return sub.asObservable();
        }
        return null;
    }

    public deleteImageById(id: string): Observable<any> {
        if (this.db) {
            const sub = new Subject<any>();
            this.db.transaction((tx) => {
                tx.executeSql("DELETE FROM " + imageTableName + " WHERE _id LIKE '" + id + "'", []);
            }, (error) => {
                console.log(error);
                sub.error(error);
            }, () => {
                sub.next(true);
            });
            return sub.asObservable();
        }
        return null;
    }

    public async setImages(images: ImageResponseModel[]) {
        const imagesToUpdate: ImageResponseModel[] = [];
        const imagesToInsert: ImageResponseModel[] = [];
        for (const img of images) {
            const storedImg = await this.getImageById(img._id);
            if (storedImg) {
                imagesToUpdate.push(img);
            } else {
                imagesToInsert.push(img);
            }
        }
        await this.updateImages(imagesToUpdate);
        await this.insertImages(imagesToInsert);
    }

    public async setImagePath(imageId: string, path: string): Promise<any> {
        const sub = new Subject<any>();
        await this.dbReady();
        this.db.transaction((tx) => {
            tx.executeSql("UPDATE " + imageTableName + " SET path = ?1 WHERE _id LIKE '" + imageId + "'", [path]);
        }, (error) => {
            console.log(error);
            sub.error(error);
        }, () => {
            sub.next(true);
            sub.complete();
        });
        return sub.toPromise();
    }

    private updateImages(images: ImageResponseModel[]): Promise<any> {
        if (this.db) {
            const sub = new Subject<any>();
            this.db.transaction((tx) => {
                for (const img of images) {
                    tx.executeSql("UPDATE " + imageTableName + " SET readableName = ?1, favorite = ?2 WHERE _id LIKE '" + img._id + "'", [img.readableName ? img.readableName : "",
                    img.favorite !== undefined ? Number(img.favorite) : -1]);
                }
            }, (error) => {
                console.log(error);
                sub.error(error);
            }, () => {
                sub.next(true);
                sub.complete();
            });
            return sub.toPromise();
        }
        return null;
    }

    private insertImages(images: ImageResponseModel[]): Promise<any> | null {
        if (this.db) {
            const sub = new Subject<any>();
            this.db.transaction((tx) => {
                tx.executeSql("CREATE TABLE IF NOT EXISTS " + imageTableName + " (_id TEXT PRIMARY KEY, readableName TEXT, uploaded TEXT, favorite INTEGER, path TEXT, cameraName TEXT)");
                for (const img of images) {
                    tx.executeSql("INSERT INTO " + imageTableName + " VALUES (?1,?2,?3,?4,?5,?6)", [img._id,
                        img.readableName ? img.readableName : "",
                        img.uploaded ? img.uploaded.toString() : "",
                        img.favorite !== undefined ? Number(img.favorite) : -1,
                        null,
                        img.camera.name]);
                }
            }, (error) => {
                console.log(error);
                sub.error(error);
            }, () => {
                sub.next(true);
                sub.complete();
            });
            return sub.toPromise();
        }
        return null;
    }

    public async getImageById(_id: string): Promise<DbImage | null> {
        await this.dbReady();
        const sub = new Subject<DbImage>();
        this.db.transaction((tx) => {
            tx.executeSql("CREATE TABLE IF NOT EXISTS " + imageTableName + " (_id TEXT PRIMARY KEY, readableName TEXT, uploaded TEXT, favorite INTEGER, path TEXT, cameraName TEXT)");
            tx.executeSql("SELECT * FROM " + imageTableName + " WHERE _id LIKE '" + _id + "'", [], (_txs: any , rs: any) => {
                if (rs.rows.length > 0) {
                    const img = rs.rows.item(0) as DbImage;
                    img.uploaded = new Date(rs.rows.item(0).uploaded);
                    sub.next(img);
                } else {
                    sub.next(null);
                }
                sub.complete();
            });
        }, (error) => {
            console.log(error);
            sub.error(error);
        });
        return sub.toPromise();
    }

    public async getAllImages(): Promise<DbImage[] | null> {
        const sub = new Subject<DbImage[]>();
        await this.dbReady();
        this.db.transaction((tx) => {
            tx.executeSql("CREATE TABLE IF NOT EXISTS " + imageTableName + " (_id TEXT PRIMARY KEY, readableName TEXT, uploaded TEXT, favorite INTEGER, path TEXT, cameraName TEXT)");
            tx.executeSql("SELECT * FROM " + imageTableName, [], (_txs: any, rs: any) => {
                if (rs.rows.length > 0) {
                    const images: DbImage[] = [];
                    for (let index = 0; index < rs.rows.length; index++) {
                        const img = rs.rows.item(index) as DbImage;
                        img.uploaded = new Date(rs.rows.item(index).uploaded);
                        images.push(img);
                    }
                    sub.next(images.sort((a: DbImage, b: DbImage) => {
                        return b.uploaded.getTime() - a.uploaded.getTime();
                    }));
                } else {
                    sub.next([]);
                }
                sub.complete();
            });
        }, (error) => {
            console.log(error);
            sub.error(error);
        });
        return sub.toPromise();
    }

    /**
     * Checks is the db ready and waits until db opened.
     */
    private async dbReady(): Promise<void> {
        if (!this.isOpened || !this.db) {
            try {
                await this.openDatabase().toPromise();
            } catch (error) {
                Sentry.captureException(error);
            }
        }
    }
}

