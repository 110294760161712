import { animate, style, transition, trigger, group, query } from "@angular/animations";

const groupAnimation = group([
    query(":leave", style({ position: "absolute", left: 0, right: 0, opacity: 1 }), { optional: true }),
    query(":enter", style({ position: "absolute", left: 0, right: 0, opacity: 0 }), {optional: true}),
    query(":leave", animate("0.25s", style({ opacity: 0 })), { optional: true }),
    query(":enter", animate("0.25s", style({ opacity: 1 })), {optional: true})
]);

export const PRESENT = trigger(
    "routeAnimations", [
        transition("* => cameras", [
            groupAnimation
        ]),
        transition("* => images", [
            groupAnimation
        ]),
        transition("* => favourites", [
            groupAnimation
        ]),
        transition("* => abo", [
            groupAnimation
        ]),
        transition("* => imprint", [
            groupAnimation
        ]),
        transition("* => datapolicy", [
            groupAnimation
        ])
    ]
);
