<div class="d-flex justify-content-between align-items-baseline">
    <h1 mat-dialog-title>{{'abos.apple.quotaSelectionTitle' | translate}}</h1>
    <mat-icon (click)="cancelButtonPressed()">close</mat-icon>
</div>
<div mat-dialog-content class="cursor-pointer d-flex justify-content-between">    
    <div class="smaller-text" (click)="showTerms()">{{'abos.privacyterms' | translate}}</div>
    <div class="smaller-text">|</div>
    <div class="smaller-text" (click)="showPolicy()">{{'abos.datapolicy' | translate}}</div> 
</div>
<div mat-dialog-content>
    <mat-radio-group [value]="selected">
        <div class="row my-3 align-self-center" *ngFor="let item of data.products">
            <small class="m-0 col font-weight-bold flex-shrink-0 flex-grow-1">{{item.localizedTitle}}</small>
            <div class="col flex-shrink-1 flex-grow-0">
                <mat-radio-button [value]="item.id" color="primary" labelPosition="before"
                    (click)="selectProduct(item.id)">
                    <span class="text-muted m-0 p-0">{{item.price}} {{item.currency}}/{{'abos.month' | translate}}</span>
                </mat-radio-button>
            </div>
            <small class="text-muted m-0 col-12">{{item.localizedDescription}}</small>
        </div>
    </mat-radio-group>
</div>
<div mat-dialog-actions>
    <button class="signInButton px-1" mat-raised-button color="primary" (click)="payButtonPressed()"
        [disabled]="!selected || (currentAboSelection === selected)"
        cdkFocusInitial>{{'abo.payPicturesContingent' | translate}}</button>
</div>