<div class="container-fluid w-100 h-100 transparent-background">
    <div class="row align-items-center h-100 justify-content-center py-3">
        <div class="col-10 col-sm-8 col-md-6 d-flex white-container mh-100 flex-column p-3">
            <div class="descriptionTitle">{{'cookiebanner.title' | translate }}</div>
            <div *ngIf="isShowingSettings">
                <div class="descriptionInfo">{{'cookiebanner.settingsText' | translate }}</div>
                <div class="row my-3">
                    <div class="col-12 d-flex align-items-center justify-content-between">
                        <div>{{ 'cookiebanner.marketingTitle' | translate }}</div>
                        <button mat-icon-button (click)="changeTracking()">
                            <mat-icon *ngIf="allowTracking" color="primary" class="mat-24">check_box</mat-icon>
                            <mat-icon *ngIf="!allowTracking" color="primary" class="mat-24">check_box_outline_blank</mat-icon>
                        </button>
                    </div>
                    <div class="col-12 descriptionInfo">{{'cookiebanner.settings.marketingInfo' | translate}}</div>

                    <div class="col-12 d-flex align-items-center justify-content-between pt-3">
                        <div>{{ 'cookiebanner.analyticsTitle' | translate }}</div>
                        <button mat-icon-button (click)="changeAnalytics()">
                            <mat-icon *ngIf="allowAnalytics" color="primary" class="mat-24">check_box</mat-icon>
                            <mat-icon *ngIf="!allowAnalytics" color="primary" class="mat-24">check_box_outline_blank</mat-icon>
                        </button>
                    </div>                    
                    <div class="col-12 descriptionInfo">{{'cookiebanner.settings.analyticsInfo' | translate}}</div>

                    <div class="col-12 d-flex align-items-center justify-content-between pt-3 pb-2">
                        <div>{{ 'cookiebanner.technicalTitle' | translate }}</div>
                    </div>
                    <div class="col-12 descriptionInfo">{{'cookiebanner.settings.technicalInfo' | translate}}</div>
                </div>                
            </div>
            <div *ngIf="!isShowingSettings" class="descriptionInfo">{{'cookiebanner.text' | translate }}</div>
            <div class="d-lg-flex mt-2 flex-row-reverse">
                <button class="btn-item" mat-raised-button color="primary" (click)="acceptButtonPressed()">{{'cookiebanner.acceptBtn' | translate }}</button>
                <div class="d-none d-lg-block mx-2"></div>
                <button *ngIf="isShowingSettings" class="btn-item mt-3 mt-lg-0" mat-raised-button (click)="declineButtonPressed()">{{'cookiebanner.declineBtn' | translate }}</button>
                <button *ngIf="!isShowingSettings" class="btn-item mt-3 mt-lg-0" mat-raised-button (click)="settingsButtonPressed()">{{'cookiebanner.settingsBtn' | translate }}</button>
            </div>            
        </div>
    </div>
</div>