<app-toolbar [name]="'update.title'" [leftBarButton]="backBarButton"></app-toolbar>
<div class="container-overflow-scroll">
    <div class="container-fluid container-safe-area-bottom d-flex flex-column align-items-stretch minHeight">
        <div class="row">
            <div class="col-12 mt-3">
                <div class="text-center descriptionText">{{'update.overviewTitle' | translate}}</div>
                <div class="text-center primTextColor font-weight-bold">{{'update.overviewSubtitle' | translate}}</div>
                <div class="text-center descriptionText">{{ versionsNummer }}</div>
            </div>
            <div class="col-12 mt-3">
                <div class="text-center warningText">{{'update.warningText' | translate}}</div>
            </div>
            <div class="col-12 align-self-center my-5">
                <img class="img-fluid mx-auto d-block" srcset="assets/img/update_step_1@2x_de.jpg 2x, assets/img/update_step_1@3x_de.jpg 3x" alt="">
            </div>
        </div>
        <div class="row justify-content-center mt-auto">
            <div class="col-12 col-md-6 col-lg-3 mb-3 py-1">
                <button class="signInButton" mat-raised-button color="primary" (click)="nextStep()">{{'update.startUpdate' | translate }}</button>
            </div>
        </div>
    </div>
</div>
