import { Component, OnInit, ViewChild, NgZone } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { firstValueFrom, lastValueFrom, Observable, of } from "rxjs";
import { map } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";
import { DeviceDetector } from "src/app/helpers/DeviceDetector";
import { MatSidenav } from "@angular/material/sidenav";
import { MatDialog } from "@angular/material/dialog";
import { FirebaseService } from "src/app/services/FirebaseService";
import { NavigationService } from "src/app/services/NavigationService";
import { PRESENT } from "src/app/animations/present.animation";
import { DialogService } from "src/app/services/DialogService";
import { AppInfoService } from "src/app/native/AppInfoService";
import { InAppPaymentService } from "src/app/native/InAppPaymentService";
import { AdoptSubscriptionsDialogComponent } from "../profile/components/adoptsubscriptionsdialog/adoptsubscriptionsdialog.component";
import { TranslateService } from "@ngx-translate/core";
import { InAppPaymentStatus } from "src/app/enums/InAppPaymentStatus";
import { TrackingService } from "src/app/services/TrackingService";
import { BaseCookieComponent } from "../base/baseCookie.component";
import * as Sentry from "@sentry/browser";
import { environment } from "src/environments/environment";
import { BreakpointService } from "src/app/services/BreakpointService";
import { UserRepository } from "src/app/repositories/UserRepository";
import { ImageFilterService } from "src/app/services/ImageFilterService";

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
  animations: [
    PRESENT
  ]
})
export class NavigationComponent extends BaseCookieComponent implements OnInit {

    @ViewChild("drawer", {static: true}) sidenav: MatSidenav;

    public childName: string;
    public selected = "";
    public appVersion$: Observable<string>;
    public menuMode: string;
    public isMenuOpenDefault: boolean;
    public isMenuOpen: boolean;
    public animation: string;

    constructor(private breakpointObserver: BreakpointObserver, private router: Router,
        public dialog: MatDialog, private firebase: FirebaseService, private zone: NgZone,
        navigationService: NavigationService, private activatedRoute: ActivatedRoute,
        dialogService: DialogService, private appInfoService: AppInfoService,
        private inappPaymentService: InAppPaymentService, private translate: TranslateService,
        trackingService: TrackingService, private breakpointService: BreakpointService,
        private readonly userRepository: UserRepository, public imageFilterService: ImageFilterService) {
            super(dialog, dialogService, trackingService);
            this.observeMenuMode();
            this.setVersion();
            if (DeviceDetector.isOnCordova()) {
                if (this.isOnline()) {
                    this.setupFirebase();
                }
                const navigation = router.getCurrentNavigation();
                if (navigation && navigation.extras && navigation.extras.queryParams && navigation.extras.queryParams.refreshPayments) {
                    this.inappPaymentService.refreshStatus();
                }
                this.listenToPaymentUpdates();
            }
            this.subscriptions.push(navigationService.changeEmitted$.subscribe(() => {
                this.sidenav.toggle();
            }));
    }

    private observeMenuMode() {
        this.isMenuOpenDefault = this.breakpointService.menuModeOver;
        this.menuMode = this.breakpointService.menuModeOver ? "over" : "side";
        this.subscriptions.push(this.breakpointService.isMenuModeOver$
            .pipe(
                map(e => {
                    this.isMenuOpenDefault = e;
                    return e;
                }),
                map(e => {
                    return e ? "over" : "side";
                })
            )
            .subscribe((mode) => {
                this.menuMode = mode;
            })
        );
    }

    private listenToPaymentUpdates() {
        // Listen on updates after login for in app payments
        this.subscriptions.push(this.inappPaymentService.subscribeUpdateChanged().subscribe((message) => {
            if (message && message.status === InAppPaymentStatus.userViolation) {
                this.subscriptions.push(this.openDialog(
                    this.translate.instant("abos.apple.userViolationTitle"),
                    this.translate.instant("abos.apple.userViolationSubtitle"),
                    [this.translate.instant("abos.apple.userViolationCancel"), this.translate.instant("abos.apple.userViolationConfirm")]).subscribe((result) => {
                        if (result === 1) {
                            this.zone.run(() => {
                                this.openLoginAdoptIOSSubscriptionsDialog();
                            });
                        }
                    }
                ));
            }
        }));
    }


    // iOS: Transfer subscriptions to another dörr account
    public openLoginAdoptIOSSubscriptionsDialog() {
        const dialogRef = this.dialog.open(AdoptSubscriptionsDialogComponent, {
            width: "300px"
        });
        this.subscriptions.push(dialogRef.afterClosed().subscribe((dialogResult) => {
            if (dialogResult.mail && dialogResult.password) {
                this.showSpinner(this.translate.instant("shared.transferAbo"), "");
                this.subscriptions.push(this.inappPaymentService.transferTransactions(dialogResult.mail, dialogResult.password).subscribe((result) => {
                    this.closeSpinner();
                    this.openDialog(
                        this.translate.instant("abos.apple.adoptedSubscriptionTitle"),
                        this.translate.instant("abos.apple.adoptedSubscriptionSubtitle"),
                        [this.translate.instant("abos.apple.adoptedSubscriptionOK")]);
                }, (err) => {
                    this.closeSpinner();
                    this.openLoginAdoptIOSSubscriptionsDialog();
                }));
            }
        }));
    }

    ngOnInit(): void {
        if (this.activatedRoute.snapshot.firstChild) {
            this.selected = this.activatedRoute.snapshot.firstChild.data["menu"];
            this.animation = this.activatedRoute.snapshot.firstChild.data["animation"];
        }
    }

    private setVersion() {
        if (DeviceDetector.isOnCordova()) {
            this.appVersion$ = this.appInfoService.getVersion().pipe(
                map((result) => {
                    return environment.prefix + result;
                })
            );
        } else {
            this.appVersion$ = of(environment.prefix + environment.version);
        }
    }

    /**
     * Checks permissions for firebase and setups push notifications.
     */
    private setupFirebase() {
        const subscription = this.firebase.checkPushPermission().subscribe((success) => {
            this.zone.run(() => {
                if (success) {
                    const updateSubscription = this.firebase.updateToken().subscribe(() => {

                    }, (err) => {
                        console.log(err);
                    });
                    this.subscriptions.push(updateSubscription);
                }
            });
        }, (err) => {
            Sentry.captureException(err);
        });
        this.subscriptions.push(subscription);
    }

    public showRoute(route: string) {
        // this.selected = route;
        if (route === 'images' || route === 'favourites' ) {
            this.imageFilterService.lastImageId = undefined;
        }

        this.router.navigate(["home/" + route]);
        this.childName = "navigation." + route;
        if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
            this.sidenav.toggle();
        }
    }

    /**
     * Sign out user from application. All user data will be removed and if Cordova app the push token will be unregistered.
     */
    public async signOut() {
        this.showSpinner("", "");
        await this.userRepository.signOut();
        this.closeSpinner();
        this.router.navigate(["signin"]);
    }
}
