<app-toolbar [name]="!isFav ? 'navigation.images' : 'navigation.favourites'" [leftBarButton]="menuBarButton" [rightBarButtons]="[selectBarButton, barButton]"></app-toolbar>
<mat-sidenav-container class="container-overflow-scroll">
    <mat-sidenav #filterDrawer position="end" class="borderRadius">
        <app-imagefilter (filterSetEmitter)="didSetFilter()"></app-imagefilter>
        <div *ngIf="hasNoInternetConnection" class="position-absolute text-center labelPos d-flex justify-content-center align-items-center">
            {{'images.onlyOnlineFilter' | translate}}
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <app-imagelist [(listState)]="selectionState" [hasNoInternetConnection]="hasNoInternetConnection" [isFav]="isFav" (imageIdEmitter)="clickedImg($event)"></app-imagelist>
    </mat-sidenav-content>
</mat-sidenav-container>    
