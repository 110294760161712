import { Component, OnInit } from "@angular/core";
import { Breakpoints, BreakpointObserver } from "@angular/cdk/layout";
import { CameraRestService } from "src/app/services/http/CameraRestService";
import { CameraResponseModel } from "src/app/api-handling/models/CameraResponseModel";
import { MatDialog } from "@angular/material/dialog";
import { NavigationBarItemModel } from "src/app/models/NavigationBarItemModel";
import { Router } from "@angular/router";
import { DeviceDetector } from "src/app/helpers/DeviceDetector";
import { NavigationService } from "src/app/services/NavigationService";
import { BaseNavComponent } from "../base/baseNav.component";
import { Location } from "@angular/common";
import { CordovaInitHandler } from "src/app/native/CordovaInitHandler";
import { TranslateService } from "@ngx-translate/core";
import { DialogService } from "src/app/services/DialogService";
import { CameraListItem, CameraListItemButton, CameraListItemButtonType, hybridAllowedProductTypes } from "../cameraListItem/models/cameraListItem";
import { ImageFilterService } from "src/app/services/ImageFilterService";

@Component({
    selector: "app-cameras",
    templateUrl: "cameras.component.html",
    styleUrls: ["cameras.component.scss"]
})

export class CamerasComponent extends BaseNavComponent implements OnInit {
    public columns = 1;
    public rightBarButton = new NavigationBarItemModel("add");
    public cameras: CameraResponseModel[];
    public userCameraListItemHeaders: CameraListItem[] = [];
    public friendCameras: CameraListItem[] = [];
    public invites: CameraListItem[] = [];

    constructor(breakpointObserver: BreakpointObserver, private cameraRestService: CameraRestService, dialog: MatDialog, private router: Router,
        navigationService: NavigationService, location: Location, initHandler: CordovaInitHandler, private translate: TranslateService, dialogService: DialogService,
        private readonly imageFilterService: ImageFilterService) {
        super(dialog, navigationService, location, initHandler, breakpointObserver, dialogService);
        this.subscriptions.push(this.rightBarButton.action.subscribe(() => {
            this.addCameraButtonPressed();
        }));
    }

    ngOnInit() {
        this.columns = this.breakpointObserver.isMatched(Breakpoints.Handset) ? 1 : 2;
        this.breakpointObserver.observe(Breakpoints.Small).subscribe((result) => {
            if (result.matches) {
                this.columns = 1;
            }
        });
        this.breakpointObserver.observe(Breakpoints.Medium).subscribe((result) => {
            if (result.matches) {
                this.columns = 2;
            }
        });
        this.breakpointObserver.observe(Breakpoints.Large).subscribe((result) => {
            if (result.matches) {
                this.columns = 3;
            }
        });

        this.update(this.imageFilterService.cameras);
        this.subscriptions.push(this.imageFilterService.cameras$.subscribe((result) => {
            this.update(result);
        }));

        this.loadCameras();
    }

    updateOnResume() {
        this.loadCameras();
    }

    private loadCameras() {
        if (this.isOnline()) {
            this.subscriptions.push(this.translate.get("cameras.load").subscribe((translation) => {
                this.showSpinner(translation, "");
                this.subscriptions.push(this.imageFilterService.filterParameterObservable$.subscribe(() => {
                    this.closeSpinner();
                }));
                this.imageFilterService.updateFilterParameters(true);
            }));
            this.loadInvites();
        }
    }

    private update(cameras: CameraResponseModel[]) {
        this.userCameraListItemHeaders = [];
        this.friendCameras = [];
        for (const camera of cameras) {
            if (camera.isOwner) {
                this.userCameraListItemHeaders.push({
                    id: camera._id,
                    title: camera.name,
                    subtitle: camera.imei,
                    showBattery: this.showBatteryAlert(camera),
                    showStolen: camera.stolen !== undefined,
                    showUnverifiedBackofficeRegistered: camera.unverifiedBackofficeRegisteredCamera,
                    productType: camera.productType,
                    supportedProduct: hybridAllowedProductTypes.includes(camera.productType)
                });
            } else {
                this.friendCameras.push({
                    id: camera._id,
                    title: camera.name,
                    subtitle: `${camera.owner.firstname} ${camera.owner.lastname}`,
                    showBattery: false,
                    productType: camera.productType,
                    supportedProduct: hybridAllowedProductTypes.includes(camera.productType)
                });
            }
        }
    }

    /**
     * Load all invitations from other users.
     */
    private loadInvites() {
        this.showSpinner("", "");
        this.subscriptions.push(this.cameraRestService.getInviteList().subscribe((resultInvites) => {
            this.invites = [];
            for (const invite of resultInvites) {
                this.invites.push({
                    id: invite.camera._id,
                    title: invite.camera.name,
                    subtitle: invite.camera.user.email,
                    showBattery: false,
                    actions: [{
                        id: 0,
                        type: CameraListItemButtonType.button,
                        info: invite._id,
                        titles: ["cameras.acceptInvite"],
                        color: "primary"
                    },
                    {
                        id: 1,
                        type: CameraListItemButtonType.button,
                        info: invite._id,
                        titles: ["cameras.rejectInvite"],
                        color: "warn"
                    }],
                    // we can always acceppt or decline an invitation, regardless of product type
                    supportedProduct: true
                });
            }
            this.closeSpinner();
        }, (err) => {
            this.closeSpinner();
        }));
    }

    private addCameraButtonPressed() {
        if (this.isOnline()) {
            this.router.navigate(["home/newcamera"]);
        } else {
            this.openDialog(this.translate.instant("shared.notOnlineTitle"), this.translate.instant("shared.needsOnline"), null);
        }
    }

    public goBackButtonPressed() {
        this.router.navigate(["home/images"]);
    }

    public onCameraClicked(item: CameraListItem) {
        this.router.navigate(["home/cameraDetail"], { queryParams: {id: item.id}});
    }

    public onButtonEvent(button: CameraListItemButton) {
        if (button.id === 0 && button.info) {
            this.acceptInvite(button.info);
        } else if (button.id === 1 && button.info) {
            this.rejectInvite(button.info);
        }
    }

    public rejectInvite(inviteId: string) {
        this.showSpinner("", "");
        this.subscriptions.push(this.cameraRestService.rejectInvite(inviteId).subscribe((resultInvite) => {
            this.closeSpinner();
            this.loadCameras();
        }, (err) => {
            this.closeSpinner();
        }));
    }

    public acceptInvite(inviteId: string) {
        this.showSpinner("", "");
        this.subscriptions.push(this.cameraRestService.acceptInvite(inviteId).subscribe((resultInvite) => {
            this.closeSpinner();
            this.imageFilterService.updateFilterParameters(true);
            this.loadCameras();
        }, (err) => {
            this.closeSpinner();
        }));
    }

    public showBatteryAlert(camera: CameraResponseModel): boolean {
        return camera.getBattery() <= 30;
    }
}
