<app-toolbar [name]="'newSettings.title'" [leftBarButton]="backBarButton"></app-toolbar>
<div class="container-fluid container-overflow-scroll">    
    <div class="row h-100 justify-content-center">
        <div class="col-12 px-0">
            <app-camerasettings [cameraSettings]="defaultCameraSettings" (notify)="onNotify($event)"></app-camerasettings>
        </div>
        <div class="col-12 col-md-5 col-lg-3 mb-3 mt-auto">
            <button class="signInButton" (click)="signUpCamera()" mat-raised-button color="primary" >{{'newcamera.addCamera' | translate }}</button>
        </div>        
    </div>
</div>

