import { Injectable } from "@angular/core";
import { FirebaseRestService } from "./http/FirebaseRestService";
import { Observable, Subject, combineLatest } from "rxjs";
import { AppUserService } from "./database/AppUserService";
import { DeviceDetector } from "../helpers/DeviceDetector";

declare var window: {
    FirebasePlugin: {
        hasPermission(callback: (data: any) => void, error: (error: any) => void ): void;
        grantPermission( callback: (data: any) => void, error: (error: any) => void ): void; unregister(): void;
        getToken(callback: (token: string) => void, error: (error: any) => void): void;
        setAnalyticsCollectionEnabled(value: boolean): void;
        setCrashlyticsCollectionEnabled(value: boolean, callback: (data: any) => void, error: (error: any) => void): void;
        setPerformanceCollectionEnabled(value: boolean): void;
        sendCrash(): void;
        logError(msg: string, stackTrace: any): void;
    }
};

@Injectable()
export class FirebaseService {

    constructor(private firebaseRestApi: FirebaseRestService, private databaseService: AppUserService) {
    }

    /**
     * Enable Crashlytics for cordova applications.
     */
    public crashReporting() {
        window.FirebasePlugin.setCrashlyticsCollectionEnabled(DeviceDetector.isOnCordova(), function() {
        }, function(error) {
            console.log(error);
        });
    }

    /**
     * Enable Google analytics
     * @param value bool that describes if analytics is enabled
     */
    public trackAnalytics(value: boolean) {
        window.FirebasePlugin.setAnalyticsCollectionEnabled(value);
        window.FirebasePlugin.setPerformanceCollectionEnabled(value);
    }

    public crash(): void {
        window.FirebasePlugin.sendCrash();
    }

    /**
     * Checks if a push token is stored.
     */
    public hasToken(): Observable<boolean> {
        const pushTokenSub = new Subject<boolean>();
        this.databaseService.getPushToken().then((token) => {
            if ( token ) {
                pushTokenSub.next(true);
            } else {
                pushTokenSub.next(false);
            }
        });
        return pushTokenSub.asObservable();
    }

    /**
     * Checks if the user has give permission to send push notifications.
     */
    public checkPushPermission(): Observable<boolean> {
        const pushSub = new Subject<boolean>();
        window.FirebasePlugin.hasPermission((data) => {
            if (!data) {
                window.FirebasePlugin.grantPermission(() => {
                    pushSub.next(true);
                }, (err) => {
                    pushSub.error(err);
                });
            } else {
                pushSub.next(true);
            }
        }, (err) => {
            pushSub.error(err);
        });
        return pushSub;
    }

    /**
     * Removes current firebase token from server.
     */
    public unregisterPush(): Observable<any> {
        const unregisterResult = new Subject<any>();
        window.FirebasePlugin.getToken((token) => {
            this.firebaseRestApi.removeToken(token).subscribe(unregisterResult);
        }, (err) => {
            console.log(err);
            unregisterResult.error(err);
        });
        return unregisterResult;
    }

    /**
     * Checks if the token is like the stored token. If the tokens not equal, the token on the server is being deleted and the new token get stored.
     */
    public updateToken(): Observable<any> {
        const updateResult = new Subject<any>();
        window.FirebasePlugin.getToken((token) => {
            this.databaseService.getPushToken().then((dbToken) => {
                if (dbToken) {
                    if ( dbToken !== token ) {
                        combineLatest(this.firebaseRestApi.uploadToken(token),
                        this.firebaseRestApi.removeToken(dbToken)).subscribe(updateResult);
                    }
                } else  {
                    this.firebaseRestApi.uploadToken(token).subscribe(updateResult);
                }
            });
        }, (error) => {
            console.log(error);
            updateResult.error(error);
        });
        return updateResult.asObservable();
    }
}
