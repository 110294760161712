import { User } from "./User";

export class ContactRespModel {
    public _id: string;
    public name: string;
    public email: string;
    public user: User;
    public languagecode: string;
    public version: number;
    public created: Date;
    public updated: Date;
}
