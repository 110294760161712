import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogData } from "../dialog/dialog.component";

@Component({
    selector: "app-spinnerdialog",
    templateUrl: "spinnerDialog.component.html",
})

export class SpinnerDialogComponent {

    constructor(
      public dialogRef: MatDialogRef<SpinnerDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}
