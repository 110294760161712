<app-toolbar [name]="'update.title'" [leftBarButton]="backBarButton"></app-toolbar>
<div class="container-overflow-scroll">
    <div class="container-fluid container-safe-area-bottom d-flex flex-column align-items-stretch minHeight">
        <div class="row justify-content-center">
            <div class="col-1 col-lg-3 step-number mt-3">2</div>
            <div class="col-10 col-lg-6 mt-3">
                <div class="text-center descriptionText">{{'update2.overviewTitle' | translate}}</div>
                <div class="text-center primTextColor font-weight-bold">{{'update2.overviewSubtitle1' | translate}}</div>
                <div class="text-center pt-3 descriptionText">{{'update2.messageDownloadUpdate' | translate}}</div>
            </div>
            <div class="col-1 col-lg-3 mt-3"></div>
            <div class="col-10 col-md-7 col-lg-5 col-xl-4 align-self-center my-3">
                <img class="w-100" src="assets/img/update_step_3_1@2x_de.jpg" srcset="assets/img/update_step_3_1@800w_de.jpg 800w, assets/img/update_step_3_1@1200w_de.jpg 1200w" alt="">
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-10 col-md-6 col-lg-3 my-3">
                <button class="signInButton" mat-raised-button color="primary" (click)="shareFirmware()">{{'update2.downloadButton' | translate }}</button>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-1 col-lg-3"></div>
            <div class="col-10 col-lg-6 mt-3">
                <div class="text-center primTextColor font-weight-bold">{{'update2.overviewSubtitle2' | translate}}</div>
                <div class="text-center descriptionText">{{'update2.messageInstallUpdate' | translate}}</div>
            </div>
            <div class="col-1 col-lg-3"></div>
            <div class="col-10 col-md-7 col-lg-5 col-xl-4 align-self-center my-3">
                <img class="w-100" src="assets/img/update_step_3@2x_de.jpg" srcset="assets/img/update_step_3@800w_de.jpg 800w, assets/img/update_step_3@1200w_de.jpg 1200w" alt="">
            </div>
        </div>
        <div class="row justify-content-center mt-auto">
            <div class="col-12 col-md-6 col-lg-3 mb-3 py-1">
                <button class="signInButton" [disabled]="!downloadedFirmware" mat-raised-button color="primary" (click)="nextStep()">{{'shared.next' | translate }}</button>
            </div>
        </div>
    </div>
</div>
