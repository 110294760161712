import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { map, share, Subscription } from "rxjs";
import { Observable } from "rxjs";
import { Breakpoints, BreakpointObserver } from "@angular/cdk/layout";
import { NavigationBarItemModel, NavigationBarMenuItems } from "src/app/models/NavigationBarItemModel";
import { BreakpointService } from "src/app/services/BreakpointService";
import { DeviceDetector } from "src/app/helpers/DeviceDetector";


@Component({
    selector: "app-toolbar",
    templateUrl: "toolbar.component.html",
    styleUrls: ["toolbar.component.scss"]
})

export class ToolbarComponent implements OnInit, OnDestroy {
    @Input() name: string;
    @Input() rightBarButtons: NavigationBarItemModel[];
    @Input() leftBarButton: NavigationBarItemModel;
    @Input() shouldNavigateBack = false;

    public isHandset: boolean;
    private subscriptions: Subscription[] = [];
    public isTabletPortrait: boolean;

    constructor(private breakpointService: BreakpointService) {
        this.subscriptions.push(breakpointService.isMenuModeOver$.subscribe((result) => {
            this.isHandset = result || DeviceDetector.isOnCordova();
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }

    ngOnInit(): void {
        this.isHandset = this.breakpointService.menuModeOver || DeviceDetector.isOnCordova();
    }

    public rightBarButtonPressed(button: NavigationBarItemModel) {
        button.action.next(void 0);
    }

    public leftBarButtonPressed() {
        this.leftBarButton.action.next(void 0);
    }

    public menuButtonPressed(button: NavigationBarMenuItems) {
        button.action.next(void 0);
    }

    public showDropdown(): boolean {
        return this.getMenuButton() !== undefined;
    }

    public getMenuButton(): NavigationBarItemModel | undefined {
        if (this.rightBarButtons && this.rightBarButtons.length > 0) {
            return this.rightBarButtons.find((mButton) => mButton.menuItems !== undefined);
        }
        return undefined;
    }

}
