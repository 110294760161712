import { HttpHeaders } from "@angular/common/http";
import { LocalStorageService } from "../LocalStorageService";
import { environment } from "src/environments/environment";

export abstract class BaseRestService {

    protected webserviceUrl: string;
    protected localStorageService: LocalStorageService;

    constructor(localStorageService: LocalStorageService) {
        this.webserviceUrl = environment.webserviceUrl;
        this.localStorageService = localStorageService;
    }
    protected getBearerHeader(): HttpHeaders | null {
        let headers = new HttpHeaders();
        const appUser = this.localStorageService.getAccessInfos();
        if (appUser && appUser.accessToken) {
            headers = headers.set("Authorization", "Bearer " + appUser.accessToken);
            return headers;
        } else {
            return null;
        }
    }
}
