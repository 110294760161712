
export class Contact {
    emails: EmailX[];
    displayName: string;
    id: string;
}
export interface ContactX {
    id: string;
    firstName: string;
    familyName: string;
    emails: [EmailX];
}
export interface EmailX {
    id: string;
    type: string;
    value: string;
}
