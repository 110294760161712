import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { classToPlain, plainToClass } from "class-transformer";
import { Observable } from "rxjs";
import { map } from "rxjs";
import { StolenReqModel } from "src/app/api-handling/models/StolenReqModel";
import { StolenRespModel } from "src/app/api-handling/models/StolenRespModel";
import { LocalStorageService } from "../LocalStorageService";
import { BaseRestService } from "./BaseRestService";



@Injectable()
export class StolenRestService extends BaseRestService {

    constructor(private httpClient: HttpClient, localStorageService: LocalStorageService) {
        super(localStorageService);
    }

    public setCameraStolen(model: StolenReqModel): Observable<StolenRespModel> | null {
        const header = this.getBearerHeader();
        if (header) {
            return this.httpClient.post(this.webserviceUrl + "v1/userstolencams", classToPlain(model), {headers: header}).pipe(
                map((result) => plainToClass(StolenRespModel, result))
            );
        }
        return null;
    }

    public getStolenContact(id: string): Observable<StolenRespModel> | null {
        const header = this.getBearerHeader();
        if (header) {
            return this.httpClient.get(this.webserviceUrl + "v1/userstolencams/" + id, {headers: header}).pipe(
                map((result) => plainToClass(StolenRespModel, result))
            );
        }
        return null;
    }

    public deleteStolen(id: string): Observable<any> | null {
        const header = this.getBearerHeader();
        if (header) {
            return this.httpClient.delete(this.webserviceUrl + "v1/userstolencams/" + id, {headers: header}).pipe(
                map((result) => plainToClass(StolenRespModel, result))
            );
        }
        return null;
    }
}
