import { Injectable } from "@angular/core";
import { BaseRestService } from "./BaseRestService";
import { LocalStorageService } from "../LocalStorageService";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs";
import { plainToClass } from "class-transformer";
import { FirmwareRespModel } from "../../api-handling/models/FirmwareRespModel";

@Injectable()
export class FirmwareRestService extends BaseRestService {

    constructor(private httpClient: HttpClient, localStorageService: LocalStorageService) {
        super(localStorageService);
    }

    public getFirmwareInfo({ cameraId }: { cameraId: string }): Observable<FirmwareRespModel> {
        const header = this.getBearerHeader();
        if ( header ) {
            return this.httpClient.get(this.webserviceUrl + "v1/userfirmware/?cameraId=" + cameraId, {headers: header}).pipe(
                map((result) => plainToClass(FirmwareRespModel, result)));
        } else {
            return null;
        }
    }

    public getFirmware({ cameraId }: { cameraId: string }): Observable<any> | null {
        const header = this.getBearerHeader();
        if ( header ) {
            return this.httpClient.get(this.webserviceUrl + "v1/userfirmware/download/?cameraId=" + cameraId, {headers: header, responseType: "blob"})
                .pipe(catchError((err: HttpErrorResponse) => {
                    if (err && err.error && err.error.id) {
                        console.log(err);
                    }
                    return throwError(err);
                }));
        } else {
            return null;
        }
    }
}
