import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MaterialModule } from "../material.module";
import { ImageControlComponent } from "./components/image/image.component";
import { MobileImageControlComponent } from "./components/mobileImage/mobileImage.component";

@NgModule({
    declarations:  [
        MobileImageControlComponent,
        ImageControlComponent
    ],
    imports: [
        CommonModule,
        MaterialModule
    ],
    exports: [
        MobileImageControlComponent,
        ImageControlComponent
    ]
})
export class ImageModule {}
