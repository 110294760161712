import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject } from "rxjs";

declare var navigator: any, Connection: any;

export interface Connection {
    isOnline: Boolean;
    connectionType: any;
}

@Injectable({
    providedIn: "root"
})

export class ConnectionService implements OnDestroy {


    private connectionCallSource = new BehaviorSubject<Connection>(null);
    public connectionChanged = this.connectionCallSource.asObservable();

    constructor() {
        window.addEventListener("offline", () => this.onOffline(), false);
        window.addEventListener("online", () => this.onOnline(), false);
    }

    private onOnline() {
        let connectionType = "Unknown connection";
        try {
            connectionType = navigator.connection.type;
        } catch (e) {
            console.log(e);
        }
        this.connectionCallSource.next({isOnline: true, connectionType: connectionType });
    }

    private onOffline() {
        let connectionType = "Unknown connection";
        try {
            connectionType = navigator.connection.type;
        } catch (e) {
            console.log(e);
        }
        this.connectionCallSource.next({isOnline: false, connectionType: connectionType });
    }

    ngOnDestroy(): void {
        window.removeEventListener("offline", () => this.onOffline());
        window.removeEventListener("online", () => this.onOnline());
    }
}
