import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DialogService } from "../../../app/services/DialogService";
import { TrackingService } from "../../../app/services/TrackingService";
import { BaseComponent } from "../base/base.component";

@Component ({
    selector: "app-cookie",
    templateUrl: "cookie.component.html",
    styleUrls: ["cookie.component.scss"]
})

export class CookieComponent extends BaseComponent {

    public isShowingSettings: boolean = false;

    public allowTracking: boolean = false;
    public allowAnalytics: boolean = false;

    constructor(private readonly trackingService: TrackingService, matDialog: MatDialog, dialogService: DialogService) {
        super(matDialog, dialogService);
        const settings = trackingService.getCookieSettings();
        this.allowTracking = settings.allowsTracking;
        this.allowAnalytics = settings.allowsAnalytics;
    }

    public acceptButtonPressed() {
        this.trackingService.storePreferences({
            allowsAnalytics: true,
            allowsTracking: true
        });
    }

    public declineButtonPressed() {
        this.trackingService.storePreferences({
            allowsAnalytics: this.allowAnalytics,
            allowsTracking: this.allowTracking
        });
    }

    public settingsButtonPressed() {
        this.isShowingSettings = !this.isShowingSettings;
    }

    public changeTracking() {
        this.allowTracking = !this.allowTracking;
    }

    public changeAnalytics() {
        this.allowAnalytics = !this.allowAnalytics;
    }

}
