import { User } from "./User";

export class UserModel extends User {
    public version: number;
    public created: Date;
    public updated: Date;
    public password: string | undefined;
    public passwordConfirm: string | undefined;
    public languagecode: string | undefined;
    public misc?: IUserMisc;

    public getCookieSettings(): CookieSettings {
        if (this.misc) {
            return {
                allowsTracking: this.misc.allowsTracking ? Boolean(JSON.parse(this.misc.allowsTracking)) : false,
                allowsAnalytics: this.misc.allowsAnalytics ? Boolean(JSON.parse(this.misc.allowsAnalytics)) : false,
                allowsTrackingIos: this.misc.allowsTrackingIos ? Boolean(JSON.parse(this.misc.allowsTrackingIos)) : false
            };
        }
        return {
            allowsTracking: false,
            allowsAnalytics: false,
            allowsTrackingIos: false
        };
    }
}

export interface IUserMisc {
    allowsTracking?: string;
    allowsAnalytics?: string;
    allowsTrackingIos?: string;
}

export interface CookieSettings {
    allowsTracking?: boolean;
    allowsAnalytics?: boolean;
    allowsTrackingIos?: boolean;
}

export class CookieSettingsAdapter {

    static updateUserMiscWith(settings: CookieSettings, userMisc?: IUserMisc): IUserMisc {
        const misc: IUserMisc = userMisc ? userMisc : {};
        for (const key in settings) {
            if (key) {
                const value = Reflect.get(settings, key);
                switch (key) {
                    case "allowsAnalytics":
                        misc.allowsAnalytics = value;
                        break;
                    case "allowsTracking":
                        misc.allowsTracking = value;
                        break;
                    case "allowsTrackingIos":
                        misc.allowsTrackingIos = value;
                        break;
                }
            }
        }
        return misc;
    }

    static allowsTracking(settings: CookieSettings): boolean {
        return settings.allowsAnalytics && settings.allowsTracking;
    }
}

