import { Injectable } from "@angular/core";
import { LocalStorageService } from "../LocalStorageService";
import { Observable } from "rxjs";
import { BaseRestService } from "./BaseRestService";
import { CameraSmtpReceiverRespModel } from "src/app/api-handling/models/CameraSmtpReceiverRespModel";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs";
import { plainToClass, classToPlain } from "class-transformer";

@Injectable()
export class CameraSmtpReceiverRestService extends BaseRestService {

    readonly endpoint = "v1/camerasmtpreceiver/";

    constructor(localStorageService: LocalStorageService, private readonly httpClient: HttpClient) {
        super(localStorageService);
    }

    public add(cameraId: string, obj: CameraSmtpReceiverRespModel): Observable<CameraSmtpReceiverRespModel> | null {
        const header = this.getBearerHeader();
        if ( header ) {
            return this.httpClient.post(`${this.webserviceUrl}${this.endpoint}${cameraId}`, classToPlain(obj), {headers: header}).pipe(
                map((result) => plainToClass(CameraSmtpReceiverRespModel, result)));
        } else {
            return null;
        }
    }

    public edit(cameraId: string, obj: CameraSmtpReceiverRespModel, id: string): Observable<CameraSmtpReceiverRespModel> | null {
        const header = this.getBearerHeader();
        if ( header ) {
            return this.httpClient.patch(`${this.webserviceUrl}${this.endpoint}${cameraId}/${id}`, classToPlain(obj), {headers: header}).pipe(
                map((result) => plainToClass(CameraSmtpReceiverRespModel, result)));
        } else {
            return null;
        }
    }

    public delete(cameraId: string, id: string): Observable<CameraSmtpReceiverRespModel> | null {
        const header = this.getBearerHeader();
        if ( header ) {
            return this.httpClient.delete(`${this.webserviceUrl}${this.endpoint}${cameraId}/${id}`, {headers: header}).pipe(
                map((result) => plainToClass(CameraSmtpReceiverRespModel, result)));
        } else {
            return null;
        }
    }

    public getById(cameraId: string, id: string): Observable<CameraSmtpReceiverRespModel> | null {
        const header = this.getBearerHeader();
        if ( header ) {
            return this.httpClient.get(`${this.webserviceUrl}${this.endpoint}${cameraId}/${id}`, {headers: header}).pipe(
                map((result) => plainToClass(CameraSmtpReceiverRespModel, result)));
        } else {
            return null;
        }
    }

    public getList(cameraId: string): Observable<CameraSmtpReceiverRespModel[]> | null {
        const header = this.getBearerHeader();
        if ( header ) {
            return this.httpClient.get<any[]>(`${this.webserviceUrl}${this.endpoint}${cameraId}`, {headers: header}).pipe(
                map((result) => plainToClass(CameraSmtpReceiverRespModel, result)));
        } else {
            return null;
        }
    }


}
