import { BaseRestService } from "./BaseRestService";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { LocalStorageService } from "../LocalStorageService";
import { Observable } from "rxjs";
import { PayPalAboReqModel } from "src/app/api-handling/models/PayPalAboReqModel";
import { classToPlain, plainToClass } from "class-transformer";
import { map } from "rxjs";
import { PayPalAboRespModel } from "src/app/api-handling/models/PayPalAboRespModel";
import { AboAgreementModel } from "src/app/api-handling/models/AboAgreementModel";


const paypalEndpoint = "v1/paypalbillingagreements/useragreement";
@Injectable()
export class PaypalRestService extends BaseRestService {

    constructor(private httpClient: HttpClient, localStorageService: LocalStorageService) {
        super(localStorageService);
    }

    public createAbo(abo: PayPalAboReqModel): Observable<PayPalAboRespModel> | null {
        const header = this.getBearerHeader();
        if (header) {
            return this.httpClient.post(this.webserviceUrl + paypalEndpoint, classToPlain(abo), {headers: header}).pipe(
                map((result) => plainToClass(PayPalAboRespModel, result))
            );
        }
        return null;
    }

    public confirmAbo(token: string): Observable<AboAgreementModel> | null {
        const header = this.getBearerHeader();
        if (header) {
            return this.httpClient.put(this.webserviceUrl + paypalEndpoint, {token: token}, {headers: header}).pipe(
                map((result) => plainToClass(AboAgreementModel, result))
            );
        }
        return null;
    }

    public cancelAbo(id: string): Observable<any> | null {
        const header = this.getBearerHeader();
        const body = {id: id, cancel_note: "User canceled in web application."};
        if (header) {
            header.append("Content-Type", "application/json");
            return this.httpClient.request("delete", this.webserviceUrl + paypalEndpoint, {body: body, headers: header});
        }
        return null;
    }

}
