import { BreakpointObserver } from "@angular/cdk/layout";
import { Component, NgZone, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { CordovaInitHandler } from "../../../../app/native/CordovaInitHandler";
import { DialogService } from "../../../../app/services/DialogService";
import { NavigationService } from "../../../../app/services/NavigationService";
import { BaseNavComponent } from "../../base/baseNav.component";
import { Location } from "@angular/common";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { StolenRestService } from "../../../../app/services/http/StolenRestService";
import { TranslateService } from "@ngx-translate/core";
import { allCountries } from "../../../../app/helpers/Countries";
import { StolenReqModel } from "../../../../app/api-handling/models/StolenReqModel";

@Component({
    selector: "app-stolen",
    templateUrl: "stolenFormular.component.html",
    styleUrls: ["stolenFormular.component.scss"]
})

export class StolenFormularComponent extends BaseNavComponent implements OnInit {

    public stolenForm: FormGroup;
    private cameraId: string;
    public countries = allCountries;

    constructor(private readonly formBuilder: FormBuilder, private readonly activatedRoute: ActivatedRoute, dialog: MatDialog,
        navigationService: NavigationService, location: Location, initHandler: CordovaInitHandler,
                breakpointObserver: BreakpointObserver, dialogService: DialogService, private readonly stolenService: StolenRestService,
                private readonly translate: TranslateService, private readonly router: Router, private readonly zone: NgZone) {
        super(dialog, navigationService, location, initHandler, breakpointObserver, dialogService);

        this.subscriptions.push(this.activatedRoute.queryParams.subscribe((params) => {
            if (params && params.id) {
                this.cameraId = params.id;
            } else {
                this.router.navigate(["home/cameras"]);
            }
        }));
    }

    ngOnInit(): void {
        this.stolenForm = this.formBuilder.group({
            firstname: ["", [Validators.required]],
            lastname: ["", [Validators.required]],
            phone: ["", [Validators.required]],
            email: ["", [Validators.required, Validators.email]],
            address: ["", [Validators.required]],
            zip: ["", [Validators.required]],
            city: ["", [Validators.required]],
            country: ["", [Validators.required]]
        });
    }

    setStolen() {
        if (!this.stolenForm.invalid) {
            this.showSpinner("", "");
            const stolen = new StolenReqModel();
            stolen.firstname = this.stolenForm.value.firstname;
            stolen.lastname = this.stolenForm.value.lastname;
            stolen.phone = this.stolenForm.value.phone;
            stolen.email = this.stolenForm.value.email;
            stolen.address = this.stolenForm.value.address;
            stolen.zip = this.stolenForm.value.zip.toString();
            stolen.city = this.stolenForm.value.city;
            stolen.country = this.stolenForm.value.country;
            stolen.camera = this.cameraId;

            this.subscriptions.push(this.stolenService.setCameraStolen(stolen).subscribe(() => {
                this.zone.run(() => {
                    this.closeSpinner();
                    this.subscriptions.push(this.openDialog(this.translate.instant("stolen.dialog.cameraReportedTitle"), this.translate.instant("stolen.dialog.cameraReportedMsg"), null).subscribe(() => {
                        this.zone.run(() => {
                            this.router.navigate(["home/cameraDetail"], { replaceUrl: true, queryParams: {id: this.cameraId}});
                        });
                    }));
                });
            }, (err) => {
                this.closeSpinner();
            }));
        }
    }

    goBackButtonPressed() {
        this.router.navigate(["home/cameraDetail"], { replaceUrl: true, queryParams: {id: this.cameraId}});
    }

}
