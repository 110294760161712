import { Injectable, OnDestroy } from "@angular/core";
import { firstValueFrom, Subscription } from "rxjs";
import { DeviceDetector } from "../helpers/DeviceDetector";
import { AppUserService } from "../services/database/AppUserService";
import { FirebaseService } from "../services/FirebaseService";
import { AuthRestService } from "../services/http/AuthRestService";
import { UserRestService } from "../services/http/UserRestService";
import { ImageFilterService } from "../services/ImageFilterService";
import { LocalStorageService } from "../services/LocalStorageService";
import { TrackingService } from "../services/TrackingService";
import { ImageRepository } from "./ImageRepository";



@Injectable({
    providedIn: "root"
})

export class UserRepository implements OnDestroy {

    public subscriptions: Subscription[] = [];

    constructor(private readonly authRestService: AuthRestService, private readonly storageService: LocalStorageService,
        private readonly imageRepository: ImageRepository, private readonly appUserService: AppUserService,
        private readonly firebase: FirebaseService, private readonly trackingService: TrackingService,
        private readonly userRestService: UserRestService, public imageFilterService: ImageFilterService) {

    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }

    /**
     * Sign out user from application. All user data will be removed and if Cordova app the push token will be unregistered.
     */
     public async signOut(): Promise<void> {
        if (DeviceDetector.isOnCordova()) {
            try {
                const hasToken = await firstValueFrom(this.firebase.hasToken());
                if (hasToken) {
                    await firstValueFrom(this.firebase.unregisterPush());
                }
            } catch (error) {
                console.log(error);
            }
        }
        await this.clearUser();
    }

    /**
     * Delete User Account
     */
    public async deleteAccount(password: String): Promise<Boolean> {
        const result = await this.userRestService.deleteAccount(password);
        if (result) {
            await this.clearUserData();
            return true;
        }
        return false;
    }

    /**
     * Remove all user data from app.
     */
    private async clearUser() {
        const observe = this.authRestService.revokeToken();
        if ( observe && (!DeviceDetector.isOnCordova() || DeviceDetector.hasInternetConnection())) {
            await firstValueFrom(observe);
            await this.clearUserData();
        } else {
            await this.clearUserData();
        }
    }

    private async clearUserData(): Promise<void> {
        this.storageService.clearAccessInfos();
        this.imageRepository.clearImageFiles();
        this.imageFilterService.clearCamerasAndUsers();
        this.trackingService.reset();
        if ( DeviceDetector.isOnCordova()) {
            await this.appUserService.removeAppUser();
        }
    }
}
