import { Component } from "@angular/core";
import { BaseNavComponent } from "../base/baseNav.component";
import { MatDialog } from "@angular/material/dialog";
import { NavigationService } from "src/app/services/NavigationService";
import { CordovaInitHandler } from "src/app/native/CordovaInitHandler";
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { DialogService } from "src/app/services/DialogService";
import { BreakpointObserver } from "@angular/cdk/layout";
import { FirmwareRestService } from "src/app/services/http/FirmwareRestService";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-update",
    templateUrl: "update.component.html",
    styleUrls: ["update.component.scss"]
})

export class UpdateComponent extends BaseNavComponent {

    private cameraId: string;
    public versionsNummer: string;
    public language: string;

    constructor(private activatedRoute: ActivatedRoute, dialog: MatDialog, navigationService: NavigationService, location: Location, initHandler: CordovaInitHandler, private router: Router,
        breakpointObserver: BreakpointObserver, dialogService: DialogService, private firmwareRestService: FirmwareRestService, translate:  TranslateService) {
        super(dialog, navigationService, location, initHandler, breakpointObserver, dialogService);
        this.subscriptions.push(this.activatedRoute.queryParams.subscribe((params) => {
            if (params && params.id) {
                this.cameraId = params.id;
            }
            this.getFirmwareVersion();
        }));

        this.language = translate.currentLang;
    }

    public getFirmwareVersion() {
        this.subscriptions.push(this.firmwareRestService.getFirmwareInfo({ cameraId: this.cameraId }).subscribe((info) => {
            this.versionsNummer = info.name;
        }));
    }

    nextStep() {
        this.router.navigate(["home/update1"] , {queryParams: {id: this.cameraId}});
    }

}
