import {NgModule} from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SignInComponent } from "./components/login/signin.component";
import { PasswordComponent } from "./components/password/password.component";
import { LoginGuard } from "./guards/LoginGuard";
import { RegistrationComponent } from "./components/registration/registration.component";
import { NavigationComponent } from "./components/navigation/navigation.component";
import { ImagesComponent } from "./components/images/images.component";
import { CamerasComponent } from "./components/cameras/cameras.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { InfoComponent } from "./components/info/info.component";
import { NewCameraComponent } from "./components/newcamera/newcamera.component";
import { SettingsComponent } from "./components/settings/settings.component";
import { NewSettingsComponent } from "./components/newSettings/newSettings.component";
import { CameraDetailComponent } from "./components/cameraDetail/cameraDetail.component";
import { CameraFriendsComponent } from "./components/cameraFriends/cameraFriends.component";
import { ImageDetailComponent } from "./components/imageDetail/imageDetail.component";
import { ContactDetailComponent } from "./components/contactDetail/contactDetail.component";
import { ContactAppComponent } from "./components/contactApp/contactApp.component";
import { PaypalPaymentComponent } from "./components/paypalPayment/paypalPayment.component";
import { UpdateComponent } from "./components/update/update.component";
import { UpdateStep1Component } from "./components/updateStep1/updateStep1.component";
import { UpdateStep2Component } from "./components/updateStep2/updateStep2.component";
import { UpdateStep3Component } from "./components/updateStep3/updateStep3.component";
import { CameraSmtpsComponent } from "./components/cameraSmtps/cameraSmtps.component";
import { CameraSmtpDetailComponent } from "./components/cameraSmtpDetail/cameraSmtpDetail.component";
import { CameraHdListComponent } from "./components/cameraHdList/cameraHdList.component";
import { StolenFormularComponent } from "./components/cameraDetail/stolenFormular/stolenFormular.component";
import { NewsletterComponent } from "./components/newsletter/newsletter.component";
import { NewsletterConfirmComponent } from "./components/newsletter/newsletterConfirm.component";
import { AccountDeleteComponent } from "./components/profile/components/accountDelete/accountDelete.component";

export const routes: Routes = [
    {
        path: "",
        redirectTo: "home",
        pathMatch: "full",
    },
    {
        path: "signin",
        component: SignInComponent,
        data: {animation: "Overlap"}
    },
    {
        path: "password",
        component: PasswordComponent,
        data: {animation: ""}
    },
    {
        path: "home",
        component: NavigationComponent,
        canActivate: [LoginGuard],
        data: {animation: ""},
        children: [
            {
                path: "",
                redirectTo: "images",
                pathMatch: "full"
            },
            {
                path: "images",
                component: ImagesComponent,
                canActivate: [LoginGuard],
                data: {animation: "images", type: "images", menu: "images"}
            },
            {
                path: "favourites",
                component: ImagesComponent,
                canActivate: [LoginGuard],
                data: {animation: "favourites", type: "favourites", menu: "favourites"}
            },
            {
                path: "cameras",
                component: CamerasComponent,
                canActivate: [LoginGuard],
                data: {animation: "cameras", menu: "cameras"}
            },
            {
                path: "abo",
                component: ProfileComponent,
                canActivate: [LoginGuard],
                data: {animation: "abo", menu: "abo"}
            },
            {
                path: "hdcameras",
                component: CameraHdListComponent,
                canActivate: [LoginGuard],
                data: {animation: "abo", menu: "abo"},
            },
            {
                path: "accountDelete",
                component: AccountDeleteComponent,
                canActivate: [LoginGuard],
                data: {menu: "abo"}
            },
            {
                path: "imprint",
                component: InfoComponent,
                canActivate: [LoginGuard],
                data: {animation: "imprint", menu: "imprint"}
            },
            {
                path: "terms",
                component: InfoComponent,
                canActivate: [LoginGuard],
                data: {animation: "terms", menu: "terms"}
            },
            {
                path: "datapolicy",
                component: InfoComponent,
                canActivate: [LoginGuard],
                data: {animation: "datapolicy", menu: "datapolicy"}
            },
            {
                path: "newcamera",
                component: NewCameraComponent,
                canActivate: [LoginGuard],
                data: {menu: "cameras"}
            },
            {
                path: "newcamera/:imei",
                component: NewCameraComponent,
                canActivate: [LoginGuard],
                data: {menu: "cameras"}
            },
            {
                path: "newSettings",
                component: NewSettingsComponent,
                canActivate: [LoginGuard],
                data: {menu: "cameras"}
            },
            {
                path: "settings",
                component: SettingsComponent,
                canActivate: [LoginGuard],
                data: {menu: "cameras"}
            },
            {
                path: "cameraDetail",
                component: CameraDetailComponent,
                canActivate: [LoginGuard],
                data: { menu: "cameras"}
            },
            {
                path: "stolen",
                component: StolenFormularComponent,
                canActivate: [LoginGuard],
                data: {menu: "cameras"}
            },
            {
                path: "update",
                component: UpdateComponent,
                canActivate: [LoginGuard],
                data: {menu: "cameras"}
            },
            {
                path: "update1",
                component: UpdateStep1Component,
                canActivate: [LoginGuard],
                data: {menu: "cameras"}
            },
            {
                path: "update2",
                component: UpdateStep2Component,
                canActivate: [LoginGuard],
                data: {menu: "cameras"}
            },
            {
                path: "update3",
                component: UpdateStep3Component,
                canActivate: [LoginGuard],
                data: {menu: "cameras"}
            },
            {
                path: "friends",
                component: CameraFriendsComponent,
                canActivate: [LoginGuard],
                data: {type: "friend", menu: "cameras"}
            },
            {
                path: "mails",
                component: CameraFriendsComponent,
                canActivate: [LoginGuard],
                data: {type: "mail", menu: "cameras"}
            },
            {
                path: "smtps",
                component: CameraSmtpsComponent,
                canActivate: [LoginGuard],
                data: {menu: "cameras"}
            },
            {
                path: "smtpDetail",
                component: CameraSmtpDetailComponent,
                canActivate: [LoginGuard],
                data: {menu: "cameras"}
            },
            {
                path: "friend",
                component: ContactDetailComponent,
                canActivate: [LoginGuard],
                data: {type: "friend", menu: "cameras"}
            },
            {
                path: "invite",
                component: ContactDetailComponent,
                canActivate: [LoginGuard],
                data: {type: "invite", menu: "cameras"}
            },
            {
                path: "imageDetail",
                component: ImageDetailComponent,
                canActivate: [LoginGuard],
                data: {menu: "images"}
            },
            {
                path: "imageDetailFav",
                component: ImageDetailComponent,
                canActivate: [LoginGuard],
                data: {menu: "favourites"}
            },
            {
                path: "mail",
                component: ContactDetailComponent,
                canActivate: [LoginGuard],
                data: {type: "mail", menu: "cameras"}
            },
            {
                path: "contactapp",
                component: ContactAppComponent,
                canActivate: [LoginGuard],
                data: {menu: "cameras"}
            },
            {
                path: "newsletter",
                component: NewsletterComponent,
                canActivate: [LoginGuard]
            },
            {
                path: "newsletter-confirm",
                component: NewsletterConfirmComponent,
                canActivate: [LoginGuard]
            }
        ]
    },
    {
        path: "paypal",
        component: PaypalPaymentComponent,
        canActivate: [LoginGuard]
    },
    {
        path: "paypal/success",
        component: PaypalPaymentComponent,
        canActivate: [LoginGuard]
    },
    {
        path: "paypal/cancel",
        component: PaypalPaymentComponent,
        canActivate: [LoginGuard]
    },
    {
        path: "datapolicy",
        component: InfoComponent,
        data: {animation: ""}
    },
    {
        path: "termsofuse",
        component: InfoComponent,
        data: {animation: ""}
    },
    {
        path: "registration",
        component: RegistrationComponent,
        data: {animation: ""}
    },
    {
        path: "confirmRegistration",
        component: SignInComponent,
        data: {animation: ""}
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
