export class AboRespModel {
    public highres: AboStatus | null;
    public quota: AboStatus[];
    public availableQuota: number;
    public usedQuota: number;
    public allowedFavoriteSharePercentage: number;
    public waitingPeriodDays: number;
    public freeHd: boolean;
    public freeQuota: boolean;
}

export class AboStatus {
    nextBillingDate: Date;
    active: boolean;
    provider: string;
    productId?: string;
    id: string;
    lastRealtimeStatus?: number;
}
