import { CameraReigster1RespModel } from "../api-handling/models/CameraRegister1RespModel";
import { ICameraSettings } from "../api-handling/models/ICameraSettings";
import { CameraRegisterReq1Model } from "../api-handling/models/CameraRegisterReq1Model";
import { Injectable } from "@angular/core";

@Injectable()
export class RegisterDataService {
    public cameraReq1: CameraRegisterReq1Model;
    public responseStep1: CameraReigster1RespModel;
    public cameraSettings: ICameraSettings;
}
