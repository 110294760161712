import { Subject } from "rxjs";

export class NavigationBarItemModel {
    title: string;
    matImageName: string;
    action: Subject<any>;
    menuItems: NavigationBarMenuItems[];

    constructor(matImageName: string) {
        this.matImageName = matImageName;
        this.action = new Subject<any>();
    }
}

export class NavigationBarMenuItems {
    name: string;
    action: Subject<any>;

    constructor(name: string) {
        this.name = name;
        this.action = new Subject<any>();
    }
}
