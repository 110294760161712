<div class="container-fluid px-3">
    <div class="row">
        <div *ngIf="cameraSettings" class="col-12">
            <form [formGroup]="newCameraForm" class="d-flex flex-column justify-content-between align-items-center w-100">    
                <div class="row">
                    <div class="col-12 mt-3 descriptionInfo">{{ 'camerasettings.info' | translate }}</div>
                </div>         
                <div class="row ">
                    <div class="col-12 col-lg-6 col-xl-4 my-3">
                        <mat-card class=" w-100 d-flex align-items-center justify-content-center flex-column">
                            <mat-card-header>
                                <mat-card-title>{{ 'camerasettings.camera' | translate }}</mat-card-title>
                            </mat-card-header>
                            <div></div>
                            <mat-form-field class="doerrInput w-100">
                                <mat-label>{{ 'camerasettings.cameramode' | translate }}</mat-label>
                                <mat-select formControlName="cameramode">
                                    <mat-option *ngFor="let mode of cameraModes" [value]="mode.key">{{mode.value | translate}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class="d-flex w-100 justify-content-between mb-3">
                                <div class="sliderLabel">{{ 'camerasettings.sdsycle' | translate }}</div>
                                <mat-slide-toggle color="primary" formControlName="sdsycle"></mat-slide-toggle>
                            </div>
                            <mat-form-field class="doerrInput w-100" *ngIf="camera">
                                <mat-label>{{ 'camerasettings.country' | translate }}</mat-label>
                                <mat-select formControlName="country" placeholder="{{'newcamera.countryPlaceholder' | translate}}">
                                    <mat-option *ngFor="let country of countries" [value]="country.code">{{country.name}}</mat-option>
                                </mat-select>
                                <mat-error>{{ 'shared.selectCountry' | translate }}</mat-error>
                            </mat-form-field>
                            <mat-form-field class="doerrInput w-100 text-dark" *ngIf="camera">
                                <mat-label>{{ 'camerasettings.phone' | translate }}</mat-label>
                                <input matInput type="tel" placeholder="{{'newcamera.phonePlaceholder' | translate}}" formControlName="phone" (focusin)="onFocus()" (blur)="onBlur()">
                                <mat-error>{{ 'shared.validPhone' | translate }}</mat-error>
                            </mat-form-field>
                            <mat-form-field class="doerrInput w-100 text-dark" *ngIf="camera">
                                <mat-label>{{ 'camerasettings.name' | translate }}</mat-label>
                                <input matInput type="text" placeholder="{{'newcamera.namePlaceholder' | translate}}" formControlName="name" (focusin)="onFocus()" (blur)="onBlur()">
                                <mat-error>{{ 'shared.validPhone' | translate }}</mat-error>
                            </mat-form-field>
                        </mat-card>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-4 my-3">
                        <mat-card class="w-100 d-flex align-items-center justify-content-center flex-column">
                            <mat-card-header>
                                <mat-card-title>{{ 'camerasettings.photo' | translate }}</mat-card-title>
                            </mat-card-header>
                            <mat-form-field class="doerrInput w-100">
                                <mat-label>{{ 'camerasettings.picturesize' | translate }}</mat-label>
                                <mat-select formControlName="picturesize">
                                    <mat-option *ngFor="let mode of pictureSizes" [value]="mode.key">{{mode.value | translate}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="doerrInput w-100">
                                <mat-label>{{ 'camerasettings.multishot' | translate }}</mat-label>
                                <mat-select formControlName="multishot">
                                    <mat-option *ngFor="let mode of multiShots" [value]="mode.key">{{mode.value | translate}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="doerrInput w-100">
                                <mat-label>{{ 'camerasettings.multishotSend' | translate }}</mat-label>
                                <mat-select formControlName="multishotSend">
                                    <mat-option *ngFor="let mode of availableMultiShotUploads" [value]="mode.key">{{mode.value | translate}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class="col mb-3">
                                <div class="row sliderLabel">{{ 'camerasettings.timelapse' | translate }}</div>                                
                                <div class="row d-flex justify-content-between">
                                    <table role="presentation">
                                        <tbody>
                                            <tr>
                                                <td class=""><input class="form-control text-center bs-timepicker-field timepicker-w-50" maxlength="2" placeholder="HH" type="tel" formControlName="timelapseHH"></td>
                                                <td class="ng-star-inserted">&nbsp;:&nbsp;</td>
                                                <td class=""><input class="form-control text-center bs-timepicker-field timepicker-w-50" maxlength="2" placeholder="MM" type="tel" formControlName="timelapseMM"></td>
                                                <td class="ng-star-inserted">&nbsp;:&nbsp;</td>
                                                <td class=""><input class="form-control text-center bs-timepicker-field timepicker-w-50" maxlength="2" placeholder="SS" type="tel" formControlName="timelapseSS"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="d-flex align-items-center">
                                        <mat-slide-toggle color="primary" formControlName="timelapseActive"></mat-slide-toggle>
                                    </div> 
                                    <mat-error *ngIf="!timelapseValid">{{ 'camerasettings.timelapseInvalid' | translate }}</mat-error>                                  
                                </div>                                
                            </div>
                            <div class="col mb-3">
                                <div class="row sliderLabel">{{ 'camerasettings.delay' | translate }}</div>                                
                                <div class="row d-flex justify-content-between">
                                    <table role="presentation">
                                        <tbody>
                                            <tr>
                                                <td class=""><input class="form-control text-center bs-timepicker-field timepicker-w-50" maxlength="2" placeholder="HH" type="tel" formControlName="delayHH"></td>
                                                <td class="ng-star-inserted">&nbsp;:&nbsp;</td>
                                                <td class=""><input class="form-control text-center bs-timepicker-field timepicker-w-50" maxlength="2" placeholder="MM" type="tel" formControlName="delayMM"></td>
                                                <td class="ng-star-inserted">&nbsp;:&nbsp;</td>
                                                <td class=""><input class="form-control text-center bs-timepicker-field timepicker-w-50" maxlength="2" placeholder="SS" type="tel" formControlName="delaySS"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="d-flex align-items-center">
                                        <mat-slide-toggle color="primary" formControlName="delayActive"></mat-slide-toggle>
                                    </div> 
                                    <mat-error *ngIf="!delayValid">{{ 'camerasettings.delayInvalid' | translate }}</mat-error>                                   
                                </div>                                
                            </div>
                        </mat-card>                        
                    </div>
                    <div class="col-12 col-lg-6 col-xl-4 my-3">
                        <mat-card class="w-100 d-flex align-items-center justify-content-center flex-column">
                            <mat-card-header>
                                <mat-card-title>{{ 'camerasettings.light' | translate }}</mat-card-title>
                            </mat-card-header>
                            <mat-form-field class="doerrInput w-100">
                                <mat-label>{{ 'camerasettings.flashled' | translate }}</mat-label>
                                <mat-select formControlName="flashled">
                                    <mat-option *ngFor="let mode of flashLEDs" [value]="mode.key">{{mode.value | translate}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="doerrInput w-100">
                                <mat-label>{{ 'camerasettings.nightmode' | translate }}</mat-label>
                                <mat-select formControlName="nightmode">
                                    <mat-option *ngFor="let mode of nightModes" [value]="mode.key">{{mode.value | translate}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-card>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-4 my-3">
                        <mat-card class="w-100 d-flex align-items-center justify-content-center flex-column">
                            <mat-card-header>
                                <mat-card-title>{{ 'camerasettings.movementtracking' | translate }}</mat-card-title>
                            </mat-card-header>
                            <div class="d-flex w-100 justify-content-between my-3">
                                <div class="sliderLabel">{{ 'camerasettings.pir' | translate }}</div>
                                <mat-slide-toggle color="primary" formControlName="movementtracking"></mat-slide-toggle>
                            </div>  
                            <mat-form-field class="doerrInput w-100">
                                <mat-label>{{ 'camerasettings.sensitivity' | translate }}</mat-label>
                                <mat-select formControlName="sensitivity">
                                    <mat-option *ngFor="let mode of pirSensitivities" [value]="mode.key">{{mode.value | translate}}</mat-option>
                                </mat-select>
                            </mat-form-field>                            
                        </mat-card>                        
                    </div>
                    <div class="col-12 col-lg-6 col-xl-4 my-3" [ngClass]="{'d-none': newCameraForm.controls.cameramode.value === 1}">
                        <mat-card class="w-100 d-flex align-items-center justify-content-center flex-column">
                            <mat-card-header>
                                <mat-card-title>{{ 'camerasettings.videosettings' | translate }}</mat-card-title>
                            </mat-card-header>
                            <mat-form-field class="doerrInput w-100">
                                <mat-label>{{ 'camerasettings.videosize' | translate }}</mat-label>
                                <mat-select formControlName="videosize">
                                    <mat-option *ngFor="let mode of videoSizes" [value]="mode.key">{{mode.value | translate}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class="col">
                                <div class="row">
                                    <div class="sliderLabel">{{ 'camerasettings.videoLength' | translate }}</div>
                                </div>
                                <div class="row align-items-center justify-content-between">
                                    <div class="sliderLabel">{{ 'camerasettings.videoLengthMin' | translate }}</div>
                                    <div class="mx-3 flex-grow-1 d-flex justify-content-center">
                                        <div class="row w-100">
                                            <mat-slider color="primary" class="w-100" [max]="59" [min]="5" [thumbLabel]="true" formControlName="videoLength"></mat-slider>
                                        </div>
                                    </div>
                                    <div class="sliderLabel">{{ 'camerasettings.videoLengthMax' | translate }}</div>
                                </div>                                        
                            </div>
                        </mat-card>                            
                    </div>
                    <div class="col-12 col-lg-6 col-xl-4 my-3">
                        <mat-card class="d-flex align-items-center justify-content-center flex-column w-100">
                            <mat-card-header>
                                <mat-card-title>{{ 'camerasettings.timeschedule' | translate }}</mat-card-title>
                            </mat-card-header>
                            <div class="col mb-3">
                                <div class="row sliderLabel">{{ 'camerasettings.worktimer1' | translate }}</div>                                
                                <div class="row d-flex justify-content-between">
                                    <div class="d-flex flex-column">
                                        <timepicker formControlName="worktimer1From" [showMinutes]="true" [showMeridian]="false" [showSpinners]="false" (focusin)="onFocus()" (blur)="onBlur()"></timepicker>
                                        <timepicker formControlName="worktimer1Until" [showMinutes]="true" [showMeridian]="false" [showSpinners]="false" (focusin)="onFocus()" (blur)="onBlur()"></timepicker>
                                        <mat-error *ngIf="!workTimer1Valid">{{ 'camerasettings.worktimer1Valid' | translate }}</mat-error>
                                    </div> 
                                    <mat-slide-toggle color="primary" formControlName="worktimer1Active"></mat-slide-toggle>
                                </div>
                            </div>
                            <div class="col mb-3">
                                <div class="row sliderLabel">{{ 'camerasettings.worktimer2' | translate }}</div>                                
                                <div class="row d-flex justify-content-between">
                                    <div class="d-flex flex-column">
                                        <timepicker formControlName="worktimer2From" [showMinutes]="true" [showMeridian]="false" [showSpinners]="false" (focusin)="onFocus()" (blur)="onBlur()"></timepicker>
                                        <timepicker formControlName="worktimer2Until" [showMinutes]="true" [showMeridian]="false" [showSpinners]="false" (focusin)="onFocus()" (blur)="onBlur()"></timepicker>
                                        <mat-error *ngIf="!workTimer2Valid">{{ 'camerasettings.worktimer2Valid' | translate }}</mat-error>
                                    </div> 
                                    <mat-slide-toggle color="primary" formControlName="worktimer2Active"></mat-slide-toggle>
                                </div>
                            </div>
                        </mat-card>                                               
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
