import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

// tslint:disable-next-line:max-line-length
declare var window: { requestFileSystem: (arg0: any, arg1: number, arg2: (fs: any) => void, onError: (error: any) => void) => void; }, LocalFileSystem: { PERSISTENT: any; };

@Injectable()
export class FileService {

    public deleteFile(name: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, (fs: any) => {
                fs.root.getFile(name, { create: true, exclusive: false }, (fileEntry: any) => {
                    fileEntry.remove(() => {
                        resolve(true);
                    }, (error) => {
                        resolve(false);
                    }, () => {
                        resolve(true);
                    });
                });
            }, (error) => {
                reject();
            });
        });
    }

    public getFile(name: string): Promise<string | ArrayBuffer | null> {
        const sub = new Subject<string | ArrayBuffer | null>();
        window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, (fs: any) => {
            fs.root.getFile(name, { create: false, exclusive: false }, (fileEntry: any) => {
                if (fileEntry) {
                    fileEntry.file((file) => {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            if (!reader.error) {
                                sub.next(reader.result);
                                sub.complete();
                            } else {
                                sub.error(reader.error);
                            }
                        };
                        reader.readAsDataURL(file);
                    });
                } else {
                    sub.error("file entry not found");
                }
            }, (error) => {
                sub.error(error);
            });
        }, (error) => {
            sub.error("Could not load FileSystem");
        });
        return sub.asObservable().toPromise();
    }
    /**
     * Stores a file to local storage.
     * @param data Data of the file.
     * @param callback Callback to respond if file is stored.
     */
    public async storeFile(data: any, name: string, callback: (success: Boolean, fileEntry: any) => void) {
        const maxChunkSizeInMB = 10;
        const maxChunkSize = maxChunkSizeInMB * 1024 * 1024;

        let quota = 0;

        if (data.size) {
            quota = data.size;
        }

        const fs = await this.requestFileSystem(LocalFileSystem.PERSISTENT, quota).catch( (err) => {
            console.error("Storing file failed: Cannot request file system:", err);
        });

        if (!fs) {
            callback(false, undefined);
            return;
        }

        const fileEntry = await this.createFile(fs, name).catch( (err) => {
            console.error("Storing file failed: Cannot create file:", err);
        });


        if (!fileEntry) {
            callback(false, undefined);
            return;
        }

        // Data is so small (or have no size informations), it can be written in one go
        if (!data.size || data.size < maxChunkSize) {
            const fileWritten = await this.writeFile(fileEntry, data, false).catch( (err) => {
                console.error("Storing file failed: Cannot write data to file:", err);
            });

            if (!fileWritten) {
                callback(false, undefined);
            } else {
                callback(true, fileEntry);
            }

            return;
        }

        // Data is too big, so we create chunks and write the data partially
        const chunksToWrite = data.size / maxChunkSize;

        let errorOccured = false;

        for (let i = 0; i < chunksToWrite; i++ ) {
            const appendData = i !== 0;

            const chunkStart = i * maxChunkSize;
            const chuckEnd = ( i < chunksToWrite - 1 )
                ? chunkStart + maxChunkSize
                : data.size
            ;

            const fileChunkWritten = await this.writeFile(fileEntry, data.slice(chunkStart, chuckEnd), appendData).catch( (err) => {
                console.error("Storing file failed: Cannot " + (appendData ? "append" : "write") + " data to file:", err);
            });

            if (!fileChunkWritten) {
                errorOccured = true;
                break;
            }
        }

        if (errorOccured) {
            callback(false, undefined);
        } else {
            callback(true, fileEntry);
        }
    }

    private createFile(fs: any, name: string): Promise<any> {
        return new Promise((resolve, reject) => {
            fs.root.getFile(name, { create: true, exclusive: false },
                (fileEntry: any) => {
                    resolve(fileEntry);
                },
                (error: any) => {
                    reject(error);
                }
            );
        });
    }

    private requestFileSystem(filesystem: any, quota: number): Promise<any> {
        return new Promise((resolve, reject) => {
            window.requestFileSystem(LocalFileSystem.PERSISTENT, quota,
                (fs: any) => {
                    resolve(fs);
                },
                (error: any) => {
                    reject(error);
                }
            );
        });
    }

    /**
     * Writes a FileEntry to disk.
     * @param fileEntry File Entry which should persistent
     * @param dataObj Content of the file
     * @param callback Callback for Response
     */
    private async writeFile(fileEntry: any, data: any, append: boolean): Promise<true> {
        return new Promise((resolve, reject) => {
            fileEntry.createWriter(async function (fileWriter: any) {

                fileWriter.onwriteend = function() {
                    resolve(true);
                };
                fileWriter.onerror = function (e: any) {
                    reject();
                };

                if (append) {
                    fileWriter.seek(fileWriter.length);
                }

                if (data instanceof Blob) {
                    fileWriter.write(data);
                } else {
                    const response = await fetch(data);
                    const blob = await response.blob();
                    fileWriter.write(blob);
                }
            });
        });
    }
}
