import { BaseNavComponent } from "./baseNav.component";
import { OnDestroy, OnInit, NgZone, Component } from "@angular/core";
import { ImageFilter } from "../images/models/ImageFilter";
import { Location } from "@angular/common";
import { NavigationService } from "src/app/services/NavigationService";
import { MatDialog } from "@angular/material/dialog";
import { BreakpointObserver } from "@angular/cdk/layout";
import { DialogService } from "src/app/services/DialogService";
import { CordovaInitHandler } from "src/app/native/CordovaInitHandler";
import { Observable } from "rxjs";
import { ImageListResponseModel } from "src/app/api-handling/models/ImageListResponseModel";
import { ImageRepository } from "src/app/repositories/ImageRepository";
import { ImageFilterService } from "src/app/services/ImageFilterService";
import * as moment from "moment";

@Component({ selector: "app-baseimg", template: `<div></div>` })

export abstract class BaseImgComponent extends BaseNavComponent implements OnDestroy, OnInit {
    public countparam = { imageCount: 0, max: 0 };
    public filteredImagesCount = 0;
    public imagesLoaded = false;
    public moreImagesToLoad = true;

    public abstract isFav: boolean;

    constructor(dialog: MatDialog, navigationService: NavigationService, location: Location,
                initHandler: CordovaInitHandler, breakpointObserver: BreakpointObserver, dialogService: DialogService,
                public zone: NgZone, public imageRepository: ImageRepository,
                public imageFilterService: ImageFilterService) {
        super(dialog, navigationService, location, initHandler, breakpointObserver, dialogService);
    }

    // in image detail component we want to await the result, in images component we do NOT want to wait (UI blocking)
    public async loadNextPage(sync: boolean = false) {
        if (this.isOnline()) {
            this.imageFilterService.page ++;
            if (sync) {
                await this.loadImages(this.imageFilterService.page, this.imageFilterService.filter);
            } else {
                this.loadImages(this.imageFilterService.page, this.imageFilterService.filter);
            }
        }
    }

    public async loadImages(page: number, filter: ImageFilter) {
        await this.processImages(this.imageRepository.loadImages(filter, page));
    }

    private processImages(imagesSubscription: Observable<ImageListResponseModel>) {
        return new Promise<void>((resolve, reject) => {
            if (imagesSubscription) {
                this.subscriptions.push(imagesSubscription.subscribe((images) => {
                    this.zone.run(() => {
                        if (images.page === 1) {
                            this.imageFilterService.firstImageUploadDate = ( images.images[0] ? moment(images.images[0]?.uploaded).toDate() : undefined);
                        }
                        this.countparam = { imageCount: images.countFiltered, max: this.countparam.max };
                        this.imagesProcessFinished();
                        this.imagesLoaded = true;
                        this.filteredImagesCount = images.countFiltered;
                        this.moreImagesToLoad = this.imageRepository.images.length < images.countFiltered;
                        this.closeSpinner();
                        resolve();
                    });
                }, (err) => {
                    this.zone.run(() => {
                        console.log(err);
                        this.imagesLoaded = true;
                        this.closeSpinner();
                        resolve();
                    });
                }));
            } else {
                this.zone.run(() => {
                    this.imagesLoaded = true;
                    this.closeSpinner();
                    resolve();
                });
            }
        });
    }

    public abstract imagesProcessFinished();
}

