import { animate, style, transition, trigger, group, query, stagger } from "@angular/animations";

export const OVERLAP = trigger(
    "routeAnimations", [
        transition("Overlap <=> *", [
            group([
                query(":leave", stagger(50, [animate("0.5s ease", style({ opacity: 0 }))]), { optional: true, delay: 150}),
                query(":enter", style({ opacity: 0 }), { optional: true }),
                query(":enter", animate("0.25s ease", style({ opacity: 1 })), { optional: true })
            ])
        ])
    ]
);
