import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import * as moment from "moment";
import { CameraResponseModel } from "src/app/api-handling/models/CameraResponseModel";
import { User } from "src/app/api-handling/models/User";
import { BaseComponent } from "src/app/components/base/base.component";
import { DialogService } from "src/app/services/DialogService";
import { ImageFilterService } from "src/app/services/ImageFilterService";
import { ImageProcessService } from "src/app/services/ImageProcessService";
import { TrackingService, TrackTag } from "src/app/services/TrackingService";
import { ImageFilter } from "../../models/ImageFilter";

@Component({
    selector: "app-imagefilter",
    templateUrl: "imageFilter.component.html",
    styleUrls: ["imageFilter.component.scss"]
})

export class ImageFilterComponent extends BaseComponent implements OnInit {

    @Output() filterSetEmitter = new EventEmitter<void>();

    // Filter propertys
    public filterForm: FormGroup;
    public today = new Date();
    public filter: ImageFilter = new ImageFilter();

    public shareButtonDisabled = true;
    public deleteButtonDisabled = true;

    private _users: User[] = [];
    get users(): User[] {
        return this._users;
    }
    set users(value: User[]) {
        (this.filterForm.controls.filterUsers as FormArray).clear();
        this._users = value;
        this.users.forEach((o) => {
            const userControl = new FormControl(this.checkContainsUserCams(o));
            (this.filterForm.controls.filterUsers as FormArray).push(userControl, {emitEvent: false});
        });
    }

    private _cameras: CameraResponseModel[] = [];
    get cameras(): CameraResponseModel[] {
        return this._cameras;
    }
    set cameras(value: CameraResponseModel[]) {
        (this.filterForm.controls.filterCameras as FormArray).clear();
        this._cameras = value;
        value.forEach((o) => {
            const cameraControl = new FormControl(this.isCamInFilter(o));
            (this.filterForm.controls.filterCameras as FormArray).push(cameraControl, {emitEvent: false});
        });
    }

    constructor(
        private readonly formBuilder: FormBuilder,
        public dialog: MatDialog,
        public dialogService: DialogService,
        private filterService: ImageFilterService,
        private imageProcessService: ImageProcessService,
        private trackingService: TrackingService
    ) {
        super(dialog, dialogService);
        this.filter = filterService.filter;
    }

    ngOnInit() {
        this.filterForm = this.formBuilder.group({
            startDate: new FormControl(),
            endDate: new FormControl(),
            filterCameras: new FormArray([]),
            filterUsers: new FormArray([])
        });
        this.filterForm.controls.startDate.setValue(this.filterService.filter.startDate);
        this.filterForm.controls.endDate.setValue(this.filterService.filter.endDate);
        this.detectFormChanges();
        this.observData();
    }

    /**
     * Load current data from service and observe if values changes.
     */
    private observData() {
        this.cameras = this.filterService.cameras;
        this.subscriptions.push(this.filterService.cameras$.subscribe((cams) => {
            this.cameras = cams;
        }));

        this.users = this.filterService.users;
        this.subscriptions.push(this.filterService.users$.subscribe((users) => {
            this.users = users;
        }));
    }

    /**
     * Detects changes on the filter sidebar.
     */
     private detectFormChanges() {
        this.detectUserFilertChanges();
        this.detectCameraFilterChanges();

        this.subscriptions.push(this.filterForm.valueChanges.subscribe(val => {
            if (val.startDate instanceof Date) {
                this.filter.startDate = val.startDate;
            }

            if (val.endDate instanceof Date) {
                this.filter.endDate = moment(val.endDate).add(23, "h").add(59, "m").toDate();
            }

            if (this.filter.cameras.length > 0 || this.filter.startDate || this.filter.endDate) {
                this.shareButtonDisabled = false;
                this.deleteButtonDisabled = false;
            } else {
                this.shareButtonDisabled = true;
                this.deleteButtonDisabled = true;
            }
        }));
    }

    /**
     * Listens to the form control for users and updates the filter.
     */
    private detectUserFilertChanges() {
        this.subscriptions.push(this.filterForm.controls.filterUsers.valueChanges.subscribe(val => {
            const userArray = val as Array<boolean>;
            userArray.forEach((element, index) => {
                const user = this.filterService.users[index];
                if (user) {
                    if (element) {
                        const cameras = this.getCamerasForUser(user);
                        cameras.forEach((cam) => {
                            this.addCamToFilter(cam);
                        });
                    } else if (this.checkContainsUserCams(user)) {
                        const userCams = this.getCamerasForUser(user);
                        userCams.forEach(uCam => {
                            this.removeCamFromFilter(uCam);
                        });
                    }
                }
            });
            this.setFilterCamsToForm();
        }));
    }

    /**
     * Listens to the form control for cameras and updates the filter.
     */
     private detectCameraFilterChanges() {
        this.subscriptions.push(this.filterForm.controls.filterCameras.valueChanges.subscribe(val => {
            const formArray = val as Array<boolean>;
            formArray.forEach((element, index) => {
                if (element) {
                    this.addCamToFilter(this.cameras[index]);
                } else if (this.isCamInFilter(this.cameras[index])) {
                    this.removeCamFromFilter(this.cameras[index]);
                }
            });
            const userArray = (this.filterForm.controls.filterUsers as FormArray).controls;
            this.filterService.users.forEach((user, uIndex) => {
                const containsUserCams = this.checkContainsUserCams(user);
                if (userArray[uIndex] && userArray[uIndex].value !== containsUserCams) {
                    (this.filterForm.controls.filterUsers as FormArray).controls[uIndex].setValue(containsUserCams, {emitEvent: false});
                }
            });
        }));
    }

    private setFilterCamsToForm() {
        const camerasArray = (this.filterForm.controls.filterCameras as FormArray).controls;
        this.cameras.forEach((cam, cIndex) => {
            const camIsInFilter = this.isCamInFilter(cam);
            if (camerasArray[cIndex] && camerasArray[cIndex].value !== camIsInFilter) {
                (this.filterForm.controls.filterCameras as FormArray).controls[cIndex].setValue(camIsInFilter, {emitEvent: false});
            }
        });
    }

    /**
     * Adds a camera to the filter if not already in it.
     * @param camera the camera to add
     */
     private addCamToFilter(camera: CameraResponseModel) {
        if (!this.isCamInFilter(camera)) {
            this.filter.cameras.push(camera._id);
        }
    }

    /**
     * Checks if a array of camera ids contains all cameras of the given user.
     * @param user user to check
     * @param cameraIds array of camera ids.
     */
    private checkContainsUserCams(user: User): boolean {
        const userCams = this.getCamerasForUser(user);
        let containsAll = true;
        userCams.forEach(cam => {
            if (!this.isCamInFilter(cam)) {
                containsAll = false;
            }
        });
        return containsAll && this.filter.cameras.length > 0;
    }

    private removeCamFromFilter(camera: CameraResponseModel) {
        const camInd = this.filter.cameras.indexOf(camera._id);
        if (camInd !== undefined || camInd !== null) {
            if (camInd === 0) {
                this.filter.cameras.shift();
            } else if (camInd === this.filter.cameras.length - 1) {
                this.filter.cameras.pop();
            } else {
                this.filter.cameras.splice(camInd);
            }
        }
    }

    /**
     * Checks if the given camera is currently in the filter.
     * @param camera the camera to check
     */
     private isCamInFilter(camera: CameraResponseModel): boolean {
        return this.filter.cameras.some((cam) => cam === camera._id);
    }

    /**
     * Returns all cameras for the given user.
     * @param user the user
     */
    private getCamerasForUser(user: User): CameraResponseModel[] {
        return this.filterService.cameras.filter((cam) => cam.owner._id === user._id);
    }

    public filterImages() {
        this.filterService.filter = this.filter;
        this.filterSetEmitter.emit();
    }

    public clearFilter() {
        this.filterService.filter = new ImageFilter();
        this.filter = new ImageFilter();
        this.filterForm.reset();
    }

    public isFilterSet(): boolean {
        return this.filter.cameras.length > 0 || this.filter.endDate !== undefined || this.filter.startDate !== undefined;
    }

    public shareImages() {
        this.trackButton("share");
        // Important to take the current selected filters and not the applied (filterService.filter) filters
        this.imageProcessService.shareFilteredImages(this.filter);
    }

    public deleteImages() {
        this.trackButton("delete");
        // Important to take the current selected filters and not the applied (filterService.filter) filters
        this.imageProcessService.deleteSelectedImages(this.filter, this.filterService.firstImageUploadDate, true, true);
    }

    public selectAllCameras() {
        (this.filterForm.controls.filterCameras as FormArray).controls.forEach( (control) => { control.setValue(true); });
    }

    public selectAllUsers() {
        (this.filterForm.controls.filterUsers as FormArray).controls.forEach( (control) => { control.setValue(true); });
    }

    /**
     * Creates a Google Tag to track how many users click on the variant B of delete or share button.
     */
    private trackButton(buttonName): void {
        const gtmTag: TrackTag = {
            event: "Buttons"
        };
        switch (buttonName) {
            case "share":
                gtmTag.variantBShare = true;
                break;
            case "delete":
                gtmTag.variantBDelete = true;
                break;
        }
        this.trackingService.trackEvent(gtmTag);
    }
}
