import { Injectable, NgZone } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as JSZip from "jszip";
import { firstValueFrom, Observable, Subject, Subscription } from "rxjs";
import { IMAGE_TYPE } from "../api-handling/models/enums/ImageType";
import { ImageListResponseModel } from "../api-handling/models/ImageListResponseModel";
import { ImageResponseModel } from "../api-handling/models/ImageResponseModel";
import { ImageFilter } from "../components/images/models/ImageFilter";
import { DeviceDetector } from "../helpers/DeviceDetector";
import { ConnectionService } from "../native/ConnectionService";
import { ImageRepository } from "../repositories/ImageRepository";
import { DialogService } from "./DialogService";
import { ImageRestService } from "./http/ImageRestService";
import { ShareFiles, ShareService } from "./ShareService";


export interface ImageProcessNotification {
    state: ImageProcessState;
    title: string | undefined;
    message: string | undefined;
}

export enum ImageProcessState {
    ERROR,
    RUNNING,
    FINISHED
}

type ImageProcessType = "runningDelete" | "runningDownloadingImages" | "runningFetchingImageInformations" | "unknownError" | "finished";

/**
 * This service takes images and can perform actions with the images. The actions are either Share Images or Delete images.
 */

@Injectable()

export class ImageProcessService {
    // Limit maximal images count to share to prevent performance issues
    private shareImageCountCap = 100;

    public selectedImages: ImageResponseModel[] = [];
    private selectionInverted: boolean = false;

    // Offline status
    public offline = true;
    public subscriptions: Subscription[] = [];

    private emitImageProcessSource = new Subject<ImageProcessNotification>();
    imageProcessObservable$ = this.emitImageProcessSource.asObservable();

    private emitInvertSelectionStatus = new Subject<Boolean>();
    invertSelectionStatus$ = this.emitInvertSelectionStatus.asObservable();

    private emitUpdateSystem = new Subject<void>();
    updateSystem$ = this.emitUpdateSystem.asObservable();

    private emitUpdateData = new Subject<void>();
    updateData$ = this.emitUpdateData.asObservable();

    constructor(
        connection: ConnectionService,
        public zone: NgZone,
        private translate: TranslateService,
        public dialogService: DialogService,
        private imageRestService: ImageRestService,
        public imageRepository: ImageRepository,
        private shareService: ShareService
    ) {
        this.offline = !(!DeviceDetector.isOnCordova() || DeviceDetector.hasInternetConnection());
        // Listening to connection changes
        this.subscriptions.push(connection.connectionChanged.subscribe(con => {
            zone.run(() => {
                if (con) {
                    this.offline = !con.isOnline;
                }
            });
        }));
    }

    ///
    // Image Selection
    ///

    public selectMany(images: ImageResponseModel[]) {
        images.forEach(img => {
            this.select(img);
        });
    }

    public select(img: ImageResponseModel) {
        const isSelected = this.selectedImages.some((i) => i._id === img._id);
        if (isSelected) {
            const index = this.selectedImages.indexOf(img);

            this.selectedImages.splice(index, 1);
        } else {
            this.selectedImages.push(img);
        }
    }

    public isSelected(img: ImageResponseModel): boolean {
        if (!this.selectionInverted) {
            return this.selectedImages.some((i) => i._id === img._id);
        } else {
            return !this.selectedImages.some((i) => i._id === img._id);
        }
    }

    public invertSelection() {
        this.selectedImages = [];
        this.selectionInverted = !this.selectionInverted;
        this.emitInvertSelectionStatus.next(this.selectionInverted);
    }

    public resetInvertSelection() {
        this.selectionInverted = false;
        this.emitInvertSelectionStatus.next(this.selectionInverted);
    }

    public clearSelection() {
        this.selectedImages = [];
        this.resetInvertSelection();
    }

    ///
    // Action Validation
    ///

    /**
     * Checks if choosen images can be deleted
     * @returns boolean value that indicates if images can be deleted.
     */
    public canDeleteImages(moreImagesToLoad: boolean, filteredImagesCount: number): boolean {
        if (!this.selectionInverted) {
            // If normal (not inverted) selection mode

            // Can NOT delete images, if all selected images are from other user
            if (this.selectedImages.every((i) => !i.camera.isOwner)) {
                return false;
            }

            // Can delete images, if there is at least one image selected
            return !this.selectionEmpty();
        } else {
            // If inverted selection mode

            // Can delete images, if there are more images to load (we assume there are some images from owner to load)
            if (moreImagesToLoad) {
                return true;
            }

            // Can NOT delete images, if all images are unselected
            if (this.selectedImages.length === filteredImagesCount) {
                return false;
            }

            return true;
        }
    }

    /**
     *
     * @returns boolean value that indicates if images can be shared.
     */

     public canShareImages(moreImagesToLoad: boolean, filteredImagesCount: number): boolean {
        if (!this.selectionInverted) {
            // If normal (not inverted) selection mode

            // Can share images, if there is at least one image selected
            return !this.selectionEmpty();
        } else {
            // If inverted selection mode

            // check if there are more images to load
            if (moreImagesToLoad) {
                return true;
            }

            // If inverted selection, check if all displayed (and loaded) images all unselected
            if (this.selectedImages.length === filteredImagesCount) {
                return false;
            }

            return true;
        }
    }

    /**
     * Checks if images are selected.
     * @returns boolean that indicates if images are selected.
     */
    public selectionEmpty(): boolean {
        return this.selectedImages.length <= 0;
    }

    public openDialog(title: string, message: string, buttons: string[]): Observable<number> {
        return this.openImgDialog(title, message, undefined, buttons);
    }

    public openImgDialog(title: string, message: string, imgName: string, buttons: string[]): Observable<number | null> {
        return this.dialogService.openDialog(title, message, imgName, buttons, this.subscriptions);
    }

    /**
     * Deletes all selected images. Needs internet connection to perform delete.
     */
    public async deleteSelectedImages(filters: ImageFilter, firstImageUploadDate: Date | undefined, selectionInverted?: boolean, noSelection?: boolean) {
        if (this.offline) {
            this.openDialog(this.translate.instant("images.deleteOfflineTitle"), this.translate.instant("images.deleteOfflineMsg"), null);
            return;
        }

        if (typeof selectionInverted === "undefined") {
            selectionInverted = this.selectionInverted;
        }

        const ids: string[] = (typeof noSelection === "undefined" || noSelection === false)
                              ? this.selectedImages.map( (image) => image._id)
                              : [];

        let deleteDialog$: Observable<number>;

        if (ids.length > 1) {
            const message = selectionInverted
                            ? ( filters.favourites ? "images.deleteInvertedMsgMany" : "images.deleteInvertedMsgManyWithoutFav" )
                            : "images.deleteMsgMany";

            deleteDialog$ = this.openDialog(this.translate.instant("images.deleteTitle"), this.translate.instant(message, { count: ids.length }),
            [this.translate.instant("images.delete"), this.translate.instant("shared.cancelButton")]);
        } else if (ids.length > 0) {
            const message = selectionInverted
                            ? ( filters.favourites ? "images.deleteInvertedMsgOne" : "images.deleteInvertedMsgOneWithoutFav" )
                            : "images.deleteMsgOne";

            deleteDialog$ = this.openDialog(this.translate.instant("images.deleteTitle"), this.translate.instant(message),
            [this.translate.instant("images.delete"), this.translate.instant("shared.cancelButton")]);
        } else if (selectionInverted) {
            const message =  ( filters.favourites ? "images.deleteInvertedMsgAll" : "images.deleteInvertedMsgAllWithoutFav" );
            deleteDialog$ = this.openDialog(this.translate.instant("images.deleteTitle"), this.translate.instant(message),
            [this.translate.instant("images.delete"), this.translate.instant("shared.cancelButton")]);
        }

        if (!deleteDialog$) {
            return; // Should never happen (if the app was not manipulated)
        }

        const buttonIndex = await firstValueFrom(deleteDialog$);

        if (typeof buttonIndex === "undefined" || buttonIndex === 1) {
            this.emitImageProcess("finished");

            return false;
        }

        this.zone.run(() => {
            this.emitImageProcess("runningDelete");

            let operation: Observable<ImageResponseModel>;
            if (!selectionInverted) {
                operation = this.imageRestService.deleteImages(ids);
            } else {
                operation = this.imageRestService.deleteImagesByFilter(ids, filters, firstImageUploadDate);
            }

            this.subscriptions.push(operation.subscribe({
                next: () => {
                    this.zone.run(() => {
                        this.selectedImages = [];
                        this.clearSelection();
                        this.emitUpdateSystem.next();
                        this.emitImageProcess("finished");
                    });
                },
                error: () => {
                    this.zone.run(() => {
                        this.emitImageProcess("unknownError");
                    });
                }
            }));
        });
    }


    public updateData() {
        this.emitUpdateData.next();
    }

    /**
     * Share images (in main image list)
     *
     * @param filters Filters choosen before
     * @param firstImageUploadDate First image upload date to share only what the user see
     */
    public async shareSelectedImages(filters: ImageFilter, firstImageUploadDate: Date | undefined, imageCount: number) {
        if (this.offline) {
            this.openDialog(
                this.translate.instant("images.shareOfflineTitle"),
                this.translate.instant("images.shareOfflineMsg"),
                null
            );
            return;
        }

        // Selected images to be included or excluded (selectionInverted considered)
        const selectedImages = this.selectedImages;
        const selectedImageIds = selectedImages.map( (image) => image._id);
        const imageCountToShare = (
            !this.selectionInverted
            ? selectedImages.length
            : imageCount - selectedImages.length
        );

        if (imageCountToShare > this.shareImageCountCap) {
            const proceed = await this.askUserToProceed();
            if (proceed === false) { return; }
        }

        this.emitImageProcess("runningDownloadingImages");

        let imagesToShare: ImageResponseModel[];

        if (!this.selectionInverted) {
            imagesToShare = selectedImages;
        } else {
            // If selection inverted, get (selected + share image cap) count of images
            const filteredImageList$ = this.imageRestService.getImageListObject(1, filters.cameras, filters.startDate, filters.endDate, filters.favourites, this.shareImageCountCap + selectedImages.length, firstImageUploadDate);

            try {
                const filteredImageList = await firstValueFrom(filteredImageList$);
                imagesToShare = filteredImageList.images.filter(
                    (filteredImage) => !selectedImageIds.includes(filteredImage._id)
                );
            } catch (_: any) {
                this.emitImageProcess("unknownError");

                return;
            }
        }

        if (imageCountToShare > this.shareImageCountCap) {
            imagesToShare = imagesToShare.slice(0, this.shareImageCountCap);
        }

        this.loadAndShareImages(imagesToShare);
    }

    /**
     * Share images (for sidebar filter share button)
     *
     * @param filters Filters choosen before
     */
    public async shareFilteredImages(filters: ImageFilter): Promise<void> {
        if (this.offline) {
            this.openDialog(
                this.translate.instant("images.shareOfflineTitle"),
                this.translate.instant("images.shareOfflineMsg"),
                null
            );
            return;
        }

        let imageCountToShare: number;
        let filteredImageList: ImageListResponseModel;

        this.emitImageProcess("runningFetchingImageInformations");

        const filteredImageList$ = this.imageRestService.getImageListObject(1, filters.cameras, filters.startDate, filters.endDate, filters.favourites, this.shareImageCountCap + 1);

        try {
            filteredImageList = await firstValueFrom(filteredImageList$);
            imageCountToShare = filteredImageList.images.length;
        } catch (_: any) {
            this.emitImageProcess("unknownError");

            return;
        }

        let imagesToShare: ImageResponseModel[];

        if (imageCountToShare > this.shareImageCountCap) {
            const proceed = await this.askUserToProceed(true);
            if (proceed !== true) { return; }

            imagesToShare = filteredImageList.images.slice(0, this.shareImageCountCap);
        } else {
            imagesToShare = filteredImageList.images;
        }

        this.emitImageProcess("runningDownloadingImages");

        this.loadAndShareImages(imagesToShare);
    }

    /**
     * Shows the user a dialog, that the count of share images is limited
     *
     * @param sidebarFiltered true if user comes from sidebar filter
     * @returns true if user wants to proceed
     *          false if user cancel
     */

    private async askUserToProceed(sidebarFiltered: boolean = false): Promise<Boolean> {
        this.emitImageProcess("finished");

        const shareDialog$ = this.openDialog(
            this.translate.instant(
                !sidebarFiltered
                ? "images.shareTooManyPicsSelectedTitle"
                : "images.shareTooManyPicsFilteredTitle"
            ),
            this.translate.instant(
                !sidebarFiltered
                ? "images.shareTooManyPicsSelectedMsg"
                : "images.shareTooManyPicsFilteredMsg",
                { count: this.shareImageCountCap }
            ),
        [this.translate.instant("shared.ok"), this.translate.instant("shared.cancelButton")]);

        try {
            const buttonIndex = await firstValueFrom(shareDialog$);

            if (typeof buttonIndex === "undefined" || buttonIndex === 1) {
                this.emitImageProcess("finished");

                return false;
            }
        } catch (_: any) {
            this.emitImageProcess("unknownError");

            return false;
        }

        return true;
    }

    /**
     * Loads (, zips if more than 1 image) and share images
     * @param choosenImages Images to load (, zip) and share
     */
    private async loadAndShareImages(choosenImages: ImageResponseModel[]): Promise<void> {
        const imageCount = choosenImages.length;

        if (imageCount === 0) {
            this.emitImageProcess("finished");
            this.openDialog(this.translate.instant("error.title"), "Nothing to share", null);
            return;
        }

        let file: ShareFiles;

        if (imageCount === 1) {
            const imageFile = await this.imageRepository.loadImgFile(choosenImages[0]._id, IMAGE_TYPE.full);
            const blob =  await (await fetch(imageFile.file)).blob();
            file = {name: choosenImages[0]._id + ".jpg", data: blob};
        } else {
            const zip = new JSZip();

            for (let i = 0; i < imageCount; i++) {
                const image = choosenImages[i];
                const imageFile = await this.imageRepository.loadImgFile(image._id, IMAGE_TYPE.full);
                const blob = await (await fetch(imageFile.file)).blob();

                zip.file(image._id + ".jpg", blob, {base64: true});
            }

            const zipConent = await zip.generateAsync({type: "blob"});

            file = {name: "images.zip", data: zipConent};
         }

        await this.shareService.shareFile(file);
        this.emitImageProcess("finished");
    }

    /**
     * Emits image processing state (to show dialogs and popups)
     * @param state State to emit
     */
    private emitImageProcess(state: ImageProcessType) {
        switch (state) {
            case "runningDelete":
                this.emitImageProcessSource.next({
                    state: ImageProcessState.RUNNING,
                    title: this.translate.instant("images.deleteTitle"),
                    message: this.translate.instant("images.deleteMsgPleaseWait")
                });
                break;
            case "runningDownloadingImages":
                this.emitImageProcessSource.next({
                    state: ImageProcessState.RUNNING,
                    title: this.translate.instant("images.downloading"),
                    message: this.translate.instant("images.downloading")
                });
                break;
            case "runningFetchingImageInformations":
                this.emitImageProcessSource.next({
                    state: ImageProcessState.RUNNING,
                    title: this.translate.instant("images.downloading"),
                    message: this.translate.instant("images.fetchInformations")
                });
                break;
            case "unknownError":
                this.emitImageProcessSource.next({
                    state: ImageProcessState.ERROR,
                    title: undefined,
                    message: undefined
                });
                break;
            case "finished":
                this.emitImageProcessSource.next({
                    state: ImageProcessState.FINISHED,
                    title: undefined,
                    message: undefined
                });
                break;
        }
    }
}
