<div class="loginContainer">
    <form [formGroup]="registrationForm" class="loginFieldsContainer" (ngSubmit)="registerUser()">
        <img class="doerrLogo" src="assets/img/doerr-outdoor-Logo.png" alt="Doerr company logo">
        <div class="inputContainer">            
            <mat-form-field appearance="fill" class="doerrInput">
                <mat-icon matSuffix>account_circle</mat-icon>
                <input matInput type="text" placeholder="{{'shared.firstnamePlaceholder' | translate}}" formControlName="firstname" (blur)="onBlur()" autocomplete="on" name="given-name">
                <mat-error>{{ 'shared.validFirstname' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" class="doerrInput">
                <mat-icon matSuffix>account_circle</mat-icon>
                <input matInput type="text" placeholder="{{'shared.namePlaceholder' | translate}}" formControlName="name" (blur)="onBlur()" autocomplete="on" name="family-name">
                <mat-error>{{ 'shared.validName' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" class="doerrInput">
                <mat-icon matSuffix>mail_outline</mat-icon>
                <input matInput type="email" inputmode="email" placeholder="{{'shared.mailPlaceholder' | translate}}" formControlName="mail" (blur)="onBlur()" autocomplete="on" name="email">
                <mat-error>{{ 'shared.validMail' | translate }}</mat-error>
            </mat-form-field>
            <div formGroupName="passwords" class="equalInput">
                <mat-form-field appearance="fill" class="doerrInput">
                    <mat-icon matSuffix>vpn_key</mat-icon>
                    <input matInput type="password" placeholder="{{'shared.passwordPlaceholder' | translate}}" formControlName="password" (blur)="onBlur()"  autocomplete="on" name="new-password">
                    <mat-error>{{ 'registration.requiredPassword' | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" class="doerrInput" >
                    <mat-icon matSuffix>vpn_key</mat-icon>
                    <input matInput type="password" placeholder="{{'registration.passwordRepeatPlaceholder' | translate}}" formControlName="passwordRepeat" (blur)="onBlur()" autocomplete="on" name="new-password">                        
                    <mat-error>{{ 'registration.notEqual' | translate }}</mat-error>
                </mat-form-field>
            </div>         
            <a mat-button class="linkText" [routerLink]="['/datapolicy']">{{ 'registration.datapolicyLink' | translate }}</a>
            <mat-checkbox class="doerrCheckBox" color="primary" formControlName="datapolicy">{{ 'registration.datapolicy' | translate }}
                <mat-error *ngIf="!registrationForm.controls.datapolicy.valid && submittClicked">{{ 'registration.datapolicyError' | translate }}</mat-error>
            </mat-checkbox>                
        </div>
        <div class="w-100">
            <div class="smallLinkText bottomTextLink" (click)="backToLogin()">{{'shared.backToSignIn' | translate }}</div>
            <button class="signInButton" mat-raised-button [disabled]="submitted" color="primary">{{'registration.registrationButton' | translate }}</button>
        </div>
    </form>
</div>
