<div class="loginContainer">
    <form [formGroup]="loginForm" class="loginFieldsContainer" (ngSubmit)="login()">
        <img class="doerrLogo" src="assets/img/doerr-outdoor-Logo.png" alt="Doerr company logo">
        <div class="inputContainer">            
                <mat-form-field appearance="fill" class="doerrInput">
                    <mat-icon matSuffix>account_circle</mat-icon>
                    <input matInput type="email" inputmode="email" placeholder="{{'shared.mailPlaceholder' | translate}}" formControlName="mail" (blur)="onBlur()">
                    <mat-error>{{ 'shared.validMail' | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" class="doerrInput">
                    <mat-icon matSuffix>vpn_key</mat-icon>
                    <input matInput type="password" placeholder="{{'shared.passwordPlaceholder' | translate}}" formControlName="password" [(ngModel)]="passwordValue" (blur)="onBlur()">
                    <mat-error>{{ 'login.requiredPassword' | translate }}</mat-error>
                </mat-form-field>            
            <div class="smallLinkText" (click)="showPasswordForgot()">{{'login.forgotPassword' | translate }}</div>
        </div>
        <div class="w-100">
            <div class="smallLinkText bottomTextLink" (click)="showRegistration()">{{'login.registrationButton' | translate }}</div>
            <button class="signInButton" mat-raised-button color="primary" >{{'login.signIn' | translate }}</button>
        </div>        
    </form>    
</div>
