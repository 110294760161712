import { Component, OnInit, ViewChild, NgZone } from "@angular/core";
import { NavigationBarItemModel } from "src/app/models/NavigationBarItemModel";
import { DeviceDetector } from "src/app/helpers/DeviceDetector";
import { BreakpointObserver } from "@angular/cdk/layout";
import { ImageRestService } from "src/app/services/http/ImageRestService";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { NavigationService } from "src/app/services/NavigationService";
import { CordovaInitHandler } from "src/app/native/CordovaInitHandler";
import { Location } from "@angular/common";
import { ImageResponseModel } from "src/app/api-handling/models/ImageResponseModel";
import { CameraRestService } from "src/app/services/http/CameraRestService";
import { ImageRepository } from "src/app/repositories/ImageRepository";
import { ImageFilterRoute, ImageFilterService } from "src/app/services/ImageFilterService";
import { TranslateService } from "@ngx-translate/core";
import { LocalStorageService } from "src/app/services/LocalStorageService";
import { UserRestService } from "src/app/services/http/UserRestService";
import { from } from "rxjs";
import { ImageSyncService } from "src/app/services/ImageSycnService";
import { ConnectionService } from "src/app/native/ConnectionService";
import { DialogService } from "src/app/services/DialogService";
import { AppRateService } from "src/app/native/AppRatingService";
import { TrackingService, TrackTag } from "src/app/services/TrackingService";
import { BaseNavComponent } from "../base/baseNav.component";
import { CameraResponseModel } from "src/app/api-handling/models/CameraResponseModel";
import { ImageListState } from "./components/imageList/imageList.component";
import { ImageProcessService } from "src/app/services/ImageProcessService";
import { ImageFilter } from "./models/ImageFilter";

declare var window: {plugins: { appMinimize: { minimize: () => void; }; }; isTablet: boolean };

@Component({
    selector: "app-images",
    templateUrl: "images.component.html",
    styleUrls: ["images.component.scss"]
})

export class ImagesComponent extends BaseNavComponent implements OnInit {

    @ViewChild("filterDrawer", {static: true}) filterSidenav: MatSidenav;

    public barButton = new NavigationBarItemModel("more_vert");
    public selectBarButton = new NavigationBarItemModel("touch_app");
    private _selectionState: ImageListState;
    public get selectionState() {
        return this._selectionState;
    }
    public set selectionState(value: ImageListState) {
        this._selectionState = value;
        this.selectBarButton.matImageName = value === ImageListState.MULTI_SELECTION ? "cancel" : "touch_app";
    }

    // Offline status
    public hasNoInternetConnection = true;

    // Filter propertys
    public isFav = false;

    private isUpdating = false;
    // This variable determines if a error during sync process.
    private couldNotSync = false;

    constructor(breakpointObserver: BreakpointObserver, public imageRestService: ImageRestService, dialog: MatDialog, private router: Router,
        navigationService: NavigationService, location: Location, initHandler: CordovaInitHandler, private userRestService: UserRestService,
        public imageRepository: ImageRepository, private readonly localStorageService: LocalStorageService,
        public imageFilterService: ImageFilterService, private translate: TranslateService, private readonly activatedRoute: ActivatedRoute, public zone: NgZone,
        private syncService: ImageSyncService, connection: ConnectionService, dialogService: DialogService, private rateService: AppRateService,
        private trackingService: TrackingService, private readonly cameraRestService: CameraRestService, private imageProcessService: ImageProcessService) {
        super(dialog, navigationService, location, initHandler, breakpointObserver, dialogService);

        this.hasNoInternetConnection = !this.isOnline();
        // Listening to connection changes
        this.subscriptions.push(connection.connectionChanged.subscribe(con => {
            zone.run(() => {
                if (con) {
                    this.hasNoInternetConnection = !con.isOnline;
                }
            });
        }));

        this.subscriptions.push(this.barButton.action.subscribe(() => {
            this.filterSidenav.toggle();
        }));

        this.subscriptions.push(this.selectBarButton.action.subscribe(() => {
            this.changeIsSelecting();
        }));

        this.subscriptions.push(this.imageProcessService.updateSystem$.subscribe(() => {
            this.updateSystem();
        }));

        this.selectionState = ImageListState.SINGLE_SELECTION;
        this.imageRepository.images = [];
        this.isFav = this.activatedRoute.snapshot.data["type"] === "favourites";
        this.imageFilterService.route = this.isFav ? ImageFilterRoute.FAVORITES : ImageFilterRoute.IMAGES;
        this.imageFilterService.page = 1;
        this.subscriptions.push(imageFilterService.cameras$.subscribe((cams) => {
            this.trackCameras(cams);
        }));

        const cameraShowPicturesEntry = this.router.getCurrentNavigation()?.extras?.state?.filtercameras;

        if (cameraShowPicturesEntry?.length > 0) {
            this.imageFilterService.filter = new ImageFilter();
            this.imageFilterService.filter.cameras = cameraShowPicturesEntry;
        }

        this.imageFilterService.updateFilterParameters();
        this.checkInvitations();
    }

    ngOnInit() {
        if (DeviceDetector.isOnCordova()) {
            this.subscriptions.push(this.translate.get(["rating"]).subscribe((translation) => {
                this.rateService.setup({
                    title: translation.rating.title,
                    message: translation.rating.message,
                    cancelBtn: translation.rating.cancelBtn,
                    laterBtn: translation.rating.laterBtn,
                    rateBtn: translation.rating.rateBtn
                });
            }));
        }
    }

    /**
     * Checks if new invitations available.
     */
     private checkInvitations() {
        if (this.isOnline()) {
            this.subscriptions.push(this.cameraRestService.getInviteList().subscribe(async (resultInvites) => {
                if (resultInvites && resultInvites.length > 0) {
                    const mButtons: string[] = [this.translate.instant("shared.yes"), this.translate.instant("shared.no")];
                    const buttonClicked = await this.openDialog(this.translate.instant("navigation.newInviteTitle"), this.translate.instant("navigation.newInviteMsg"), mButtons ).toPromise();
                    if (buttonClicked !== null && buttonClicked === 0) {
                        this.router.navigate(["home/cameras"]);
                    }
                }
            }));
        }
    }

    /**
     * Toggles between single and multiple selection mode.
     * If isSelecting true user can select multiple images.
     */
    private changeIsSelecting() {
        switch (this.selectionState) {
            case ImageListState.MULTI_SELECTION:
                this.selectionState = ImageListState.SINGLE_SELECTION;
                break;
            case ImageListState.SINGLE_SELECTION:
                this.selectionState = ImageListState.MULTI_SELECTION;
                break;
        }
    }

    private async updateData() {
        this.imageProcessService.updateData();
    }

    /**
     * Creates a Google Tag to track how many cameras the user uses and if he is owner or not.
     */
    private trackCameras(cameras: CameraResponseModel[]): void {
        const ownCameras = cameras.filter((cam) => cam.isOwner === true);
        const friendCameras = cameras.filter((cam) => !cam.isOwner);
        const gtmTag: TrackTag = {
            event: "Cameras",
            cameraCount: ownCameras.length,
            friendCameras: friendCameras.length
        };
        this.trackingService.trackEvent(gtmTag);
    }

    updateOnResume() {
        this.updateSystem();
        this.checkInvitations();
    }

    /**
     * Starts synchronisation of the database and if this is successfully, load data for component.
     */
    private updateSystem() {
        if (!this.isUpdating) {
            this.isUpdating = true;
            this.zone.runOutsideAngular(() => {
                if (DeviceDetector.isOnCordova() && this.isOnline()) {
                    const that = this;
                    this.subscriptions.push(from(this.syncService.syncDatabase()).subscribe(() => {
                        this.couldNotSync = false;
                        this.zone.run(() => {
                            that.updateData();
                            this.isUpdating = false;
                        });
                    }, () => {
                        this.zone.run(() => {
                            that.couldNotSync = true;
                            this.isUpdating = false;
                        });
                    }));
                } else {
                    this.updateData();
                    this.isUpdating = false;
                }
            });
        }
    }

    public goBackButtonPressed() {
        if (DeviceDetector.isOnCordova()) {
            // If filter sidebar open close on back button event.
            if (this.filterSidenav.opened) {
                this.filterSidenav.toggle();
            } else if (!this.isFav) {
                // Close Application
                window.plugins.appMinimize.minimize();
            } else {
                super.goBackButtonPressed();
            }
        } else {
            super.goBackButtonPressed();
        }
    }

    public clickedImg(img: ImageResponseModel): void {
        this.navigateTo(img);
    }

    public didSetFilter(): void {
        this.filterSidenav.toggle();
    }

    private navigateTo(img: ImageResponseModel) {
        const navigationExtras: NavigationExtras = { queryParams: {id: img._id, fav: this.isFav}};
        // this.imageFilterService.images = this.images;
        this.imageFilterService.lastImageId = img._id;
        if (this.isFav) {
            this.router.navigate(["home/imageDetailFav"], navigationExtras);
        } else {
            this.router.navigate(["home/imageDetail"], navigationExtras);
        }
    }

    /*public getMinDate(): Date {
        if ( this.filter.startDate ) {
            return this.filter.startDate;
        }
        return  new Date(0);
    }*/
}
