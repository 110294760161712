import { Injectable, OnDestroy } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, throwError, from } from "rxjs";
import { catchError, flatMap } from "rxjs";
import { IApiErrorResponse } from "../models/IApiErrorResponse";
import { LocalStorageService } from "src/app/services/LocalStorageService";
import { DeviceDetector } from "../../helpers/DeviceDetector";
import { AppUserService } from "../../services/database/AppUserService";
import { AuthRestService } from "../../services/http/AuthRestService";

/** Pass untouched request through to the next request handler. */
@Injectable()
export class HttpAccessInterceptor implements HttpInterceptor, OnDestroy {

    ngOnDestroy(): void {
        throw new Error("Method not implemented.");
    }

    constructor(private readonly router: Router,
        private readonly localStorageService: LocalStorageService, private readonly appUserService: AppUserService,
        private readonly authRestService: AuthRestService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(
            catchError(
                (err: HttpErrorResponse) => {
                    if (err && err.error && err.error.id && !req.url.endsWith("applogin")) {
                        const errorResponse: IApiErrorResponse = err.error;
                        if (errorResponse.code === "authentication_required") {
                            this.localStorageService.clearAccessInfos();
                            if (DeviceDetector.isOnCordova()) {
                                return from(this.refreshLogin()).pipe(flatMap((result) => {
                                    if (result) {
                                        return next.handle(this.updateHeader(req));
                                    } else {
                                        this.router.navigate(["signin"]);
                                        return throwError(err);
                                    }
                                }));
                            } else {
                                this.router.navigate(["signin"]);
                            }
                        }
                    }
                    return throwError(err);
                }
            )
        );
    }

    /**
     * Performs sign in and refreshs token if user data stored in database.
     * @param callback called with true if token refreshed.
     */
    private async refreshLogin(): Promise<boolean> {
        const user = await this.appUserService.getAppUser();
        if (user && user.email && user.password ) {
            try {
                const result = await this.authRestService.signIn(user.email, user.password).toPromise();
                return result.success;
            } catch (error) {
                return false;
            }
        } else {
            return false;
        }
    }

    /**
     * Updates a given request with current Token.
     * @param request Request to update
     */
    private updateHeader(request: HttpRequest<any>): HttpRequest<any> {
        const infos = this.localStorageService.getAccessInfos();
        if (infos.accessToken) {
            return request.clone({
                setHeaders: {
                    "Authorization": `"Bearer ${infos.accessToken}`
                }
            });
        }
        return request;
    }
}
