<app-toolbar [name]="title" [leftBarButton]="backBarButton"></app-toolbar>
<div class="container-overflow-scroll px-fit overflow-auto flex-column d-flex justify-content-between">
    <div *ngIf="userCameraListItemHeaders && userCameraListItemHeaders.length > 0" class="row">
        <div class="col-12 descriptionText px-4 pt-3">{{'cameraHdList.description' | translate}}</div>
        <mat-grid-list class="col-12 cameraList" [cols]="columns" rowHeight="190px">
            <mat-grid-tile class="selectableCard" *ngFor="let cameraHeader of userCameraListItemHeaders">
                <app-cameralistitem [cameraListItem]="cameraHeader" (notify)="onCameraClicked($event)" [class.unsupported-product]="!cameraHeader.supportedProduct"></app-cameralistitem>
            </mat-grid-tile>
        </mat-grid-list>        
    </div>
    <div class="row py-3 d-flex justify-content-center">
        <button class="col-10 col-sm-6 col-md-3" mat-raised-button color="primary" (click)="goBackButtonPressed()" >{{'shared.store' | translate }}</button>
    </div>
    <div class="row" *ngIf="!userCameraListItemHeaders || userCameraListItemHeaders.length === 0" class="descriptionText px-4 pt-3 text-center">{{'cameraHdList.noCameras' | translate}}</div>
</div>
