<div class="loginContainer">
    <form [formGroup]="resetForm" class="loginFieldsContainer" (ngSubmit)="resetPassword()">
        <img class="doerrLogo" src="assets/img/doerr-outdoor-Logo.png" alt="Doerr company logo">
        <div class="inputContainer">
            <mat-form-field appearance="fill" class="doerrInput">
                <mat-icon matSuffix>account_circle</mat-icon>
                <input matInput type="email" inputmode="email" placeholder="{{'shared.mailPlaceholder' | translate}}" formControlName="mail" (blur)="onBlur()">
                <mat-error>{{ 'shared.validMail' | translate }}</mat-error>
            </mat-form-field>                    
        </div>
        <div class="w-100">
            <div class="smallLinkText bottomTextLink" (click)="backToLogin()">{{'shared.backToSignIn' | translate }}</div>
            <button class="signInButton" mat-raised-button [disabled]="submitted" color="primary">{{'password.resetPassword' | translate }}</button>
        </div>        
    </form>    
</div>
    