import { BreakpointObserver } from "@angular/cdk/layout";
import { Component, NgZone, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CordovaInitHandler } from "src/app/native/CordovaInitHandler";
import { DialogService } from "src/app/services/DialogService";
import { NavigationService } from "src/app/services/NavigationService";
import { BaseNavComponent } from "../base/baseNav.component";
import { Location } from "@angular/common";
import { CleverReachService } from "src/app/services/http/CleverReachRestService";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

declare var window: any;
@Component({
    selector: "app-newsletter",
    templateUrl: "newsletter.component.html",
    styleUrls: ["newsletter.component.scss"]
})

export class NewsletterComponent extends BaseNavComponent implements OnInit {

    public cleverReachUrl: string;
    public mail: string = "";
    public newsletterForm: FormGroup;

    constructor(dialog: MatDialog, navigationService: NavigationService, location: Location, initHandler: CordovaInitHandler,
        breakpointObserver: BreakpointObserver, dialogService: DialogService, private cleverReachService: CleverReachService,
        private zone: NgZone, private translate: TranslateService, private router: Router, private formBuilder: FormBuilder) {
        super(dialog, navigationService, location, initHandler, breakpointObserver, dialogService);

        this.cleverReachUrl = environment.cleverReachUrl;
    }

    ngOnInit(): void {
        this.newsletterForm = this.formBuilder.group({
            email: ["", [Validators.required, Validators.email]]
        });
    }

    registerForNewsletter() {
        if (!this.newsletterForm.invalid) {
            this.showSpinner("", "");
            this.subscriptions.push(this.cleverReachService.postNewsletter(this.newsletterForm.value.email).subscribe((response) => {
                this.zone.run(() => {
                    this.closeSpinner();
                    if (response) {
                        this.router.navigate(["home/newsletter-confirm"]);
                    } else {
                        this.openDialog(this.translate.instant("newsletter.errorTitle"), this.translate.instant("newsletter.errorMsg"), null);
                    }
                });
            }, (err) => {
                this.closeSpinner();
            }));
        }
    }
}
