<div class="container-fluid pt-3 h-100 pb-0 overflow-hidden">
    <div class="row h-100 overflow-hidden position-relative">
        <div *ngIf="hasNoInternetConnection && !isFav" class="col-12 h-info text-center descriptionText">{{ 'images.imageCount' | translate:countparam }} / {{ 'images.offlineLabel' | translate }}</div>
        <div *ngIf="hasNoInternetConnection && isFav" class="col-12 h-info text-center descriptionText">{{ 'images.favCount' | translate:countparam }} / {{ 'images.offlineLabel' | translate }}</div>
        <div *ngIf="!hasNoInternetConnection && !isFav" class="col-12 h-info text-center descriptionText">{{ 'images.imageCount' | translate:countparam }}</div>
        <div *ngIf="!hasNoInternetConnection && isFav" class="col-12 h-info text-center descriptionText">{{ 'images.favCount' | translate:countparam }}</div>                
        <div *ngIf="images.length == 0 && !isFav" class="col-12 h-list text-center">{{ 'images.noImages' | translate }}</div>
        <div *ngIf="images.length == 0 && isFav" class="col-12 h-list text-center">{{ 'images.noFavourites' | translate }}</div>
        <div #imageList class="col-12 h-list overflow-auto pb-2" infiniteScroll [infiniteScrollDistance]="5" [infiniteScrollThrottle]="50" (scrolled)="runNextPage()" [scrollWindow]="false">
            <div *ngFor="let mYear of imageYears">
                <div *ngIf="mYear.year !== currentYear" class="year-container">{{mYear.year}}</div>
                <div id="day_{{day.day}}" *ngFor="let day of mYear.days">
                    <div id="day_title_{{day.day}}" class="date-container">{{day.dayReadable}}</div>
                    <mat-grid-list [cols]="columns" [gutterSize]="8" rowHeight="1:1">
                        <mat-grid-tile id="tileId_{{img._id}}" class="rounded cursor-pointer mb-3 imgContainer" *ngFor="let img of day.images"  (tap)="select(img)" (press)="startSelection(img)">
                            <img class="thumbnailImg" src="{{getImg(img)}}" (error)="imgErrorAccured(img._id)" alt="">
                            <div [@opacity]="listState === allListStates.MULTI_SELECTION ? 'show' : 'hide'">
                                <div class="select-icon-background">
                                    <mat-icon *ngIf="!isSelected(img)" color="primary" mat-list-icon class="selectionIcon">radio_button_unchecked</mat-icon>
                                    <mat-icon *ngIf="isSelected(img)" color="primary" mat-list-icon class="selectionIcon">radio_button_checked</mat-icon>
                                </div>                                        
                            </div>
                        </mat-grid-tile>
                    </mat-grid-list>
                </div>                        
            </div>
        </div>
        <div class="d-flex selectionToolbar flex-row justify-content-evenly align-items-center" [@fade]="listState === allListStates.MULTI_SELECTION ? 'inUp' : 'outDown'">
            <mat-icon *ngIf="canDeleteImages()" mat-list-icon color="warn" class="button-icon" (click)="deleteSelectedImages()">delete</mat-icon>
            <mat-icon *ngIf="!canDeleteImages()" mat-list-icon class="disabledIcon">delete</mat-icon>
            <mat-icon *ngIf="canShareImages()" mat-list-icon color="primary" class="button-icon" (click)="shareSelectedImages()">share</mat-icon>
            <mat-icon *ngIf="!canShareImages()" mat-list-icon class="disabledIcon">share</mat-icon>
            <button mat-button class="descriptionText" (click)="invertSelection()">
                {{ (selectionInverted ? 'images.buttons.deselectall' : 'images.buttons.selectall') | translate }}
            </button>
        </div>
    </div>
</div>