import { Component } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { BaseNavComponent } from "../base/baseNav.component";
import { Location } from "@angular/common";
import { NavigationBarItemModel } from "../../models/NavigationBarItemModel";
import { MatDialog } from "@angular/material/dialog";
import { NavigationService } from "../../services/NavigationService";
import { CordovaInitHandler } from "../../native/CordovaInitHandler";
import { ActivatedRoute, Router } from "@angular/router";
import { ContactRestService } from "../../services/http/ContactRestService";
import { ContactRespModel } from "../../api-handling/models/ContactRespModel";
import { LanguageDetector } from "../../helpers/LanguageDetector";
import { TranslateService } from "@ngx-translate/core";
import { ComponentDetailMode } from "../../api-handling/models/enums/ComponentDetailMode";
import { BreakpointObserver } from "@angular/cdk/layout";
import { DeviceDetector } from "../../helpers/DeviceDetector";
import { ContactService } from "../../native/ContactService";
import { DialogService } from "../../services/DialogService";
import { ContactType } from "../../api-handling/models/enums/ContactType";
import { from } from "rxjs";

@Component({
    selector: "app-contactdetail",
    templateUrl: "contactDetail.component.html",
    styleUrls: ["contactDetail.component.scss"]
})
export class ContactDetailComponent extends BaseNavComponent {

    public contactDetailForm: FormGroup;
    public title = "";
    public type: ContactType;
    private cameraId: string;
    public contactId: string;
    private mode: ComponentDetailMode;
    private model: ContactRespModel;
    public navigationButtons: NavigationBarItemModel[] = [];

    constructor(private readonly formBuilder: FormBuilder, dialog: MatDialog, navigationService: NavigationService, location: Location,
        initHandler: CordovaInitHandler, private readonly activatedRoute: ActivatedRoute, private readonly contactRestService: ContactRestService,
        private readonly translate: TranslateService, breakpointObserver: BreakpointObserver, private readonly router: Router, contactService: ContactService,
        dialogService: DialogService) {
        super(dialog, navigationService, location, initHandler, breakpointObserver, dialogService);
        this.setType();
        this.setForm();
        this.subscriptions.push(activatedRoute.queryParams.subscribe((params) => {
            if (params && params.id) {
                this.cameraId = params.id;
            } else {
                setTimeout(() => {
                    router.navigate(["home/cameras"]);
                }, 200);
                return;
            }
            if (params && params.contactId) {
                this.contactId = params.contactId;
                this.mode = ComponentDetailMode.edit;
                this.loadContactInfos();
            } else {
                this.mode = ComponentDetailMode.add;
                this.model = new ContactRespModel;
                this.model.name = "";
                this.model.email = "";

                if (DeviceDetector.isOnCordova()) {
                    const contactBarButton = new NavigationBarItemModel("perm_contact_calendar");
                    this.subscriptions.push(contactBarButton.action.subscribe(() => {
                        this.contactButtonPressed();
                    }));
                    this.navigationButtons.push(contactBarButton);
                    const contact = contactService.getSelectedContact();
                    if ( contact ) {
                        const importContact = new ContactRespModel;
                        importContact.email = contact.emails[0].value;
                        importContact.name = contact.displayName;
                        this.setForm(importContact);
                    }
                }
            }
        }));
    }

    updateOnResume() {
        if (this.mode === ComponentDetailMode.edit) {
            this.loadContactInfos();
        }
    }

    /**
     * Loads contact informations from backend.
     */
    private loadContactInfos() {
        this.subscriptions.push( this.translate.get("contactDetail.load").subscribe((translation) => {
            this.showSpinner(translation, "");
            switch (this.type) {
                case ContactType.friend:
                    this.subscriptions.push( this.contactRestService.getFriendContactById(this.cameraId, this.contactId).subscribe((resultContacts) => {
                        this.model = resultContacts;
                        if (resultContacts.user) {
                            this.model.name = `${resultContacts.user.firstname} ${resultContacts.user.lastname}`;
                        }
                        this.setForm(resultContacts);
                        this.closeSpinner();
                    }, (err) => {
                        this.closeSpinner();
                    }));
                    break;
                case ContactType.invite:
                    this.subscriptions.push( this.contactRestService.getInviteContactById(this.cameraId, this.contactId).subscribe((resultContacts) => {
                        this.model = resultContacts;
                        this.setForm(resultContacts);
                        this.closeSpinner();
                    }, (err) => {
                        this.closeSpinner();
                    }));
                    break;
                case ContactType.mail:
                    this.subscriptions.push( this.contactRestService.getMailContactById(this.cameraId, this.contactId).subscribe((resultContacts) => {
                        this.model = resultContacts;
                        this.setForm(resultContacts);
                        this.closeSpinner();
                    }, (err) => {
                        this.closeSpinner();
                    }));
                    break;
            }
        }));
    }

    private setForm(model?: ContactRespModel) {
        this.contactDetailForm = this.formBuilder.group({
            name: [model ? model.name : "", this.type === ContactType.mail ? [Validators.required] : ""],
            email: [model ? model.email : "", [Validators.required, Validators.email]]
        });
    }

    private setType(): void {
        switch (this.activatedRoute.snapshot.data["type"]) {
            case "friend":
                this.type = ContactType.friend;
                this.title = "contactDetail.friends";
                break;
            case "invite":
                this.type = ContactType.invite;
                this.title = "contactDetail.friends";
                break;
            default:
                this.type = ContactType.mail;
                this.title = "contactDetail.mails";
                break;
        }
    }

    public deleteFriend() {
        this.showSpinner(this.translate.instant("contactDetail.deleteLoad"), "");
        switch (this.type) {
            case ContactType.friend:
                this.subscriptions.push(this.contactRestService.deleteFriendFromContactList(this.cameraId, this.contactId).subscribe((resultContacts) => {
                    this.closeSpinner();
                    super.goBackButtonPressed();
                }, (err) => {
                    this.closeSpinner();
                }));
                break;
            case ContactType.invite:
                this.subscriptions.push(this.contactRestService.deleteInvite(this.cameraId, this.contactId).subscribe((resultContacts) => {
                    this.closeSpinner();
                    super.goBackButtonPressed();
                }, (err) => {
                    this.closeSpinner();
                }));
                break;
            case ContactType.mail:
                this.subscriptions.push(this.contactRestService.deleteMailFromContactList(this.cameraId, this.contactId).subscribe((resultContacts) => {
                    this.closeSpinner();
                    super.goBackButtonPressed();
                }, (err) => {
                    this.closeSpinner();
                }));
                break;
        }
    }

    public goBackButtonPressed() {
        // Friends can not be changed.
        if (this.type !== ContactType.friend) {
            this.canGoBack().then((goBack) => {
                if (goBack) {
                    if (this.formChanged() && this.contactDetailForm.valid) {
                        from(this.createContactInfos()).subscribe((contact) => {
                            if (this.mode === ComponentDetailMode.edit) {
                                 from(this.updateContact(contact)).subscribe((success) => {
                                    if (success) {
                                        super.goBackButtonPressed();
                                    }
                                 });
                            } else {
                                from(this.addContact(contact)).subscribe((success) => {
                                    if (success) {
                                        super.goBackButtonPressed();
                                    }
                                });
                            }
                        });
                    } else {
                        super.goBackButtonPressed();
                    }
                }
            });
        } else {
            super.goBackButtonPressed();
        }
    }

    /**
     * Create Contact info based on the form.
     */
    private async createContactInfos(): Promise<ContactRespModel> {
        /* New model important because possible validation error on server. */
        const newContact = new ContactRespModel();
        newContact.email = this.contactDetailForm.value.email;
        newContact.name = this.contactDetailForm.value.name;
        const lang = await LanguageDetector.getLanguage();
        newContact.languagecode = (lang ? lang : "en");
        return newContact;
    }

    /**
     * Updates the contact informations on the server.
     * @param contact new contact informations
     */
    private async updateContact(contact: ContactRespModel): Promise<boolean> {
        contact.version = this.model.version;
        this.showSpinner(this.translate.instant("contactDetail.load"), "");
        try {
            switch (this.type) {
                case ContactType.invite:
                    await this.contactRestService.editInvitation(this.cameraId, contact, this.contactId).toPromise();
                    break;
                case ContactType.mail:
                    await this.contactRestService.editMailFromContactList(this.cameraId, contact, this.contactId).toPromise();
                    break;
                default:
                    break;
            }
            this.closeSpinner();
            return true;
        } catch (error) {
            this.closeSpinner();
            this.setForm();
            return false;
        }
    }

    /**
     * Adding a new mail receiver or creating an invitation for a new friend.
     * @param newContact New contact to append to camera.
     */
    private async addContact(newContact: ContactRespModel): Promise<boolean> {
        this.showSpinner(this.translate.instant("contactDetail.addLoad"), "");
        try {
            switch (this.type) {
                case ContactType.invite:
                    await this.contactRestService.addInvitation(this.cameraId, newContact).toPromise();
                    break;
                case ContactType.mail:
                    await this.contactRestService.addMailToContactList(this.cameraId, newContact).toPromise();
                    break;
                default:
                    break;
            }
            this.closeSpinner();
            return true;
        } catch (error) {
            this.closeSpinner();
            this.setForm();
            return false;
        }
    }

    private formChanged() {
        return this.model.name !== this.contactDetailForm.value.name || this.model.email !== this.contactDetailForm.value.email;
    }

    private async canGoBack(): Promise<boolean> {
        if (this.contactDetailForm.valid) {
            return true;
        } else {
            if (this.mode === ComponentDetailMode.edit) {
                const mButtons: string[] = [this.translate.instant("shared.yes"), this.translate.instant("shared.no")];
                const buttonClicked = await this.openDialog(this.translate.instant("contactDetail.invalidTitle"), this.translate.instant("contactDetail.invalidMsg"), mButtons ).toPromise();
                if (buttonClicked !== null && buttonClicked === 0) {
                    return true;
                }
            } else {
                if (this.formChanged()) {
                    const mButtons: string[] = [this.translate.instant("shared.yes"), this.translate.instant("shared.no")];
                    const buttonClicked = await this.openDialog(this.translate.instant("contactDetail.invalidTitle"), this.translate.instant("contactDetail.invalidMsg"), mButtons ).toPromise();
                    if (buttonClicked !== null && buttonClicked === 0) {
                        return true;
                    }
                    return false;
                }
                return true;
            }
        }
        return false;
    }


    public contactButtonPressed() {
        this.router.navigate(["home/contactapp"]);
    }
}
