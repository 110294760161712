import { Injectable, NgZone } from "@angular/core";
import { Observable, Subject, from } from "rxjs";
import { DeviceDetector } from "../helpers/DeviceDetector";
import { saveAs } from "file-saver";
import { FileService } from "../native/FileService";
import * as JSZip from "jszip";

export interface ShareFiles {
    data: any;
    name: string;
}

// tslint:disable-next-line:max-line-length
declare var window: { requestFileSystem: (arg0: any, arg1: number, arg2: (fs: any) => void) => void; plugins: { socialsharing: { shareWithOptions: (arg0: { files: string[]; }, arg1: (result: any) => void, arg2: (msg: string) => void) => void; }; }; }, LocalFileSystem: { PERSISTENT: any; };

@Injectable()
export class ShareService {

    constructor(private fileService: FileService, private zone: NgZone) {
    }

    public async share(files: ShareFiles[]): Promise<boolean> {
        const shareSubject = new Subject<boolean>();
        if (files.length > 1) {
            const zip = await this.zip(files);
            from(this.shareFile({data: zip, name: "images.zip"})).subscribe(shareSubject);
        } else if (files.length === 1) {
            from(this.shareFile(files[0])).subscribe(shareSubject);
        } else {
            shareSubject.error("Nothing to share");
        }
        return shareSubject.toPromise();
    }

    public async shareFile(file: ShareFiles): Promise<boolean> {
        const shareSubject = new Subject<boolean>();
        if (DeviceDetector.isOnCordova()) {
            const url = await this.getUrl(file);
            this.shareFiles([url]).subscribe(shareSubject);
        } else {
            saveAs(file.data, file.name);
            shareSubject.next(true);
            shareSubject.complete();
        }
        return shareSubject.toPromise();
    }

    private async zip(files: ShareFiles[]): Promise<any> {

        const zip = new JSZip();
        for (const file of files) {
            zip.file(file.name, file.data, {base64: true});
        }
        const zipConent = await zip.generateAsync({type: "blob"});
        return zipConent;
    }

    private getUrl(file: ShareFiles): Promise<string> {
        const shareSubject = new Subject<string>();
        if (file.data) {
            if (DeviceDetector.isOnCordova()) {
                this.fileService.storeFile(file.data, file.name, (success: boolean, fileEntry: any) => {
                    if (success) {
                        shareSubject.next(fileEntry.nativeURL);
                        shareSubject.complete();
                    } else {
                        shareSubject.error("Could not store image");
                    }
                });
            } else {
                shareSubject.error("Web not supported");
            }
        } else {
            shareSubject.error("No blob");
        }
        return shareSubject.toPromise();
    }

    public shareFiles(fileUrls: string[]): Observable<boolean> {
        const shareSubject = new Subject<boolean>();
        const options = {
            files: fileUrls
        };
        const onSuccess = function(result: any) {
            shareSubject.next(result);
            shareSubject.complete();
        };
        const onError = (msg: string) => {
            shareSubject.error(msg);
        };

        window.plugins.socialsharing.shareWithOptions(options, onSuccess, onError);
        return shareSubject.asObservable();
    }

    public shareLocalFile(fileName: string) {
        (window.plugins.socialsharing as any).share("", "", fileName);
    }
}
