import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface PayDialogData {
    aboId: string;
    message: string;
    name: string;
}

@Component({
    selector: "app-dialog",
    templateUrl: "paydialog.component.html",
    styleUrls: ["paydialog.component.scss"]
})

export class PayDialogComponent {

    public selected = "paypal";

    constructor(public dialogRef: MatDialogRef<PayDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: PayDialogData) {

    }

    onClick(index): void {
      this.dialogRef.close(index);
    }

    public switchPayment(payment: string) {
        this.selected = payment;
    }

    public payButtonPressed() {
        this.dialogRef.close(this.selected);
    }

    public cancelButtonPressed() {
        this.dialogRef.close();
    }
}
