import { OnDestroy, OnInit, Component } from "@angular/core";
import { BaseComponent } from "./base.component";
import { MatDialog } from "@angular/material/dialog";
import { NavigationService } from "src/app/services/NavigationService";
import { NavigationBarItemModel } from "src/app/models/NavigationBarItemModel";
import { Location } from "@angular/common";
import { CordovaInitHandler } from "src/app/native/CordovaInitHandler";
import { DeviceDetector } from "src/app/helpers/DeviceDetector";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { map, share } from "rxjs";
import { DialogService } from "src/app/services/DialogService";

export interface BaseNavComponent {
    /**
     * Implement this methode to update content after app comes to foreground.
     */
    updateOnResume?(): void;
}

@Component({ selector: "app-basenav", template: `<div></div>` })

export abstract class BaseNavComponent extends BaseComponent implements OnDestroy, OnInit {

    public backBarButton = new NavigationBarItemModel("arrow_back_ios");
    public menuBarButton = new NavigationBarItemModel("menu");
    public isHandset: boolean;
    public breakpointObserver: BreakpointObserver;

    public isHandset$: Observable<boolean>;

    constructor(public dialog: MatDialog, private readonly navigationService: NavigationService,
        private readonly location: Location, initHandler: CordovaInitHandler, breakpointObserver: BreakpointObserver,
        dialogService: DialogService) {
        super(dialog, dialogService);
        this.breakpointObserver = breakpointObserver;
        this.subscriptions.push(this.backBarButton.action.subscribe(() => {
            this.goBackButtonPressed();
        }));
        this.subscriptions.push(this.menuBarButton.action.subscribe(() => {
            this.toggleSideBar();
        }));
        if (DeviceDetector.isOnCordova()) {
            this.subscriptions.push(initHandler.backButtonPressed.subscribe((result) => {
                if (result) {
                    this.goBackButtonPressed();
                }
            }));

            this.subscriptions.push(initHandler.resume.subscribe((result) => {
                if (result && this.updateOnResume) {
                    this.updateOnResume();
                }
            }));
        }

        this.isHandset$ = this.breakpointObserver.observe(Breakpoints.Handset)
            .pipe( map(result => result.matches), share());
        this.subscriptions.push(this.isHandset$.subscribe((result) => {
            this.isHandset = result;
            this.isHandsetChanged();
        }));
    }

    ngOnInit(): void {
        this.isHandset = this.breakpointObserver.isMatched(Breakpoints.Handset);
    }

    public toggleSideBar() {
        this.navigationService.emitChange();
    }

    public goBackButtonPressed() {
        this.location.back();
    }

    public isHandsetChanged() {
        return;
    }
}
