import { Injectable } from "@angular/core";
import { BaseRestService } from "./BaseRestService";
import { LocalStorageService } from "../LocalStorageService";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs";
import { AppleTransferModel } from "src/app/api-handling/models/AppleTransferModel";
import { classToPlain } from "class-transformer";

@Injectable()
export class AppleSubscriptionRestService extends BaseRestService {

    constructor(private httpClient: HttpClient, localStorageService: LocalStorageService) {
        super(localStorageService);
    }

    public transferAppleSubscriptions(transferModel: AppleTransferModel): Observable<boolean> {
        const header = this.getBearerHeader();
        if ( header ) {
            return this.httpClient.post<Object>(this.webserviceUrl + "v1/applesubscription/transfer/", classToPlain(transferModel), {headers: header}).pipe(
                map((result) => result && (result as any).success));
        } else {
            return null;
        }
    }
}
