export const environment = {
    version: require("../../package.json").version,
    production: true,
    prefix: "Test-",
    logErrorInConsole: true,
    webserviceUrl: "https://api.doerr-wildcamera.qa.impacgroup.de/api/",
    organizationKey: "doerr",
    aboConfigFile: "abo_config_dev.json",
    validationUrl: "v1/applesubscription/register",
    androidValidationUrl: "v1/googlesubscription/register",
    crashlogApiTrackEndpoint: "https://api.doerr-wildcamera.qa.impacgroup.de/api/v1/trackcrashlog/",
    crashlogApiToken: "b474ef3c6ddfc6b1f0ac17ae62981f4a3a86dec9",
    trackAnalytics: false,
    ownHostDomain: "https://doerr-wildcamera.qa.impacgroup.de",
    cleverReachUrl: "https://eu2.cleverreach.com/f/249743-270703/wcs/",
    googleTagManagerId: "GTM-W389FQ3",
    sentryDns: "https://f0522acd79044777a325894aa5a7be58@o685053.ingest.sentry.io/5772053",
    sentryCordovaDns: "https://7b89f3163de2414cb05f4f2c9ca4fa90@o685053.ingest.sentry.io/5773408",
    sentryEnvironmentName: "staging",
    maxParallelDownloads: 9,
    appIdentifier: "de.doerroutdoor.doerrcamera.Test"
};
