
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DialogFactory } from "../helpers/DialogFactory";
import { map, Subject, Subscription } from "rxjs";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})

export class DialogService {

    public isShowingDialog = false;
    private dialogFactory: DialogFactory;

    constructor(dialog: MatDialog) {
        this.dialogFactory = new DialogFactory(dialog);
    }

    public openDialog(title: string, message: string, imgName: string, buttons: string[], subscriptions: Subscription[]): Observable<number | null> {
        const dialogSubject = new Subject<number>();
        const observable = this.showDialog(title, message, buttons, imgName);
        if (observable) {
            subscriptions.push(observable.subscribe((result) => {
                dialogSubject.next(result);
                dialogSubject.complete();
            }));
        } else {
            dialogSubject.next(null);
            dialogSubject.complete();
        }
        return dialogSubject.asObservable();
    }

    public showDialog(title: string, message: string, buttons: string[], imgName: string | undefined): Observable<number> | undefined {
        if (!this.isShowingDialog) {
            this.isShowingDialog = true;
            return this.dialogFactory.showDialog(title, message, buttons, imgName).pipe( map( result => {
                this.isShowingDialog = false;
                return result;
            }));
        } else {
            return undefined;
        }
    }
}
