<mat-toolbar color="primary">
    <button type="button" mat-icon-button (click)="leftBarButtonPressed()" *ngIf="isHandset && leftBarButton">
        <mat-icon>{{leftBarButton.matImageName}}</mat-icon>
    </button>
    <span>{{ name | translate }}</span>
    <span class="spacer"></span>

    <div *ngFor="let rightBarButton of rightBarButtons"> 
        <button type="button" mat-icon-button (click)="rightBarButtonPressed(rightBarButton)" *ngIf="rightBarButton && !rightBarButton.menuItems">
            <mat-icon>{{rightBarButton.matImageName}}</mat-icon>
        </button>
    </div>

    <button type="button" mat-icon-button [matMenuTriggerFor]="menu" *ngIf="showDropdown()">
        <mat-icon>{{getMenuButton().matImageName}}</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <div *ngIf="showDropdown()">
            <button mat-menu-item *ngFor="let buttonItem of getMenuButton().menuItems" (click)="menuButtonPressed(buttonItem)">
                <span>{{ buttonItem.name | translate }}</span>
            </button>
        </div>        
    </mat-menu>
</mat-toolbar>