import { Injectable, OnDestroy, NgZone } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";
import { UserRestService } from "../services/http/UserRestService";
import { DeviceDetector } from "../helpers/DeviceDetector";
import { InAppPaymentService } from "./InAppPaymentService";
import { LocalStorageService } from "../services/LocalStorageService";
import { environment } from "src/environments/environment";


declare var window: {
    cordova: {
        platformId: string;
        require: (value: string) => any;
    };
    plugins: {
        appMinimize: { minimize: () => void; };
    };
    isTablet: boolean;
    screen: {
        orientation: {lock: (orientation: string) => void, unlock: () => void, type: string }
    }
};
declare var navigator: {  splashscreen: { hide: () => void; }; };

@Injectable()
export class CordovaInitHandler implements OnDestroy {

    public initDoneCallSource = new BehaviorSubject<boolean>(false);
    public backButtonCallSource = new BehaviorSubject<boolean>(false);
    public resumeCallSource = new BehaviorSubject<any>(false);
    public backButtonPressed = this.backButtonCallSource.asObservable();
    public initDone = this.initDoneCallSource.asObservable();
    public resume = this.resumeCallSource.asObservable();

    constructor(private router: Router, private zone: NgZone, private userRestService: UserRestService,
        private inappPaymentService: InAppPaymentService, private localstorageService: LocalStorageService) {    }

    public async init(): Promise<void> {
        document.addEventListener("deviceready", this.onDeviceReady, false);
    }

    ngOnDestroy(): void {
        if (window.cordova.platformId === "android") {
            document.removeEventListener("backbutton", () => this.onBackButtonPressed());
        }
        document.removeEventListener("deviceready", this.onDeviceReady, false);
        document.removeEventListener("resume", () => this.onResume(), false);
    }

    private onDeviceReady = async (): Promise<void> => {
        // Angular Init Stuf
        this.initDoneCallSource.next(true);
        this.startSentry();

        this.inappPaymentService.prepareInApp();
        if (!window.isTablet) {
            window.screen.orientation.lock("portrait-primary");
        }
        // here you can add event listeners for resume or the backbutton
        document.addEventListener("resume", () => this.onResume());
        if (window.cordova.platformId === "android") {
            document.addEventListener("backbutton", () => this.onBackButtonPressed());
        }
        await this.onResume();
    }

    /**
     * Sentry with cordova plugin.
     */
    private startSentry() {
        const Sentry = window.cordova.require("sentry-cordova.Sentry");
        Sentry.init({
            dsn: environment.sentryCordovaDns,
            environment: environment.sentryEnvironmentName,
            debug: !environment.logErrorInConsole,
            beforeBreadcrumb(breadcrumb) {
                if (breadcrumb.category === "console") {
                  return null;
                }
                return breadcrumb;
              },
         });
    }

    private async onResume() {
        this.zone.run(() => {
            const user = this.localstorageService.getAccessInfos();
            if (user && user.accessToken) {
                this.inappPaymentService.setValidationConfig(user.accessToken);
            }
        });
        if (DeviceDetector.hasInternetConnection()) {
            await this.userRestService.loadQuotaInformation();
        }
        this.zone.run(() => {
            this.resumeCallSource.next(true);
            this.resumeCallSource.next(false);
            navigator.splashscreen.hide();
        });
    }

    private onBackButtonPressed() {
        if (this.router.url === "/home" || this.router.url === "/signin") {
            this.zone.run(() => {
                window.plugins.appMinimize.minimize();
            });
        } else if (this.router.url === "/password" || this.router.url === "/registration") {
            this.zone.run(() => {
                this.router.navigate(["signin"]);
            });
        } else {
            this.zone.run(() => {
                this.backButtonCallSource.next(true);
                this.backButtonCallSource.next(false);
            });
        }
    }
}
