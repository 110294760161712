import { Component } from "@angular/core";
import { BaseComponent } from "../base/base.component";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { PaypalRestService } from "src/app/services/http/PaypalRestService";
import { DialogService } from "src/app/services/DialogService";
import { AboAgreementModel } from "src/app/api-handling/models/AboAgreementModel";
import { JSONService } from "src/app/services/JSONService";

const hdAboKey = "HD_IMAGES_ABO";
@Component({
    selector: "app-paypalpay",
    templateUrl: "paypalPayment.component.html",
    styleUrls: ["paypalPayment.component.scss"]
})

export class PaypalPaymentComponent extends BaseComponent {

    public isConfirmed = false;
    public showManagementBtn = false;

    constructor(public dialog: MatDialog, activeRoute: ActivatedRoute, private translate: TranslateService, private router: Router,
        private paypalRestService: PaypalRestService, dialogService: DialogService, private jsonService: JSONService) {
        super(dialog, dialogService);

        if (router.url.includes("success")) {
            this.subscriptions.push(activeRoute.queryParams.subscribe((params) => {
                if (params.token) {
                    this.confirmAbo(params.token);
                } else {
                    this.translate.get(["error", "paypalPayment"]).subscribe((translations) => {
                        this.openDialog(translations.error.title, translations.paypalPayment.errorMissingTokenMsg , null).subscribe(() => {
                            this.router.navigate(["home/abo"]);
                        });
                    });
                }
            }));
        } else if (router.url.includes("cancel")) {
            this.router.navigate(["home/abo"]);
        }
    }

    public confirmAbo(token: string) {
        this.subscriptions.push(this.translate.get("paypalPayment.loadConfirmation").subscribe((translation) => {
            this.showSpinner(translation, "");
            this.subscriptions.push(this.paypalRestService.confirmAbo(token).subscribe((result) => {
                if (result) {
                    this.setButtonsFor(result);
                }
                this.closeSpinner();
                this.isConfirmed = true;
            }, (err) => {
                this.closeSpinner();
            }));
        }));
    }

    public setButtonsFor(agreement: AboAgreementModel) {
        this.subscriptions.push(this.jsonService.getJSON().subscribe((abos) => {
            const hdAbo = abos.find((a) => a.key === hdAboKey);
            this.showManagementBtn = hdAbo && hdAbo.paypal.billingPlanId === agreement.billingPlanId;
        }));
    }

    public finishButtonPressed() {
        this.router.navigate(["home/abo"]);
    }

    public managementButtonPressed() {
        this.router.navigate(["home/hdcameras"], {queryParams: {backAbo: true}});
    }
}
