import { AfterViewInit, Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DialogService } from "src/app/services/DialogService";
import { TrackingService } from "src/app/services/TrackingService";
import { BaseComponent } from "./base.component";

@Component({ selector: "app-basecookie", template: `<div></div>` })

export abstract class BaseCookieComponent extends BaseComponent implements AfterViewInit {

    public displayCookieBanner: boolean = false;

    constructor(dialog: MatDialog, dialogService: DialogService, private trackingService: TrackingService) {
        super(dialog, dialogService);

        this.subscriptions.push(trackingService.displayRequest$.subscribe(() => {
            this.displayCookieBanner = !this.displayCookieBanner;
        }));
    }

    ngAfterViewInit(): void {
        this.trackingService.requestTracking();
    }
}
