import { Component, NgZone, OnInit } from "@angular/core";
import { NavigationBarItemModel, NavigationBarMenuItems } from "../../../app/models/NavigationBarItemModel";
import { CameraRequestModel, CameraResponseModel, CameraUpdateSortPushModel, ResendSettingsState } from "../../../app/api-handling/models/CameraResponseModel";
import { BaseNavComponent } from "../base/baseNav.component";
import { ActivatedRoute, Router, NavigationExtras } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { NavigationService } from "../../../app/services/NavigationService";
import { CordovaInitHandler } from "../../../app/native/CordovaInitHandler";
import { CameraRestService } from "../../../app/services/http/CameraRestService";
import { Location } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { BreakpointObserver } from "@angular/cdk/layout";
import * as moment from "moment";
import { Observable, Subject } from "rxjs";
import { DialogService } from "../../../app/services/DialogService";
import { FirmwareRestService } from "../../../app/services/http/FirmwareRestService";
import { UserRestService } from "../../../app/services/http/UserRestService";
import { StolenRestService } from "../../../app/services/http/StolenRestService";
import { StolenRespModel } from "../../../app/api-handling/models/StolenRespModel";
import { CountryHelper } from "../../../app/helpers/CountryHelper";
import { ImageFilterService } from "src/app/services/ImageFilterService";
import { MatSelectChange } from "@angular/material/select";


@Component ({
    selector: "app-cameradetail",
    templateUrl: "cameraDetail.component.html",
    styleUrls: ["cameraDetail.component.scss"]
})

export class CameraDetailComponent extends BaseNavComponent implements OnInit {
    public camera: CameraResponseModel = new CameraResponseModel();
    public firmwareVersion: string = "";
    public rightBarButton = new NavigationBarItemModel("more_vert");
    public showMenu = false;
    public lastDailyReportDate: string = "";
    public hasHDAbo = false;
    public stolenInfo: StolenRespModel;
    public sortNumbers: number[] = [];
    public sortNumberChangeSubject: Subject<number> = new Subject<number>();
    public resendSettingsStateEnum = ResendSettingsState;

    constructor(private activatedRoute: ActivatedRoute, dialog: MatDialog,
        navigationService: NavigationService, location: Location, initHandler: CordovaInitHandler, private router: Router,
        private cameraRestService: CameraRestService, private translate: TranslateService, private zone: NgZone,
        breakpointObserver: BreakpointObserver, dialogService: DialogService, private readonly firmwareRestService: FirmwareRestService,
        private readonly userAboService: UserRestService, private readonly stolenService: StolenRestService, private readonly imageFilterService: ImageFilterService) {
        super(dialog, navigationService, location, initHandler, breakpointObserver, dialogService);
        this.loadCamera();
        this.loadAboInfos();
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    private createNavigationMenu() {
        this.subscriptions.push(this.rightBarButton.action.subscribe(() => {
            this.showMenu = !this.showMenu;
        }));
        const deleteItem = new NavigationBarMenuItems("cameraDetail.delete");
        this.subscriptions.push(deleteItem.action.subscribe(() => {
            this.showDeleteDialog();
        }));

        if (this.camera.isOwner) {
            const stolenItem = this.getStolenItem();

            this.subscriptions.push(this.firmwareRestService.getFirmwareInfo({ cameraId: this.camera._id }).subscribe((info) => {
                const upadteItem = new NavigationBarMenuItems(this.translate.instant("cameraDetail.update") + ": " + info.name);
                this.subscriptions.push(upadteItem.action.subscribe(() => {
                    this.updateCamera();
                }));
                this.rightBarButton.menuItems = [upadteItem, stolenItem, deleteItem];
            }, () => {
                this.rightBarButton.menuItems = [stolenItem, deleteItem];
            }));
        } else {
            this.rightBarButton.menuItems = [deleteItem];
        }
    }

    /**
     * Shows a dialog to ask the user if he want to delete the camera;
     */
    private showDeleteDialog() {
        let dialogObservable: Observable<number>;
            if (this.camera.isOwner) {
                dialogObservable = this.openDialog(this.translate.instant("cameraDetail.deleteAlertTitle"), this.translate.instant("cameraDetail.deleteAlertMsg"),
                [this.translate.instant("cameraDetail.deleteButton"), this.translate.instant("shared.cancelButton")]);
            } else {
                dialogObservable = this.openDialog(this.translate.instant("cameraDetail.deleteFriendAlertTitle"), this.translate.instant("cameraDetail.deleteFriendAlertMsg"),
                [this.translate.instant("cameraDetail.deleteButton"), this.translate.instant("shared.cancelButton")]);
            }
            this.subscriptions.push(dialogObservable.subscribe((index) => {
                this.zone.run(() => {
                    if (index !== null && index === 0 ) {
                        this.deleteCameraForId();
                    }
                });
            }));
    }

    /**
     * Start process to update the camera.
     */
    private updateCamera() {
        if (this.camera.getBattery() <= 50) {
            const dialogObservable = this.openDialog(this.translate.instant("cameraDetail.updateBatteryTitle"), this.translate.instant("cameraDetail.updateBatteryMsg"),
            [this.translate.instant("shared.yes"), this.translate.instant("shared.no")]);
            this.subscriptions.push(dialogObservable.subscribe((index) => {
                this.zone.run(() => {
                    if (index !== null && index === 0 ) {
                        this.router.navigate(["home/update"], {queryParams: {id: this.camera._id}});
                    }
                });
            }));
        } else {
            this.router.navigate(["home/update"], {queryParams: {id: this.camera._id}});
        }
    }

    private getStolenItem(): NavigationBarMenuItems {
        if (!this.camera.isStolen()) {
            const stolenItem = new NavigationBarMenuItems(this.translate.instant("cameraDetail.stolenFeature.stolen"));
            this.subscriptions.push(stolenItem.action.subscribe(() => {
                this.setToStolen();
            }));
            return stolenItem;
        } else {
            const stolenItem = new NavigationBarMenuItems(this.translate.instant("cameraDetail.stolenFeature.notStolen"));
            this.subscriptions.push(stolenItem.action.subscribe(() => {
                this.openDialog(
                    this.translate.instant("cameraDetail.stolenFeature.notStolenAlertTitle"),
                    this.translate.instant("cameraDetail.stolenFeature.notStolenAlertMsg"),
                    [this.translate.instant("shared.yes"), this.translate.instant("shared.no")]).subscribe((btn) => {
                        if (btn === 0) {
                            this.deleteStolen();
                        }
                });
            }));
            return stolenItem;
        }
    }

    private getStolenInfo() {
        if (this.camera.isStolen()) {
            this.subscriptions.push(this.stolenService.getStolenContact(this.camera.stolen).subscribe((response) => {
                this.zone.run(() => {
                    this.stolenInfo = response;
                    this.stolenInfo.country = CountryHelper.getCountryNameFor(this.stolenInfo.country).name;
                });
            }));
        }
    }

    private deleteStolen() {
        if (this.camera.isStolen()) {
            this.showSpinner("", "");
            this.subscriptions.push(this.stolenService.deleteStolen(this.camera.stolen).subscribe(() => {
                this.closeSpinner();
                this.loadCamera();
            }, () => {
                this.closeSpinner();
            }));
        }
    }

    private setToStolen(): void {
        this.router.navigate(["home/stolen"], { replaceUrl: true, queryParams: {id: this.camera._id}});
    }

    updateOnResume() {
        this.loadCamera();
    }

    private loadCamera() {
        this.subscriptions.push(this.activatedRoute.queryParams.subscribe((params) => {
            if (params && params.id) {
                this.loadCameraInfosForId(params.id);
            }
        }));
    }

    private deleteCameraForId() {
        const deleteSubscription = this.cameraRestService.deleteCameraById(this.camera._id);
        if (deleteSubscription) {
            this.subscriptions.push(this.translate.get("cameraDetail.deletingCamera").subscribe((translation) => {
                this.showSpinner(translation, "");
                this.subscriptions.push(deleteSubscription.subscribe(() => {
                    this.imageFilterService.updateFilterParameters(true);
                    this.closeSpinner();
                    if (this.camera.isOwner) {
                        this.subscriptions.push(this.openDialog(this.translate.instant("cameraDetail.cameraDeletedTitle"), this.translate.instant("cameraDetail.cameraDeletedMsg"), [this.translate.instant("shared.ok")]).subscribe(() => {
                            this.router.navigate(["home/cameras"]);
                        }));
                    } else {
                        this.router.navigate(["home/cameras"]);
                    }
                }, (err) => {
                    this.closeSpinner();
                }));
            }));
        }
    }

    private loadCameraInfosForId(id: string) {
        this.subscriptions.push(this.translate.get("cameraDetail.loadCamera").subscribe((translation) => {
            this.showSpinner(translation, "");
            const cameraSubscription = this.cameraRestService.getCameraDetailsById(id);
            if ( cameraSubscription ) {
                this.subscriptions.push(cameraSubscription.subscribe((cameraResult: CameraResponseModel) => {
                    this.camera = cameraResult;
                    this.firmwareVersion = cameraResult.firmware.substring(4, 10);
                    this.sortNumbers = Array.from({length: cameraResult.metaCameraCount}, (_, index) => index + 1);
                    this.createNavigationMenu();
                    this.closeSpinner();
                    this.setSyncState(cameraResult);
                    this.getStolenInfo();
                }, (err) => {
                    this.closeSpinner();
                }));
            }
        }));
    }

    private async loadAboInfos() {
        try {
            const abos = await this.userAboService.getAbos().toPromise();
            this.hasHDAbo = abos.highres !== null && abos.highres.active;
        } catch (error) {
        }
    }

    /** Checks last daily report date from camera and shows user estimated next synchronisation or if daily report to old that sync currently not available. */
    private setSyncState(camera: CameraResponseModel) {
        const diff = moment().diff(moment(camera.lastDailyReport), "hours");
        if (diff > 50) {
            this.lastDailyReportDate = this.translate.instant("cameraDetail.SyncNotPossible");
        } else {
            const mMoment = moment(camera.lastDailyReport).add(1, "days");
            this.lastDailyReportDate = mMoment.format(this.translate.instant("shared.dateFormat")) + ", " + mMoment.format(this.translate.instant("shared.timeFormat"));
        }
    }

    public showPictures() {
        const extras: NavigationExtras = { state: {filtercameras: [this.camera._id]}};
        this.router.navigate(["home/images"], extras);
    }

    public resendSettings() {
        this.showSpinner(this.translate.instant("cameraDetail.resendSettingsPopupTitle"), "");
        this.subscriptions.push(this.cameraRestService.resendSettings(this.camera._id).subscribe((result) => {
            this.closeSpinner();

            if (result.success) {
                this.subscriptions.push(
                    this.openDialog(
                        this.translate.instant("cameraDetail.resendSettingsSuccessTitle"),
                        this.translate.instant("cameraDetail.resendSettingsSuccessMsg"),
                        [this.translate.instant("shared.ok")]
                    ).subscribe(() => {
                }));
            }
            // There is no { success: false }
        }, (err) => {
            this.closeSpinner();

            this.subscriptions.push(
                this.openDialog(
                    this.translate.instant("cameraDetail.resendSettingsFailedTitle"),
                    this.translate.instant("cameraDetail.resendSettingsFailedMsg"),
                    [this.translate.instant("shared.ok")]
                ).subscribe(() => {
            }));
        }));
    }

    public changePush() {
        const patchCamera = new CameraUpdateSortPushModel();
        patchCamera.sendPush = !this.camera.sendPush;
        this.patchCameraSortOrPush(patchCamera, patchCamera.sendPush ? "cameraDetail.activatePush" : "cameraDetail.deactivatePush");
    }

    public sortNumberChanged(event: MatSelectChange) {
        const patchCamera = new CameraUpdateSortPushModel();
        patchCamera.sortNumber = event.value;
        this.patchCameraSortOrPush(patchCamera, "cameraDetail.changingSortNumber");
    }

    public changeHD() {
        const patch = new CameraResponseModel();
        patch.doNotSendHighRes = !this.camera.doNotSendHighRes;
        const diff = moment().diff(this.camera.lastDailyReport, "days");
        const mMoment = moment(this.camera.lastDailyReport).add(diff + 1, "days");
        const alertMsg =  patch.doNotSendHighRes ? "shared.synchronisationMsg" : "cameraDetail.synchronisationMsgHdOn";
        this.openDialog(this.translate.instant("shared.synchronisationTitle"),
            this.translate.instant(alertMsg,
            { syncDate: mMoment.format(this.translate.instant("shared.dateFormat")), syncTime: mMoment.format(this.translate.instant("shared.timeFormat"))}),
            [this.translate.instant("shared.ok")]).subscribe((_pressed) => {
                this.zone.run(() => {
                    this.patchCamera(patch, patch.doNotSendHighRes ? "cameraDetail.deactivateHD" : "cameraDetail.activateHD");
                });
        });
    }

    private patchCamera(patch: CameraRequestModel, title: string) {
        patch._id = this.camera._id;
        patch.version = this.camera.version;
        this.showSpinner(this.translate.instant(title), "");
        this.subscriptions.push(this.cameraRestService.patchCamera(patch).subscribe((resultCam) => {

            Object.keys(patch).forEach( key => {
                this.camera[key] = resultCam[key];
            });
            this.closeSpinner();
        }, (err) => {
            if (err.status === 423) {
                this.loadCamera();
            }
            this.closeSpinner();
        }));
    }

    private patchCameraSortOrPush(patch: CameraUpdateSortPushModel, title: string) {
        patch._id = this.camera._id;
        this.showSpinner(this.translate.instant(title), "");
        this.subscriptions.push(this.cameraRestService.patchCameraSortOrPush(patch).subscribe((resultCam) => {

            Object.keys(patch).forEach( key => {
                this.camera[key] = resultCam[key];
            });
            this.closeSpinner();
        }, (err) => {
            if (err.status === 423) {
                this.loadCamera();
            }
            this.closeSpinner();
        }));
    }

    public showCameraSettings() {
        this.router.navigate(["home/settings"], { queryParams: {id: this.camera._id}});
    }

    public showFriends() {
        this.router.navigate(["home/friends"], { queryParams: {id: this.camera._id }});
    }

    public showMails() {
        this.router.navigate(["home/mails"], { queryParams: {id: this.camera._id }});
    }

    public showSmtps() {
        this.router.navigate(["home/smtps"], { queryParams: {id: this.camera._id }});
    }

    public showResendSettings() : boolean {
        return (this.camera.resendSettingsState === ResendSettingsState.disabled || this.camera.resendSettingsState === ResendSettingsState.enabled)
    }

    public batteryLow(): boolean {
        if (this.camera.battery !== undefined) {
            const value = Number(this.camera.battery.substring(0, this.camera.battery.length - 1));
            return value <= 30;
        }
        return false;
    }

    public confirmedByCam(): boolean {
        if (this.camera.settings !== undefined) {
            return this.camera.settings.confirmedByCam;
        }
        return false;
    }
}
