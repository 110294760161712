import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AboModel } from "../models/AboModel";
import { plainToClass } from "class-transformer";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable()
export class JSONService {

    constructor(private http: HttpClient) {
    }

    public getJSON(): Observable<AboModel[]> {

        return this.http.get("./assets/data/" + environment.aboConfigFile).pipe(
            map((result) => plainToClass(AboModel, result as any[]))
        );
    }
}
