import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";
import { AuthRestService } from "src/app/services/http/AuthRestService";
import { Validators, FormGroup, FormBuilder } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { BaseComponent } from "../base/base.component";
import { ConfirmRegisterModel } from "src/app/api-handling/models/ConfirmRegisterModel";
import { MatDialog } from "@angular/material/dialog";
import { LanguageDetector } from "src/app/helpers/LanguageDetector";
import { UserRestService } from "src/app/services/http/UserRestService";
import { DialogService } from "src/app/services/DialogService";
import { DeviceDetector } from "src/app/helpers/DeviceDetector";
import { from, Observable } from "rxjs";
import { AppUserService } from "src/app/services/database/AppUserService";

@Component({
    selector: "app-signin",
    templateUrl: "signin.component.html",
    styleUrls: ["signin.component.scss"]
})

export class SignInComponent extends BaseComponent implements OnInit {

    loginForm: FormGroup;
    submitted = false;
    passwordValue = "";

    constructor(private router: Router, private restService: AuthRestService,
        private formBuilder: FormBuilder, private translate: TranslateService, activatedRoute: ActivatedRoute,
        public dialog: MatDialog, private userRestService: UserRestService, dialogService: DialogService,
        private appUserDbService: AppUserService) {
        super(dialog, dialogService);
        this.subscriptions.push(activatedRoute.queryParams.subscribe((params) => {
            if ( params.token ) {
                this.subscriptions.push(this.translate.get("login.confirmLoad").subscribe((translation) => {
                    this.showSpinner(translation, "");
                    from(LanguageDetector.getLanguage()).subscribe((locale) => {
                        const model = new ConfirmRegisterModel(params.token, locale ? locale : "en");
                        this.subscriptions.push(restService.confirmRegister(model).subscribe(() => {
                            this.closeSpinner();
                            this.openDialog(this.translate.instant("login.registrationConfirmedTitle"),  this.translate.instant("login.registrationConfirmed"), null);
                        }, (err) => {
                            this.closeSpinner();
                        }));
                    });
                }));
            }
        }));
    }

    ngOnInit(): void {
        this.loginForm = this.formBuilder.group({
            mail: ["", [Validators.required, Validators.email]],
            password: ["", Validators.required]
        });
    }

    public showRegistration() {
        this.router.navigate(["registration"]);
    }

    public showPasswordForgot() {
        this.router.navigate(["password"]);
    }

    public login() {
        this.submitted = true;
        if ( !this.loginForm.invalid ) {
            this.subscriptions.push(from(this.performLogin(this.loginForm.value.mail, this.loginForm.value.password)).subscribe(success => {
                if (success) {
                    const navigationExtras: NavigationExtras = { queryParams: {refreshPayments: DeviceDetector.isOnIos()}};
                    this.router.navigate(["home/images"], navigationExtras);
                }
            }));
        }
    }

    public async performLogin(mail: string, password: string): Promise<boolean> {
        const translation = await this.translate.get("login.load").toPromise();

        this.showSpinner(translation, "");
        await this.appUserDbService.clearDexieDatabase();
        try {
            const result = await this.restService.signIn(mail, password).toPromise();
            if ( result.success ) {
                await this.userRestService.loadQuotaInformation();
                this.closeSpinner();
                return true;
            } else {
                this.passwordValue = "";
                this.closeSpinner();
            }
        } catch (error) {
            this.passwordValue = "";
            this.closeSpinner();
        }
        return false;
    }
}
