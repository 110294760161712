<app-toolbar [name]="title" [leftBarButton]="backBarButton" [rightBarButtons]="navigationButtons"></app-toolbar>
<form [formGroup]="cameraSmtpReceiverDetailForm" class="container-fluid container-overflow-scroll container-safe-area-bottom d-flex flex-column align-items-stretch">
 
    <div class="row justify-content-center">
        <div class="col-12 mt-3 descriptionInfo">{{ 'cameraSmtpDetail.description' | translate }}</div>
        <div class="col-12 col-md-8 col-lg-5 px-3 pt-20">
            <div class="d-flex flex-column justify-content-between align-items-center w-100">                
                <div class="inputContainer">
                    <mat-form-field appearance="fill" class="doerrInput">
                        <input matInput type="email" placeholder="{{'cameraSmtpDetail.receiver' | translate}}" formControlName="receiver" (blur)="onBlur()">
                        <mat-error>{{ 'shared.validMail' | translate }}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="doerrInput">
                        <input matInput type="email" placeholder="{{'cameraSmtpDetail.from' | translate}}" formControlName="from" (blur)="onBlur()">
                        <mat-error>{{ 'shared.validMail' | translate }}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="doerrInput">
                        <input matInput type="text" inputmode="url" autocorrect="off" autocapitalize="none" placeholder="{{'cameraSmtpDetail.host' | translate}}" formControlName="host" (blur)="onBlur()">
                        <mat-error>{{ 'cameraSmtpDetail.requiredHost' | translate }}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="doerrInput">
                        <input matInput type="email" autocorrect="off" placeholder="{{'cameraSmtpDetail.user' | translate}}" formControlName="user" (blur)="onBlur()">
                        <mat-error>{{ 'cameraSmtpDetail.requiredUser' | translate }}</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="doerrInput">
                        <input matInput type="password" placeholder="{{'cameraSmtpDetail.password' | translate}}" formControlName="password" (blur)="onPasswordBlur()" (focus)="onPasswordFocus()">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="doerrInput">
                        <input matInput type="text" inputmode="numeric" placeholder="{{'cameraSmtpDetail.port' | translate}}" formControlName="port" (blur)="onBlur()">
                        <mat-error>{{ 'cameraSmtpDetail.requiredPort' | translate }}</mat-error>
                    </mat-form-field>
                    
                        <mat-checkbox class="doerrCheckBox" color="primary" formControlName="secure">{{ 'cameraSmtpDetail.secure' | translate }}
                        </mat-checkbox>   
                  
                </div>                
            </div>
        </div>        
    </div>
    <div class="row justify-content-center mt-auto">
        <div class="col-12 col-md-5 col-lg-2 mb-3">
            <button class="signInButton" mat-raised-button color="primary" (click)="goBackButtonPressed()">{{'shared.store' | translate }}</button>
        </div>
        <div *ngIf="cameraSmtpReceiverId" class="col-12 col-md-5 col-lg-2 mb-3">
            <button class="signInButton" mat-raised-button color="warn" (click)="delete()">{{'cameraSmtpDetail.deleteSmtp' | translate }}</button>
        </div>
    </div>
</form>
