import { Component, Output, EventEmitter, ElementRef, ViewChild } from "@angular/core";
import { ImageInfo, Rect } from "../../models/ImageInfo";
import { ImageControlComponent } from "../image/image.component";

declare var window: {
    plugins: {
        zoomimageview: {
            presentImage: (info: string | undefined, callback: (result: boolean) => void, errorCallback: (error: any) => void) => void
        }
    },
    isTablet: boolean;
    screen: {
        orientation: {
            lock: (orientation: string) => void,
            unlock: () => void,
            type: string
        }
    }
};

@Component({
    selector: "app-mobile-image-control",
    templateUrl: "mobileImage.component.html",
    styleUrls: ["mobileImage.component.scss"]
})

export class MobileImageControlComponent extends ImageControlComponent {

    @ViewChild("imageView") imageControlElement: ElementRef;

    @Output() clickImage = new EventEmitter<void>();

    public onClick() {
        this.clickImage.emit();
        if (window.plugins && window.plugins.zoomimageview) {
            this.showImage();
        }
    }

    /**
     * Shows only image in new component with zoom function. Works only on cordova.
     */
    public showImage() {
        window.screen.orientation.unlock?.();
        const imageOffset = this.getTopOffsetFor(this.imageControlElement);
        const imageOffsetLeft = this.getLeftOffsetFor(this.imageControlElement);
        const rect: Rect = {
            x: imageOffsetLeft,
            y: this.imageControlElement.nativeElement.offsetTop + imageOffset,
            width: this.imageControlElement.nativeElement.width,
            height: this.imageControlElement.nativeElement.height
        };
        const info: ImageInfo = {
            image: this.image,
            closeButton: true,
            imageRect: rect
        };
        window.plugins.zoomimageview.presentImage(JSON.stringify(info), (result) => {
            if (!window.isTablet) {
                window.screen.orientation.lock("portrait-primary");
            }
        }, (error) => {
            console.log(error);
        });
    }

    /**
     * Calculates the left offset to the window.
     * @param element HTML element which offset should be calculated.
     * @returns left offset as number
     */
    public getLeftOffsetFor(element: ElementRef): number {
        let offset = element.nativeElement.offsetLeft;

        let currentElement: any = element.nativeElement;
        const offsetParent: any = currentElement.offsetParent;

        while (currentElement !== offsetParent) {
            currentElement = currentElement.parentNode;
        }
        offset += currentElement.offsetLeft;
        return offset;
    }

    public getTopOffsetFor(element: ElementRef): number {
        const factore = element.nativeElement.naturalWidth / element.nativeElement.width;
        const imgHeight = element.nativeElement.naturalHeight / factore;
        return (element.nativeElement.height - imgHeight) / 2;
    }
}
