import { Component } from "@angular/core";
import { BaseNavComponent } from "../base/baseNav.component";
import { Location } from "@angular/common";
import { NavigationBarItemModel } from "src/app/models/NavigationBarItemModel";
import { MatDialog } from "@angular/material/dialog";
import { NavigationService } from "src/app/services/NavigationService";
import { CordovaInitHandler } from "src/app/native/CordovaInitHandler";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { ContactRestService } from "src/app/services/http/ContactRestService";
import { ContactRespModel } from "src/app/api-handling/models/ContactRespModel";
import { ContactType } from "src/app/api-handling/models/enums/ContactType";
import { BreakpointObserver } from "@angular/cdk/layout";
import { TranslateService } from "@ngx-translate/core";
import { DialogService } from "src/app/services/DialogService";

@Component({
    selector: "app-camerafriends",
    templateUrl: "cameraFriends.component.html",
    styleUrls: ["cameraFriends.component.scss"]
})

export class CameraFriendsComponent extends BaseNavComponent {

    public rightBarButton = new NavigationBarItemModel("add");
    public title = "";
    private type: ContactType;
    public mails: ContactRespModel[];
    public openInvites: ContactRespModel[];
    public friends: ContactRespModel[];
    private cameraId: string;
    public hasMails = false;
    public hasOpenInvites = false;
    public hasFriends = false;

    constructor(private router: Router, dialog: MatDialog, navigationService: NavigationService, location: Location,
        initHandler: CordovaInitHandler, private activatedRoute: ActivatedRoute, private contactRestService: ContactRestService,
        breakpointObserver: BreakpointObserver, private translate: TranslateService, dialogService: DialogService) {
        super(dialog, navigationService, location, initHandler, breakpointObserver, dialogService);
        this.subscriptions.push(this.rightBarButton.action.subscribe(() => {
            this.addUser();
        }));
        this.setType();
        this.subscriptions.push(activatedRoute.queryParams.subscribe((params) => {
            if (params && params.id) {
                this.cameraId = params.id;
                this.loadContacts(this.cameraId);
            }
        }));

    }

    /**
     * Set the current Contact Type based on the router path.
     */
    private setType(): void {
        switch (this.activatedRoute.snapshot.data["type"]) {
            case "friend":
                this.type = ContactType.friend;
                this.title = "camerafriends.friends";
                break;
            default:
                this.type = ContactType.mail;
                this.title = "camerafriends.mails";
                break;
        }
    }

    /**
     * Loads contacts depending on the Conact type
     */
    private loadContacts(cameraId: string) {
        this.subscriptions.push(this.translate.get("camerafriends.loadingTitle").subscribe((translation) => {
            this.showSpinner(translation, "");
            switch (this.type) {
            case ContactType.invite: // will never be the case (enum will be "ContactType.friend")
            case ContactType.friend:
                this.subscriptions.push( this.contactRestService.getOpenInviteList(cameraId).subscribe((resultContacts) => {
                    this.openInvites = resultContacts;
                    this.closeSpinner();
                    this.hasOpenInvites = this.openInvites.length > 0;
                }));
                this.subscriptions.push( this.contactRestService.getAcceptedInviteList(cameraId).subscribe((resultContacts) => {
                    this.friends = resultContacts;
                    this.closeSpinner();
                    this.hasFriends = this.friends.length > 0;
                }));
                break;

            default:
                this.subscriptions.push( this.contactRestService.getMailContactList(cameraId).subscribe((resultContacts) => {
                    this.mails = resultContacts;
                    this.closeSpinner();
                    this.hasMails = this.mails.length > 0;
                }));
                break;
        }
        }));
    }

    updateOnResume() {
        this.loadContacts(this.cameraId);
    }

    public addUser() {
        switch (this.type) {
            case ContactType.invite: // will never be the case (enum will be "ContactType.friend")
            case ContactType.friend:
                this.router.navigate(["home/invite"], { queryParams: {id: this.cameraId }});
                break;
            default:
                this.router.navigate(["home/mail"], { queryParams: {id: this.cameraId }});
                break;
        }
    }

    public editFriend(contact: ContactRespModel, type: number) {
        switch (type) {
            case ContactType.friend:
                this.router.navigate(["home/friend"], { queryParams: {contactId: contact._id, id: this.cameraId }});
                break;
            case ContactType.invite:
                this.router.navigate(["home/invite"], { queryParams: {contactId: contact._id, id: this.cameraId }});
                break;
            default:
                this.router.navigate(["home/mail"], { queryParams: {contactId: contact._id, id: this.cameraId }});
                break;
        }
    }
}


