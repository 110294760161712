import { ICameraSettings } from "./ICameraSettings";
import { User } from "./User";

export enum CameraResolution {
    hd = "hd",
    sd = "sd",
    none = "none"
}

export enum ResendSettingsState {
    hidden = 0,
    disabled = 1,
    enabled = 2
}

export enum CameraType {
    wildcam = "wildcam"
}
export class CameraRequestModel {
    public _id: string;
    public name: string;
    public phone: string;
    public phoneRegion: string;
    public phoneFormatted: string;
    public settings: ICameraSettings;
    public version: number;
    public doNotSendHighRes: boolean;
}

export class CameraResponseModel extends CameraRequestModel {
    public imei: string;
    public productType: string;
    public metaCameraCount: number;
    public sortNumber?: number;
    public sendPush: boolean;
    public cameraType: CameraType;
    public camTimeToServerTimeDiff: string; // <= add this to camtime and you will have the server time
    public user: string;
    public lastDailyReport: Date | null;
    public battery: string;
    public firmware: string;
    public created: Date;
    public updated: Date;
    public isHighRes: boolean;
    private readonly isSendingHighRes?: boolean;
    public isAboutToChangeResolution: CameraResolution;
    public unverifiedBackofficeRegisteredCamera: boolean;
    public resendSettingsState?: ResendSettingsState;
    public stolen: string | null;

    // Transient field
    isOwner?: boolean;
    owner?: User;

    public getBattery(): number {
        return Number(this.battery.substring(0, this.battery.length - 1));
    }

    /**
     * Returns true if camera is sending images in biggest format.
     */
    public isHighResCamera(): boolean {
        return (this.isSendingHighRes === undefined && this.isHighRes === true || this.isSendingHighRes !== undefined && this.isSendingHighRes === true);
    }

    public hasReducedMultishotUpload(): boolean {
        return (this.isAboutToChangeResolution !== CameraResolution.sd && this.isSendingHighRes === true) ||
            (this.isSendingHighRes === undefined && this.isHighRes === true && this.isAboutToChangeResolution !== CameraResolution.sd) ||
            (this.isAboutToChangeResolution === CameraResolution.hd);
    }

    public isStolen(): boolean {
        return this.stolen && this.stolen.length > 0;
    }
}

export class CameraUpdateSortPushModel {
    public _id: string;
    public sortNumber?: number;
    public sendPush?: boolean;
}
