import { Injectable } from "@angular/core";
import { AppUserModel } from "../models/AppUserModel";

@Injectable()
export class LocalStorageService {

    public storeAccessInfos(user: AppUserModel) {
        localStorage.setItem("accessToken", user.accessToken);
        localStorage.setItem("accessTokenExpiresAt", user.accessTokenExpiresAt.toString());
    }

    public getAccessInfos(): AppUserModel {
        const info = new AppUserModel();
        info.accessToken = localStorage.getItem("accessToken");
        const date = new Date(localStorage.getItem("accessTokenExpiresAt"));
        info.accessTokenExpiresAt = date;
        return info;
    }

    public clearAccessInfos() {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("accessTokenExpiresAt");
    }

    public storeQuotaInformation(availablePictures: number, availableFavorites: number) {
        localStorage.setItem("availablePictures", String(availablePictures));
        localStorage.setItem("availableFavorites", String(availableFavorites));
    }

    public getQuotaInformation(): {
        availablePictures: number;
        availableFavorites: number;
    } | null {

        try {
            return {
                availablePictures: parseInt(localStorage.getItem("availablePictures"), 10),
                availableFavorites: parseInt(localStorage.getItem("availableFavorites"), 10)
            };
        } catch (e) {
            console.log(e);
        }
        return null;
    }
}
