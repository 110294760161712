import { Injectable } from "@angular/core";
import { from, map, Observable, of } from "rxjs";


declare var cordova: { getAppVersion: () => Promise<string> };

@Injectable({
    providedIn: "root",
})

export class AppInfoService {

    private version: string;

    public getVersion(): Observable<string> {

        if (this.version) {
            return of(this.version);
        } else {
            return from(cordova.getAppVersion()).pipe(
                map((result) => {
                    this.version = result;
                    return result;
                })
            );
        }
    }
}
