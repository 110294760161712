import { Component, OnInit, NgZone } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router, NavigationExtras, NavigationStart } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { NavigationService } from "src/app/services/NavigationService";
import { CordovaInitHandler } from "src/app/native/CordovaInitHandler";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ImageRestService } from "src/app/services/http/ImageRestService";
import { Image } from "../images/models/Image";
import { NavigationBarItemModel } from "src/app/models/NavigationBarItemModel";
import { TranslateService } from "@ngx-translate/core";
import { ImageResponseModel } from "src/app/api-handling/models/ImageResponseModel";
import { BreakpointObserver } from "@angular/cdk/layout";
import { CameraResponseModel } from "src/app/api-handling/models/CameraResponseModel";
import { ImageRepository } from "src/app/repositories/ImageRepository";
import { DeviceDetector } from "src/app/helpers/DeviceDetector";
import { ShareService } from "src/app/services/ShareService";
import { ImageFilterService } from "src/app/services/ImageFilterService";
import { from, Observable } from "rxjs";
import { IMAGE_TYPE } from "src/app/api-handling/models/enums/ImageType";
import { DialogService } from "src/app/services/DialogService";
import { filter } from "rxjs";
import { BaseImgComponent } from "../base/baseImg.component";
import { CameraRestService } from "src/app/services/http/CameraRestService";
import { ImageEvent } from "src/app/modules/imageModule/models/ImageEvent";


@Component({
    selector: "app-imagedetail",
    templateUrl: "imageDetail.component.html",
    styleUrls: ["imageDetail.component.scss"],
})

export class ImageDetailComponent extends BaseImgComponent implements OnInit {

    public starBarButton: NavigationBarItemModel;
    public downloadBarButton: NavigationBarItemModel;
    public imageMeta = new ImageResponseModel();
    public imageForm: FormGroup;
    private mImageModel: ImageResponseModel;
    private favoriteInit: boolean;
    public title = "imageDetail.title";
    public images: ImageResponseModel[] = [];
    private path = "home/images";
    private imageDetailPath = "home/imageDetail";
    public image: Image;
    public index = 0;
    public isFav = false;
    public canDisplayImageZoom: boolean = false;

    constructor(private formBuilder: FormBuilder, dialog: MatDialog, navigationService: NavigationService, location: Location,
        initHandler: CordovaInitHandler, public imageRestService: ImageRestService, private activatedRoute: ActivatedRoute, private translate: TranslateService,
        breakpointObserver: BreakpointObserver, private router: Router, public imageRepository: ImageRepository, private shareService: ShareService,
        public imageFilterService: ImageFilterService, public zone: NgZone, dialogService: DialogService) {
        super(dialog, navigationService, location, initHandler, breakpointObserver, dialogService, zone,
            imageRepository, imageFilterService);
        this.canDisplayImageZoom = DeviceDetector.isOnCordova();
        this.configNavBar();
    }

    private configNavBar() {
        this.downloadBarButton = new NavigationBarItemModel("cloud_download");
        this.subscriptions.push(this.downloadBarButton.action.subscribe(() => {
            this.shareImage();
        }));

        this.starBarButton = new NavigationBarItemModel("");
        this.subscriptions.push(this.starBarButton.action.subscribe(() => {
            if (this.isOnline()) {
                this.starButtonPressed();
            } else {
                this.openDialog(this.translate.instant("shared.notOnlineTitle"), this.translate.instant("shared.needsOnline"), null);
            }
        }));
    }

    updateOnResume() {
        this.getImageData();
    }

    imagesProcessFinished() {
    }

    public showButtonIfOwner(): NavigationBarItemModel[] {
        return this.imageMeta.camera.isOwner ? [this.starBarButton, this.downloadBarButton] : [this.downloadBarButton];
    }

    public deleteImage() {
        const deleteDialogObservable = this.openDialog(this.translate.instant("imageDetail.deleteTitle"),
            this.translate.instant("imageDetail.deleteMsg"),
            [this.translate.instant("imageDetail.delete"), this.translate.instant("shared.cancelButton")]);
        this.subscriptions.push(deleteDialogObservable.subscribe((buttonIndex) => {
            if (buttonIndex !== null && buttonIndex === 0) {
                this.zone.run(() => {
                    this.translate.get("imageDetail.deleteTitle").subscribe((translation) => {
                        this.showSpinner(translation, "");
                        this.subscriptions.push(this.imageRestService.deleteImage(this.mImageModel._id).subscribe(() => {
                            this.zone.run(() => {
                                this.closeSpinner();
                                if (this.isLastImage()) {
                                    this.images.splice(this.index, 1);
                                    this.swipeRight();
                                } else {
                                    this.images.splice(this.index, 1);
                                    this.swipeLeft(true);
                                }
                            });
                        }, (err) => {
                            this.zone.run(() => {
                                this.closeSpinner();
                            });
                        }));
                    });
                });
            }
        }));
    }

    public canEditImg(): boolean {
        return DeviceDetector.hasInternetConnection() && this.imageMeta.camera.isOwner && this.mImageModel !== undefined;
    }

    /**
     * Perform native Share function if is in Cordova, or download file if is webinterface.
     */
    public shareImage() {
        const data = this.getImgFile();
        this.subscriptions.push(from(this.shareService.shareFile({data: data, name: this.imageMeta.readableName + ".jpg"})).subscribe(() => {
        }, () => {
            this.openDialog(this.translate.instant("error.title"), this.translate.instant(DeviceDetector.isOnCordova() ? "imageDetail.shareErrorMsg" : "imageDetail.downloadErrorMsg"), undefined);
        }));
    }

    public getImgFile(): string {
        if (this.image) {
            return this.image.file ? this.image.file : undefined;
        } else if (!this.isOnline()) {
            return this.imageRepository.getImgFile(this.mImageModel._id, IMAGE_TYPE.thumbnail);
        }
        return undefined;
    }

    private async loadImage(images: ImageResponseModel[], _id: string) {
        this.images = images;
        this.mImageModel = this.images.find((e) => String(e._id) === String(_id));
        if (this.mImageModel) {
            this.imageFilterService.lastImageId = this.mImageModel._id;
            if (this.isOnline()) {
                const imageLoad = await this.imageRepository.getImg(this.mImageModel, IMAGE_TYPE.full);
                this.image = imageLoad.image;
                if (imageLoad.loadingPromise) {
                    this.subscriptions.push(from(imageLoad.loadingPromise).subscribe((loadingResult) => {
                        this.image = loadingResult;
                    }));
                }
            }
        } else {
            this.goBackButtonPressed();
        }

        let index = this.images.findIndex((e) => String(e._id) === String(_id));
        if (index >= this.images.length - 1 || index < 0) {
            await this.loadNextPage(true);
            this.closeSpinner();
            index = this.images.findIndex((e) => String(e._id) === String(_id));
        }
        this.index = index;
        this.getImageData();

    }

    /**
     * Returns a bool that indicates if the current index is the last image.
     */
    private isLastImage(): boolean {
        return this.index === this.images.length - 1;
    }

    public changeImg(event: ImageEvent): void {
        switch (event) {
            case ImageEvent.next:
                this.swipeLeft();
                break;
            case ImageEvent.prev:
                this.swipeRight();
                break;
            default:
                break;
        }
    }

    public async swipeLeft(afterDelete = false) {
        const loadNext = await this.canLoadNext();
        if ((afterDelete || !this.isLastImage())  && loadNext) {
            const navigationExtras: NavigationExtras = { queryParams: { id: this.images[afterDelete ? this.index : this.index + 1]._id, fav: this.isFav }, state: { images: this.images } };
            this.router.navigate([this.imageDetailPath], navigationExtras);
        }
    }

    public async swipeRight() {
        const loadNext = await this.canLoadNext();
        if (this.index > 0 && loadNext) {
            const navigationExtras: NavigationExtras = { queryParams: { id: this.images[this.index - 1]._id, fav: this.isFav }, state: { images: this.images } };
            this.router.navigate([this.imageDetailPath], navigationExtras);
        }
    }

    /**
     * Checks if next image can be shown.
     */
    public async canLoadNext(): Promise<boolean> {
        if (this.needsUpdate()) {
            if (this.imageForm.valid) {
                this.updateData();
                return true;
            }
            return await this.shouldRevokeUpdate();
        }
        return true;
    }

    public getImageData() {
        if (this.mImageModel) {
            this.subscriptions.push(this.translate.get("imageDetail.loadingImage").subscribe((translation) => {
                this.showSpinner(translation, "");
                if (DeviceDetector.hasInternetConnection()) {
                    this.processImage(this.imageRestService.getImageData(this.mImageModel._id));
                } else {
                    this.processImage(this.imageRepository.getImageById(this.mImageModel._id));
                }
            }));
        }
    }

    private processImage(subscription: Observable<ImageResponseModel>) {
        if (subscription) {
            this.subscriptions.push(subscription.subscribe((imageModel: ImageResponseModel) => {
                this.imageMeta = imageModel;
                this.favoriteInit = this.imageMeta.favorite;
                this.setFavoriteButton();
                this.imageForm = this.formBuilder.group({
                    name: [this.imageMeta.readableName, [Validators.required]]
                });
                this.closeSpinner();
            }, (err) => {
                this.closeSpinner();
            }));
        } else {
            this.closeSpinner();
        }
    }

    ngOnInit(): void {
        this.imageMeta.camera = new CameraResponseModel();
        this.imageForm = this.formBuilder.group({
            name: ["", [Validators.required]]
        });
        this.subscriptions.push(this.activatedRoute.queryParams.subscribe((params) => {
            if (params) {
                if (params.fav === "true") {
                    this.isFav = true;
                    this.path = "home/favourites";
                    this.imageDetailPath = "home/imageDetailFav";
                }
                if (params.id) {
                    if (this.imageRepository.images.length > 0) {
                        this.loadImage(this.imageRepository.images, params.id);
                    } else {
                        this.router.navigate([this.path]);
                    }
                }
            }
        }));

        this.subscriptions.push(this.router.events.pipe(
            filter(
                (event: any) => {
                    return (event instanceof NavigationStart);
                }
            )
        ).subscribe((event: NavigationStart) => {
            // detect browser back button
            if (event.url === "/" + this.path && event.navigationTrigger === "popstate") {
                this.imageFilterService.ignoreStateAndForceKeepData = true;
            }
        }));
    }

    /**
     * Performs api call to patch new image informations.
     */
    private updateData(): boolean {
        this.imageMeta.readableName = this.imageForm.value.name;
        this.imageMeta.favorite = this.imageMeta.favorite;
        const cameraPatch = this.imageRestService.patchImage(this.mImageModel._id, this.imageMeta);
        if (cameraPatch) {
            this.subscriptions.push(cameraPatch.subscribe((result) => {
                this.imageMeta.favorite = result.favorite;
                this.imageMeta.readableName = result.readableName;
                this.imageMeta.version = result.version;
                this.setFavoriteButton();
                return true;
            }, () => {
                return false;
            }));
        }
        return false;
    }

    /**
     * Checks if changes detected.
     */
    private needsUpdate() {
        return this.imageMeta.readableName !== this.imageForm.value.name || this.imageMeta.favorite !== this.favoriteInit;
    }

    /**
     * Checks if changed should ignored.
     */
    private async shouldRevokeUpdate(): Promise<boolean> {
        const mButtons: string[] = [this.translate.instant("shared.yes"), this.translate.instant("shared.no")];
        const buttonClicked = await this.openDialog(this.translate.instant("imageDetail.invalidTitle"), this.translate.instant("contactDetail.invalidMsg"), mButtons).toPromise();
        return buttonClicked !== null && buttonClicked === 0;
    }

    public goBackButtonPressed() {
        if (this.mImageModel && this.needsUpdate()) {
            if (this.imageForm.valid) {
                this.imageMeta.readableName = this.imageForm.value.name;
                this.imageMeta.favorite = this.imageMeta.favorite;
                const cameraPatch = this.imageRestService.patchImage(this.mImageModel._id, this.imageMeta);
                if (cameraPatch) {
                    this.subscriptions.push(cameraPatch.subscribe((result) => {
                        this.imageFilterService.lastImageId = this.mImageModel._id;
                        this.router.navigate([this.path], { replaceUrl: true, state: { keepData: this.isFav ? this.imageMeta.favorite : true } });
                    }, (err) => {
                        this.imageFilterService.lastImageId = this.mImageModel._id;
                        this.router.navigate([this.path], { replaceUrl: true, state: { keepData: this.isFav ? this.imageMeta.favorite : true } });
                    }));
                }
            } else {
                const mButtons: string[] = [this.translate.instant("shared.yes"), this.translate.instant("shared.no")];
                this.subscriptions.push(this.openDialog(this.translate.instant("imageDetail.invalidTitle"), this.translate.instant("contactDetail.invalidMsg"), mButtons).subscribe((buttonClicked) => {
                    if (buttonClicked !== null && buttonClicked === 0) {
                        this.imageFilterService.lastImageId = this.mImageModel._id;
                        this.router.navigate([this.path], { replaceUrl: true, state: { keepData: true } });
                    }
                }));
            }
        } else if (this.mImageModel) {
            this.imageFilterService.lastImageId = this.mImageModel._id;
            this.router.navigate([this.path], { replaceUrl: true, state: { keepData: true } });
        } else {
            this.router.navigate(["home/images"], { replaceUrl: true, state: { keepData: true } });
        }
    }

    public storeImage() {
        this.canLoadNext();
    }

    /**
     * Changes favorite status of image.
     */
    public starButtonPressed() {
        this.imageMeta.favorite = !this.imageMeta.favorite;
        if (!this.updateData()) {
            this.imageMeta.favorite = !this.imageMeta.favorite;
        }
    }

    /**
     * Sets icon for favorite button
     */
    private setFavoriteButton() {
        if (this.imageMeta.favorite) {
            this.starBarButton.matImageName = "star";
        } else {
            this.starBarButton.matImageName = "star_border";
        }
    }

    /**
     * Shows image list with filtert after current camera.
     */
    public showCameraImages() {
        if (this.isOnline()) {
            const extras: NavigationExtras = { state: { filtercameras: [this.imageMeta.camera._id] } };
            this.router.navigate(["home/images"], extras);
        } else {
            this.openDialog(this.translate.instant("shared.notOnlineTitle"), this.translate.instant("shared.needsOnline"), null);
        }
    }
}
