import { Component, NgZone, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DeviceDetector } from "./helpers/DeviceDetector";
import { LanguageDetector } from "./helpers/LanguageDetector";
import { CordovaInitHandler } from "./native/CordovaInitHandler";
import { OVERLAP } from "./animations/overlap.animation";
import { PRESENT } from "./animations/present.animation";
import { UserRestService } from "./services/http/UserRestService";
import { from } from "rxjs";
import { environment } from "../environments/environment";
import { FirebaseService } from "./services/FirebaseService";
import { NavigationEnd, Router, RoutesRecognized } from "@angular/router";
import { TrackingService, TrackTag } from "./services/TrackingService";
import { setTheme } from "ngx-bootstrap/utils";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.css"],
    animations: [
        OVERLAP,
        PRESENT
    ]
})

export class AppComponent implements OnInit {

    title = "doerr-app";
    public deviceReady = false;

    public animation: string = "";

    constructor(translate: TranslateService, initHandler: CordovaInitHandler,
        zone: NgZone, private readonly userRestService: UserRestService, firebaseService: FirebaseService,
        private readonly trackingService: TrackingService, private readonly router: Router) {

        setTheme("bs5"); // or 'bs4'
        this.trackPageEvents();
        translate.setDefaultLang("en");
        if ( !DeviceDetector.isOnCordova() ) {
            from(LanguageDetector.getLanguage()).subscribe((locale) => {
                translate.use(locale ? locale : "en");
                this.userRestService.loadQuotaInformation().finally(() => {
                    this.deviceReady = true;
                });
            });
        } else {
            const _this = this;
            initHandler.initDone.subscribe({ next(result) {
                if (result) {
                    firebaseService.trackAnalytics(environment.trackAnalytics);
                    firebaseService.crashReporting();
                    from(LanguageDetector.getLanguage()).subscribe((locale) => {
                        zone.run(() => {
                            translate.use(locale ? locale : "en");
                            _this.deviceReady = true;
                        });
                    });
                }
            }});
        }
    }

    ngOnInit(): void {
        this.router.events.subscribe((data) => {
            if (data instanceof RoutesRecognized) {
                this.animation = data.state.root.firstChild.data["animation"];
            }
        });
    }

    public trackPageEvents() {
        this.router.events.forEach( async item => {
            if (item instanceof NavigationEnd) {
                const gtmTag: TrackTag = {
                    event: "page",
                    pageName: item.url
                };
                this.trackingService.trackEvent(gtmTag);
            }
        });
    }
}
