import { Observable } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { DeviceDetector } from "./DeviceDetector";
import { DialogComponent, DialogButton } from "../components/base/dialog/dialog.component";
import { ImageDialogComponent } from "../components/base/imageDialog/imageDialog.component";

declare var navigator: {notification: { confirm(message: string, resultCallback: (buttonIndex) => void, title: string, buttonLabels: string[]): void; } };

export class DialogFactory {

    constructor(private dialog: MatDialog) {}

    public showDialog(title: string, message: string, buttons: string[], imgName: string | undefined): Observable<number> {
        let mButtons: string[] = [];
        if ( !buttons ) {
            mButtons.push("Ok");
        } else {
            mButtons = buttons;
        }

        if ( DeviceDetector.isOnCordova() && !imgName) {
             return this.showNativeDialog(title, message, mButtons);
        } else {
            if (imgName) {
                const dialogRef = this.dialog.open(ImageDialogComponent, {
                    width: "250px",
                    data: {title: title, message: message, imgName: imgName, buttons: this.createDialogButtons(mButtons)}
                });
                return dialogRef.afterClosed();
            } else {
                const dialogRef = this.dialog.open(DialogComponent, {
                    width: "250px",
                    data: {title: title, message: message, buttons: this.createDialogButtons(mButtons)}
                });
                return dialogRef.afterClosed();
            }
        }
    }

    private createDialogButtons(buttons: string[]): DialogButton[] {
        const dialogButtons: DialogButton[] = [];
        buttons.forEach((element, index) => {
            const button: DialogButton = {
                id: index,
                title: element
            };
            dialogButtons.push(button);
        });
        return dialogButtons;
    }

    private showNativeDialog(title: string, message: string, buttons: string[]): Observable<number> {
        return Observable.create(observer => navigator.notification.confirm(message, (buttonIndex) => {
            observer.next(buttonIndex - 1);
        }, title, buttons));
    }
}
