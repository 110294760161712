import { Injectable, NgZone } from "@angular/core";
import { firstValueFrom, Subject } from "rxjs";
import { CameraResponseModel } from "../api-handling/models/CameraResponseModel";
import { User } from "../api-handling/models/User";
import { ImageFilter } from "../components/images/models/ImageFilter";
import { CameraRestService } from "./http/CameraRestService";
import { ImageRestService } from "./http/ImageRestService";

export enum ImageFilterRoute {
    IMAGES,
    FAVORITES
}
@Injectable()

export class ImageFilterService {

    ///
    // Current filter settings
    ///

    public page: number = 1;

    private _route: ImageFilterRoute = ImageFilterRoute.IMAGES;
    get route(): ImageFilterRoute {
        return this._route;
    }
    set route(value: ImageFilterRoute) {
        if (value !== this._route) {
            this.clearData();
            this.filter.favourites = value === ImageFilterRoute.FAVORITES;
            this.emitFilterSettingsSource.next();
        }
        this._route = value;
    }

    /**
     * Currently selected filter
     */
    private _filter?: ImageFilter = new ImageFilter();
    get filter(): ImageFilter | undefined {
        return this._filter;
    }
    set filter(value: ImageFilter) {
        this._filter = value;
        this.emitFilterSettingsSource.next();
    }

    /**
     * Last image id that was selected in a images list. Used to jump back to the image in the images list.
     */
    public lastImageId: string | undefined;

    public ignoreStateAndForceKeepData = false;

    /**
     * Upload date of the first shown image (for prevention of deleting newer images, when images arrives after user loads the image list)
     */
    public firstImageUploadDate: Date | undefined;


    ///
    // Availble filter parameters
    ///

    /**
     * Cameras that can be selected.
     */
    private _cameras: CameraResponseModel[] = [];
    get cameras() {
        return this._cameras;
    }
    set cameras(value: CameraResponseModel[]) {
        this._cameras = value;
        this.zone.run(() => {
            this.emitCamerasSource.next(this.cameras);
        });
    }

    /**
     * Users that can be selected. Note: Users are summary of all users for the given cameras.
     */
    private _users: User[] = [];
    get users() {
        return this._users;
    }
    set users(value: User[]) {
        this._users = value;
        this.zone.run(() => {
            this.emitUserSource.next(this._users);
        });
    }

    ///
    // Observables for filter changes.
    ///

    /**
     * Subject that changes if the available filter parameters changes.
     */
    private emitFilterParameterSource = new Subject<void>();

    filterParameterObservable$ = this.emitFilterParameterSource.asObservable();

    /**
     * Subject that changes if the available cameras changes.
     */
     private emitCamerasSource = new Subject<CameraResponseModel[]>();

     cameras$ = this.emitCamerasSource.asObservable();

     /**
     * Subject that changes if the available users changes.
     */
      private emitUserSource = new Subject<User[]>();

      users$ = this.emitUserSource.asObservable();

    /**
     * Subject that changes if the currently selected filter changes.
     */
    private emitFilterSettingsSource = new Subject<void>();

    filterSettingsObservable$ = this.emitFilterSettingsSource.asObservable();


    constructor(private imageRestService: ImageRestService, private zone: NgZone, private readonly cameraRestService: CameraRestService) {

    }

    /**
     * Clear all set filter parameters
     */
    public clearData() {
        this.page = 1;
        this.filter = new ImageFilter();
        this.filter.favourites = this._route === ImageFilterRoute.FAVORITES;
        this.lastImageId = undefined;
        this.ignoreStateAndForceKeepData = false;
        this.emitFilterParameterSource.next();
    }

    /**
     * Clear all cameras and users (used only in signOff)
     */
    public clearCamerasAndUsers() {
        this.cameras = [];
        this.users =  [];
    }

    /**
     * Updates the available filter parameters.
     * @param force Boolean that forces to load all information even if not necessary. This could be necessary if the applications reopens after a long pause or a users added a camera.
     */
    public async updateFilterParameters(force: Boolean = false) {
        this.zone.runOutsideAngular(async () => {
            await this.checkLastImgExist();
            await this.loadCamerasAndUsers(force);
            this.zone.run(() => {
                this.emitFilterParameterSource.next();
            });
        });
    }

    /**
     * I the camera array is empty this mehtod loads all cameras from the backend.
     * @param force Boolean that forces to load cameras any way.
     */
    private async loadCamerasAndUsers(force: Boolean = false) {
        if (this.cameras.length === 0 || force) {
            const cameraSubscription = this.cameraRestService.getCameraList();
            if ( cameraSubscription ) {
                this.cameras = await firstValueFrom(cameraSubscription);
                this.users =  this.getUsersFor(this.cameras);
            }
        }
    }

    /**
     * Build list of users based on camera request.
     * @param cameras current cameras request result.
     */
     private getUsersFor(cameras: CameraResponseModel[]): User[] {
        const userArray: User[] = [];
        cameras.forEach(camera => {
            if (camera.owner) {
                if (!userArray.some((user) => user._id === camera.owner._id)) {
                    userArray.push(camera.owner);
                }
            }
        });
        return userArray;
    }

    /**
     * Check if the currently lastImageId is still available on the server. If not reset the lastImageId.
     * @returns Promise returns void if lastImageId is updated
     */
     private async checkLastImgExist(): Promise<void> {
        if (this.lastImageId) {
            try {
                await this.imageRestService.getImageData(this.lastImageId, true).toPromise();
                return;
            } catch (error) {
                this.zone.run(() => {
                    this.lastImageId = undefined;
                });
            }
        }
    }

    public isFilterSet(): boolean {
        return this.filter.cameras.length > 0 || this.filter.endDate !== undefined || this.filter.startDate !== undefined;
    }

}
