import { Component, NgZone, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BaseNavComponent } from "../base/baseNav.component";
import { NavigationService } from "src/app/services/NavigationService";
import { MatDialog } from "@angular/material/dialog";
import { Location } from "@angular/common";
import { CordovaInitHandler } from "src/app/native/CordovaInitHandler";
import { allCountries } from "src/app/helpers/Countries";
import { CameraRestService } from "src/app/services/http/CameraRestService";
import { CameraRegisterReq1Model } from "src/app/api-handling/models/CameraRegisterReq1Model";
import { TranslateService } from "@ngx-translate/core";
import { HttpErrorResponse } from "@angular/common/http";
import { RegisterDataService } from "src/app/services/RegisterDatatService";
import { DeviceDetector } from "src/app/helpers/DeviceDetector";
import { BreakpointObserver } from "@angular/cdk/layout";
import * as libphonenumber from "google-libphonenumber";
import { DialogService } from "src/app/services/DialogService";
import { Code, QRCodeScannerService, ScannerButton, ScannerInfo, ScannerState } from "src/app/native/QRCodeScannerService";


declare var window: {plugins: { insomnia: { keepAwake: () => void; allowSleepAgain: () => void; }; }; };

@Component({
    selector: "app-newcamera",
    templateUrl: "newcamera.component.html",
    styleUrls: ["newcamera.component.scss"]
})

export class NewCameraComponent extends BaseNavComponent implements OnInit {

    public newCameraForm: FormGroup;
    public countries = allCountries;
    public imei: string;
    public popupShown = false;

    constructor(private formBuilder: FormBuilder, activatedRoute: ActivatedRoute, dialog: MatDialog,
        navigationService: NavigationService, location: Location, initHandler: CordovaInitHandler, private router: Router,
        private cameraRestService: CameraRestService, private translateService: TranslateService, private dataService: RegisterDataService,
        breakpointObserver: BreakpointObserver, dialogService: DialogService, private scannerService: QRCodeScannerService, private zone: NgZone) {
        super(dialog, navigationService, location, initHandler, breakpointObserver, dialogService);
        if (DeviceDetector.isOnCordova()) {
            this.openScanner();
        }
    }

    ngOnInit(): void {
        this.newCameraForm = this.formBuilder.group({
            imei: ["", [Validators.required, Validators.maxLength(15), Validators.minLength(15)]],
            phone: ["", [Validators.required]],
            country: ["", [Validators.required]],
            name: ["", [Validators.required]]
        });
        this.updateCountry();
    }

    public openScanner() {
        this.listenToScanner();
        this.subscriptions.push(this.translateService.get(["scanner"]).subscribe((translation) => {
            const button: ScannerButton = {
                title: translation.scanner.manuelImei,
                tintColor: "#FFFFFF",
                backgroundColor: "#4b5b45",
                roundedCorners: true
            };
            const scannerInfo: ScannerInfo = {
                title: translation.scanner.titleDescription,
                infoText: translation.scanner.description,
                button: button
            };
            const title = this.translateService.instant("navigation.scanner");

            const permissionButton: ScannerButton = {
                title: translation.scanner.manuelImei,
                tintColor: "#FFFFFF",
                backgroundColor: "#4b5b45",
                roundedCorners: true
            };
            const permissionInfo: ScannerInfo = {
                title: translation.scanner.permissionError,
                infoText: translation.scanner.permissionDescription,
                button: button
            };

            this.scannerService.startScanningWith(title, scannerInfo, permissionInfo);
        }));
    }

    private listenToScanner() {
        this.subscriptions.push(this.scannerService.subscribeUpdateChanged().subscribe((message) => {
            if (message) {
                this.zone.run(() => {
                    switch (message.state) {
                        case ScannerState.DETECT_CODE:
                            this.imei = message.object.value;
                            this.showPopup();
                            break;
                        default:
                            break;
                    }
                });
            }
        }));
    }

    private showPopup() {
        if (!this.popupShown) {
            this.openImgDialog(this.translateService.instant("newcamera.note"), this.translateService.instant("newcamera.noteCamera"), "register_pikto", [this.translateService.instant("shared.next")]).subscribe((finish) => {
                this.popupShown = true;
            });
        }
    }
    public onBlur() {
        super.onBlur();
        if (this.newCameraForm.controls.imei.valid) {
            this.showPopup();
        }
    }

    private updateCountry() {
        this.newCameraForm.controls.phone.valueChanges.subscribe(val => {
            const phoneNumberUtil = libphonenumber.PhoneNumberUtil.getInstance();
            try {
                // phone must begin with '+'
                const numberProto = phoneNumberUtil.parse(val, "");
                const countryCode = phoneNumberUtil.getRegionCodeForNumber(numberProto);
                if (countryCode) {
                    this.newCameraForm.controls.country.setValue(countryCode, undefined);
                }
            } catch (e) {
            }
        });
    }

    public addCamera() {
        if (this.newCameraForm.valid) {
            const reqModel = new CameraRegisterReq1Model(this.newCameraForm.value.imei, this.newCameraForm.value.name.trim(),
                this.newCameraForm.value.phone, this.newCameraForm.value.country, 360000);
            const registerSubscription = this.cameraRestService.registerCameraStep1(reqModel);
            if ( registerSubscription ) {
                if (DeviceDetector.isOnCordova()) {
                    window.plugins.insomnia.keepAwake();
                }
                this.showSpinner(this.translateService.instant("newcamera.spinnerTitle"), this.translateService.instant("newcamera.spinnerMessage"));
                this.subscriptions.push(registerSubscription.subscribe((result) => {
                    this.closeSpinner();
                    if (DeviceDetector.isOnCordova()) {
                        window.plugins.insomnia.allowSleepAgain();
                    }
                    this.dataService.responseStep1 = result;
                    this.dataService.cameraReq1 = reqModel;
                    this.router.navigate(["home/newSettings"]);
                }, (err: HttpErrorResponse) => {
                    this.closeSpinner();
                    if (DeviceDetector.isOnCordova()) {
                        window.plugins.insomnia.allowSleepAgain();
                    }
                    if (err.status === 503) {
                        this.openDialog(this.translateService.instant("newcamera.dialog.timeoutTitle"), this.translateService.instant("newcamera.dialog.timeoutMsg"), null);
                    }
                    if (err.status === 423) {
                        this.openDialog(this.translateService.instant("newcamera.dialog.supportTitle"), this.translateService.instant("newcamera.dialog.supportMsg"), null);
                    }
                }));
            }
        }
    }
}
