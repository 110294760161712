import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { AppUserService } from "../database/AppUserService";
import { LocalStorageService } from "../LocalStorageService";
import { Observable, Subject, throwError, from } from "rxjs";
import { BaseRestService } from "./BaseRestService";
import { FirebaseRegisterModel } from "../../api-handling/models/FirebaseRegisterModel";
import { LanguageDetector } from "src/app/helpers/LanguageDetector";
import { switchMap, catchError } from "rxjs";
import { classToPlain } from "class-transformer";
import { FirebaseDeleteResponse } from "src/app/api-handling/models/FirebaseDeleteResponse";
import { IApiErrorResponse } from "src/app/api-handling/models/IApiErrorResponse";

@Injectable()

export class FirebaseRestService extends BaseRestService {

    constructor(private httpClient: HttpClient, private userService: AppUserService, localStorageService: LocalStorageService) {
        super(localStorageService);
    }

    public uploadToken(token: string): Observable<any> | null {
        const subject = new Subject<any>();
        const header = this.getBearerHeader();
        if ( header ) {
            from(LanguageDetector.getLanguage()).subscribe((locale) => {
                const body = new FirebaseRegisterModel(token, locale ? locale : "en");
                header.append("Content-Type", "application/json");
                this.httpClient.post<any>(this.webserviceUrl + "v1/firebasedevices/userdevice", classToPlain(body), {headers: header}).pipe( switchMap( async (result) => {
                    this.userService.removeTokens();
                    this.userService.addPushToken(token);
                }),
                catchError(
                (err: HttpErrorResponse) => {
                    if (err && err.error && err.error.id) {
                        const errorResponse: IApiErrorResponse = err.error;
                        if ( errorResponse && errorResponse.code === "device_already_registered" ) {
                            this.userService.removeTokens();
                            this.userService.addPushToken(token);
                        }
                    }
                    return throwError(err);
                }
            )).subscribe(subject);
            });
            return subject.asObservable();
        } else {
            return null;
        }
    }

    public removeToken(token: string): Observable<any> | null {
        const header = this.getBearerHeader();
        if ( header ) {
            header.append("Content-Type", "application/json");
            return this.httpClient.request("delete", this.webserviceUrl + "v1/firebasedevices/userdevice", {body: {token: token}, headers: header}).pipe( switchMap( async (result) => {
                const res = result as FirebaseDeleteResponse;
                if (res && res.token === token) {
                    this.userService.removeTokens();
                }
            }));
        }
        return null;
    }
}
