import { Component } from "@angular/core";
import { BaseNavComponent } from "../base/baseNav.component";
import { ContactService } from "src/app/native/ContactService";
import { CordovaInitHandler } from "src/app/native/CordovaInitHandler";
import { MatDialog } from "@angular/material/dialog";
import { NavigationService } from "src/app/services/NavigationService";
import { Location } from "@angular/common";
import { BreakpointObserver } from "@angular/cdk/layout";
import { Contact } from "src/app/models/Contact";
import { SettingsService } from "src/app/native/SettingsService";
import { DialogService } from "src/app/services/DialogService";
import { catchError, Observable } from "rxjs";

@Component({
    selector: "app-contactapp",
    templateUrl: "contactApp.component.html",
    styleUrls: ["contactApp.component.scss"]
})

export class ContactAppComponent extends BaseNavComponent {

    public $contacts: Observable<Contact[]>;
    public permissionMissing = false;

    constructor(private contactService: ContactService, dialog: MatDialog, navigationService: NavigationService, location: Location,
        initHandler: CordovaInitHandler, breakpointObserver: BreakpointObserver, private settingsService: SettingsService, dialogService: DialogService) {
        super(dialog, navigationService, location, initHandler, breakpointObserver, dialogService);

        this.$contacts = contactService.getAllMailContacts$().pipe(
            catchError((error: any) => {
                this.permissionMissing = true;
                throw error;
            })
        );
    }

    public selected(contact: Contact) {
        this.contactService.selectContact(contact);
        this.goBackButtonPressed();
    }

    public openSettings() {
        this.settingsService.openAppSettings();
    }

}
