import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, filter, first, mergeMap } from "rxjs";
import { environment } from "src/environments/environment";
import { LanguageDetector } from "src/app/helpers/LanguageDetector";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class HttAppInformationInterceptor implements HttpInterceptor, OnDestroy {
    private deviceLanguage?: string;

    private id: string = "hybrid";
    private version?: string;
    private language?: string;

    private initSuccessful = new BehaviorSubject<boolean>(false);

    constructor(private translate: TranslateService) {
        this.init();
    }

    async init() {
        this.version = environment.version;
        this.deviceLanguage = await LanguageDetector.getDeviceLanguage();

        this.translate.onLangChange.subscribe( (event) => {
            this.language = event.lang;
            this.checkAndEmitSuccess();
        });

        if (this.translate.currentLang) {
            this.language = this.translate.currentLang;
        }
        this.checkAndEmitSuccess();
    }

    ngOnDestroy(): void {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Skip all local file requests
        if (!req.url.startsWith('http')) {
            return next.handle(req);
        }

        // If already initialized, pass enriched request
        if (this.initSuccessful.getValue()) {
            return this.enrichedRequest(req, next);
        }

        // "Wait until initialized"
        return this.initSuccessful.asObservable()
            .pipe(
                filter( initialized => initialized ),
                first(),
                mergeMap(
                    (_) => this.enrichedRequest(req, next)
                )
            );
    }


    private checkAndEmitSuccess() {
        if (this.deviceLanguage && this.id && this.version && this.language) {
            this.initSuccessful.next(true);
        }
    }

    private enrichedRequest(req: HttpRequest<any>,  next: HttpHandler) {
        let headers = req.headers
            .set("x-doerr-device-language", this.deviceLanguage)
            .set("x-doerr-application-id", this.id)
            .set("x-doerr-application-version", this.version)
            .set("x-doerr-application-language", this.language)
        ;

        const clonedRequest = req.clone({ headers: headers });

        return next.handle(clonedRequest);
    }

}
