import { BreakpointObserver } from "@angular/cdk/layout";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Location } from "@angular/common";
import { CordovaInitHandler } from "src/app/native/CordovaInitHandler";
import { DialogService } from "src/app/services/DialogService";
import { NavigationService } from "src/app/services/NavigationService";
import { BaseNavComponent } from "src/app/components/base/baseNav.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserRepository } from "src/app/repositories/UserRepository";

@Component({
    selector: "app-accountdelete",
    templateUrl: "accountDelete.component.html",
    styleUrls: ["accountDelete.component.scss"]
})

export class AccountDeleteComponent extends BaseNavComponent implements OnInit {

    public deleteForm: FormGroup;
    public passwordValue = "";

    constructor(breakpointObserver: BreakpointObserver, dialog: MatDialog, navigationService: NavigationService,
        location: Location, initHandler: CordovaInitHandler, private router: Router, dialogService: DialogService,
        private formBuilder: FormBuilder, private readonly userRepository: UserRepository, private readonly translate: TranslateService) {
        super(dialog, navigationService, location, initHandler, breakpointObserver, dialogService);
    }

    ngOnInit(): void {
        this.deleteForm = this.formBuilder.group({
            password: ["", Validators.required]
        });
    }

    public async delete() {
        if (this.deleteForm.controls.password.valid) {
            this.showSpinner(this.translate.instant("abo.accountDelete.deleteProgress"), "");
            const result = await this.userRepository.deleteAccount(this.deleteForm.value.password);
            this.closeSpinner();
            if (result) {
                this.router.navigate(["signin"]);
            }
        }
    }
}
