import { animate, state, style, transition, trigger } from "@angular/animations";

export const FADE =   trigger("fade", [
    state("inUp", style({
      opacity: 1,
      transform: "translate3D(0,0,0)"
    })),
    state("outDown", style({
      opacity: 0,
      transform: "translate3D(0,100%,0)"
    })),
    transition("inUp => outDown", [
      animate("0.5s ease")
    ]),
    transition("outDown => inUp", [
      animate("0.5s ease")
    ]),
  ]);
