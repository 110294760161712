import { Component, OnInit, Input, EventEmitter, Output, OnChanges, AfterViewInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { CameraMode, PictureSize, VideoSize, MultiShot, NightMode, FlashLED, SDSycle, PIRSensitivity, PIRSwitch, ICameraSettings } from "src/app/api-handling/models/ICameraSettings";
import * as moment from "moment";
import { allCountries } from "src/app/helpers/Countries";
import { CameraResponseModel } from "src/app/api-handling/models/CameraResponseModel";
import { DeviceDetector } from "src/app/helpers/DeviceDetector";
import * as libphonenumber from "google-libphonenumber";

interface ISelectObj {
    key: any;
    value: any;
}

interface ITimeControlValidationObj {
    hhControlName: string;
    mmControlName: string;
    ssControlName: string;
    isActive: boolean;
    storedValue: string;
    minValueSS: number;
    maxValueSS: number;
    maxValueMM: number;
    maxValueHH: number;
    currentHHValue: string;
    currentMMValue: string;
    currentSSValue: string;
}

interface ITimeValidationResult {
    isValid: boolean;
    value?: string;
}

declare var window: { scrollTo: (x: number, y: number) => void };

export interface ICameraValidSettings {
    settings: ICameraSettings;
    isValid: boolean;
    camera?: CameraResponseModel;
}

@Component({
    selector: "app-camerasettings",
    templateUrl: "cameraSettings.component.html",
    styleUrls: ["cameraSettings.component.scss"]
})

export class CameraSettingsComponent implements OnInit, OnChanges, AfterViewInit {

    @Input() cameraSettings: ICameraSettings;
    @Input() camera: CameraResponseModel;
    @Output() notify = new EventEmitter<ICameraValidSettings>();


    public newCameraForm: FormGroup;
    public countries = allCountries;
    // boolean which detects if the phone number of the camera got changed.
    private phoneChanged = false;

    // Settings - selectable values
    public cameraModes: ISelectObj[] = [];
    public pictureSizes: ISelectObj[] = [];
    public videoSizes: ISelectObj[] = [];
    public multiShots: ISelectObj[] = [];
    public availableMultiShotUploads: ISelectObj[] = [];
    public nightModes: ISelectObj[] = [];
    public flashLEDs: ISelectObj[] = [];
    public sdSycles: ISelectObj[] = [];
    public pirSensitivities: ISelectObj[] = [];
    public pirSwitches: ISelectObj[] = [];

    public timelapseValid = true;
    public delayValid = true;
    public workTimer1Valid = true;
    public workTimer2Valid = true;
    public blurTimeout: NodeJS.Timeout;

    /**
     * Min value for timelapse function in seconds
     */
    public minValueTimelapse = 5;

    /**
    * Min value for delay function in seconds
    */
    public minValueDelay = 3;

    constructor(private readonly formBuilder: FormBuilder, private readonly translateService: TranslateService) {

        // Settings - selectable values
        this.numberEnumsToISelectObj(CameraMode, this.cameraModes);
        this.numberEnumsToISelectObj(PictureSize, this.pictureSizes);
        this.numberEnumsToISelectObj(VideoSize, this.videoSizes);
        this.numberEnumsToISelectObj(MultiShot, this.multiShots);
        this.numberEnumsToISelectObj(NightMode, this.nightModes);
        this.numberEnumsToISelectObj(FlashLED, this.flashLEDs);
        this.numberEnumsToISelectObj(SDSycle, this.sdSycles);
        this.numberEnumsToISelectObj(PIRSensitivity, this.pirSensitivities);
        this.numberEnumsToISelectObj(PIRSwitch, this.pirSwitches);
    }

    ngOnInit(): void {
        this.updateForm();
        this.onChange();
    }

    ngAfterViewInit(): void {
        if (DeviceDetector.isOnCordova()) {
            const timepickerFields = Array.prototype.slice.call( document.getElementsByClassName("bs-timepicker-field") );
            for (const pickerField of timepickerFields) {
                if (pickerField.getAttribute("listener") !== "true") {
                    pickerField.addEventListener("blur", () => {
                        this.onBlur();
                    });
                    pickerField.setAttribute("listener", "true");
                }
            }
        }
    }

    ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
        this.updateForm();
    }

    private updateForm() {
        if ( this.cameraSettings ) {
            const timeLapse = this.cameraSettings.timeLapse.split(":");
            const delay = this.cameraSettings.delay.split(":");

            // Create valid options for multishot send property
            this.availableMultiShotUploads =  this.getAvailableOptionsMultiShotSend();
            // Update the current multishot send property if not valid.
            if (Number(this.cameraSettings.multishotUpload) > Number(this.getBiggestAvailableMultishotSend().key)) {
                this.cameraSettings.multishotUpload = this.getBiggestAvailableMultishotSend().key;
            }

            this.newCameraForm = this.formBuilder.group({
                cameramode: [this.cameraSettings.cameraMode, [Validators.required]],
                sdsycle: [this.cameraSettings.sdSycle],
                picturesize: [this.cameraSettings.pictureSize, [Validators.required]],
                multishot: [this.cameraSettings.multiShot, [Validators.required]],
                multishotSend: [this.cameraSettings.multishotUpload, [Validators.required]],
                flashled: [this.cameraSettings.flashLED, [Validators.required]],
                nightmode: [this.cameraSettings.nightMode, [Validators.required]],
                videosize: [this.cameraSettings.videoSize, [Validators.required]],
                videoLength: [this.cameraSettings.videoLength],
                sensitivity: [this.cameraSettings.pirSensitivity, [Validators.required]],
                movementtracking: [!this.cameraSettings.pirSwitch, [Validators.required]],
                timelapseHH: [timeLapse[0]],
                timelapseMM: [timeLapse[1]],
                timelapseSS: [timeLapse[2]],
                timelapseActive: [this.cameraSettings.timeLapseActivated],
                delayHH: [delay[0]],
                delayMM: [delay[1]],
                delaySS: [delay[2]],
                delayActive: [this.cameraSettings.delayActivated],
                worktimer1Active: [this.cameraSettings.workTimer1Activated],
                worktimer1From: [moment(this.cameraSettings.workTimer1, "HH:mm").toDate()],
                worktimer1Until: [moment(this.cameraSettings.workTimer1end, "HH:mm").toDate()],
                worktimer2Active: [this.cameraSettings.workTimer2Activated],
                worktimer2From: [moment(this.cameraSettings.workTimer2, "HH:mm").toDate()],
                worktimer2Until: [moment(this.cameraSettings.workTimer2end, "HH:mm").toDate()]
            });

            if (this.camera) {
                this.newCameraForm.addControl("phone", new FormControl(this.camera.phone, [Validators.required]));
                this.newCameraForm.addControl("country", new FormControl(this.camera.phoneRegion, [Validators.required]));
                this.newCameraForm.addControl("name", new FormControl(this.camera.name, [Validators.required]));
                this.updateCountry();
            }
            this.updateDisabledState();
        } else {
            this.newCameraForm = this.formBuilder.group({});
        }
    }

    /**
     * Updating country if phone number changes
     */
    private updateCountry() {
        this.newCameraForm.controls.phone.valueChanges.subscribe(val => {
            const phoneNumberUtil = libphonenumber.PhoneNumberUtil.getInstance();
            try {
                // phone must begin with '+'
                const numberProto = phoneNumberUtil.parse(val, "");
                const countryCode = phoneNumberUtil.getRegionCodeForNumber(numberProto);
                if (countryCode) {
                    this.newCameraForm.controls.country.setValue(countryCode);
                }
            } catch (e) {
            }
        });
    }

    /**
     * Updating disabled enabled state of timepickers.
     */
    private updateDisabledState() {
        if (this.newCameraForm.value.timelapseActive) {
            this.newCameraForm.controls["timelapseHH"].enable({emitEvent: false});
            this.newCameraForm.controls["timelapseMM"].enable({emitEvent: false});
            this.newCameraForm.controls["timelapseSS"].enable({emitEvent: false});
        } else {
            this.newCameraForm.controls["timelapseHH"].disable({emitEvent: false});
            this.newCameraForm.controls["timelapseMM"].disable({emitEvent: false});
            this.newCameraForm.controls["timelapseSS"].disable({emitEvent: false});
        }
        if (this.newCameraForm.value.delayActive) {
            this.newCameraForm.controls["delayHH"].enable({emitEvent: false});
            this.newCameraForm.controls["delayMM"].enable({emitEvent: false});
            this.newCameraForm.controls["delaySS"].enable({emitEvent: false});
        } else {
            this.newCameraForm.controls["delayHH"].disable({emitEvent: false});
            this.newCameraForm.controls["delayMM"].disable({emitEvent: false});
            this.newCameraForm.controls["delaySS"].disable({emitEvent: false});
        }
        if (this.newCameraForm.value.worktimer1Active) {
            this.newCameraForm.controls["worktimer1From"].enable({emitEvent: false});
            this.newCameraForm.controls["worktimer1Until"].enable({emitEvent: false});
        } else {
            this.newCameraForm.controls["worktimer1From"].disable({emitEvent: false});
            this.newCameraForm.controls["worktimer1Until"].disable({emitEvent: false});
        }
        if (this.newCameraForm.value.worktimer2Active) {
            this.newCameraForm.controls["worktimer2From"].enable({emitEvent: false});
            this.newCameraForm.controls["worktimer2Until"].enable({emitEvent: false});
        } else {
            this.newCameraForm.controls["worktimer2From"].disable({emitEvent: false});
            this.newCameraForm.controls["worktimer2Until"].disable({emitEvent: false});
        }
    }

    private numberEnumsToISelectObj(T: any, list: ISelectObj[]) {
        for (const value in T) {
            if (typeof T[value] === "number") {
                list.push({
                    key: T[value],
                    value: this.translateService.instant(`camerasettings.enums.${value}`)
                });
            }
        }
    }

    public onChange() {
        this.newCameraForm.valueChanges.subscribe(val => {
            this.cameraSettings.cameraMode = val.cameramode;
            this.cameraSettings.sdSycle = val.sdsycle;
            this.cameraSettings.pictureSize = val.picturesize;
            this.updateMultiShots(val.multishot, val.multishotSend);
            this.cameraSettings.flashLED = val.flashled;
            this.cameraSettings.nightMode = val.nightmode;
            this.cameraSettings.videoSize = val.videosize;
            this.cameraSettings.videoLength = val.videoLength;
            this.cameraSettings.pirSensitivity = val.sensitivity;
            this.cameraSettings.pirSwitch = val.movementtracking ? 0 : 1;
            this.validateTimelapse(val);
            this.cameraSettings.timeLapseActivated = val.timelapseActive;
            this.validateDelay(val);
            this.cameraSettings.delayActivated = val.delayActive;
            this.checkValidWorkTimer1(val, this.cameraSettings.workTimer1Activated !== val.worktimer1Active);
            this.cameraSettings.workTimer1Activated = val.worktimer1Active;
            this.checkValidWorkTimer2(val, this.cameraSettings.workTimer2Activated !== val.worktimer2Active);
            this.cameraSettings.workTimer2Activated = val.worktimer2Active;
            this.updateDisabledState();
            if ( this.camera ) {
                this.phoneChanged = !this.phoneChanged ? this.camera.phone !== val.phone || this.camera.phoneRegion !== val.country : this.phoneChanged;
                this.camera.phone = val.phone;
                this.camera.phoneRegion = val.country;
                this.camera.name = val.name;
            }
            this.notifyChanges();
        });
    }

    private updateMultiShots(multishot: any, multishotSend: any) {
        if (multishotSend > multishot) {
            this.cameraSettings.multiShot = multishot;
            this.cameraSettings.multishotUpload = multishot;
            this.newCameraForm.controls["multishotSend"].setValue(multishot, {emitEvent: false});

        } else {
            this.cameraSettings.multiShot = multishot;
            this.cameraSettings.multishotUpload = multishotSend;
        }

        this.availableMultiShotUploads =  this.getAvailableOptionsMultiShotSend();
    }

    public getAvailableOptionsMultiShotSend(): ISelectObj[] {
        const multiShotUploadOptions: ISelectObj[] = [];

        if (this.cameraSettings) {
            for (const value in MultiShot) {
                if (typeof MultiShot[value] === "number" && Number(MultiShot[value]) <= Number(this.cameraSettings.multiShot)) {
                    if (this.camera) {
                        if ((this.camera.hasReducedMultishotUpload() && Number(MultiShot[value]) <= 2) || !this.camera.hasReducedMultishotUpload()) {
                            multiShotUploadOptions.push({
                                key: MultiShot[value],
                                value: this.translateService.instant(`camerasettings.enums.${value}`)
                            });
                        }
                    } else {
                        multiShotUploadOptions.push({
                            key: MultiShot[value],
                            value: this.translateService.instant(`camerasettings.enums.${value}`)
                        });
                    }
                }
            }
        }
        return multiShotUploadOptions;
    }

    /**
     * Returns the biggest possible option for multi shot upload.
     */
    private getBiggestAvailableMultishotSend(): ISelectObj {
        return this.availableMultiShotUploads.sort((a: ISelectObj, b: ISelectObj) => {
                if (Number(a.key) < Number(b.key)) {
                    return 1;
                }
                if (Number(a.key) > Number(b.key)) {
                    return -1;
                }
                return 0;
            })[0];
    }

    private validateTimelapse(val: any) {
        const validationObj: ITimeControlValidationObj = {
            hhControlName: "timelapseHH",
            mmControlName: "timelapseMM",
            ssControlName: "timelapseSS",
            isActive: val.timelapseActive,
            storedValue: this.cameraSettings.timeLapse,
            minValueSS: this.minValueTimelapse,
            maxValueHH: 24,
            maxValueMM: 59,
            maxValueSS: 59,
            currentHHValue: val.timelapseHH,
            currentMMValue: val.timelapseMM,
            currentSSValue: val.timelapseSS
        };
        const result = this.checkValidTime(validationObj, this.cameraSettings.timeLapseActivated !== val.timelapseActive);
        this.timelapseValid = result.isValid;
        if (result.value && this.cameraSettings) {
            this.cameraSettings.timeLapse = result.value;
        }
    }

    private validateDelay(val: any) {
        const validationObj: ITimeControlValidationObj = {
            hhControlName: "delayHH",
            mmControlName: "delayMM",
            ssControlName: "delaySS",
            isActive: val.delayActive,
            storedValue: this.cameraSettings.delay,
            minValueSS: this.minValueDelay,
            maxValueHH: 23,
            maxValueMM: 59,
            maxValueSS: 59,
            currentHHValue: val.delayHH,
            currentMMValue: val.delayMM,
            currentSSValue: val.delaySS
        };
        const result = this.checkValidTime(validationObj, this.cameraSettings.delayActivated !== val.delayActive);
        this.delayValid = result.isValid;
        if (result.value) {
            this.cameraSettings.delay = result.value;
        }
    }

    /**
     * Notifiys parent component about setting changes.
     */

    private notifyChanges() {
        if (this.phoneChanged) {
            this.notify.emit( {
                settings: this.cameraSettings,
                isValid: this.newCameraForm.valid && this.timelapseValid && this.delayValid && this.workTimer1Valid && this.workTimer2Valid,
                camera: this.camera
            });
        } else {
            this.notify.emit( {
                settings: this.cameraSettings,
                isValid: this.newCameraForm.valid && this.timelapseValid && this.delayValid && this.workTimer1Valid && this.workTimer2Valid
            });
        }
    }

    private checkValidTime(validationObj: ITimeControlValidationObj, shouldReset: boolean): ITimeValidationResult {
        if (validationObj.isActive) {
            if (shouldReset) {
                const stored = validationObj.storedValue.split(":");
                if (stored.length >= 3) {
                    validationObj.currentHHValue = stored[0];
                    validationObj.currentMMValue = stored[1];
                    validationObj.currentSSValue = stored[2];
                } else {
                    validationObj.currentHHValue = "00";
                    validationObj.currentMMValue = "00";
                    validationObj.currentSSValue = `${validationObj.minValueSS}`;
                }

                this.newCameraForm.controls[validationObj.hhControlName].setValue(validationObj.currentHHValue, {emitEvent: false});
                this.newCameraForm.controls[validationObj.mmControlName].setValue(validationObj.currentMMValue, {emitEvent: false});
                this.newCameraForm.controls[validationObj.ssControlName].setValue(validationObj.currentSSValue, {emitEvent: false});
            }

            if (!validationObj.currentHHValue[1]) {
                validationObj.currentHHValue = `0${validationObj.currentHHValue}`;
            }
            if (!validationObj.currentMMValue[1]) {
                validationObj.currentMMValue = `0${validationObj.currentMMValue}`;
            }
            if (!validationObj.currentSSValue[1]) {
                validationObj.currentSSValue = `0${validationObj.currentSSValue}`;
            }
            const time = validationObj.currentHHValue + validationObj.currentMMValue + validationObj.currentSSValue;
            if (Number(validationObj.currentHHValue) <= validationObj.maxValueHH
                && Number(validationObj.currentMMValue) <= validationObj.maxValueMM
                && Number(validationObj.currentSSValue) <= validationObj.maxValueSS
                && Number(time) <= 240000 && Number(time) >= validationObj.minValueSS) {
                const value = `${validationObj.currentHHValue}:${validationObj.currentMMValue}:${validationObj.currentSSValue}`;
                return {isValid: true, value: value};
            } else {
                return {isValid: false};
            }
        } else {
            const valid = !validationObj.isActive ||
                (validationObj.currentHHValue !== undefined && validationObj.currentMMValue !== undefined && validationObj.currentSSValue !== undefined);
            return {isValid: valid};
        }
    }

    private checkValidWorkTimer1(val: any, shouldReset: boolean) {
        if (val.worktimer1Active) {
            if (shouldReset) {
                val.worktimer1From = moment(this.cameraSettings.workTimer1, "HH:mm").toDate();
                val.worktimer1Until = moment(this.cameraSettings.workTimer1end, "HH:mm").toDate();
            }
            if (val.worktimer1From && val.worktimer1Until) {
                const isValid = moment(val.worktimer1From).isValid() && moment(val.worktimer1Until).isValid();
                this.workTimer1Valid = isValid;
                if (isValid) {
                    this.cameraSettings.workTimer1 = moment(val.worktimer1From).format("HH:mm");
                    this.cameraSettings.workTimer1end = moment(val.worktimer1Until).format("HH:mm");
                }
            }
        } else {
            this.workTimer1Valid = !val.worktimer1Active || (val.worktimer1From && val.worktimer1Until);
        }
    }

    private checkValidWorkTimer2(val: any, shouldReset: boolean) {
        if (val.worktimer2Active) {
            if (shouldReset) {
                val.worktimer2From = moment(this.cameraSettings.workTimer2, "HH:mm").toDate();
                val.worktimer2Until = moment(this.cameraSettings.workTimer2end, "HH:mm").toDate();
            }
            if (val.worktimer2From && val.worktimer2Until) {
                const isValid = moment(val.worktimer2From).isValid() && moment(val.worktimer2Until).isValid();
                this.workTimer2Valid = isValid;
                if (isValid) {
                    this.cameraSettings.workTimer2 = moment(val.worktimer2From).format("HH:mm");
                    this.cameraSettings.workTimer2end = moment(val.worktimer2Until).format("HH:mm");
                }
            }
        } else {
            this.workTimer2Valid = !val.worktimer2Active || (val.worktimer2From && val.worktimer2Until);
        }
    }

    public onBlur() {
        this.blurTimeout = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 200);
    }

    public onFocus() {
        clearTimeout(this.blurTimeout);
    }
}
