import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { BaseComponent } from "../../../base/base.component";
import { DialogService } from "src/app/services/DialogService";

interface AdoptsubscriptionsdialogData {
    transactions: string[];
}

@Component({
    selector: "app-adoptsubscriptionsdialog",
    templateUrl: "./adoptsubscriptionsdialog.component.html",
    styleUrls: ["./adoptsubscriptionsdialog.component.scss"]
})
export class AdoptSubscriptionsDialogComponent extends BaseComponent implements OnInit {
    loginForm: FormGroup;
    submitted = false;
    passwordValue = "";

    constructor(public dialogRef: MatDialogRef<AdoptsubscriptionsdialogData>,
        @Inject(MAT_DIALOG_DATA) public data: AdoptsubscriptionsdialogData,
        private formBuilder: FormBuilder, public dialog: MatDialog, dialogService: DialogService) {
        super(dialog, dialogService);
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            mail: ["", [Validators.required, Validators.email]],
            password: ["", Validators.required]
        });
    }

    public cancelButtonPressed() {
        this.dialogRef.close();
    }

    public confirm() {
        this.submitted = true;
        if (this.loginForm.valid) {
            this.dialogRef.close({mail: this.loginForm.controls.mail.value, password: this.loginForm.controls.password.value});
        }
    }
}
