import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

declare var window: {plugins: {
    impacTracking: {
        canRequestTracking: (callback: (result: boolean) => void) => void,
        trackingAvailable: (callback: (result: boolean) => void) => void
        requestTracking: (info: string | undefined, callback: (result: boolean) => void, errorCallback: (error: any) => void) => void
    }
}};

export interface TrackingRequestInfo {
    primaryColor: string;
    secondaryColor: string;
    onPrimaryColor: string;
    onSecondaryColor: string;
    title: string;
    text?: string;
    subText: string;
    buttonTitle: string;
    reasons: TrackingRequestReason[];
}

export interface TrackingRequestReason {
    text: string;
    image: string;
    tintImage: boolean;
}

/**
 * Implements cordova plugin tracking transparency. ONLY available for iOS.
 */

@Injectable()
export class ATTTrackingService {

    public async requestTracking(info: TrackingRequestInfo | undefined): Promise<boolean> {
        const available = await this.trackingAvailable();
        if (available) {
            return available;
        } else if (await this.canRequestTracking()) {
            return await this.request(info);
        } else {
            return false;
        }
    }

    async trackingAvailable(): Promise<boolean> {
        const sub = new Subject<boolean>();
        window.plugins.impacTracking.trackingAvailable((result) => {
            sub.next(result);
            sub.complete();
        });
        return sub.toPromise();
    }

    private async request(info: TrackingRequestInfo | undefined): Promise<boolean> {
        const sub = new Subject<boolean>();
        window.plugins.impacTracking.requestTracking(JSON.stringify(info), (result) => {
            sub.next(result);
            sub.complete();
        }, (error) => {
            console.log(error);
            sub.next(false);
            sub.complete();
        });
        return sub.toPromise();
    }

    private async canRequestTracking(): Promise<boolean> {
        const sub = new Subject<boolean>();
        window.plugins.impacTracking.canRequestTracking((result) => {
            sub.next(result);
            sub.complete();
        });
        return sub.toPromise();
    }

}
