import { NgZone, Injectable } from "@angular/core";

declare var window: {cordova: {plugins: {settings: {open: (constant: string, success_callback: (result: any) => void, failure_callback: (err: any) => void) => void} }}};

@Injectable()
export class SettingsService {
    constructor(private zone: NgZone) { }

    public openAppSettings() {
        window.cordova.plugins.settings.open("application_details", (result) => {
        }, (err) => {
            console.log(err);
        });
    }
}
