<app-toolbar [name]="'navigation.abo'" [leftBarButton]="menuBarButton" [rightBarButtons]="[rightBarButton]"></app-toolbar>
<mat-sidenav-container class="container-overflow-scroll">
    <div *ngIf="isOnline()" class="container-fluid">
        <div *ngIf="!isWeb()" class="row">
            <div class="col-12 mt-3 descriptionInfo">{{ 'abo.info' | translate }}</div>
        </div>  
        <div *ngIf="isWeb()" class="row">
            <div class="col-12 backgroundBanner">
                <h3 class="text-white bannerText p-3 pb-5">{{ 'abo.banner' | translate }}</h3>            
            </div>
        </div>
        <div class="row">
            <div class="col col-12 col-md-6 col-xl-4 py-3">
                <mat-card class=" w-100 d-flex align-items-center justify-content-center flex-column pt-0">
                    <mat-card-header class="qualityHeader">
                        <mat-card-title>{{ 'abo.hdQuality' | translate }}</mat-card-title>
                    </mat-card-header>
                    <img mat-card-image src="assets/img/quality_hd.jpg" class="p-3 mb-0 rounded-img" alt="Image representing high quality image">
                    <mat-card-content class="w-100">
                        <div  class="pb-1 small-abo">{{'abo.hdShortDescription' | translate}}</div>
                        <div  class="py-1 small-abo">{{'abo.hdPriceInfo' | translate}}</div>
                        <div  class="py-1 small-abo">{{'abo.hdAboConditions' | translate}}</div>
                        <div  class="py-1 small-abo">{{'abo.hdDescription' | translate}}</div>
                    </mat-card-content>
                    <mat-card-content *ngIf="hasHDAbo() && !isFreeUser()" class="w-100 text-secondary">
                        <div  class="small-abo">{{'abo.isActive' | translate}}</div>
                        <div  class="small-abo">{{'abo.activeDate' | translate:aboparam}}</div>
                    </mat-card-content>
                    <mat-card-content *ngIf="hasCanceledHDAbo() && !isFreeUser()" class="w-100 text-secondary">
                        <div  class="small-abo">{{'abo.isCanceled' | translate}}</div>
                        <div  class="small-abo">{{'abo.deleteDate' | translate:aboparam}}</div>
                    </mat-card-content>
                    <mat-card-content *ngIf="textForGoogleHDStatus()" class="w-100 text-secondary">
                        <div  class="small-abo">{{textForGoogleHDStatus()}}</div>
                    </mat-card-content>
                    <mat-card-actions *ngIf="isWeb() && !hasHDAbo()" class="mat-actions">
                        <button [disabled]="isFreeUser()" class="signInButton rounded m-2 mat-card-button" mat-raised-button color="primary" (click)="aboHD()">{{ 'abo.aboHdButton' | translate }}</button>
                    </mat-card-actions>
                    <mat-card-actions *ngIf="!isWeb() && !hasHDAbo() && canMakePurchase" class="mat-actions">
                        <button [disabled]="isFreeUser()" class="signInButton rounded m-2 mat-card-button" mat-raised-button color="primary" (click)="inAppAboHD()">{{ 'abo.aboHdButton' | translate }}</button>
                    </mat-card-actions>
                    <mat-card-actions *ngIf="hasHDAbo()" class="mat-actions"> 
                        <button class="signInButton rounded m-2 mat-card-button" mat-raised-button color="primary" (click)="showCameraManagement()">{{ 'abo.manageHdCameras' | translate }}</button>
                    </mat-card-actions>
                    <mat-card-content class="cursor-pointer w-100 d-flex justify-content-around" (click)="showPolicy()" *ngIf="!hasHDAbo()">
                        <div class="smaller-text" (click)="showTerms()">{{'abos.privacyterms' | translate}}</div>
                        <div class="smaller-text">|</div>
                        <div class="smaller-text" (click)="showPolicy()">{{'abos.datapolicy' | translate}}</div> 
                    </mat-card-content>
                </mat-card>
            </div>

            <div *ngIf="isWeb()" class="col-12 col-md-6 col-xl-4 py-3">
                <mat-card class=" w-100 d-flex align-items-center justify-content-center flex-column pt-0">
                    <mat-card-header class="qualityHeader">
                        <mat-card-title>{{ 'abo.sdQuality' | translate }}</mat-card-title>
                    </mat-card-header>
                    <img mat-card-image src="assets/img/quality_sd.jpg" class="p-3 rounded-img" alt="Image representing low quality image">
                    <mat-card-content class="w-100">
                        <div  class="pb-1 small-abo">{{'abo.sdShortDescription' | translate}}</div>
                        <div  class="py-1 small-abo">{{'abo.sdPriceInfo' | translate}}</div>
                        <div  class="py-1 small-abo">{{'abo.sdDescription' | translate}}</div>
                    </mat-card-content>
                    <mat-card-actions class="mat-actions">
                        <button *ngIf="hasHDAbo() && canBeCancelled(currentAbos.highres)" [disabled]="isFreeAbo()" class="cancelBtn signInButton rounded m-2 mat-card-button" mat-raised-button (click)="aboSD()">{{ 'abo.aboSdButton' | translate }}</button>
                    </mat-card-actions>
                    
                </mat-card>
            </div>
            <div *ngIf="isWeb()" class="col-12 col-md-6 col-xl-4">
                <div class="row">
                    <div class="col-12 py-3">
                        <mat-card class=" w-100 d-flex align-items-center justify-content-center flex-column">
                            <mat-card-header>
                                <mat-card-title>{{ 'abo.space' | translate }}</mat-card-title>
                            </mat-card-header>
                            <mat-card-content class="w-100">
                                <div  class="py-1 small-abo">{{'abo.currentSpace' | translate}}</div>
                                <div class="d-flex align-items-center">
                                    <div  class="py-1 small-abo">{{getCurrentQuota()}}</div>
                                    <mat-progress-bar class="mx-3" mode="determinate" color="{{getCurrentQuotaSlider() > 80 ? 'warn' : 'primary'}}" value="{{getCurrentQuotaSlider()}}"></mat-progress-bar>
                                    <div  class="py-1 small-abo">{{getQuotaMax()}}</div>
                                </div>                            
                                <div  class="py-1 small-abo">{{'abo.quotaDescription' | translate}}</div>
                            </mat-card-content>
                            <mat-card-actions class="mat-actions">
                                <button class="signInButton rounded m-2 mat-card-button" mat-raised-button color="primary" (click)="aboQuota()">{{ 'abo.raiseQuota' | translate }}</button>
                                <button *ngIf="hasActiveWebPayedQuotaAbo()" class="signInButton rounded m-2 mat-card-button" mat-raised-button (click)="cancelQuota()">{{ 'abo.reduceQuota' | translate }}</button>
                            </mat-card-actions>
                        </mat-card>
                    </div>
                    <div class="col-12 position-relative pb-3">
                        <mat-card class=" w-100 d-flex align-items-center justify-content-center flex-column" *ngIf="user">
                            <form [formGroup]="userInfoForm" class="w-100" (ngSubmit)="updateUserInformations()">
                                <mat-card-header>
                                    <mat-card-title>{{ 'abo.user' | translate }}</mat-card-title>
                                </mat-card-header>
                                <mat-form-field class="doerrInput w-100 text-dark">
                                    <mat-label>{{ 'abo.mail' | translate }}</mat-label>
                                    <input matInput type="email" placeholder="{{'abo.mailPlaceholder' | translate}}" formControlName="mail"  (blur)="onBlur()">
                                    <mat-error>{{ 'shared.validMail' | translate }}</mat-error>
                                </mat-form-field>
                                <div formGroupName="passwords" class="equalInput">
                                    <mat-form-field class="doerrInput w-100 text-dark">
                                        <mat-label>{{ 'abo.newPassword' | translate }}</mat-label>
                                        <input matInput type="password" placeholder="{{'shared.passwordPlaceholder' | translate}}" formControlName="password" (blur)="onBlur()">
                                        <mat-error>{{ 'registration.requiredPassword' | translate }}</mat-error>
                                    </mat-form-field>
                                    <mat-form-field class="doerrInput w-100 text-dark" >
                                        <mat-label>{{ 'abo.newPasswordRepeat' | translate }}</mat-label>
                                        <input matInput type="password" placeholder="{{'registration.passwordRepeatPlaceholder' | translate}}" formControlName="passwordRepeat" (blur)="onBlur()">
                                        <mat-error>{{ 'registration.notEqual' | translate }}</mat-error>
                                    </mat-form-field>
                                </div>
                                <mat-form-field class="doerrInput w-100 text-dark">
                                    <mat-label>{{ 'abo.registrationDate' | translate }}</mat-label>
                                    <input matInput type="text" readonly [value]="user.created | date:'longDate'" [readonly]="true">
                                </mat-form-field>
                                <mat-card-actions class="mat-actions ml-0 mb-0 pb-0">
                                    <button class="signInButton rounded m-2 mat-card-button"  mat-raised-button color="primary">{{ 'abo.changeUserInfos' | translate }}</button>                 
                                </mat-card-actions>
                            </form>                            
                        </mat-card>
                    </div>
                </div>
            </div>            
            <div *ngIf="!isWeb()" class="col-12 col-md-6 col-xl-4 py-3">
                <mat-card class=" w-100 d-flex align-items-center justify-content-center flex-column">
                    <mat-card-header>
                        <mat-card-title>{{ 'abo.abosTitle' | translate }}</mat-card-title>
                    </mat-card-header>
                    <mat-form-field class="doerrInput w-100 text-dark">
                        <mat-label>{{ 'abo.hdQuality' | translate }}</mat-label>
                        <input matInput type="text" [(ngModel)]="bookedQuality" [readonly]="true">
                        <mat-hint *ngIf="hasHDAbo()">{{'abo.activeDate' | translate:aboparam}}</mat-hint>
                    </mat-form-field>
                    <mat-card-content class="w-100 pt-4">
                        <div  class="py-1 small-abo">{{'abo.currentSpace' | translate}}</div>
                        <div class="d-flex align-items-center">
                            <div  class="py-1 small-abo">{{getCurrentQuota()}}</div>
                            <mat-progress-bar class="mx-3" mode="determinate" color="{{getCurrentQuotaSlider() > 80 ? 'warn' : 'primary'}}" value="{{getCurrentQuotaSlider()}}"></mat-progress-bar>
                            <div  class="py-1 small-abo">{{getQuotaMax()}}</div>
                        </div>                            
                        <div  class="py-1 small-abo">{{'abo.quotaDescriptionApp' | translate}}</div>
                    </mat-card-content>
                    <mat-card-actions class="mat-actions">
                        <button *ngIf="canMakePurchase" class="signInButton rounded m-2 mat-card-button" mat-raised-button color="primary" (click)="inAppQuotaAbo()">{{ 'abo.inAppQuotaAboButton' | translate }}</button>
                    </mat-card-actions>
                </mat-card>
            </div>
            <div *ngIf="!isWeb()" class="col-12 col-md-6 col-xl-4 py-3">
                <mat-card class=" w-100 d-flex align-items-center justify-content-center flex-column" *ngIf="user">
                    <form [formGroup]="userInfoForm" class="w-100" (ngSubmit)="updateUserInformations()">
                        <mat-card-header>
                            <mat-card-title>{{ 'abo.user' | translate }}</mat-card-title>
                        </mat-card-header>
                        <mat-form-field class="doerrInput w-100 text-dark">
                            <mat-label>{{ 'abo.mail' | translate }}</mat-label>
                            <input matInput type="email" placeholder="{{'abo.mailPlaceholder' | translate}}" formControlName="mail"  (blur)="onBlur()">
                            <mat-error>{{ 'shared.validMail' | translate }}</mat-error>
                        </mat-form-field>
                        <div formGroupName="passwords" class="equalInput">
                            <mat-form-field class="doerrInput w-100 text-dark">
                                <mat-label>{{ 'abo.newPassword' | translate }}</mat-label>
                                <input matInput type="password" placeholder="{{'shared.passwordPlaceholder' | translate}}" formControlName="password" (blur)="onBlur()">
                                <mat-error>{{ 'registration.requiredPassword' | translate }}</mat-error>
                            </mat-form-field>
                            <mat-form-field class="doerrInput w-100 text-dark" >
                                <mat-label>{{ 'abo.newPasswordRepeat' | translate }}</mat-label>
                                <input matInput type="password" placeholder="{{'registration.passwordRepeatPlaceholder' | translate}}" formControlName="passwordRepeat" (blur)="onBlur()">
                                <mat-error>{{ 'registration.notEqual' | translate }}</mat-error>
                            </mat-form-field>
                        </div>
                        <mat-form-field class="doerrInput w-100 text-dark">
                            <mat-label>{{ 'abo.registrationDate' | translate }}</mat-label>
                            <input matInput type="text" readonly [value]="user.created | date:'longDate'" [readonly]="true">
                        </mat-form-field>
                        <mat-card-actions class="mat-actions ml-0 mb-0 pb-0">
                            <button class="signInButton rounded m-2 mat-card-button"  mat-raised-button color="primary">{{ 'abo.changeUserInfos' | translate }}</button>                 
                        </mat-card-actions>
                    </form>                            
                </mat-card>
            </div>
        </div>
    </div>
    <div *ngIf="!isOnline()" class="container pt-5 text-center">
        <div class="p-1">{{ 'shared.offline' | translate}}</div>
    </div>    
</mat-sidenav-container>