<app-toolbar [name]="title" [leftBarButton]="backBarButton" [rightBarButtons]="showButtonIfOwner()"></app-toolbar>
<div class="container-fluid container-overflow-scroll container-safe-area-bottom d-flex flex-column align-items-stretch">
     
    <div class="row justify-content-center" [ngClass]="{'h-sm-75': !canDisplayImageZoom, 'h-100': canDisplayImageZoom && !isHandset, 'h-75': canDisplayImageZoom && isHandset}">
        <div class="col-12 px-0 d-flex" [ngClass]="{'h-sm-75': !canDisplayImageZoom, 'h-75': canDisplayImageZoom }">
            <app-mobile-image-control #imageControl *ngIf="canDisplayImageZoom" [image]="getImgFile()" (changeImage)="changeImg($event)"></app-mobile-image-control>
            <app-image-control *ngIf="!canDisplayImageZoom" [image]="getImgFile()" [showNextBtn]="index < images.length - 1" [showPreviousBtn]="index != 0" (changeImage)="changeImg($event)"></app-image-control>
        </div>
        <div class="col-12 col-md-8 col-lg-6 col-xl-4 pt-20">
            <div class="row" (click)="showCameraImages()">
                <div class="col-2 d-flex flex-column justify-content-center">
                    <img class="scaleImg" srcset="assets/img/doerrCameraImageAvatar@3x.jpg 2x, assets/img/doerrCameraImageAvatar@3x.jpg 2x" alt="">
                </div>                
                <div class="col-10">
                    <div>{{imageMeta.camera.name}}</div>
                    <div class="descriptionText">{{ 'imageDetail.date' | translate }}: {{imageMeta.uploaded | date : "dd.MM.y  HH:mm" }}</div>
                </div>
            </div>
            <form [formGroup]="imageForm" class="d-flex flex-column justify-content-between align-items-center w-100">                
                <div class="inputContainer">
                    <mat-form-field appearance="fill" class="doerrInput" *ngIf="this.canEditImg()">
                        <input matInput type="name" placeholder="{{'imageDetail.name' | translate}}" formControlName="name" (blur)="onBlur()">
                        <mat-error>{{ 'imageDetail.validName' | translate }}</mat-error>
                    </mat-form-field>
                </div>                      
            </form>
            <div *ngIf="!this.canEditImg()">{{imageMeta.readableName}}</div>
        </div>        
    </div>
    <div class="row justify-content-center mt-auto">
        <div class="col-6 col-md-5 col-lg-3 col-xl-2 mb-3" >
            <button *ngIf="this.canEditImg()" class="signInButton" mat-raised-button color="warn" (click)="deleteImage()">{{'imageDetail.delete' | translate }}</button>
        </div>
        <div *ngIf="isWeb() && this.canEditImg()" class="col-6 col-md-5 col-lg-3 col-xl-2 mb-3">
            <button class="signInButton" mat-raised-button color="primary" (click)="storeImage()">{{'shared.store' | translate }}</button>
        </div>
    </div>
</div>
