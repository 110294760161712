<app-toolbar [name]="'update.title'" [leftBarButton]="backBarButton"></app-toolbar>
<div class="container-overflow-scroll">
    <div class="container-fluid container-safe-area-bottom d-flex flex-column align-items-stretch minHeight">
        <div class="row justify-content-center">
            <div class="col-1 step-number mt-3 ">3</div>
            <div class="col-10 mt-3">
                <div class="text-center descriptionText">{{'update3.overviewTitle' | translate}}</div>
                <div class="text-center primTextColor font-weight-bold">{{'update3.overviewSubtitle' | translate}}</div>                
            </div>
            <div class="col-1 mt-3"></div>
            <div class="col-1 col-lg-2 col-xl-3 mt-3"></div>
            <div class="col-10 col-md-7 col-lg-6 col-xl-5 mt-3">
                <div class="text-center pt-3 descriptionText" [innerHTML]="'update3.messageGoToFirmwareUpdate' | translate"></div>
            </div>
            <div class="col-1 col-lg-2 col-xl-3 mt-3"></div>
            <div class="col-10 col-md-7 col-lg-6 col-xl-5 align-self-center my-3">
                <img class="w-100" src="assets/img/update_step_4_1@2x_{{language}}.jpg" srcset="assets/img/update_step_4_1@800w_{{language}}.jpg 800w, assets/img/update_step_4_1@1200w_{{language}}.jpg 1200w" alt="">
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-1 col-lg-3"></div>
            <div class="col-10 col-md-7 col-lg-6 col-xl-5 mt-3">
                <div class="text-center descriptionText" [innerHTML]="'update3.messageStartFirmwareUpdate' | translate"></div>
            </div>
            <div class="col-1 col-lg-3"></div>
            <div class="col-10 col-md-7 col-lg-6 col-xl-5 align-self-center my-3">
                <img class="w-100" src="assets/img/update_step_4_2@2x_{{language}}.jpg" srcset="assets/img/update_step_4_2@800w_{{language}}.jpg 800w, assets/img/update_step_4_2@1200w_{{language}}.jpg 1200w" alt="">
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-1 col-lg-3"></div>
            <div class="col-10 col-md-7 col-lg-6 col-xl-5 mt-3">
                <div class="text-center descriptionText" [innerHTML]="'update3.messageFinishFirmwareUpdate' | translate"></div>
            </div>
            <div class="col-1 col-lg-3"></div>
            <div class="col-10 col-md-7 col-lg-6 col-xl-5 align-self-center my-3">
                <img class="w-100" src="assets/img/update_step_4_3@2x_{{language}}.jpg" srcset="assets/img/update_step_4_3@800w_{{language}}.jpg 800w, assets/img/update_step_4_3@1200w_{{language}}.jpg 1200w" alt="">
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-1 col-lg-3"></div>
            <div class="col-10 col-md-7 col-lg-6 col-xl-5 mt-3">
                <div class="text-center descriptionText" [innerHTML]="'update3.messageReconnectUpdatedCamera' | translate"></div>
            </div>
            <div class="col-1 col-lg-3"></div>
            <div class="col-10 col-md-7 col-lg-6 col-xl-5 align-self-center my-3">
                <img class="w-100" src="assets/img/update_step_4_4@2x_{{language}}.jpg" srcset="assets/img/update_step_4_4@800w_{{language}}.jpg 800w, assets/img/update_step_4_4@1200w_{{language}}.jpg 1200w" alt="">
            </div>
        </div>
        <div class="row justify-content-center mt-auto">
            <div class="col-12 col-md-6 col-lg-3 mb-3 py-1">
                <button class="signInButton" mat-raised-button color="primary" (click)="finishUpdate()">{{'update3.finish' | translate }}</button>
            </div>
        </div>
        
    </div>
</div>
