import { ImageRestService } from "./http/ImageRestService";
import { Observable, Subject } from "rxjs";
import { Injectable } from "@angular/core";
import { IMAGE_TYPE } from "../api-handling/models/enums/ImageType";
import { max } from "moment";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";

interface LoadProcess {
    loadingProcess: ImageProcess;
    waiting: Subject<any>;
}

interface ImageProcess {
    observable: Observable<any>;
    imgId: string;
}

@Injectable({
    providedIn: "root"
})

/**
 * This Service controls loading img files from the backend. With maxRunning you can define how many paralle downloads should run.
 */
export class ImageLoadingService {

    /**
     * Current running download processes.
     */
    private runningProcesses: ImageProcess[] = [];

    private waitingProcesses: LoadProcess[] = [];

    /**
     * Maximum of parallel download processes.
     */
    private maxRunning = 1;

    constructor(private imageRestService: ImageRestService, private translate: TranslateService) {
        this.maxRunning = environment.maxParallelDownloads;
    }

    /**
     * Downloads image file from server. Note that only a fix number of parallel downloads are possible.
     * @param imgId Image which should be downloaded.
     * @param type type of the image to download.
     */
    public async loadImgFile(imgId: string, type: IMAGE_TYPE): Promise<Blob | null> {
        const observable = this.getObservable(imgId, type);
        if (this.runningProcesses.length >= this.maxRunning) {
            const sub = new Subject<Blob>();
            this.waitingProcesses.push({loadingProcess: {observable: observable, imgId: imgId}, waiting: sub});
            return sub.toPromise();
        } else {
            return await this.run({observable: observable, imgId: imgId});
        }
    }

    private async updateRequests() {
        const nextObs = this.waitingProcesses.shift();
        if (nextObs) {
            const result = await this.run(nextObs.loadingProcess);
            nextObs.waiting.next(result);
            nextObs.waiting.complete();
        }
    }

    /**
     * Run Download Observable
     * @param observable observable to run.
     */
    private async run(process: ImageProcess): Promise<Blob | null> {
        this.runningProcesses.push(process);
        try {
            const result = await process.observable.toPromise();
            return result;
        } catch (error) {
            console.log(error);
        } finally {
            const index = this.runningProcesses.indexOf(process, 0);
            this.runningProcesses.splice(index, 1);
            this.updateRequests();
        }
        return null;
    }

    private getObservable(imgId: string, type: IMAGE_TYPE): Observable<Blob | null> {
        return this.imageRestService.getImage(imgId, type === IMAGE_TYPE.thumbnail);
    }
}
