import { Injectable, NgZone } from "@angular/core";
import { environment } from "src/environments/environment";
import { Subject, BehaviorSubject, Observable } from "rxjs";
import { InAppProduct } from "../models/InAppProduct";
import { plainToClass } from "class-transformer";
import { JSONService } from "../services/JSONService";
import { DeviceDetector } from "../helpers/DeviceDetector";
import { AppleTransferModel } from "../api-handling/models/AppleTransferModel";
import { AppleSubscriptionRestService } from "../services/http/AppleSubscriptionRestService";
import { map } from "rxjs";
import { InAppPaymentStatus } from "../enums/InAppPaymentStatus";


class UpdateMessage {
    public products: InAppProduct[];
    public status: string;
    public description: string;
    public transactions: string[] | undefined;
}

declare var window: {plugins: { impacInappPayment: { setIds: (ids: string[]) => void, onUpdate: (callback: (result: any) => void, error: (result: any) => void) => void,
    getProducts: (callback: (result: any) => void, error: (result: any) => void) => void,
    restorePurchases: () => void, setValidation: (accessToken: string, url: string, authorizationType: string) => void, buyProduct: (productId: string, oldSku?: string) => void,
    refreshStatus: () => void, canMakePayments: (callback: (result: boolean) => void) => void,  manageSubscriptions: (callback: (result: any) => void, error: (result: any) => void) => void}; }; };

@Injectable({
    providedIn: "root"
})

export class InAppPaymentService  {

    private updateCallSource = new BehaviorSubject<UpdateMessage>(null);
    private errorCallSource = new BehaviorSubject<UpdateMessage>(null);
    public errorHandler = this.errorCallSource.asObservable();
    private originalTransactionIds: string[];

    constructor(private zone: NgZone, private jsonService: JSONService, private appleRestService: AppleSubscriptionRestService) {  }

    public subscribeUpdateChanged(): Observable<UpdateMessage> {
        return this.updateCallSource.asObservable();
    }

    public async getAppleIdsFor(key: string): Promise<string[]> {
        const abos = await this.jsonService.getJSON().toPromise();
        for (const abo of abos) {
            if (abo.key === key) {
                return abo.appleProductIds;
            }
        }
        return undefined;
    }

    public async getGoogleIdsFor(key: string): Promise<string[]> {
        const abos = await this.jsonService.getJSON().toPromise();
        for (const abo of abos) {
            if (abo.key === key) {
                return abo.googleProductIds;
            }
        }
        return undefined;
    }

    public refreshStatus() {
        window.plugins.impacInappPayment.refreshStatus();
    }

    public canMakePayment(): Promise<boolean> {
        const sub = new Subject<boolean>();
        window.plugins.impacInappPayment.canMakePayments((result) => {
            sub.next(result);
            sub.complete();
        });
        return sub.toPromise();
    }

    public async prepareInApp() {
        const abos = await this.jsonService.getJSON().toPromise();
        const ids: string[] = [];
        for (const abo of abos) {
            const aboIds = DeviceDetector.isOnIos() ? abo.appleProductIds : abo.googleProductIds;
            for (const id of aboIds) {
                ids.push(id);
            }
        }
        window.plugins.impacInappPayment.setIds(ids);
        this.listenOnUpdates();
    }

    public listenOnUpdates() {
        window.plugins.impacInappPayment.onUpdate((result) => {
            this.zone.run(() => {
                const json = JSON.parse(result);
                const message = plainToClass(UpdateMessage, json as any);
                if (message.transactions) {
                    this.originalTransactionIds = message.transactions;
                }
                this.updateCallSource.next(message);
            });
        }, (error) => {
            this.zone.run(() => {
                const json = JSON.parse(error);
                const message = plainToClass(UpdateMessage, json as any);
                this.errorCallSource.next(message);
            });
        });
    }

    public setValidationConfig(token: string) {
        if (DeviceDetector.isOnIos()) {
            window.plugins.impacInappPayment.setValidation(token, environment.webserviceUrl + environment.validationUrl, "Bearer");
        }
        if (DeviceDetector.isOnAndroid()) {
            window.plugins.impacInappPayment.setValidation(token, environment.webserviceUrl + environment.androidValidationUrl, "Bearer");
        }
    }

    public buyProduct(id: string, oldId?: string) {
        if (oldId) {
            window.plugins.impacInappPayment.buyProduct(id, oldId); // used for quota crossgrading on android
        } else {
            window.plugins.impacInappPayment.buyProduct(id);
        }
    }

    public restoreProducts() {
        if (DeviceDetector.isOnIos()) {
            window.plugins.impacInappPayment.restorePurchases();
        }
    }

    public getProductList(): Promise<InAppProduct[]> {
        const subject = new Subject<InAppProduct[]>();
        window.plugins.impacInappPayment.getProducts((products) => {
            const json = JSON.parse(products);
            subject.next(plainToClass(InAppProduct, json as any[]));
            subject.complete();
        }, (err) => {
            subject.error(err);
        });
        return subject.toPromise();
    }

    public transferTransactions(mail: string, password: string): Observable<boolean> {
        const model = new AppleTransferModel(mail, password, this.originalTransactionIds);
        return this.appleRestService.transferAppleSubscriptions(model).pipe( map((result) => {
            const message = new UpdateMessage();
            message.status = InAppPaymentStatus.transfered;
            this.updateCallSource.next(message);
            return result;
        }));
    }

    public openSubscriptionManagement() {
        if (DeviceDetector.isOnCordova()) {
            window.plugins.impacInappPayment.manageSubscriptions(() => {

            }, (err) => {
                console.log(err);
            });
        }
    }
}
