import { Component, OnInit, NgZone } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthRestService } from "src/app/services/http/AuthRestService";
import { TranslateService } from "@ngx-translate/core";
import { PasswordValidation } from "src/app/validators/PasswordValidation";
import { RegisterReqModel } from "src/app/api-handling/models/RegisterReqModel";
import { environment } from "src/environments/environment";
import { BaseComponent } from "../base/base.component";
import { MatDialog } from "@angular/material/dialog";
import { LanguageDetector } from "src/app/helpers/LanguageDetector";
import { DialogService } from "src/app/services/DialogService";
import { from } from "rxjs";

@Component({
    selector: "app-registration",
    templateUrl: "registration.component.html",
    styleUrls: ["registration.component.scss"]
})

export class RegistrationComponent extends BaseComponent implements OnInit {

    registrationForm: FormGroup;
    submitted = false;
    submittClicked = false;

    constructor(private router: Router, private restService: AuthRestService,
        private formBuilder: FormBuilder, private translate: TranslateService, public dialog: MatDialog,
        private zone: NgZone, dialogService: DialogService) {
            super(dialog, dialogService);
    }

    ngOnInit(): void {
        this.registrationForm = this.formBuilder.group({
            firstname: ["", [Validators.required]],
            name: ["", Validators.required],
            mail: ["", [Validators.required, Validators.email]],
            passwords: this.formBuilder.group({
                password: ["", Validators.required],
                passwordRepeat: ["", Validators.required],
            }, {
                validator: PasswordValidation.MatchPassword
            }),
            datapolicy: ["", Validators.requiredTrue]
        });
    }

    public backToLogin() {
        this.router.navigate(["signin"]);
    }

    public registerUser() {
        this.submittClicked = true;
        if ( !this.registrationForm.invalid ) {
            this.showSpinner(this.translate.instant("registration.load"), "");
            from(LanguageDetector.getLanguage()).subscribe((locale) => {
                this.submitted = true;
                const model = new RegisterReqModel();
                model.email = this.registrationForm.value.mail;
                model.firstname = this.registrationForm.value.firstname;
                model.lastname = this.registrationForm.value.name;
                const passwordGroup = this.registrationForm.value.passwords;
                model.password = passwordGroup.password;
                model.passwordConfirm = passwordGroup.passwordRepeat;
                model.language = locale ? locale : "en";
                model.organizationKey = environment.organizationKey;
                model.agbAccepted = this.registrationForm.value.datapolicy;
                this.subscriptions.push(this.restService.register(model).subscribe((response) => {
                    this.zone.run(() => {
                        this.closeSpinner();
                        this.openDialog(this.translate.instant("registration.sendRegistrationMailTitle"),
                            this.translate.instant("registration.sendRegistrationMail"),
                            null).subscribe(() => {
                                this.zone.run(() => {
                                    this.backToLogin();
                                });
                        });
                    });
                }, (err) => {
                    this.zone.run(() => {
                        this.closeSpinner();
                        this.submitted = false;
                        this.submittClicked = false;
                    });
                }));
            });
        }
    }
}
