import { Component, OnInit } from "@angular/core";
import { BaseNavComponent } from "../base/baseNav.component";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { NavigationService } from "src/app/services/NavigationService";
import { CordovaInitHandler } from "src/app/native/CordovaInitHandler";
import { CameraRestService } from "src/app/services/http/CameraRestService";
import { Location } from "@angular/common";
import { CameraRequestModel, CameraResponseModel } from "src/app/api-handling/models/CameraResponseModel";
import { TranslateService } from "@ngx-translate/core";
import { ICameraValidSettings } from "../cameraSettings/cameraSettings.component";
import { BreakpointObserver } from "@angular/cdk/layout";
import * as moment from "moment";
import { DialogService } from "src/app/services/DialogService";

@Component ({
    selector: "app-settings",
    templateUrl: "settings.component.html",
    styleUrls: ["settings.component.scss"]
})

export class SettingsComponent extends BaseNavComponent implements OnInit {

    public camera: CameraResponseModel;
    public changedSettings = false;
    private settingsValid = true;

    constructor(private activatedRoute: ActivatedRoute, dialog: MatDialog,
        navigationService: NavigationService, location: Location, initHandler: CordovaInitHandler, private router: Router,
        private cameraRestService: CameraRestService, private translate: TranslateService, breakpointObserver: BreakpointObserver, dialogService: DialogService) {
        super(dialog, navigationService, location, initHandler, breakpointObserver, dialogService);
    }

    ngOnInit(): void {
        this.loadCameraInfos();
    }

    updateOnResume() {
        this.loadCameraInfos();
    }

    private loadCameraInfos() {
        this.activatedRoute.queryParams.subscribe((params) => {
            if (params && params.id) {
                this.loadCameraInfosForId(params.id);
            }
        });
    }

    private loadCameraInfosForId(id: string) {
        this.showSpinner(this.translate.instant("settings.loadSettings"), "");
        const cameraSubscription = this.cameraRestService.getCameraDetailsById(id);
        if ( cameraSubscription ) {
            this.subscriptions.push(cameraSubscription.subscribe((cameraResult: CameraResponseModel) => {
                this.camera = cameraResult;
                this.closeSpinner();
                if (this.camera.isStolen()) {
                    this.subscriptions.push(this.openDialog(this.translate.instant("settings.stolen.title"), this.translate.instant("settings.stolen.msg"), null).subscribe(() => {
                        this.router.navigate(["home/cameras"], {replaceUrl: true});
                    }));
                }
            }, (err) => {
                this.closeSpinner();
            }));
        }
    }

    public onNotify(cameraSettings: ICameraValidSettings) {
        this.changedSettings = true;
        this.settingsValid = cameraSettings.isValid;
        if (this.settingsValid) {
            this.camera.settings = cameraSettings.settings;
        }
        if ( cameraSettings.camera ) {
            this.camera.phone = cameraSettings.camera.phone;
            this.camera.phoneRegion = cameraSettings.camera.phoneRegion;
        }
    }

    public goBackButtonPressed() {
        if (this.changedSettings) {
            if (this.settingsValid) {

                let updateCamera = { ...this.camera };
                delete updateCamera.sendPush;
                delete updateCamera.sortNumber;
                let updateCameraData: CameraRequestModel = updateCamera;

                const cameraPatch = this.cameraRestService.patchCamera(updateCameraData);
                    if ( cameraPatch ) {
                        this.subscriptions.push(cameraPatch.subscribe((result) => {
                            const diff = moment().diff(this.camera.lastDailyReport, "days");
                            const mMoment = moment(this.camera.lastDailyReport).add(diff + 1, "days");
                            this.openDialog(this.translate.instant("shared.synchronisationTitle"),
                                this.translate.instant("shared.synchronisationMsg",
                                { syncDate: mMoment.format(this.translate.instant("shared.dateFormat")), syncTime: mMoment.format(this.translate.instant("shared.timeFormat"))}),
                                [this.translate.instant("shared.ok")]).subscribe((_pressed) => {
                                super.goBackButtonPressed();
                            });
                        }, (err) => {
                            super.goBackButtonPressed();
                        }));
                    }
            } else {
                const deleteDialogObservable = this.openDialog(this.translate.instant("settings.invalidTitle"),
                this.translate.instant("settings.invalidMsg"),
                [this.translate.instant("shared.discard"), this.translate.instant("shared.ok")]);
                this.subscriptions.push(deleteDialogObservable.subscribe((buttonIndex) => {
                    if (buttonIndex !== null && buttonIndex === 0) {
                        super.goBackButtonPressed();
                    }
                }));
            }
        } else {
            super.goBackButtonPressed();
        }
    }
}
