
<app-toolbar [name]="'navigation.cameras'" [leftBarButton]="menuBarButton" [rightBarButtons]="[rightBarButton]"></app-toolbar>
<div class="container-overflow-scroll overflow-auto">
    <div>
        <div *ngIf="userCameraListItemHeaders && userCameraListItemHeaders.length > 0">
            <h2 class="cameraListTitle pl-20 pt-3">{{'cameras.titleOwnerCameras' | translate}}</h2>
            <mat-grid-list class="cameraList" [cols]="columns" rowHeight="125px">
                <mat-grid-tile class="selectableCard" *ngFor="let cameraHeader of userCameraListItemHeaders" (click)="cameraHeader.supportedProduct && onCameraClicked(cameraHeader)">
                    <app-cameralistitem [cameraListItem]="cameraHeader" [class.unsupported-product]="!cameraHeader.supportedProduct"></app-cameralistitem>
                </mat-grid-tile>
            </mat-grid-list>
        </div>
        <div *ngIf="invites && invites.length > 0">
            <h2 class="cameraListTitle pl-20 pt-3">{{'cameras.titleCameraInvitations' | translate}}</h2>
            <mat-grid-list class="cameraList" [cols]="columns" rowHeight="150px">
                <mat-grid-tile class="selectableCard" *ngFor="let invite of invites">
                    <app-cameralistitem [cameraListItem]="invite" (notify)="onButtonEvent($event)"></app-cameralistitem>
                </mat-grid-tile>
            </mat-grid-list>
        </div>
        <div *ngIf="friendCameras.length > 0">
            <h2 class="cameraListTitle pl-20 pt-3">{{'cameras.titleSharedCameras' | translate}}</h2>
            <mat-grid-list class="cameraList" [cols]="columns" rowHeight="125px">
                <mat-grid-tile class="selectableCard" *ngFor="let cameraHeader of friendCameras"  (click)="cameraHeader.supportedProduct && onCameraClicked(cameraHeader)">
                    <app-cameralistitem [cameraListItem]="cameraHeader" [class.unsupported-product]="!cameraHeader.supportedProduct"></app-cameralistitem>                    
                </mat-grid-tile>
            </mat-grid-list>
        </div>
        <div *ngIf="cameras && cameras.length == 0 && invites.length == 0 && isOnline()" class="container pt-5 text-center">
            <div class="p-1">{{ 'cameras.noCamerasAvailable' | translate}}</div>
            <button class="signInButton" mat-raised-button color="primary" (click)="addCameraButtonPressed()">{{ 'cameras.addCameraButton' | translate }}</button>
        </div>
        <div *ngIf="!isOnline()" class="container pt-5 text-center">
            <div class="p-1">{{ 'shared.offline' | translate}}</div>
        </div>
    </div>
</div>
