import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppUserModel } from "../../models/AppUserModel";
import { DeviceDetector } from "../../helpers/DeviceDetector";
import { AppUserService } from "../database/AppUserService";
import { LocalStorageService } from "../LocalStorageService";
import { PasswordReqModel } from "src/app/api-handling/models/PasswordRepModel";
import { RegisterReqModel } from "src/app/api-handling/models/RegisterReqModel";
import { ConfirmRegisterModel } from "src/app/api-handling/models/ConfirmRegisterModel";
import { Observable } from "rxjs";
import { switchMap } from "rxjs";
import { classToPlain, plainToClass } from "class-transformer";
import { BaseRestService } from "./BaseRestService";
import { InAppPaymentService } from "src/app/native/InAppPaymentService";
import { environment } from "src/environments/environment";

@Injectable()
export class AuthRestService extends BaseRestService {

    constructor(private httpClient: HttpClient, private userService: AppUserService, localStorageService: LocalStorageService,
        private inAppPaymentService: InAppPaymentService) {
        super(localStorageService);
    }

    /**
     * Sign in with oauth
     * @param mail Mail address from user
     * @param password Password from user.
     * @param callback get called if signin process finished.
     */
    public signIn(mail: string, password: string): Observable <{success: boolean}> {
        let headers = new HttpHeaders();
        headers = headers.append("Content-Type", "application/x-www-form-urlencoded");
        const body = new URLSearchParams();
        body.set("grant_type", "password");
        body.set("username", mail);
        body.set("password", password);
        let parameter = new HttpParams();
        parameter = parameter.set("o", environment.organizationKey);
        return this.httpClient.post<any>(this.webserviceUrl + "v1/auth/applogin", body.toString(), {headers: headers, params: parameter}).pipe( switchMap(async (result) => {
            const user = plainToClass(AppUserModel, result);
            if (user != null) {
                await this.storeUserData(user, password);
                return {success: true};
            } else {
                return {success: false};
            }
        }));
    }

    private async storeUserData(user: AppUserModel, password: string) {
        this.localStorageService.storeAccessInfos(user);
        if (DeviceDetector.isOnCordova()) {
            await this.userService.addAppUser(user, password);
            this.inAppPaymentService.setValidationConfig(user.accessToken);
        } else {
            return;
        }
    }

    /**
     * API Call to reset user password.
     * @param mail User mail address
     * @param language language of the user.
     */
    public resetPassword(passwordModel: PasswordReqModel): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append("Content-Type", "application/json");
        return this.httpClient.post<any>(this.webserviceUrl + "v1/resetpassword", classToPlain(passwordModel), {headers: headers});
    }

    /**
     * API Call to register user for new account.
     * @param registerModel Model for registration
     * @param callback get called
     */
    public register(registerModel: RegisterReqModel): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append("Content-Type", "application/json");
        return this.httpClient.post<any>(this.webserviceUrl + "v1/registration", classToPlain(registerModel), {headers: headers});
    }

    public confirmRegister(confirmModel: ConfirmRegisterModel): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append("Content-Type", "application/json");
        return this.httpClient.put<any>(this.webserviceUrl + "v1/registration", classToPlain(confirmModel), {headers: headers});
    }

    public revokeToken(): Observable<any> {
        const header = this.getBearerHeader();
        if ( header ) {
            return this.httpClient.post<any>(this.webserviceUrl + "v1/auth/revoke", null, {headers: header});
        } else {
            return null;
        }
    }
}
