import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CameraListItem, CameraListItemButton } from "./models/cameraListItem";

@Component({
    selector: "app-cameralistitem",
    templateUrl: "cameraListItem.component.html",
    styleUrls: ["cameraListItem.component.scss"]
})

export class CameraListItemComponent {
    @Input() cameraListItem: CameraListItem;
    @Output() notify = new EventEmitter<CameraListItemButton>();

    public onClick(button: CameraListItemButton) {
        this.notify.emit(button);
    }

    public onValChange(button: CameraListItemButton, value: boolean) {
        button.value = value;
        this.notify.emit(button);
    }
}
