import { catchError, mergeMap, Observable, Subject, of, map } from "rxjs";
import { Contact, ContactX } from "../models/Contact";
import { NgZone, Injectable } from "@angular/core";


declare var window: {ContactsX: {
    hasPermission: (onSuccess: (result: any) => void, onError: (err: any) => void) => void,
    requestPermission: (onSuccess: (result: any) => void, onError: (err: any) => void) => void,
    pick: (onSuccess: (result: ContactX) => void, onError: (err: any) => void) => void,
    find: (onSuccess: (result: [ContactX]) => void, onError: (err: any) => void, options: any) => void,
    fieldType: any
}};

@Injectable()
export class ContactService {

    constructor(private zone: NgZone) {    }

    private selectedContact: Contact | undefined;

    /**
     * Set a contact as currently selected.
     * @param contact the currently selected contact.
     */
    public selectContact(contact: Contact) {
        this.selectedContact = contact;
    }

    public getSelectedContact(): Contact | undefined {
        if (this.selectedContact) {
            const mContact = this.selectedContact;
            this.selectedContact = undefined;
            return mContact;
        }
    }

    /**
     * Method checks if the app has permission to read contacts from the device.
     * @returns a boolean that indicates if the app has permission.
     */
    private hasPermission(): Observable<boolean> {
        const sub = new Subject<boolean>();
        window.ContactsX.hasPermission((result) => {
            if (result.read === true) {
                this.zone.run(() => {
                    sub.next(true);
                    sub.complete();
                });
            } else {
                this.zone.run(() => {
                    sub.error("Permission denied");
                });
            }
        }, (error) => {
            sub.error(error);
        });
        return sub.asObservable();
    }

    /**
     * A method to request permission to the device contacts.
     * @returns a boolean that indicate if the user gave permission to read contacts.
     */
    private requestPermission(): Observable<boolean> {
        const sub = new Subject<boolean>();
        window.ContactsX.requestPermission((result) => {
            this.zone.run(() => {
                if (result.read === true) {
                    sub.next(true);
                    sub.complete();
                } else {
                    sub.error("Permission denied");
                }
            });
        }, (error) => {
            this.zone.run(() => {
                sub.error(error);
            });
        });
        return sub.asObservable();
    }

    private accessContacts(): Observable<boolean> {
        return this.hasPermission().pipe(
            catchError( () => {
                return this.requestPermission();
            }),
            mergeMap((result): Observable<boolean> => {
                if (!result) {
                    return this.requestPermission();
                }
                return of(result);
            }),
        );
    }

    public getAllMailContacts$(): Observable<Contact[]> {
        return this.accessContacts().pipe(
            mergeMap((canAccess): Observable<ContactX[]> => {
                const sub = new Subject<ContactX[]>();
                if (canAccess) {
                    window.ContactsX.find((result) => {
                        const filtered = result.filter((con) => con.emails.length > 0);
                        sub.next(filtered);
                    }, (error) => {
                        console.log(error);
                        sub.error(error);
                    },
                    {
                        fields: {
                            emails: true
                        }
                    });
                }
                return sub.asObservable();
            }),
            map((contacts): Contact[] => {
                return contacts.map( contactX => {
                    const con = new Contact();
                    con.displayName = contactX.firstName + " " + contactX.familyName;
                    con.emails = contactX.emails;
                    return con;
                }).sort(function(a, b) {
                    if (a.displayName < b.displayName) { return -1; }
                    if (a.displayName > b.displayName) { return 1; }
                    return 0;
                });
            })
        );
    }
}

