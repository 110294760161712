export class AboModel {
    public key: string;
    public price: string;
    public currency: string;
    public description: string;
    public name: string;
    public paypal: PaypalModel;
    public appleProductIds: string[];
    public googleProductIds: string[];
}
export class PaypalModel {
    public billingPlanId: string;
    public name: string;
    public description: string;
}
