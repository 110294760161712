<app-toolbar [name]="title" [leftBarButton]="backBarButton" [rightBarButtons]="[rightBarButton]"></app-toolbar>
<div *ngIf="!hasCameraSmtpReceivers" class="pt-5 text-center">{{'cameraSmtps.addCameraSmtpReceiver' | translate}}</div>
<div class="container-overflow-scroll">
    <div>
        <div>
            <mat-list>
                <mat-list-item *ngFor="let cameraSmtpReceiver of cameraSmtpReceivers" class="cursor-pointer" (click)="editCameraSmtpReceiver(cameraSmtpReceiver)">
                    <h3 mat-line>{{ cameraSmtpReceiver.receiver }}</h3>            
                    <p mat-line>
                        <span class="descriptionInfo">{{ cameraSmtpReceiver.host }}</span>
                    </p>
                    <mat-icon mat-list-icon>mail</mat-icon>
                    <button mat-icon-button>
                        <mat-icon class="mat-24 listIcon">chevron_right</mat-icon>
                    </button>
                </mat-list-item>
            </mat-list>
        </div>
    </div>
</div>

