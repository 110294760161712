import { Injectable } from "@angular/core";
import { LocalStorageService } from "../LocalStorageService";
import { Observable, of } from "rxjs";
import { BaseRestService } from "./BaseRestService";
import { ContactRespModel } from "src/app/api-handling/models/ContactRespModel";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs";
import { plainToClass, classToPlain } from "class-transformer";


const cameraFriendInvitationEndpoint = "v1/camerafriendinvitations/";
const cameraMailEndpoint = "v1/cameramailreceiver/";
const cameraFriendsEndpoint = "v1/camerafriends/";
@Injectable()

export class ContactRestService extends BaseRestService {

    constructor(localStorageService: LocalStorageService, private httpClient: HttpClient) {
        super(localStorageService);
    }

    //
    // Friend Invitations
    //

    /**
     * Loading all open invites for a specific camera from backend.
     * @param cameraID _id of the camera
     */
    public getOpenInviteList(cameraID: string): Observable<ContactRespModel[]> | null {
        const header = this.getBearerHeader();
        if ( header ) {
            return this.httpClient.get(this.webserviceUrl + cameraFriendInvitationEndpoint + cameraID, {headers: header}).pipe(
                map((result) => plainToClass(ContactRespModel, result as any[])));
        } else {
            return null;
        }
    }

    /**
     * Loading the contact data of a pending invite for a specific camera from backend.
     * @param cameraID _id of the camera
     * @param contactId id of the contact
     */
    public getInviteContactById(cameraID: string, contactID: string): Observable<ContactRespModel> | null {
        const header = this.getBearerHeader();
        if ( header ) {
            return this.httpClient.get(this.webserviceUrl + cameraFriendInvitationEndpoint + cameraID + "/" + contactID, {headers: header}).pipe(
                map((result) => plainToClass(ContactRespModel, result as any)));
        } else {
            return null;
        }
    }

    public addInvitation(cameraID: string, newContact: ContactRespModel): Observable<ContactRespModel> | null {
        const header = this.getBearerHeader();
        if ( header ) {
            return this.httpClient.post(this.webserviceUrl + cameraFriendInvitationEndpoint + cameraID, classToPlain(newContact), {headers: header}).pipe(
                map((result) => plainToClass(ContactRespModel, result as any)));
        } else {
            return null;
        }
    }

    public editInvitation(cameraID: string, newContact: ContactRespModel, contactId: string): Observable<ContactRespModel> | null {
        const header = this.getBearerHeader();
        if ( header ) {
            return this.httpClient.patch(this.webserviceUrl + cameraFriendInvitationEndpoint + cameraID + "/" + contactId, classToPlain(newContact), {headers: header}).pipe(
                map((result) => plainToClass(ContactRespModel, result as any)));
        } else {
            return null;
        }
    }

    /**
     * Delete invitaion for a camera.
     * @param cameraID Id of the camera
     * @param contactId Id of the contact to be deleted
     */
    public deleteInvite(cameraID: string, contactId: string): Observable<ContactRespModel> | null {
        const header = this.getBearerHeader();
        if ( header ) {
            return this.httpClient.delete(this.webserviceUrl + cameraFriendInvitationEndpoint + cameraID + "/" + contactId, {headers: header}).pipe(
                map((result) => plainToClass(ContactRespModel, result as any)));
        } else {
            return null;
        }
    }


    //
    // Mail Receivers
    //

    /**
     * Loading all mail contacts for a specific camera from backend.
     * @param cameraID _id of the camera
     */
    public getMailContactList(cameraID: string): Observable<ContactRespModel[]> | null {
        const header = this.getBearerHeader();
        if ( header ) {
            return this.httpClient.get(this.webserviceUrl + cameraMailEndpoint + cameraID, {headers: header}).pipe(
                map((result) => plainToClass(ContactRespModel, result as any[])));
        } else {
            return null;
        }
    }

    /**
     * Loading a mail contact for a specific camera from backend.
     * @param cameraID _id of the camera
     * @param contactId id of the contact
     */
    public getMailContactById(cameraID: string, contactID: string): Observable<ContactRespModel> | null {
        const header = this.getBearerHeader();
        if ( header ) {
            return this.httpClient.get(this.webserviceUrl + cameraMailEndpoint + cameraID + "/" + contactID, {headers: header}).pipe(
                map((result) => plainToClass(ContactRespModel, result as any)));
        } else {
            return null;
        }
    }

    public addMailToContactList(cameraID: string, newContact: ContactRespModel): Observable<ContactRespModel> | null {
        const header = this.getBearerHeader();
        if ( header ) {
            return this.httpClient.post(this.webserviceUrl + cameraMailEndpoint + cameraID, classToPlain(newContact), {headers: header}).pipe(
                map((result) => plainToClass(ContactRespModel, result as any)));
        } else {
            return null;
        }
    }

    public editMailFromContactList(cameraID: string, newContact: ContactRespModel, contactId: string): Observable<ContactRespModel> | null {
        const header = this.getBearerHeader();
        if ( header ) {
            return this.httpClient.patch(this.webserviceUrl + cameraMailEndpoint + cameraID + "/" + contactId, classToPlain(newContact), {headers: header}).pipe(
                map((result) => plainToClass(ContactRespModel, result as any)));
        } else {
            return null;
        }
    }

    public deleteMailFromContactList(cameraID: string, contactId: string): Observable<ContactRespModel> | null {
        const header = this.getBearerHeader();
        if ( header ) {
            return this.httpClient.delete(this.webserviceUrl + cameraMailEndpoint + cameraID + "/" + contactId, {headers: header}).pipe(
                map((result) => plainToClass(ContactRespModel, result as any)));
        } else {
            return null;
        }
    }


    //
    // Friends
    //

    public deleteFriendFromContactList(cameraID: string, contactId: string): Observable<ContactRespModel> | null {
        const header = this.getBearerHeader();
        if ( header ) {
            return this.httpClient.delete(this.webserviceUrl + cameraFriendsEndpoint + cameraID + "/" + contactId, {headers: header}).pipe(
                map((result) => plainToClass(ContactRespModel, result as any)));
        } else {
            return null;
        }
    }

    /**
     * Loading a friend contact for a specific camera from backend.
     * @param cameraID _id of the camera
     * @param contactId id of the contact
     */
    public getFriendContactById(cameraID: string, contactID: string): Observable<ContactRespModel> | null {
        const header = this.getBearerHeader();
        if ( header ) {
            return this.httpClient.get(this.webserviceUrl + cameraFriendsEndpoint + cameraID + "/" + contactID, {headers: header}).pipe(
                map((result) => plainToClass(ContactRespModel, result as any)));
        } else {
            return null;
        }
    }

    /**
     * Loading all open invites for a specific camera from backend.
     * @param cameraID _id of the camera
     */
    public getAcceptedInviteList(cameraID: string): Observable<ContactRespModel[]> | null {
        const header = this.getBearerHeader();
        if ( header ) {
            return this.httpClient.get(this.webserviceUrl + cameraFriendsEndpoint + cameraID, {headers: header}).pipe(
                map((result) => plainToClass(ContactRespModel, result as any[])));
        } else {
            return null;
        }
    }

}
