import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NavigationBarItemModel } from "src/app/models/NavigationBarItemModel";
import { BaseNavComponent } from "../base/baseNav.component";
import { NavigationService } from "src/app/services/NavigationService";
import { MatDialog } from "@angular/material/dialog";
import { CordovaInitHandler } from "src/app/native/CordovaInitHandler";
import { Location } from "@angular/common";
import { BreakpointObserver } from "@angular/cdk/layout";
import { DialogService } from "src/app/services/DialogService";

@Component({
    selector: "app-info",
    templateUrl: "info.component.html",
    styleUrls: ["info.component.scss"]
})

export class InfoComponent extends BaseNavComponent {

    public infoText: string;
    public barButton: NavigationBarItemModel | undefined;
    public name: string;

    constructor(private router: Router, dialog: MatDialog, navigationService: NavigationService, location: Location,
        initHandler: CordovaInitHandler, breakpointObserver: BreakpointObserver, dialogService: DialogService) {
        super(dialog, navigationService, location, initHandler, breakpointObserver, dialogService);
        if (router.url.endsWith("imprint")) {
            this.infoText = "info.imprintText";
            this.name = "navigation.imprint";
        } else if (router.url.endsWith("datapolicy")) {
            this.infoText = "info.datapoliyText";
            this.name = "navigation.datapolicy";
        } else if (router.url.endsWith("terms") || router.url.endsWith("termsofuse")) {
            this.infoText = "info.terms";
            this.name = "navigation.terms";
        }

        this.barButton = !router.url.startsWith("/home") || router.url.endsWith("terms") ? this.backBarButton : this.menuBarButton;
    }

    public backToLogin() {
        this.router.navigate(["registration"]);
    }

    public goBackButtonPressed() {
        if (this.router.url.startsWith("/home") && !this.router.url.endsWith("terms")) {
            this.router.navigate(["home/images"]);
        } else {
            super.goBackButtonPressed();
        }
    }
}

