import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";


declare var window: {AppRate: {setPreferences: (fields: any) => void, promptForRating: (value?: boolean) => void, preferences: any}, open: any};

interface AppRateText {
    title: string;
    message: string;
    cancelBtn: string;
    laterBtn: string;
    rateBtn: string;
}

@Injectable({
    providedIn: "root"
})
export class AppRateService {

    private alreadyPromted = false;

    public setup(text: AppRateText) {
        window.AppRate.setPreferences({
            usesUntilPrompt: 5,
            promptAgainForEachNewVersion: false,
            reviewType: {
                ios: "InAppReview",
                android: "InAppBrowser"
            },
            storeAppURL: {
                ios: environment.appIdentifier,
                android: "market://details?id=" + environment.appIdentifier
            },
            simpleMode: true,
            customLocale: {
                title: text.title,
                message: text.message,
                cancelButtonLabel: text.cancelBtn,
                laterButtonLabel: text.laterBtn,
                rateButtonLabel: text.rateBtn,
            },
            openUrl: function(url) {
                window.open(url, "_blank", "location=yes");
            }
        });
        window.AppRate.promptForRating(false);
    }
}
