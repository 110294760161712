<app-toolbar [name]="'stolen.title'" [leftBarButton]="backBarButton"></app-toolbar>
<div class="container-fluid container-overflow-scroll container-safe-area-bottom d-flex flex-column align-items-stretch">
    <div class="row">
        <div class="col-12">
            <div class="row justify-content-center">
                <div class="col-12 col-md-8 col-lg-5 px-3">
                    <div class="descriptionInfo mt-3">{{ 'stolen.info' | translate }}</div>            
                </div>        
            </div>
            <div class="row justify-content-center">
                <div class="col-12 col-md-8 col-lg-5 px-3">
                    <form [formGroup]="stolenForm" id="ngForm" class="d-flex flex-column justify-content-between align-items-center w-100">                
                        <div class="inputContainer mt-3">                    
                            <div>{{ 'stolen.personalInformations' | translate }}</div>
                            <mat-form-field appearance="fill" class="doerrInput">
                                <input matInput type="text"  placeholder="{{'stolen.placeholder.firstname' | translate}}" formControlName="firstname" (blur)="onBlur()" autocomplete="on" name="given-name">
                                <mat-error>{{ 'shared.validFirstname' | translate }}</mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="doerrInput">
                                <input matInput type="text"  placeholder="{{'stolen.placeholder.lastname' | translate}}" formControlName="lastname" (blur)="onBlur()" autocomplete="on" name="family-name">
                                <mat-error>{{ 'shared.validName' | translate }}</mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="doerrInput">
                                <input matInput type="tel" inputmode="tel" placeholder="{{'stolen.placeholder.phone' | translate}}" formControlName="phone" (blur)="onBlur()" autocomplete="on" name="tel">
                                <mat-hint>{{'stolen.hint.phone' | translate}}</mat-hint>
                                <mat-error>{{ 'shared.validPhone' | translate }}</mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="doerrInput">
                                <input matInput type="email" inputmode="email" placeholder="{{'stolen.placeholder.email' | translate}}" formControlName="email" (blur)="onBlur()" autocomplete="on" name="email">
                                <mat-error>{{ 'shared.validMail' | translate }}</mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="doerrInput">
                                <input matInput type="text" placeholder="{{'stolen.placeholder.address' | translate}}" formControlName="address" (blur)="onBlur()" autocomplete="on" name="street-address">
                                <mat-error>{{ 'stolen.errors.validAddress' | translate }}</mat-error>                        
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="doerrInput">
                                <input matInput type="text" placeholder="{{'stolen.placeholder.zip' | translate}}" formControlName="zip" (blur)="onBlur()" autocomplete="on" name="postal-code">
                                <mat-error>{{ 'stolen.errors.validZip' | translate }}</mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="doerrInput">
                                <input matInput type="text" placeholder="{{'stolen.placeholder.city' | translate}}" formControlName="city" (blur)="onBlur()" autocomplete="on" name="address-level2">
                                <mat-error>{{ 'stolen.errors.validCity' | translate }}</mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="doerrInput">
                                <mat-select formControlName="country" placeholder="{{'stolen.placeholder.country' | translate}}">
                                    <mat-option *ngFor="let country of countries" [value]="country.code">{{country.name}}</mat-option>
                                </mat-select>
                                <mat-error>{{ 'stolen.errors.validCountry' | translate }}</mat-error>
                            </mat-form-field>
                        </div>
                    </form>
                </div>        
            </div>
            <div class="row justify-content-center">
                <div class="col-12 col-md-8 col-lg-5 px-3 mb-3">
                    <mat-card class="d-flex align-items-center justify-content-center flex-column pt-0">
                        <mat-card-header>
                            <mat-card-title class="warnText">{{ 'stolen.note' | translate }}</mat-card-title>
                        </mat-card-header>
                        <mat-card-content class="">
                            <div  class="descriptionInfo">{{ 'stolen.noteMsg' | translate }}</div>
                        </mat-card-content>
                    </mat-card>
                </div>    
            </div>
            <div class="row justify-content-center mt-auto">            
                <div class="col-12 col-md-5 col-lg-3 mb-3">
                    <button class="signInButton" mat-raised-button color="primary" form="ngForm" (click)="setStolen()">{{'stolen.send' | translate }}</button>
                </div>  
            </div>
        </div>
    </div>    
</div>