<app-toolbar [name]="'settings.title'" [leftBarButton]="backBarButton"></app-toolbar>
<div class="container-fluid container-overflow-scroll">    
    <div class="row h-100 justify-content-center">
        <div class="col-12 px-0">
            <app-camerasettings *ngIf="camera" [cameraSettings]="camera.settings" [camera]="camera" (notify)="onNotify($event)"></app-camerasettings>
        </div>
        <div class="col-12 col-md-5 col-lg-4 col-xl-3 mb-3 pb-3 mt-auto">
            <button class="signInButton" (click)="goBackButtonPressed()" mat-raised-button color="primary" >{{'settings.confirmSettings' | translate }}</button>
        </div>        
    </div>
</div>
