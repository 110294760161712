
import { BreakpointObserver } from "@angular/cdk/layout";
import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { from, Observable, Subject } from "rxjs";
import { ImageResponseModel } from "src/app/api-handling/models/ImageResponseModel";
import { BaseImgComponent } from "src/app/components/base/baseImg.component";
import { CordovaInitHandler } from "src/app/native/CordovaInitHandler";
import { ImageRepository } from "src/app/repositories/ImageRepository";
import { DialogService } from "src/app/services/DialogService";
import { ImageRestService } from "src/app/services/http/ImageRestService";
import { ImageFilterService } from "src/app/services/ImageFilterService";
import { ImageProcessNotification, ImageProcessService, ImageProcessState } from "src/app/services/ImageProcessService";
import { NavigationService } from "src/app/services/NavigationService";
import { ImageDay, ImageYear } from "./models/ImageYear";
import { Location } from "@angular/common";
import * as moment from "moment";
import { TranslateService } from "@ngx-translate/core";
import { LanguageDetector } from "src/app/helpers/LanguageDetector";
import { DateAdapter } from "@angular/material/core";
import { UserRestService } from "src/app/services/http/UserRestService";
import { LocalStorageService } from "src/app/services/LocalStorageService";
import { IMAGE_TYPE } from "src/app/api-handling/models/enums/ImageType";
import { OPACITY } from "src/app/animations/opacity.animation";
import { FADE } from "src/app/animations/fade.animation";
import { TrackingService, TrackTag } from "src/app/services/TrackingService";

export enum ImageListState {
    MULTI_SELECTION,
    SINGLE_SELECTION
}

@Component({
    selector: "app-imagelist",
    templateUrl: "imageList.component.html",
    styleUrls: ["imageList.component.scss"],
    animations: [ FADE, OPACITY ]
})

export class ImageListComponent extends BaseImgComponent implements AfterViewChecked, OnDestroy, OnChanges {

    @Input() isFav: boolean;
    public allListStates = ImageListState;
    private _listState: ImageListState = ImageListState.SINGLE_SELECTION;
    get listState(): ImageListState {
        return this._listState;
    }
    @Input() set listState(value: ImageListState) {
        this._listState = value;
        if (value === ImageListState.SINGLE_SELECTION) {
            this.imageProcessService.clearSelection();
        }
        this.listStateChange.emit(value);
    }
    @Input() hasNoInternetConnection: boolean = true;
    @Output() imageIdEmitter = new EventEmitter<ImageResponseModel>();
    @Output() listStateChange = new EventEmitter<ImageListState>();

    @ViewChild("imageList", { static: true }) imageListElement: ElementRef;

    private _images: ImageResponseModel[];
    get images(): ImageResponseModel[] {
        return this.imageRepository.images;
    }

    private locale: string;
    public currentYear: string;
    public imageYears: ImageYear[] = [];
    public columns = 3;
    public selectionInverted: Boolean = false;

    constructor(
        readonly imageRestService: ImageRestService, private imageProcessService: ImageProcessService,
        imageFilterService: ImageFilterService, breakpointObserver: BreakpointObserver, dialog: MatDialog, router: Router,
        navigationService: NavigationService, location: Location, initHandler: CordovaInitHandler, zone: NgZone,
        dialogService: DialogService, imageRepository: ImageRepository, private translate: TranslateService,
        private filterService: ImageFilterService, private dateAdapter: DateAdapter<Date>,
        private readonly userRestService: UserRestService, private localStorageService: LocalStorageService,
        private trackingService: TrackingService
    ) {
        super(dialog, navigationService, location, initHandler, breakpointObserver, dialogService, zone,
            imageRepository, imageFilterService);

        this.subscriptions.push(filterService.filterSettingsObservable$.subscribe(() => {
            this.clearImages();
            this.loadImages(1, this.filterService.filter);
        }));

        this.subscriptions.push(filterService.filterParameterObservable$.subscribe(() => {
            this.loadQuota();
            this.clearImages();
            this.loadImages(1, this.filterService.filter);
        }));

        this.subscriptions.push(imageProcessService.invertSelectionStatus$.subscribe((value: Boolean) => {
            this.selectionInverted = value;
        }));

        this.subscriptions.push(imageProcessService.imageProcessObservable$.subscribe((value: ImageProcessNotification) => {
            switch ( value.state ) {
                case ImageProcessState.RUNNING:
                    if (value.title && value.message) {
                        this.showSpinner(value.title, value.message);
                    }
                    break;
                case ImageProcessState.FINISHED:
                    this.closeSpinner();
                    break;
                case ImageProcessState.ERROR:
                    this.closeSpinner();
                    if (value.title && value.message) {
                        this.openDialog(value.title, value.message, null);
                    }
                    break;
            }
        }));

        this.subscriptions.push(this.imageProcessService.updateData$.subscribe(() => {
            this.clearImages();
            this.loadImages(1, this.filterService.filter);
        }));

        from(LanguageDetector.getLanguage()).subscribe((locale) => {
            this.locale = locale;
            this.dateAdapter.setLocale(locale ? locale : "en");
        });
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isFav) {
            this.loadQuota();
        }
    }

    private clearImages() {
        this.imageRepository.images = [];
        this.imageFilterService.page = 1;
        this.imageProcessService.resetInvertSelection();
    }

    private async loadQuota() {
        if (typeof this.isFav === undefined) {
            return;
        }
        if (!this.isFav) {
            this.countparam.max = 0;
            return;
        }

        let quotaInfo = this.localStorageService.getQuotaInformation();
        if (!quotaInfo) {
            await this.userRestService.loadQuotaInformation();
            quotaInfo = this.localStorageService.getQuotaInformation();
        }

        this.zone.run(() => {
            this.countparam.max = quotaInfo.availableFavorites;
        });
    }

    public imagesProcessFinished() {
        this.imageYears = [];
        this.currentYear = moment().format("YYYY");
        for (const image of this.imageRepository.images) {
            const momentDate = moment(image.uploaded);
            const year = momentDate.format("YYYY");

            const existingYear = this.imageYears.find( (y) => y.year === year);
            if (existingYear) {
                this.addImageTo(existingYear, image);
            } else {
                const newYear: ImageYear = {
                    year: year,
                    days: []
                };
                this.imageYears.push(newYear);
                this.addImageTo(newYear, image);
            }
        }
        if (this.needsScrolling() && !this.lastImageIsLoaded()) {
            this.loadNextPage();
        }
    }

    ngAfterViewChecked(): void {
        if (this.imageRepository.images.length > 0 && this.needsScrolling()) {
            if (this.lastImageIsLoaded()) {
                const lastImageId = this.imageFilterService.lastImageId;
                this.imageFilterService.lastImageId = undefined;
                this.subscriptions.push(this.scrollToImageId(lastImageId).subscribe( result => {
                    if (result) {
                        this.imageFilterService.lastImageId = undefined;
                        this.closeSpinner();
                    }
                }));
            }
        }
    }

    private lastImageIsLoaded() {
        return this.imageRepository.images.some((img) => img._id === this.imageFilterService.lastImageId);
    }

      /**
     * Scrolls to a image in the grid list.
     * @param imageId Id of the image to scroll to.
     * @returns Observable that publishes a boolean to indicate if scrolling was successfully.
     */
       private scrollToImageId(imageId: string): Observable<Boolean> {
        const subject = new Subject<Boolean>();
        const day = this.getImageDayFor(imageId);
        if (day) {
            if (this.imageListElement) {
                    this.scrollDownToOffset(subject, imageId, day);
            } else {
                subject.next(false);
                subject.complete();
            }
        } else {
            subject.next(false);
            subject.complete();
        }
        return subject.asObservable();
    }

    private async scrollDownToOffset(subject: Subject<Boolean>, imageId: string, day: ImageDay) {
        let timeoutReached = false;
        let offsetReached = false;
        let isVisible = false;

        setTimeout( () => {
            timeoutReached = true;
        }, 1000);

        do {
            const dayElement = document.getElementById("day_" + day.day);
            const dayTitleElement = document.getElementById("day_title_" + day.day);
            const element = document.getElementById("tileId_" + imageId);

            if (dayElement && dayTitleElement && element) {
                const offsetToScroll = dayElement.offsetTop + element.offsetTop + dayTitleElement.offsetHeight - this.imageListElement.nativeElement.offsetTop;
                this.imageListElement.nativeElement.scrollTop = (offsetToScroll);

                await new Promise( (resolve) => { setTimeout( () => { resolve(true); }, 1); } );

                offsetReached = this.imageListElement.nativeElement.scrollTop === offsetToScroll;
            }

            if (element) {
                const rect = element.getBoundingClientRect();
                isVisible = (rect.top >= 0) && (rect.bottom <= window.innerHeight);
            }

            if (!(offsetReached && isVisible)) {
                await new Promise( (resolve) => { setTimeout( () => { resolve(true); }, 10); } );
            }
        } while (!timeoutReached && !(offsetReached && isVisible));

        subject.next(true);
        subject.complete();
    }

    private getImageDayFor(imageId: string): ImageDay | undefined {
        for (const year of this.imageYears) {
            const day = year.days.find((d) => {
                return d.images.some((i) => i._id === imageId);
            });
            if (day) {
                return day;
            }
        }
        return undefined;
    }

    private needsScrolling() {
        return this.imageFilterService.lastImageId !== undefined;
    }

    private addImageTo(year: ImageYear, image: ImageResponseModel) {
        const momentDate = moment(image.uploaded);
        const day = momentDate.format(this.translate.instant("shared.dateFormat"));
        const existingDay = year.days.find( (d) => d.day === day);
        if (existingDay) {
            existingDay.images.push(image);
        } else {
            const newDay: ImageDay = {
                day: day,
                dayReadable: momentDate.locale(this.locale).format(this.translate.instant("shared.dateFormatReadable")),
                images: [image]
            };
            year.days.push(newDay);
        }
    }

    isHandsetChanged() {
        /* Quick fix, needed to update grid list */
        setTimeout( () => {
            this.columns = this.isHandset ? 3 : 5;
        });
    }

    public getImg(img: ImageResponseModel): any {
        return this.imageRepository.getImgFile(img._id, IMAGE_TYPE.thumbnail);
    }

    public imgErrorAccured(imageId: string) {
        this.imageRepository.setImgBroken(imageId);
    }

    // Image Actions

    public select(img: ImageResponseModel) {
        if (this.listState === ImageListState.MULTI_SELECTION) {
            this.imageProcessService.select(img);
        } else {
            this.imageIdEmitter.emit(img);
        }
    }

    public startSelection(img: ImageResponseModel) {
        this.listState = ImageListState.MULTI_SELECTION;
        this.select(img);
    }

    public runNextPage() {
        this.zone.runOutsideAngular(() => {
            this.loadNextPage();
        });
    }

    public canDeleteImages(): boolean {
        return this.imageProcessService.canDeleteImages(this.moreImagesToLoad, this.filteredImagesCount);
    }

    public canShareImages(): boolean {
        return this.imageProcessService.canShareImages(this.moreImagesToLoad, this.filteredImagesCount);
    }

    public isSelected(img: ImageResponseModel): boolean {
        return this.imageProcessService.isSelected(img);
    }

    public shareSelectedImages() {
        this.trackButton("share");
        this.imageProcessService.shareSelectedImages(this.filterService.filter, this.filterService.firstImageUploadDate, this.countparam.imageCount);
    }

    public deleteSelectedImages() {
        this.trackButton("delete");
        this.imageProcessService.deleteSelectedImages(this.filterService.filter, this.filterService.firstImageUploadDate);
    }

    public invertSelection() {
        this.imageProcessService.invertSelection();
    }

    /**
     * Creates a Google Tag to track how many users click on the variant A of delete or share button.
     */
    private trackButton(buttonName): void {
        const gtmTag: TrackTag = {
            event: "Buttons"
        };
        switch (buttonName) {
            case "share":
                gtmTag.variantAShare = true;
                break;
            case "delete":
                gtmTag.variantADelete = true;
                break;
        }
        this.trackingService.trackEvent(gtmTag);
    }
}
