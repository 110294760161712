<app-toolbar [name]="'contactapp.title'" [leftBarButton]="backBarButton"></app-toolbar>
<div *ngIf="!permissionMissing" class="container-overflow-scroll">
    <mat-list>
        <mat-list-item *ngFor="let contact of $contacts | async" (click)="selected(contact)">
            <h3 mat-line>{{ contact.displayName }}</h3>
            <p mat-line>
                <span class="descriptionInfo">{{ contact.emails[0].value }}</span>
            </p>
        </mat-list-item>
    </mat-list>
</div>
<div *ngIf="permissionMissing" class="container pt-5 text-center">
    <div class="p-1">{{ 'contactapp.permissionMissing' | translate}}</div>
    <button class="signInButton" mat-raised-button color="primary" (click)="openSettings()">{{ 'contactapp.settingsButton' | translate }}</button>
</div>