import { CameraReigster1RespModel } from "./CameraRegister1RespModel";
import { ICameraSettings } from "./ICameraSettings";
import { CameraRegisterReq1Model } from "./CameraRegisterReq1Model";

export class CameraRegister2ReqModel {
    imei: string;
    name: string;
    phone: string;
    phoneRegion: string;
    camTimeToServerTimeDiff: string;
    battery: string;
    batteryType: string;
    firmware: string;
    settings: ICameraSettings;
    isHighRes: boolean;

    constructor(req1Model: CameraRegisterReq1Model, register1: CameraReigster1RespModel, settings: ICameraSettings) {
        this.imei = req1Model.imei;
        this.name = req1Model.name;
        this.phone = req1Model.phone;
        this.phoneRegion = req1Model.phoneRegion;
        this.camTimeToServerTimeDiff = register1.camTimeToServerTimeDiff;
        this.battery = register1.battery;
        this.batteryType = register1.batteryType;
        this.firmware = register1.firmware;
        this.isHighRes = register1.isHighRes;
        this.settings = settings;
    }
}
