import { TimeoutModel } from "./TimeoutModel";

export class CameraRegisterReq1Model extends TimeoutModel {
    imei: string;
    name: string;
    phone: string;
    phoneRegion: string;

    constructor(imei: string, name: string, phone: string, phoneRegion: string, timeout: number) {
        super(timeout);
        this.imei = imei;
        this.name = name;
        this.phone = phone;
        this.phoneRegion = phoneRegion;
    }
}
