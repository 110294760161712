import { Component, NgZone } from "@angular/core";
import { BaseNavComponent } from "../base/baseNav.component";
import { MatDialog } from "@angular/material/dialog";
import { NavigationService } from "src/app/services/NavigationService";
import { CordovaInitHandler } from "src/app/native/CordovaInitHandler";
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { BreakpointObserver } from "@angular/cdk/layout";
import { DialogService } from "src/app/services/DialogService";
import { FirmwareRestService } from "src/app/services/http/FirmwareRestService";
import { ShareService } from "src/app/services/ShareService";
import { TranslateService } from "@ngx-translate/core";
import { DeviceDetector } from "src/app/helpers/DeviceDetector";
import { Observable, from } from "rxjs";

@Component({
    selector: "app-update",
    templateUrl: "updateStep2.component.html",
    styleUrls: ["updateStep2.component.scss"]
})

export class UpdateStep2Component extends BaseNavComponent {

    public downloadedFirmware = false;
    private cameraId: string;
    public language: string;

    constructor(private activatedRoute: ActivatedRoute, dialog: MatDialog, navigationService: NavigationService, location: Location, initHandler: CordovaInitHandler, private router: Router,
        breakpointObserver: BreakpointObserver, dialogService: DialogService, private firmwareRestService: FirmwareRestService,
        private shareService: ShareService, private translate: TranslateService, private zone: NgZone) {
        super(dialog, navigationService, location, initHandler, breakpointObserver, dialogService);
        this.subscriptions.push(this.activatedRoute.queryParams.subscribe((params) => {
            if (params && params.id) {
                this.cameraId = params.id;
            }
        }));
        this.language = translate.currentLang;
    }

    nextStep() {
        if (this.downloadedFirmware) {
            this.router.navigate(["home/update3"] , {queryParams: {id: this.cameraId}});
        }
    }

    /**
     * Perform native Share function if is in Cordova, or download file if is webinterface.
     */
    public async shareFirmware() {
        const text = await this.translate.get("update2.downloadingFirmware").toPromise();
        this.showSpinner(text, "");
        try {
            const data = await this.firmwareRestService.getFirmware({ cameraId: this.cameraId }).toPromise();
            this.downloadedFirmware = true;
            this.zone.run(() => {
                this.closeSpinner();
                from(this.shareService.shareFile({
                        data: data,
                        name: "firmware.zip"
                    })).subscribe(() => {
                }, (error) => {
                    this.openDialog(this.translate.instant("error.title"), this.translate.instant(DeviceDetector.isOnCordova() ? "imageDetail.shareErrorMsg" : "imageDetail.downloadErrorMsg"), undefined);
                });
            });
        } catch (error) {
            this.closeSpinner();
        }
    }
}
