<mat-card> 
    <mat-card-header>
        <img *ngIf="cameraListItem.supportedProduct" mat-card-avatar class="cameraImg" srcset="assets/img/doerrCameraImageAvatar@2x.jpg 2x, assets/img/doerrCameraImageAvatar@2x.jpg 3x" alt="">
        <div *ngIf="!cameraListItem.supportedProduct" mat-card-avatar class="cameraImg unsupported">?</div>
        <mat-card-title class="no-wrap-ellipsis">{{cameraListItem.title}} </mat-card-title>
        <mat-card-subtitle *ngIf="cameraListItem.subtitle" class="no-wrap">{{cameraListItem.subtitle}}</mat-card-subtitle>
        <div>
            <mat-icon *ngIf="cameraListItem.showBattery" class="align-self-center" style="font-size: 28px;" color="warn">battery_alert</mat-icon>
            <mat-icon *ngIf="cameraListItem.showStolen" class="align-self-center" style="font-size: 28px; width: auto;" color="warn">local_police</mat-icon>
            <mat-icon *ngIf="cameraListItem.showUnverifiedBackofficeRegistered" class="align-self-center" color="warn">cloud_off</mat-icon>
        </div>
    </mat-card-header>
    <div *ngIf="cameraListItem.actions">        
        <mat-card-actions class="mb-0 mx-0 p-0" align="end">
            <div *ngFor="let action of cameraListItem.actions">
                <button *ngIf="action.type === 0" class="mx-1" mat-raised-button color="{{action.color}}" (click)="onClick(action)">{{action.titles[0] | translate}}</button>
            </div>                
        </mat-card-actions>
        <mat-card-actions class="mb-0 mx-0 p-0">
            <div *ngFor="let action of cameraListItem.actions">
                <mat-button-toggle-group *ngIf="action.type === 1 && action.value !== undefined" class="col-12 px-0 flex-wrap" #group="matButtonToggleGroup" [value]="action.value" (change)="onValChange(action, $event.value)" [disabled]="!cameraListItem.supportedProduct">
                    <mat-button-toggle class="col-6" checked="{{action.value}}" [value]="true">{{action.titles[0] | translate}}</mat-button-toggle>
                    <mat-button-toggle class="col-6" checked="{{!action.value}}" [value]="false">{{action.titles[1] | translate}}</mat-button-toggle>
                </mat-button-toggle-group>
            </div>                
        </mat-card-actions>   
    </div>
    <div *ngIf="!cameraListItem.supportedProduct" class="unsupported-product-warning">{{'cameras.unsupportedProduct' | translate}}</div>
</mat-card>
