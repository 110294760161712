import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Injectable, OnDestroy, OnInit } from "@angular/core";
import { map, Observable, share, Subject, Subscription } from "rxjs";

@Injectable({
    providedIn: "root"
})

export class BreakpointService implements OnDestroy, OnInit {

    public isHandset: boolean;
    public isTabletPortrait: boolean;
    public menuModeOver: boolean;

    private subscriptions: Subscription[] = [];

    private emitMenuModeOver = new Subject<boolean>();
    public isMenuModeOver$ = this.emitMenuModeOver.asObservable();

    public isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
        map(result => result.matches),
        share()
    );

    private isTabletPortrait$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.TabletPortrait)
        .pipe(
            map(result => result.matches),
            share()
        );

    constructor(private breakpointObserver: BreakpointObserver) {
        this.updateMenuMode();
    }

    private updateMenuMode() {
        this.subscriptions.push(this.isHandset$.subscribe((result) => {
            this.isHandset = result;
            this.menuModeOver = this.isHandset || this.isTabletPortrait;
            this.emitMenuModeOver.next(this.menuModeOver);
        }));
        this.subscriptions.push(this.isTabletPortrait$.subscribe((result) => {
            this.isTabletPortrait = result;
            this.menuModeOver = this.isHandset || this.isTabletPortrait;
            this.emitMenuModeOver.next(this.menuModeOver);
        }));
    }

    ngOnInit(): void {
        this.isHandset = this.breakpointObserver.isMatched(Breakpoints.Handset);
    }

    ngOnDestroy(): void {
        for (const sub of this.subscriptions) {
            sub.unsubscribe();
        }
    }

}
