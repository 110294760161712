import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { BaseNavComponent } from "../base/baseNav.component";
import { Location } from "@angular/common";
import { NavigationBarItemModel } from "src/app/models/NavigationBarItemModel";
import { MatDialog } from "@angular/material/dialog";
import { NavigationService } from "src/app/services/NavigationService";
import { CordovaInitHandler } from "src/app/native/CordovaInitHandler";
import { ActivatedRoute, Router } from "@angular/router";
import { LanguageDetector } from "src/app/helpers/LanguageDetector";
import { TranslateService } from "@ngx-translate/core";
import { ComponentDetailMode } from "src/app/api-handling/models/enums/ComponentDetailMode";
import { BreakpointObserver } from "@angular/cdk/layout";
import { DialogService } from "src/app/services/DialogService";
import { CameraSmtpReceiverRespModel } from "src/app/api-handling/models/CameraSmtpReceiverRespModel";
import { CameraSmtpReceiverRestService } from "src/app/services/http/CameraSmtpReceiverRestService";
import { from } from "rxjs";

@Component({
    selector: "app-smtpdetail",
    templateUrl: "cameraSmtpDetail.component.html",
    styleUrls: ["cameraSmtpDetail.component.scss"]
})
export class CameraSmtpDetailComponent extends BaseNavComponent {

    public static readonly PASSWORD_PLACEHOLDER_EDITMODE = "[[XXXXXX]]";

    public cameraSmtpReceiverDetailForm: FormGroup;
    public title = "";
    public type: boolean;
    private cameraId: string;
    public cameraSmtpReceiverId: string;
    private mode: ComponentDetailMode;
    private model: CameraSmtpReceiverRespModel;
    public navigationButtons: NavigationBarItemModel[] = [];

    constructor(private formBuilder: FormBuilder, dialog: MatDialog, navigationService: NavigationService, location: Location, initHandler: CordovaInitHandler, private activatedRoute: ActivatedRoute,
        private cameraSmtpReceiverRestService: CameraSmtpReceiverRestService, private translate: TranslateService, breakpointObserver: BreakpointObserver,
        private router: Router, dialogService: DialogService) {
        super(dialog, navigationService, location, initHandler, breakpointObserver, dialogService);

        this.setForm();
        this.subscriptions.push(activatedRoute.queryParams.subscribe((params) => {
            if (params && params.id) {
                this.cameraId = params.id;
            } else {
                setTimeout(() => {
                    router.navigate(["home/cameras"]);
                }, 200);
                return;
            }
            if (params && params.cameraSmtpReceiverId) {
                this.cameraSmtpReceiverId = params.cameraSmtpReceiverId;
                this.mode = ComponentDetailMode.edit;
                this.loadDetails();
            } else {
                this.mode = ComponentDetailMode.add;
                this.model = new CameraSmtpReceiverRespModel();
            }
        }));
    }

    /**
     * Loads contact informations from backend.
     */
    private loadDetails() {
        this.subscriptions.push( this.translate.get("cameraSmtpDetail.loadingTitle").subscribe((translation) => {
            this.showSpinner(translation, "");

            this.subscriptions.push( this.cameraSmtpReceiverRestService.getById(this.cameraId, this.cameraSmtpReceiverId).subscribe((resultSmtp) => {
                this.model = resultSmtp;
                this.model.password = CameraSmtpDetailComponent.PASSWORD_PLACEHOLDER_EDITMODE;
                this.setForm(this.model);
                this.closeSpinner();
            }, (err) => {
                this.closeSpinner();
            }));
        }));
    }

    private setForm(model?: CameraSmtpReceiverRespModel) {
        this.cameraSmtpReceiverDetailForm = this.formBuilder.group({
            receiver: [model ? model.receiver : "", [Validators.required, Validators.email]],
            from: [model ? model.from : "", [Validators.required, Validators.email]],
            host: [model ? model.host : "", [Validators.required]],
            port: [model ? model.port : "", [Validators.required]],
            user: [model ? model.user : "", [Validators.required]],
            password: [model ? model.password : ""],
            secure: [model ? model.secure : false]
        });
    }

    public delete() {
        this.showSpinner(this.translate.instant("cameraSmtpDetail.deleteLoad"), "");

        this.subscriptions.push(this.cameraSmtpReceiverRestService.delete(this.cameraId, this.cameraSmtpReceiverId).subscribe((resultSmtp) => {
            this.closeSpinner();
            super.goBackButtonPressed();
        }, (err) => {
            this.closeSpinner();
        }));
    }

    public onPasswordFocus() {
        if (this.mode === ComponentDetailMode.edit) {
            if (this.cameraSmtpReceiverDetailForm.value.password === CameraSmtpDetailComponent.PASSWORD_PLACEHOLDER_EDITMODE) {
                this.cameraSmtpReceiverDetailForm.controls["password"].setValue("");
            }
        }
    }

    public onPasswordBlur() {
        if (this.mode === ComponentDetailMode.edit) {
            if (this.cameraSmtpReceiverDetailForm.value.password === "") {
                this.cameraSmtpReceiverDetailForm.controls["password"].setValue(CameraSmtpDetailComponent.PASSWORD_PLACEHOLDER_EDITMODE);
            }
        }
        super.onBlur();
    }

    public goBackButtonPressed() {
        this.canGoBack().then((goBack) => {
            if (goBack) {
                if (this.formChanged() && this.cameraSmtpReceiverDetailForm.valid) {

                    const port = Number(this.cameraSmtpReceiverDetailForm.value.port);
                    if (isNaN(port)) {
                        this.subscriptions.push(this.openImgDialog(this.translate.instant("error.title"), this.translate.instant("cameraSmtpDetail.portInvalid"), null, [this.translate.instant("shared.ok")]).subscribe((finish) => {
                        }));
                        return;
                    }

                    /* New model important because possible validation error on server. */
                    const newSmtp = new CameraSmtpReceiverRespModel();
                    newSmtp.receiver = this.cameraSmtpReceiverDetailForm.value.receiver;
                    newSmtp.from = this.cameraSmtpReceiverDetailForm.value.from;
                    newSmtp.host = this.cameraSmtpReceiverDetailForm.value.host;
                    newSmtp.port = port;
                    newSmtp.user = this.cameraSmtpReceiverDetailForm.value.user;
                    newSmtp.secure = this.cameraSmtpReceiverDetailForm.value.secure;

                    // on edit mode we only send password to api if password field is filled (otherwise we dont want to change the password)
                    if (this.mode === ComponentDetailMode.add || (this.cameraSmtpReceiverDetailForm.value.password && this.cameraSmtpReceiverDetailForm.value.password !== CameraSmtpDetailComponent.PASSWORD_PLACEHOLDER_EDITMODE)) {
                        newSmtp.password = this.cameraSmtpReceiverDetailForm.value.password;
                    }

                    from(LanguageDetector.getLanguage()).subscribe((locale) => {
                        newSmtp.languagecode = (locale ? locale : "en");
                        if (this.mode === ComponentDetailMode.edit) {
                            newSmtp.version = this.model.version;
                            this.subscriptions.push(this.cameraSmtpReceiverRestService.edit(this.cameraId, newSmtp, this.cameraSmtpReceiverId).subscribe((resultSmtp) => {
                                super.goBackButtonPressed();
                            }, (err) => {
                                this.closeSpinner();
                                this.setForm(newSmtp);
                            }));
                        } else {
                            this.showSpinner(this.translate.instant("cameraSmtpDetail.addLoad"), "");

                            this.subscriptions.push(this.cameraSmtpReceiverRestService.add(this.cameraId, newSmtp).subscribe((resultSmtp) => {
                                this.closeSpinner();
                                super.goBackButtonPressed();
                            }, (err) => {
                                this.closeSpinner();
                                this.setForm(newSmtp);
                            }));
                        }
                    });
                } else {
                    super.goBackButtonPressed();
                }
            }
        });
    }

    private formChanged() {
        return !(this.model.receiver === this.cameraSmtpReceiverDetailForm.value.receiver) ||
                !(this.model.from === this.cameraSmtpReceiverDetailForm.value.from) ||
                !(this.model.host === this.cameraSmtpReceiverDetailForm.value.host) ||
                !(this.model.secure === this.cameraSmtpReceiverDetailForm.value.secure) ||
                !(this.model.port === this.cameraSmtpReceiverDetailForm.value.port) ||
                !(this.model.user === this.cameraSmtpReceiverDetailForm.value.user) ||
                (this.cameraSmtpReceiverDetailForm.value.password);
    }

    private async canGoBack(): Promise<boolean> {
        if (this.cameraSmtpReceiverDetailForm.valid) {
            return true;
        } else {
            if (this.mode === ComponentDetailMode.edit) {
                const mButtons: string[] = [this.translate.instant("shared.yes"), this.translate.instant("shared.no")];
                const buttonClicked = await this.openDialog(this.translate.instant("cameraSmtpDetail.invalidTitle"), this.translate.instant("cameraSmtpDetail.invalidMsg"), mButtons ).toPromise();
                if (buttonClicked !== null && buttonClicked === 0) {
                    return true;
                }
            } else {
                if (this.formChanged()) {
                    const mButtons: string[] = [this.translate.instant("shared.yes"), this.translate.instant("shared.no")];
                    const buttonClicked = await this.openDialog(this.translate.instant("cameraSmtpDetail.invalidTitle"), this.translate.instant("cameraSmtpDetail.invalidMsg"), mButtons ).toPromise();
                    if (buttonClicked !== null && buttonClicked === 0) {
                        return true;
                    }
                    return false;
                }
                return true;
            }
        }
        return false;
    }

}
