<app-toolbar [name]="'paypalPayment.title'"></app-toolbar>
<div *ngIf="isConfirmed" class="container-fluid">
    <div class="row d-flex flex-column align-items-center">
        <div class="col-12 text-center p-5">
            <h1>{{'paypalPayment.thankYouTitle' | translate }}</h1>
            <div>{{'paypalPayment.thankYouDescription' | translate }}</div>
        </div>
        <div class="py-3 col-8 col-sm-5 col-lg-3">
            <button class="signInButton" mat-raised-button color="primary" (click)="finishButtonPressed()">{{'paypalPayment.finishButton' | translate }}</button>
        </div>
        <div *ngIf="showManagementBtn" class="py-3 col-8 col-sm-5 col-lg-3">
            <button class="signInButton" mat-raised-button color="primary" (click)="managementButtonPressed()">{{'paypalPayment.managementButton' | translate }}</button>
        </div>
    </div>
</div>
