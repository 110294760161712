import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { Subject } from "rxjs";
import { CookieSettings, CookieSettingsAdapter, UserModel } from "../api-handling/models/UserModel";
import { DeviceDetector } from "../helpers/DeviceDetector";
import { ATTTrackingService, TrackingRequestInfo, TrackingRequestReason } from "../native/ATTTrackingService";
import { AssetService } from "./AssetService";
import { UserRestService } from "./http/UserRestService";

export interface TrackTag {
    event: string;
    pageName?: string;
    cameraCount?: number;
    friendCameras?: number;
    allowsTracking?: boolean;
    allowsAnalytics?: boolean;
    variantAShare?: boolean;
    variantADelete?: boolean;
    variantBShare?: boolean;
    variantBDelete?: boolean;
}

@Injectable({
    providedIn: "root"
})

export class TrackingService {

    private userProfile?: UserModel;

    /**
     * Current tracking settings. Indicates if tracking or analytics is currently available.
     */
    private _currentSettings: CookieSettings = {};
    get currentSettings(): CookieSettings {
        return this._currentSettings;
    }
    private get internalCurrentSettings(): CookieSettings {
        return this.currentSettings;
    }
    private set internalCurrentSettings(value: CookieSettings) {
        this._currentSettings = value;
        this.onTrackingChanged();
        this.settingsChanged.next(value);
    }


    /**
     * Indicates if the user already have been asked to allow tracking.
     */
    private _didPerformRequest: boolean = false;
    private set didPerformRequest(value: boolean) {
        this._didPerformRequest = value;
        if (value) {
            this.pushOpenTags();
        }
    }
    private get didPerformRequest(): boolean {
        return this._didPerformRequest;
    }

    /**
     * Tags that could not be pushed yet because the request is not finished yet.
     */
    private openTags: TrackTag[] = [];

    private displayRequest = new Subject<any>();
    public displayRequest$ = this.displayRequest.asObservable();

    private settingsChanged = new Subject<CookieSettings>();
    public settingsChanged$ = this.settingsChanged.asObservable();

    constructor(private attTrackingService: ATTTrackingService, private assetService: AssetService, private userRestService: UserRestService,
        private gtmService: GoogleTagManagerService, private translate: TranslateService) {
    }

    public trackEvent(tag: TrackTag) {
        if (this.didPerformRequest) {
            this.gtmService.pushTag(tag);
        } else {
            this.openTags.push(tag);
        }
    }

    /**
     * Push all tags that are waiting to be pushed to google tag manager.
     */
    private pushOpenTags() {
        for (const tag of this.openTags) {
            if (tag) {
                this.gtmService.pushTag(tag);
            }
        }
    }

    public getCookieSettings(): CookieSettings {
        if (this.userProfile) {
            return this.userProfile.getCookieSettings();
        }
        return {
            allowsTracking: false,
            allowsAnalytics: false,
            allowsTrackingIos: false
        };
    }

    private async loadTrackingInfo(): Promise<UserModel | undefined> {
        try {
            return await this.userRestService.getUserProfile().toPromise();
        } catch (error) {
            console.log(error);
        }
        return undefined;
    }

    /**
     * Checks the settings of the user for cookies and tracking informations. If the user has not allowed all cookies or tracking it returns false.
     * @returns boolean which indicates if the user allows tracking.
     */
    async trackingAvailable(): Promise<boolean> {
        if (DeviceDetector.isOnAndroid()) {
            return true;
        } else {
            if (DeviceDetector.isOnIos()) {
                const result = await this.attTrackingService.trackingAvailable();
                this.internalCurrentSettings = {
                    allowsTrackingIos: result
                };
            } else {
                this.userProfile = await this.loadTrackingInfo();
                this.internalCurrentSettings = this.userProfile.getCookieSettings();
            }
        }
        return CookieSettingsAdapter.allowsTracking(this.currentSettings);
    }

    /**
     * Depending on the user preferences for tracking it will prompt a popup to request the user to allow tracking.
     * The popup depends on the used platform.
     * @returns boolean which indicates if the user accpected the tracking request.
     */
    async requestTracking(): Promise<boolean> {
        if (!this.didPerformRequest) {
            await this.trackingAvailable();
            if (!CookieSettingsAdapter.allowsTracking(this.currentSettings)) {
                if (DeviceDetector.isOnIos()) {
                    const info = await this.getiOSTrackingInfo();
                    const result = await this.attTrackingService.requestTracking(info);
                    this.storePreferences({
                        allowsTrackingIos: result
                    });
                    return result;
                } else if (!DeviceDetector.isOnCordova()) {
                    this.displayRequest.next(void 0);
                } else {
                    this.didPerformRequest = true;
                }
            } else {
                this.didPerformRequest = true;
            }
        }
        return CookieSettingsAdapter.allowsTracking(this.currentSettings);
    }

    /**
     * Stores the settings of the user in the user profile.
     * @param settings settings that should be stored.
     */
    async storePreferences(settings: CookieSettings) {
        this.internalCurrentSettings = settings;
        this.didPerformRequest = true;
        const user = await this.loadTrackingInfo();
        const misc = user && user.misc ? user.misc : {};
        user.misc = CookieSettingsAdapter.updateUserMiscWith(settings, misc);
        try {
            this.userProfile = await this.userRestService.setUserProfile(user).toPromise();
            if (!DeviceDetector.isOnCordova()) {
                this.displayRequest.next(void 0);
            }
        } catch (error) {
            console.log(error);
        }
    }

    private onTrackingChanged() {
        const tracking: boolean = this.internalCurrentSettings.allowsTracking && !DeviceDetector.isOnCordova()
            || this.internalCurrentSettings.allowsTrackingIos && DeviceDetector.isOnIos()
            || DeviceDetector.isOnAndroid();
        const tag: TrackTag = {
            event: "trackingChanged",
            allowsAnalytics: this.internalCurrentSettings.allowsAnalytics || DeviceDetector.isOnCordova(),
            allowsTracking: tracking
        };
        this.gtmService.pushTag(tag);
    }

    private async getiOSTrackingInfo(): Promise<TrackingRequestInfo> {
        const translation = await this.translate.get(["trackingInfo"]).toPromise();
        const reasons: TrackingRequestReason[] = [
            {
                text: translation.trackingInfo.point1,
                image: "heart.fill",
                tintImage: true
            },
            {
                text: translation.trackingInfo.point2,
                image: "newspaper",
                tintImage: true
            },
            {
                text: translation.trackingInfo.point3,
                image: "hammer.fill",
                tintImage: true
            },
            {
                text: translation.trackingInfo.point4,
                image: "chevron.forward.2",
                tintImage: true
            }
        ];
        return {
            buttonTitle: translation.trackingInfo.nextBtn,
            title: translation.trackingInfo.title,
            text: translation.trackingInfo.text,
            subText: translation.trackingInfo.subtitle,
            reasons: reasons,
            primaryColor: "#4b5b45",
            secondaryColor: "#FFFFFF",
            onPrimaryColor: "#FFFFFF",
            onSecondaryColor: "#626262"
        };
    }

    /**
     * Resets all loaded informations. This is typical used for logout process.
     */
    public reset() {
        this.didPerformRequest = false;
        this.internalCurrentSettings = {};
    }
}
