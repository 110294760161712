<style>
    .cr_site{margin:0;padding:75px 0 0 0;text-align:center;background-color:#eeeeee;}
    .cr_font{font-size: 14px;font-family: Arial, sans-serif;}
    .cr_body h2, .cr_header h2{font-size:22px;line-height:28px;margin:0 0 10px 0;}
    .wrapper, .cr_page{margin:0 auto 10px auto;text-align:left;border-radius:4px;}
    .cr_header{text-align:center;background: transparent !Important;}
    .cr_body label{float:none;clear:both;display:block;width:auto;margin-top:8px;text-align:left;font-weight:bold;position:relative;}
    .cr_button{display:inline-block;font-family:'Helvetica', Arial, sans-serif;width:auto;white-space:nowrap;height:32px;margin:5px 5px 0 0;padding:0 22px;text-decoration:none;text-align:center;font-weight:bold;font-style:normal;font-size:15px;line-height:32px;cursor:pointer;border:0;-moz-border-radius:4px;border-radius:4px;-webkit-border-radius:4px;vertical-align:top;}
    .cr_button{background-color:#333;color:#ffffff;}
    .cr_button:hover,.cr_button-small:hover{opacity:0.7;filter:alpha(opacity=70);}
    .powered{padding:20px 0;width:560px;margin:0 auto;}
    .cr_ipe_item label{line-height:150%;font-size:14px;}
    .cr_ipe_item textarea {background: none repeat scroll 0 0 #eeeeee;border: 1px solid #aaa;font-family: Helvetica, sans-serif;font-size: 16px;}
    .cr_ipe_item input {background: none repeat scroll 0 0 #eeeeee;border: 1px solid #aaa;padding: 5px;font-family: Helvetica, sans-serif;font-size: 16px;}
    .cr_ipe_item select {background: none repeat scroll 0 0 #eeeeee;border: 1px solid #aaa;display: block;margin: 0;padding: 5px;width: 100%;font-family: Helvetica, sans-serif;font-size: 16px;}
    .cr_ipe_item input.cr_ipe_radio, input.cr_ipe_checkbox {-moz-binding: none;-moz-box-sizing: border-box;background-color: -moz-field !important;border: 2px inset threedface !important;color: -moz-fieldtext !important;cursor: default;height: 13px;padding: 0 !important;width: 13px;}
    .cr_ipe_item input.cr_ipe_radio{-moz-appearance: radio;border-radius: 100% 100% 100% 100% !important;margin: 3px 3px 0 5px;}
    .submit_container{text-align:center}
    .cr_ipe_item.inactive {display:none;}
    .imprint{font-size:0.8em;}
    .cr_captcha{padding-left:130px;}
    .cr_error{font-size:1.1em;padding:10px;}
    .clever_form_error{background-color:#f99; color:#000; border:1px solid #f22 !important}
    .clever_form_note {margin:26px 0 0 3px;position:absolute;display:inline; padding: 2px 4px; font-weight:bold;background-color:#f2ecb5; color:#000; font-size:12px !important;  }
    .cr_site {background-color:#eee;}
    .cr_header {color:#000000;}
    .cr_hr {background-color:#ccc;}
    .cr_site a {color:#0084ff;}
    .imprint{color:#000;}    
</style>
    
    
<style id="style">
    .cr_site {background-color:#4b5b45;}
    .cr_header {color:#4b5b45;}
    .cr_hr {background-color:#ccc;}
    .cr_site a {color:#4b5b45;}
    .imprint {color:#ffffff;}
    .cr_page {width:100%;}
    .cr_button {background-color:#4b5b45;}    
</style>
    
<app-toolbar [name]="'newsletter.title'" [leftBarButton]="menuBarButton"></app-toolbar>       
<div class="container-fluid container-overflow-scroll container-safe-area-bottom d-flex flex-column align-items-stretch">
    <div class="row justify-content-center">
        <div class="col-12 col-md-8 col-lg-5 px-3">
            <form *ngIf="!isWeb()" [formGroup]="newsletterForm" class="layout_form cr_form cr_font mt-3" rel="noopener noreferrer">
                <div class="cr_body cr_page cr_font formbox">
                    <div class="non_sortable" style="text-align:left;">                
                    </div>            
                    <div class="editable_content" style="text-align:left;">
                        <div id="5910349" rel="mce_text" class="cr_ipe_item ui-sortable">
                            <div class="mce_text">
                                <p style="text-align: center;" mce_style="text-align: center;">
                                    <span style="font-size: medium;" mce_style="font-size: medium;">
                                        <b>
                                            <span style="color: rgb(75, 91, 69);" mce_style="color: #4b5b45;">
                                                <span>{{ 'newsletter.message' | translate }}</span>
                                            </span>
                                        </b>
                                    </span>
                                </p>
                            </div>
                        </div>            
                        <div id="5910350" rel="email" class="ui-sortable musthave">
                            <mat-form-field class="doerrInput w-100 text-dark">
                                <mat-label>{{ 'abo.mail' | translate }}</mat-label>
                                <input matInput type="email" inputmode="email" placeholder="{{'newsletter.mailPlaceholder' | translate}}" name="email" formControlName="email" (blur)="onBlur()"/>
                                <mat-error>{{ 'shared.validMail' | translate }}</mat-error>
                            </mat-form-field>
                        </div>
                        <div id="5910353" rel="mce_text" class="cr_ipe_item ui-sortable">
                            <div class="mce_text">
                                <p style="text-align: center;">
                                    <span class="descriptionText" mce_style="font-size: xx-small;">                            
                                        <span style="color: rgb(75, 91, 69);" mce_style="color: #4b5b45;">{{'newsletter.datapolicy' | translate }}<a class="linkText" [routerLink]="['/datapolicy']">{{ 'registration.datapolicyLink' | translate }}</a></span>
                                    </span>
                                </p>
                            </div>
                        </div>            
                        <div id="5910355" rel="mce_text" class="cr_ipe_item ui-sortable">
                            <div class="descriptionText">
                                <p style="text-align: center;">
                                    <span>
                                        <span style="color: rgb(75, 91, 69);" mce_style="color: #4b5b45;">{{ 'newsletter.note' | translate }}</span>
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div id="5910354" rel="button" class="cr_ipe_item ui-sortable submit_container" style="text-align:center; margin-bottom:15px;">
                            <button class="signInButton" mat-raised-button color="primary" (click)="registerForNewsletter()" >{{'newsletter.signup' | translate }}</button>
                        </div>
                    </div>
                </div>
            </form>
            <form *ngIf="isWeb()" ngNoForm class="layout_form cr_form cr_font mt-3" action="{{cleverReachUrl}}" method="post" rel="noopener noreferrer">
                <div class="cr_body cr_page cr_font formbox">
                    <div class="non_sortable" style="text-align:left;">                
                    </div>            
                    <div class="editable_content" style="text-align:left;">
                        <div id="5910349" rel="mce_text" class="cr_ipe_item ui-sortable">
                            <div class="mce_text">
                                <p style="text-align: center;" mce_style="text-align: center;">
                                    <span style="font-size: medium;" mce_style="font-size: medium;">
                                        <b>
                                            <span style="color: rgb(75, 91, 69);" mce_style="color: #4b5b45;">
                                                <span>{{ 'newsletter.message' | translate }}</span>
                                            </span>
                                        </b>
                                    </span>
                                </p>
                            </div>
                        </div>            
                        <div id="5910350" rel="email" class="ui-sortable musthave">
                            <mat-form-field class="doerrInput w-100 text-dark">
                                <mat-label>{{ 'abo.mail' | translate }}</mat-label>
                                <input matInput type="email" inputmode="email" placeholder="{{'newsletter.mailPlaceholder' | translate}}" id="text5910350" name="email" value="" type="text" (blur)="onBlur()"/>
                                <mat-error>{{ 'shared.validMail' | translate }}</mat-error>
                            </mat-form-field>
                        </div>
                        <div id="5910353" rel="mce_text" class="cr_ipe_item ui-sortable">
                            <div class="mce_text">
                                <p style="text-align: center;">
                                    <span class="descriptionText" mce_style="font-size: xx-small;">                            
                                        <span style="color: rgb(75, 91, 69);" mce_style="color: #4b5b45;">{{'newsletter.datapolicy' | translate }}<a class="linkText" [routerLink]="['/datapolicy']">{{ 'registration.datapolicyLink' | translate }}</a></span>
                                    </span>
                                </p>
                            </div>
                        </div>            
                        <div id="5910355" rel="mce_text" class="cr_ipe_item ui-sortable">
                            <div class="descriptionText">
                                <p style="text-align: center;">
                                    <span>
                                        <span style="color: rgb(75, 91, 69);" mce_style="color: #4b5b45;">{{ 'newsletter.note' | translate }}</span>
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div id="5910354" rel="button" class="cr_ipe_item ui-sortable submit_container" style="text-align:center; margin-bottom:15px;">
                            <button type="submit" class="signInButton" mat-raised-button color="primary">{{'newsletter.signup' | translate }}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
