<app-toolbar [name]="title" [leftBarButton]="backBarButton" [rightBarButtons]="[rightBarButton]"></app-toolbar>
<div *ngIf="!(hasMails || hasOpenInvites || hasFriends)" class="pt-5 px-3 text-center">{{'camerafriends.addContact' | translate}}</div>
<div class="container-overflow-scroll">
    <div>
        <div *ngIf="hasMails">
            <mat-list>
                <mat-list-item *ngFor="let mail of mails" class="cursor-pointer" (click)="editFriend(mail, 0)">
                    <h3 mat-line>{{ mail.name }}</h3>            
                    <p mat-line>
                        <span class="descriptionInfo">{{ mail.email }}</span>
                    </p>
                    <mat-icon mat-list-icon>mail</mat-icon>
                    <button mat-icon-button>
                        <mat-icon class="mat-24 listIcon">chevron_right</mat-icon>
                    </button>
                </mat-list-item>
            </mat-list>
        </div>
        <div *ngIf="hasFriends">
            <mat-list>
                <h3 mat-subheader>{{'camerafriends.accepted' | translate}}</h3>
                <mat-list-item *ngFor="let friend of friends" class="cursor-pointer" (click)="editFriend(friend, 1)">
                    <h3 mat-line *ngIf="friend.user">{{ friend.user.firstname }} {{ friend.user.lastname }}</h3>            
                    <p mat-line>
                        <span class="descriptionInfo">{{ friend.email }}</span>
                    </p>
                    <mat-icon mat-list-icon>person</mat-icon>
                    <button mat-icon-button>
                        <mat-icon class="mat-24 listIcon">chevron_right</mat-icon>
                    </button>
                </mat-list-item>
            </mat-list>
        </div>
        <div *ngIf="hasOpenInvites">
            <mat-list>
                <h3 mat-subheader>{{'camerafriends.pending' | translate}}</h3>
                <mat-list-item *ngFor="let invite of openInvites" class="cursor-pointer" (click)="editFriend(invite, 2)">         
                    <p mat-line>
                        <span class="descriptionInfo">{{ invite.email }}</span>
                    </p>
                    <mat-icon mat-list-icon>person_outline</mat-icon>
                    <button mat-icon-button>
                        <mat-icon class="mat-24 listIcon">chevron_right</mat-icon>
                    </button>
                </mat-list-item>
            </mat-list>
        </div>
    </div>
</div>

