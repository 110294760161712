<app-toolbar [name]="'update.title'" [leftBarButton]="backBarButton"></app-toolbar>
<div class="container-overflow-scroll">
    <div class="container-fluid container-safe-area-bottom d-flex flex-column align-items-stretch minHeight">
        <div class="row justify-content-center">
            <div class="col-1 step-number mt-3 ">1</div>
            <div class="col-10 mt-3">
                <div class="text-center descriptionText">{{'update1.overviewTitle' | translate}}</div>
                <div class="text-center primTextColor font-weight-bold">{{'update1.overviewSubtitle' | translate}}</div>
                <div class="text-center pt-3 descriptionText">{{'update1.messageRemoveSD' | translate}}</div>
                <div class="text-center pt-3 descriptionText">{{'update1.messageInstallSD' | translate}}</div>
            </div>
            <div class="col-1 mt-3"></div>
            <div class="col-10 col-md-7 col-lg-5 col-xl-4 align-self-center my-3">
                <img class="w-100" src="assets/img/update_step_2@2x_de.jpg" srcset="assets/img/update_step_2@800w_de.jpg 800w, assets/img/update_step_2@1200w_de.jpg 1200w" alt="">
            </div>
        </div>
        <div class="row justify-content-center align-items-baseline">
            <div class="col-10 col-md-7 col-lg-5 col-xl-4">
                <img class="note-icon" src="assets/img/info_icon@2x.jpg" srcset="assets/img/info_icon@3x.jpg 800w" alt="info icon">
                <div class="text-center descriptionText">{{'update1.messageNoteFormatDisk' | translate}}</div>
            </div>
        </div>
        <div class="row justify-content-center mt-auto">
            <div class="col-12 col-md-6 col-lg-3 mb-3 py-1">
                <button class="signInButton" mat-raised-button color="primary" (click)="nextStep()">{{'shared.next' | translate }}</button>
            </div>
        </div>
    </div>
</div>
