import {NgModule} from "@angular/core";
import { MatButtonToggleModule} from "@angular/material/button-toggle";
import { MatButtonModule } from "@angular/material/button";
import { MatSliderModule } from "@angular/material/slider";
import { MatMenuModule } from "@angular/material/menu";
import { MatInputModule } from "@angular/material/input";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatRadioModule } from "@angular/material/radio";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatNativeDateModule } from "@angular/material/core";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSelectModule } from "@angular/material/select";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatListModule } from "@angular/material/list";
import { MatDividerModule } from "@angular/material/divider";
import { MatCardModule } from "@angular/material/card";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";


@NgModule({
  imports: [MatIconModule, MatButtonModule, MatInputModule, MatCheckboxModule, MatDialogModule,
    MatSidenavModule, MatToolbarModule, MatListModule, MatDividerModule, MatCardModule, MatGridListModule,
    MatSelectModule, MatSlideToggleModule, MatSliderModule, MatProgressSpinnerModule, MatMenuModule,
    MatDatepickerModule, MatNativeDateModule, MatRadioModule, MatProgressBarModule, MatButtonToggleModule],
  exports: [MatIconModule, MatButtonModule, MatInputModule, MatCheckboxModule, MatDialogModule,
    MatSidenavModule, MatToolbarModule, MatListModule, MatDividerModule, MatCardModule, MatGridListModule,
    MatSelectModule, MatSlideToggleModule, MatSliderModule, MatProgressSpinnerModule, MatMenuModule,
    MatDatepickerModule, MatNativeDateModule, MatRadioModule, MatProgressBarModule, MatButtonToggleModule]
})
export class MaterialModule {}
