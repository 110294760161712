declare var window: any, navigator: any, Connection: any;

export class DeviceDetector {

    public static isOnCordova(): boolean {
        return !!window.cordova;
    }

    public static isOnIos(): boolean {
        return window.cordova && window.cordova.platformId === "ios";
    }

    public static isOnAndroid(): boolean {
        return window.cordova && window.cordova.platformId === "android";
    }

    public static hasInternetConnection(): boolean {
        if (DeviceDetector.isOnCordova()) {
            const networkState = navigator.connection.type;
            if (networkState === Connection.NONE) {
                return false;
            }
        }
        return true;
    }
}
