import { DeviceDetector } from "./DeviceDetector";
import { Subject, Observable } from "rxjs";

declare var navigator: { globalization: { getPreferredLanguage: (successCallback: (locale: any) => void, errorCallback: (error: any) => void) => void; }; language: string, userLanguage: string };

export class LanguageDetector {

    public static async getDeviceLanguage(): Promise<string> {
        if ( !DeviceDetector.isOnCordova() ) {
            const lang = navigator.language || navigator.userLanguage;
            return lang.length > 2 ? lang.substring(0, 2) : lang;
        }

        return new Promise( (resolve, reject) => {
            const callback = () => {
                navigator.globalization.getPreferredLanguage(
                    (locale) => {
                        const lang = locale.value
                        resolve(lang.length > 2 ? lang.substring(0, 2) : lang);
                    },
                    (error) => {
                        reject(error);
                    }
                );
            };

            if (navigator.globalization) {
                callback();
            } else {
                document.addEventListener("deviceready", callback, false);
            }
        });
    }

    public static async getLanguage(): Promise<string> {
        if ( DeviceDetector.isOnCordova() ) {
            const result = await this.getMobileLanguage().toPromise();
            return this.validateISO(result);
        } else {
            const userLang = navigator.language || navigator.userLanguage;
            return(this.validateISO(userLang));
        }
    }

    private static getMobileLanguage(): Observable<string | null> {
        const langSub = new Subject<string>();
        navigator.globalization.getPreferredLanguage((locale) => {
            langSub.next(locale.value);
            langSub.complete();
        }, (error) => {
            console.log(error);
            langSub.next(null);
            langSub.complete();
        });
        return langSub.asObservable();
    }

    /**
     * If Language is in BCP-47 string will be convertes to ISO639-1.
     * @param locale string to validate
     */
    private static validateISO(locale: string): string {
        if ( locale.length > 2 ) {
            return this.validateAllowedLanguage(locale.substr(0, 2));
        } else {
            return this.validateAllowedLanguage(locale);
        }
    }

    private static validateAllowedLanguage(locale: string): string {
        if (locale === "de" || locale === "en") {
            return locale;
        } else {
            return "en";
        }
    }
}
