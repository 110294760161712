<div class="h4">{{'abos.apple.adoptSubscriptionLoginTitle'| translate}}</div>
<div class="h5 text-muted">{{'abos.apple.adoptSubscriptionLoginSubtitle'| translate}}</div>
<form [formGroup]="loginForm" (ngSubmit)="confirm()">
    <div class="inputContainer">
        <mat-form-field appearance="fill" class="doerrInput">
            <mat-icon matSuffix>account_circle</mat-icon>
            <input matInput type="email" placeholder="{{'shared.mailPlaceholder' | translate}}" formControlName="mail"
                (blur)="onBlur()">
            <mat-error>{{ 'shared.validMail' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="doerrInput">
            <mat-icon matSuffix>vpn_key</mat-icon>
            <input matInput type="password" placeholder="{{'shared.passwordPlaceholder' | translate}}"
                formControlName="password" [(ngModel)]="passwordValue" (blur)="onBlur()">
            <mat-error>{{ 'login.requiredPassword' | translate }}</mat-error>
        </mat-form-field>
    </div>
    <div class="w-100">
        <button class="mx-2" (click)="cancelButtonPressed()" mat-button
            color="primary">{{'shared.cancelButton' | translate }}</button>
        <button class="mx-2" mat-raised-button color="primary">{{'login.signIn' | translate }}</button>
    </div>
</form>