import { BreakpointObserver } from "@angular/cdk/layout";
import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CordovaInitHandler } from "src/app/native/CordovaInitHandler";
import { DialogService } from "src/app/services/DialogService";
import { NavigationService } from "src/app/services/NavigationService";
import { BaseNavComponent } from "../base/baseNav.component";
import { Location } from "@angular/common";

@Component({
    selector: "app-newsletterconfirm",
    templateUrl: "newsletterConfirm.component.html",
    styleUrls: ["newsletterConfirm.component.scss"]
})

export class NewsletterConfirmComponent extends BaseNavComponent {

    constructor(dialog: MatDialog, navigationService: NavigationService, location: Location, initHandler: CordovaInitHandler,
        breakpointObserver: BreakpointObserver, dialogService: DialogService) {
        super(dialog, navigationService, location, initHandler, breakpointObserver, dialogService);
    }
}
