import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { plainToClass } from "class-transformer";
import { Subject, Observable, BehaviorSubject } from "rxjs";

interface Answer {
    requestId: string;
    result: any;
}

class Message {
    object: any;
    action: string;
}

class Request extends Message {
    id: string;
    responseTo: string | undefined;
}

export class Code {
    value: string;
    type: string;
}

export interface ScannerInfo {
    title: string;
    infoText: string;
    button: ScannerButton;
}

export interface ScannerButton {
    title: string;
    tintColor: string;
    backgroundColor: string;
    roundedCorners: boolean;
}

export interface ScannerResult {
    title: string;
    successImg: string | null;
    imgTintColor: string;
}

export enum ScannerState {
    WILL_CLOSE,
    DID_CLOSE,
    DETECT_CODE
}

export interface ScannerMessage {
    state: ScannerState;
    object: any;
}

declare var window: { plugins: {barcodeScanner: {
    openScanner(title: string | undefined, callback: (result: any) => void, error: (result: any) => void),
    validationResult(answer: string | undefined, error: (result: any) => void),
    setInfoScreen(info: string | undefined, error: (result: any) => void),
    setResultScreen(resultScreen: string, error: (error: any) => void),
    setPermissionInfo(info: string | undefined, error: (result: any) => void);
}}};

@Injectable()
export class QRCodeScannerService {

    private updateCallSource = new Subject<ScannerMessage>();

    constructor(private translate: TranslateService) {}

    public subscribeUpdateChanged(): Observable<ScannerMessage> {
        return this.updateCallSource.asObservable();
    }

    public startScanningWith(title: string, info: ScannerInfo, permissionError: ScannerInfo) {
        this.prepareScanner(info, permissionError);
        window.plugins.barcodeScanner.openScanner(title, (response) => {
            const json = JSON.parse(response);
            const request = plainToClass(Request, json as any);
            if (request && request.id) {
                this.answer(request);
            } else {
                const message = plainToClass(Message, json as any);
                this.process(message);
            }
        }, (error) => {
            console.log(error);
        });
    }

    public process(message: Message) {
        switch (message.action) {
            case "didSelectCode":
                const code = message.object as Code;
                if (code) {
                    this.updateCallSource.next({
                        state: ScannerState.DETECT_CODE,
                        object: code
                    });
                } else {
                    console.log("Invalid Message");
                }
                break;
            case "willClose":
                this.updateCallSource.next({
                    state: ScannerState.WILL_CLOSE,
                    object: undefined
                });
                break;
            case "didClose":
                this.updateCallSource.next({
                    state: ScannerState.DID_CLOSE,
                    object: undefined
                });
                break;
            default:
                break;
        }
    }

    public answer(request: Request) {
        switch (request.action) {
            case "isValid":
                const valid = this.isValid(request.object.value);
                const answer: Answer = {
                    requestId: request.id,
                    result: valid
                };
                window.plugins.barcodeScanner.validationResult(JSON.stringify(answer), (error) => {
                    console.log(error);
                });
                break;
            case "resultScreen":
                this.setResult({
                    title: this.translate.instant("scanner.resultTitle"),
                    successImg: null,
                    imgTintColor: "#4b5b45"
                }, request);
                break;
            default:
                break;
        }
    }

    public prepareScanner(info: ScannerInfo, permissionError: ScannerInfo) {
        window.plugins.barcodeScanner.setInfoScreen(JSON.stringify(info), (error) => {
            console.log(error);
        });

        window.plugins.barcodeScanner.setPermissionInfo(JSON.stringify(permissionError), (error) => {
            console.log(error);
        });
    }

    private setResult(screen: ScannerResult, request: Request) {
        const answer: Answer = {
            requestId: request.id,
            result: screen
        };
        window.plugins.barcodeScanner.setResultScreen(JSON.stringify(answer), (error) => {
            console.log(error);
        });
    }

    private isValid(code: string): boolean {
        return code.length === 15 && code.match(/^[0-9]+$/) !== null;
    }
}
