import { animate, state, style, transition, trigger } from "@angular/animations";

export const OPACITY = trigger("opacity", [
    state("show", style({
      opacity: 1,
    })),
    state("hide", style({
      opacity: 0,
    })),
    transition("show => hide", [
      animate("0.5s ease")
    ]),
    transition("hide => show", [
      animate("0.5s ease")
    ]),
]);
