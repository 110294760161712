import { BrowserModule, HammerModule } from "@angular/platform-browser";
import { ErrorHandler, LOCALE_ID, NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app.routing";
import { SignInComponent } from "./components/login/signin.component";
import { MaterialModule } from "./modules/material.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateLoaderService } from "./services/TranslateLoaderService";
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { PasswordComponent } from "./components/password/password.component";
import { AuthRestService } from "./services/http/AuthRestService";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpErrorInterceptor } from "./api-handling/helpers/HttpErrorInterceptor";
import { LocationStrategy, HashLocationStrategy, registerLocaleData } from "@angular/common";
import { LocalStorageService } from "./services/LocalStorageService";
import { LoginGuard } from "./guards/LoginGuard";
import { HttpAccessInterceptor } from "./api-handling/helpers/HttpAccessInterceptor";
import { CordovaInitHandler } from "./native/CordovaInitHandler";
import { RegistrationComponent } from "./components/registration/registration.component";
import { DialogComponent } from "./components/base/dialog/dialog.component";
import { DeviceDetector } from "./helpers/DeviceDetector";
import { NavigationComponent } from "./components/navigation/navigation.component";
import { ImagesComponent } from "./components/images/images.component";
import { CamerasComponent } from "./components/cameras/cameras.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { InfoComponent } from "./components/info/info.component";
import { FirebaseService } from "./services/FirebaseService";
import { FirebaseRestService } from "./services/http/FirebaseRestService";
import { CameraRestService } from "./services/http/CameraRestService";
import { NavigationService } from "./services/NavigationService";
import { ToolbarComponent } from "./components/toolbar/toolbar.component";
import { NewCameraComponent } from "./components/newcamera/newcamera.component";
import { QRCodeScannerService } from "./native/QRCodeScannerService";
import { SettingsComponent } from "./components/settings/settings.component";
import { CameraSettingsComponent } from "./components/cameraSettings/cameraSettings.component";
import { NewSettingsComponent } from "./components/newSettings/newSettings.component";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { SpinnerDialogComponent } from "./components/base/spinnerDialog/spinnerDialog.component";
import { RegisterDataService } from "./services/RegisterDatatService";
import { CameraDetailComponent } from "./components/cameraDetail/cameraDetail.component";
import { CameraFriendsComponent } from "./components/cameraFriends/cameraFriends.component";
import { ContactDetailComponent } from "./components/contactDetail/contactDetail.component";
import { ContactRestService } from "./services/http/ContactRestService";
import { ImageRestService } from "./services/http/ImageRestService";
import { ImageDetailComponent } from "./components/imageDetail/imageDetail.component";
import { ContactService } from "./native/ContactService";
import { ContactAppComponent } from "./components/contactApp/contactApp.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { ImageRepository } from "./repositories/ImageRepository";
import { ShareService } from "./services/ShareService";
import { ImageFilterService } from "./services/ImageFilterService";
import { SettingsService } from "./native/SettingsService";
import { PaypalRestService } from "./services/http/PaypalRestService";
import { SafePipe } from "./pipes/SafePipe";
import { PaypalPaymentComponent } from "./components/paypalPayment/paypalPayment.component";
import { UserRestService } from "./services/http/UserRestService";
import { PayDialogComponent } from "./components/profile/components/paydialog/paydialog.component";
import { JSONService } from "./services/JSONService";
import { RouteReuseStrategy } from "@angular/router";
import { CustomRouteReuseStrategy } from "./CustomRouteReuseStrategy";
import { ImageDialogComponent } from "./components/base/imageDialog/imageDialog.component";
import { DatabaseService } from "./native/DatabaseService";
import { FileService } from "./native/FileService";
import { ImageSyncService } from "./services/ImageSycnService";
import { ImageLoadingService } from "./services/ImageLoadingService";
import { ConnectionService } from "./native/ConnectionService";
import { UpdateComponent } from "./components/update/update.component";
import { DialogService } from "./services/DialogService";
import { UpdateStep1Component } from "./components/updateStep1/updateStep1.component";
import { UpdateStep2Component } from "./components/updateStep2/updateStep2.component";
import { FirmwareRestService } from "./services/http/FirmwareRestService";
import { UpdateStep3Component } from "./components/updateStep3/updateStep3.component";
import { InAppPaymentService } from "./native/InAppPaymentService";
import { InAppQuotaDialogComponent } from "./components/profile/components/inappquotadialog/inappquotadialog.component";
import { AdoptSubscriptionsDialogComponent } from "./components/profile/components/adoptsubscriptionsdialog/adoptsubscriptionsdialog.component";
import { AppleSubscriptionRestService } from "./services/http/AppleSubscriptionRestService";
import { CameraSmtpsComponent } from "./components/cameraSmtps/cameraSmtps.component";
import { CameraSmtpReceiverRestService } from "./services/http/CameraSmtpReceiverRestService";
import { CameraSmtpDetailComponent } from "./components/cameraSmtpDetail/cameraSmtpDetail.component";
import { AppInfoService } from "./native/AppInfoService";
import { CameraListItemComponent } from "./components/cameraListItem/cameraListItem.component";
import { CameraHdListComponent } from "./components/cameraHdList/cameraHdList.component";
import { AppRateService } from "./native/AppRatingService";
import { StolenFormularComponent } from "./components/cameraDetail/stolenFormular/stolenFormular.component";
import { NewsletterComponent } from "./components/newsletter/newsletter.component";
import { NewsletterConfirmComponent } from "./components/newsletter/newsletterConfirm.component";
import { CleverReachService } from "./services/http/CleverReachRestService";
import { StolenRestService } from "./services/http/StolenRestService";
import { environment } from "../environments/environment";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { SentryErrorHandler } from "./services/errorHandler/SentryErrorHandler";
import { ATTTrackingService } from "./native/ATTTrackingService";
import { AssetService } from "./services/AssetService";
import { TrackingService } from "./services/TrackingService";
import { CookieComponent } from "./components/cookie/cookie.component";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { ImageModule } from "./modules/imageModule/image.module";
import localeDe from "@angular/common/locales/de";
import localeDeExtra from "@angular/common/locales/extra/de";
import { AccountDeleteComponent } from "./components/profile/components/accountDelete/accountDelete.component";
import { ImageListComponent } from "./components/images/components/imageList/imageList.component";
import { ImageFilterComponent } from "./components/images/components/imageFilter/imageFilter.component";
import { ImageProcessService } from "./services/ImageProcessService";
import { HttAppInformationInterceptor } from "./api-handling/helpers/HttpAppInformationInterceptor";

export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: HttpAccessInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    // Comment next line to simulate old hybrid app
    { provide: HTTP_INTERCEPTORS, useClass: HttAppInformationInterceptor, multi: true }
];

registerLocaleData(localeDe, "de-DE", localeDeExtra);

@NgModule({
    declarations: [
        AppComponent,
        SignInComponent,
        PasswordComponent,
        RegistrationComponent,
        DialogComponent,
        SpinnerDialogComponent,
        ImageDialogComponent,
        NavigationComponent,
        ImagesComponent,
        ImageDetailComponent,
        CamerasComponent,
        ProfileComponent,
        InfoComponent,
        ToolbarComponent,
        NewCameraComponent,
        SettingsComponent,
        CameraSettingsComponent,
        NewSettingsComponent,
        CameraDetailComponent,
        CameraFriendsComponent,
        CameraSmtpsComponent,
        CameraSmtpDetailComponent,
        ContactDetailComponent,
        ContactAppComponent,
        SafePipe,
        PaypalPaymentComponent,
        PayDialogComponent,
        UpdateComponent,
        UpdateStep1Component,
        UpdateStep2Component,
        UpdateStep3Component,
        InAppQuotaDialogComponent,
        AdoptSubscriptionsDialogComponent,
        CameraListItemComponent,
        CameraHdListComponent,
        StolenFormularComponent,
        NewsletterComponent,
        NewsletterConfirmComponent,
        CookieComponent,
        AccountDeleteComponent,
        ImageListComponent,
        ImageFilterComponent
    ],
    imports: [
        BrowserModule,
        MaterialModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        TimepickerModule.forRoot(),
        InfiniteScrollModule,
        HammerModule,
        TooltipModule,
        ImageModule
    ],
    providers: [
        { provide: LOCALE_ID, useValue: "de-DE" },
        LoginGuard,
        CordovaInitHandler,
        {
            provide: TranslateLoader,
            useFactory: TranslateLoaderService,
            deps: [HttpClient]
        },
        {
            provide: ErrorHandler,
            useClass: SentryErrorHandler,
        },
        AuthRestService,
        httpInterceptorProviders,
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        {
            provide: RouteReuseStrategy,
            useClass: CustomRouteReuseStrategy
        },
        {
            provide: "googleTagManagerId",
            useValue: environment.googleTagManagerId
        },
        GoogleTagManagerService,
        LocalStorageService,
        FirebaseService,
        FirebaseRestService,
        CameraRestService,
        NavigationService,
        QRCodeScannerService,
        RegisterDataService,
        ContactRestService,
        ImageRestService,
        ImageLoadingService,
        ContactService,
        DatabaseService,
        FileService,
        ImageRepository,
        ImageSyncService,
        ShareService,
        ImageFilterService,
        SettingsService,
        PaypalRestService,
        UserRestService,
        JSONService,
        ConnectionService,
        DialogService,
        FirmwareRestService,
        InAppPaymentService,
        AppleSubscriptionRestService,
        CameraSmtpReceiverRestService,
        AppInfoService,
        AppRateService,
        CleverReachService,
        StolenRestService,
        ATTTrackingService,
        AssetService,
        TrackingService,
        ImageProcessService
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
    constructor(private cordovaInitHandler: CordovaInitHandler) {
        if (DeviceDetector.isOnCordova()) {
            this.cordovaInitHandler.init();
        }
    }
}
