<mat-sidenav-container class="sidenav-container component-background">
    <mat-sidenav #drawer class="sidenav d-flex flex-column justify-content-between" fixedInViewport
        [attr.role]="isMenuOpenDefault ? 'dialog' : 'navigation'"
        [mode]="menuMode"
        [opened]="!isMenuOpenDefault">

        <div>
            <mat-toolbar>
                <a href="https://www.doerr-outdoor.de" target="_blank" rel="noopener noreferrer" style="outline: none;">
                    <img style="width: 50%;" src="assets/img/doerr-outdoor-Logo.png" alt="Doerr company logo">
                </a>
            </mat-toolbar>
            <mat-toolbar>{{'navigation.menu' | translate}}</mat-toolbar>
            <mat-divider></mat-divider>
            <mat-nav-list>            
                <a mat-list-item (click)="showRoute('images')" [ngClass]="{selectedItem: selected === 'images'}">
                    <mat-icon mat-list-icon *ngIf="selected === 'images'" color="primary">insert_photo</mat-icon>
                    <mat-icon mat-list-icon *ngIf="selected !== 'images'" class="deselectedItemContent">insert_photo</mat-icon>
                    <span mat-list-text [ngClass]="{'selectedItemContent': selected === 'images', 'deselectedItemContent': selected !== 'images'}" class="selectedItemContent">{{ 'navigation.images' | translate}}</span>
                </a>
                <a mat-list-item (click)="showRoute('favourites')" [ngClass]="{selectedItem: selected === 'favourites'}">
                    <mat-icon mat-list-icon *ngIf="selected === 'favourites'" color="primary">grade</mat-icon>
                    <mat-icon mat-list-icon *ngIf="selected !== 'favourites'" class="deselectedItemContent">grade</mat-icon>
                    <span mat-list-text [ngClass]="{'selectedItemContent': selected === 'favourites', 'deselectedItemContent': selected !== 'favourites'}" class="selectedItemContent">{{ 'navigation.favourites' | translate}}</span>
                </a>
                <a mat-list-item (click)="showRoute('cameras')" [ngClass]="{selectedItem: selected === 'cameras'}">
                    <mat-icon mat-list-icon *ngIf="selected === 'cameras'" color="primary">camera_alt</mat-icon>
                    <mat-icon mat-list-icon *ngIf="selected !== 'cameras'" class="deselectedItemContent">camera_alt</mat-icon>
                    <span mat-list-text [ngClass]="{'selectedItemContent': selected === 'cameras', 'deselectedItemContent': selected !== 'cameras'}" class="selectedItemContent">{{ 'navigation.cameras' | translate}}</span>
                </a>
                <a mat-list-item (click)="showRoute('abo')" [ngClass]="{selectedItem: selected === 'abo'}">
                    <mat-icon mat-list-icon *ngIf="selected === 'abo'" color="primary">account_circle</mat-icon>
                    <mat-icon mat-list-icon *ngIf="selected !== 'abo'" class="deselectedItemContent">account_circle</mat-icon>
                    <span mat-list-text [ngClass]="{'selectedItemContent': selected === 'abo', 'deselectedItemContent': selected !== 'abo'}" class="selectedItemContent">{{ 'navigation.abo' | translate}}</span>
                </a>
                <a mat-list-item href="https://www.doerr-outdoor.de" target="_blank" rel="noopener noreferrer">
                    <mat-icon mat-list-icon *ngIf="selected === 'store'" color="primary">store</mat-icon>
                    <mat-icon mat-list-icon *ngIf="selected !== 'store'" class="deselectedItemContent">store</mat-icon>
                    <span mat-list-text [ngClass]="{'selectedItemContent': selected === 'store', 'deselectedItemContent': selected !== 'store'}" class="selectedItemContent">{{ 'navigation.store' | translate}}</span>
                </a>
                <a mat-list-item (click)="showRoute('newsletter')" [ngClass]="{selectedItem: selected === 'newsletter'}">
                    <mat-icon mat-list-icon *ngIf="selected === 'newsletter'" color="primary">mail</mat-icon>
                    <mat-icon mat-list-icon *ngIf="selected !== 'newsletter'" class="deselectedItemContent">mail</mat-icon>
                    <span mat-list-text [ngClass]="{'selectedItemContent': selected === 'newsletter', 'deselectedItemContent': selected !== 'newsletter'}" class="selectedItemContent">{{ 'navigation.newsletter' | translate}}</span>
                </a>
                <a mat-list-item (click)="showRoute('imprint')" [ngClass]="{selectedItem: selected === 'imprint'}">
                    <mat-icon mat-list-icon *ngIf="selected === 'imprint'" color="primary">info</mat-icon>
                    <mat-icon mat-list-icon *ngIf="selected !== 'imprint'" class="deselectedItemContent">info</mat-icon>
                    <span mat-list-text [ngClass]="{'selectedItemContent': selected === 'imprint', 'deselectedItemContent': selected !== 'imprint'}" class="selectedItemContent">{{ 'navigation.imprint' | translate}}</span>
                </a>
                <a mat-list-item (click)="showRoute('datapolicy')" [ngClass]="{selectedItem: selected === 'datapolicy'}">
                    <mat-icon mat-list-icon *ngIf="selected === 'datapolicy'" color="primary">assignment</mat-icon>
                    <mat-icon mat-list-icon *ngIf="selected !== 'datapolicy'" class="deselectedItemContent">assignment</mat-icon>
                    <span mat-list-text [ngClass]="{'selectedItemContent': selected === 'datapolicy', 'deselectedItemContent': selected !== 'datapolicy'}" class="selectedItemContent">{{ 'navigation.datapolicy' | translate}}</span>
                </a>
                <a mat-list-item (click)="signOut()">
                    <mat-icon mat-list-icon *ngIf="selected === 'signout'" color="primary">exit_to_app</mat-icon>
                    <mat-icon mat-list-icon *ngIf="selected !== 'signout'" class="deselectedItemContent">exit_to_app</mat-icon>
                    <span mat-list-text [ngClass]="{'selectedItemContent': selected === 'signout', 'deselectedItemContent': selected !== 'signout'}" class="selectedItemContent">{{ 'navigation.signout' | translate}}</span>
                </a>
            </mat-nav-list>
        </div>
        
        <h6 class="p-2 pl-4 smaller-text text-muted">{{ 'navigation.appVersionLabel' | translate }} {{ appVersion$ | async }}</h6>
    </mat-sidenav>
    <mat-sidenav-content [@routeAnimations]="animation" class="overflow-hidden component-background">  
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
<app-cookie *ngIf="displayCookieBanner"></app-cookie>
