import { Subject } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable()
export class NavigationService {
    constructor() { }

    private emitChangeSource = new Subject<any>();

    changeEmitted$ = this.emitChangeSource.asObservable();

    emitChange() {
        this.emitChangeSource.next(void 0);
    }
}
