import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Location } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { CordovaInitHandler } from "src/app/native/CordovaInitHandler";
import { DialogService } from "src/app/services/DialogService";
import { CameraRestService } from "src/app/services/http/CameraRestService";
import { NavigationService } from "src/app/services/NavigationService";
import { BaseNavComponent } from "../base/baseNav.component";
import { CameraListItem, CameraListItemButton, CameraListItemButtonType, hybridAllowedProductTypes } from "../cameraListItem/models/cameraListItem";
import { CameraResponseModel } from "src/app/api-handling/models/CameraResponseModel";
import { ActivatedRoute, Router } from "@angular/router";
import { first } from "rxjs";

@Component({
    selector: "app-camerahdlist",
    templateUrl: "cameraHdList.component.html",
    styleUrls: ["cameraHdList.component.scss"]
})

export class CameraHdListComponent extends BaseNavComponent implements OnInit {

    public userCameraListItemHeaders: CameraListItem[] = [];
    private cameras: CameraResponseModel[] = [];
    private userMadeChanges = false;
    public title: string = "cameraHdList.title";
    public columns = 1;

    constructor(breakpointObserver: BreakpointObserver, private readonly cameraRestService: CameraRestService, dialog: MatDialog, navigationService: NavigationService,
        location: Location, initHandler: CordovaInitHandler, private readonly translate: TranslateService, private router: Router,
        dialogService: DialogService, private readonly activatedRoute: ActivatedRoute) {
        super(dialog, navigationService, location, initHandler, breakpointObserver, dialogService);
        this.loadCameras();
    }

    ngOnInit() {
        this.columns = this.breakpointObserver.isMatched(Breakpoints.Handset) ? 1 : 2;
        this.breakpointObserver.observe(Breakpoints.Small).subscribe((result) => {
            if (result.matches) {
                this.columns = 1;
            }
        });
        this.breakpointObserver.observe(Breakpoints.Medium).subscribe((result) => {
            if (result.matches) {
                this.columns = 2;
            }
        });
        this.breakpointObserver.observe(Breakpoints.Large).subscribe((result) => {
            if (result.matches) {
                this.columns = 3;
            }
        });
    }

    updateOnResume() {
        this.loadCameras();
    }

    private loadCameras() {
        if (this.isOnline()) {
            this.subscriptions.push(this.translate.get("cameras.load").subscribe((translation) => {
                this.showSpinner(translation, "");
                const cameraSubscription = this.cameraRestService.getCameraList();
                if ( cameraSubscription ) {
                    this.subscriptions.push(cameraSubscription.subscribe((result) => {
                        this.userCameraListItemHeaders = [];
                        this.cameras = [];
                        for (const camera of result) {
                            if (camera.isOwner) {
                                this.cameras.push(camera);
                            }
                        }
                        this.updateCameraItems();
                        this.closeSpinner();
                    }));
                } else {
                    this.closeSpinner();
                }
            }));
        }
    }

    public onCameraClicked(itemBtn: CameraListItemButton) {
        if (itemBtn.info) {
            const camera = this.getCameraFor(itemBtn.info);
            if (camera) {
                const patch = new CameraResponseModel();
                patch.doNotSendHighRes = !camera.doNotSendHighRes;
                patch._id = camera._id;
                patch.version = camera.version;
                this.patchCamera(patch);
            }
        }
    }

    private getCameraFor(id: string): CameraResponseModel | undefined {
        return this.cameras.find((c) => c._id === id);
    }

    private updateCameraItems() {
        this.userCameraListItemHeaders = [];
        for (const camera of this.cameras) {
            const highRes = camera.isHighResCamera();
            this.userCameraListItemHeaders.push({
                id: camera._id,
                title: camera.name,
                subtitle: highRes ? this.translate.instant("cameraHdList.cameraSendsHighres") : this.translate.instant("cameraHdList.cameraSendsNotHighres"),
                showBattery: false,
                actions: [{
                    id: 0,
                    type: CameraListItemButtonType.toggle,
                    titles: ["cameraHdList.setHighRes", "cameraHdList.setDefault"],
                    value: !camera.doNotSendHighRes,
                    color: "primary",
                    info: camera._id
                }],
                supportedProduct: hybridAllowedProductTypes.includes(camera.productType)
            });
        }
    }

    private patchCamera(patch: CameraResponseModel) {
        this.showSpinner(this.translate.instant("cameraHdList.updateHD"), "");
        this.userMadeChanges = true;
        this.subscriptions.push(this.cameraRestService.patchCamera(patch).subscribe((resultCam) => {

            Object.keys(patch).forEach( key => {
                const camera = this.getCameraFor(patch._id);
                if (camera) {
                    camera[key] = resultCam[key];
                }
            });
            this.updateCameraItems();
            this.closeSpinner();
        }, (err) => {
            this.closeSpinner();
        }));
    }

    async goBackButtonPressed() {
        const params = await this.activatedRoute.queryParams.pipe(first()).toPromise();
        if (this.userMadeChanges) {
            this.subscriptions.push(this.openDialog(this.translate.instant("cameraHdList.syncAlertTitle"), this.translate.instant("cameraHdList.syncAlertMsg"), null).subscribe(() => {
                if (params && params.backAbo) {
                    this.router.navigate(["home/abo"]);
                } else {
                    super.goBackButtonPressed();
                }
            }));
        } else {
            if (params && params.backAbo) {
                this.router.navigate(["home/abo"]);
            } else {
                super.goBackButtonPressed();
            }
        }
    }
}
